// import React from 'react';
// import '../assets/css/about.css'
// import Navbar from './Navbar';
// import topimage from '../assets/images/profile/uppper-side.svg'
// import tushar from '../assets/images/profile/tushar.svg'
// import about1 from '../assets/images/profile/about1.svg'
// import about2 from '../assets/images/profile/about2.svg'
// import about3 from '../assets/images/profile/about3.svg'

// const Aboutus = () => {
//   return (
//     <>
//       <Navbar />
//       <div className="page-container">
//         <div className="header2">
//           <h1>Pioneering solutions that</h1>
//           <h1>redefine the legal landscape</h1>
//           <img src={topimage} className="top-image" alt="Top Image" />
//         </div>
//         <div className="content1">
//           <div className="story-section">
//             <h2> <span style={{ color: '#5d54f1' }}>Our </span>Story</h2>
//             <p>CaseMinister was founded on the belief that justice should
//               be a right for everyone, not just a privilege for a few. By
//               dismantling the barriers within the legal system, we're
//               making legal support more  <span style={{ fontWeight: '700' }}>transparent, efficient, and fair.</span></p>
//           </div>
//           <div className="timeline">
//             <div className="timeline-item">
//               <div className="dot"></div>
//               <div className="text">
//                 <h3><span style={{ fontWeight: '800' }}> 2023</span> Founded</h3>
//                 <p>Founder: Tushar Atri</p>
//                 {/* <img src={about1} alt="" /> */}
//                 <p>Vision: Democratize access to justice through AI</p>
//               </div>
//             </div>
//             <div className="timeline-item">
//               <div className="dot"></div>
//               <div className="text">
//                 {/* <img src={about2} alt="" /> */}
//                 <h3> <span style={{ fontWeight: '800' }}>100k+</span> Lawyers</h3>
//                 <p>Endorsed by Leading Bar Council and Associations Across India</p>
//               </div>
//             </div>
//             <div className="timeline-item">
//               <div className="dot"></div>
//               <div className="text">
//                 {/* <img src={about3} alt="" /> */}
//                 <h3><span style={{ fontWeight: '800' }}> 300k+</span> Lawyers</h3>
//                 <p>Endorsed by Leading Bar Council and Associations Across India</p>
//               </div>
//             </div>
//             <svg className="graph-line" viewBox="0 0 100 100" preserveAspectRatio="none">
//               <path d="M0,100 Q30,90 50,50 T100,0" vectorEffect="non-scaling-stroke" />
//             </svg>
//           </div>
//           <div className="mission-section" style={{ position: 'relative' }}>
//             <h2>Our Mission</h2>
//             <blockquote>
//               <span style={{
//                 fontSize: '3em',
//                 fontWeight: 'bold',
//                 verticalAlign: 'top',
//                 position: 'absolute',
//                 top: '25%',
//                 left: '20%'
//               }}>“</span>
//               Empowering legal professionals, businesses, and individuals
//               with innovative AI-powered solutions to transform the Indian
//               Justice Delivery System
//               <span style={{
//                 fontSize: '3em',
//                 fontWeight: 'bold',
//                 verticalAlign: 'bottom',
//                 position: 'absolute',
//                 right: '35%',
//                 top: '60%',

//               }}>”</span>
//             </blockquote>
//           </div>
//         </div>
//         <div className="founder-section">
//           <div className="about-text-content">
//             <h2><span style={{ color: '#5d54f1' }}>Our</span> Founder</h2>
//             <p>Tushar Atri began his career in <strong>litigation and in-house legal roles</strong> across startups and companies before transitioning to legal tech at a multinational corporation. Seeking to broaden his expertise, he earned a master's degree from the <strong>University of Exeter</strong>, gaining international experience. After working in the UK, Tushar returned to India to leverage his global insights and legal experience to create <a href="#">Advanced Technology and Research Innovation Group’s legal tech platform—CaseMinister</a>.</p>
//           </div>
//           <div className="image-content">
//             <img src={tushar} alt="Tushar Atri at University of Exeter" />
//           </div>
//         </div>
//       </div>
//     </>

//   );
// };

// export default Aboutus;

import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

import OurStory from "../assets/images/aboutus/OurStory.png";
import OurMission from "../assets/images/aboutus/OurMission.png";
// import ProfileAvatar from "../assets/images/aboutus/ProfileAvatar.png";

// import { BsLinkedin } from "react-icons/bs";

const Aboutus = () => {
  return (
    <div className="pt-16 bg-light-600 dark:bg-dark-600">
      {/* Header */}
      <div>
        <div className="bg-dark-600 dark:bg-dark-400 py-32 flex items-center flex-col gap-10">
          <h1 className="text-gray-200  text-center text-6xl font-semibold w-2/3 mx-auto leading-[1.2]">
            Pioneering solutions{" "}
            <span className="text-gray-400 ">that redefine the</span> legal
            landscape
          </h1>
          <Link to="/contactus">
            <button className="max-sm:text-xs px-5 py-2  font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm transition duration-200">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
      {/* Story Section */}
      <div className="px-28 max-md:px-4 py-20 flex flex-col gap-24 dark:bg-dark-600">
        <div className="flex gap-20 bg-[#fefefe] dark:bg-dark-400 pr-10 max-md:pr-0 max-md:pb-4 max-md:flex-col">
          <img src={OurStory} alt="our story" />
          <div className="flex flex-col gap-6 justify-center ">
            <h3 className="text-gray-700 font-black text-3xl dark:text-gray-300">
              Our Story
            </h3>
            <p className="text-gray-700 font-roboto leading-[40px] text-xl tracking-wide dark:text-gray-300">
              CaseMinister was founded on the belief that justice should be a
              right for everyone, not just a privilege for a few. By dismantling
              the barriers within the legal system, we're making legal support
              more 
              <span className="font-semibold">
                transparent, efficient, and fair.
              </span>
            </p>
          </div>
        </div>
        <div className="flex gap-24 bg-[#fefefe] dark:bg-dark-400 pl-10 max-md:pl-0 max-md:pb-4 max-md:flex-col">
          <div className="flex flex-col gap-6 justify-center max-md:order-2">
            <h3 className="text-gray-700 font-black text-3xl dark:text-gray-300">
              Our Mission
            </h3>
            <p className="text-gray-700 font-roboto leading-[40px] text-xl tracking-wide dark:text-gray-300">
              Empowering legal professionals, businesses, and individuals with
              innovative AI-powered solutions to transform the{" "}
              <span className="font-semibold">
                Indian Justice Delivery System.
              </span>
            </p>
          </div>
          <img src={OurMission} alt="Our mission" className="max-md:order-1" />
        </div>
      </div>
      {/* Founder Section */}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Aboutus;
