import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "../assets/css/mycases.css";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import NonPrivilegedPage from "./NonPrivilegedPage";
import CourtSelection from "./CourtSelection";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const MyCases = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [privilegeData, setPrivilegeData] = useState(null);
  const [error, setError] = useState(null);
  const access_token = sessionStorage.getItem("access_token");

  useEffect(() => {
    const fetchPrivileges = async () => {
      try {
        const response = await axiosInstance.get("casedetail/privileges/", {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        const privileges = response.data.length
          ? response.data[0].privileges
          : false;
        setPrivilegeData(privileges);
      } catch (err) {
        console.error("Failed to load privileges:", err);
        setError("Failed to load privileges.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrivileges();
  }, [access_token]);

  if (isLoading) {
    return (
      <div className="loading">
        {/* <Navbar /> */}
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="dark:bg-dark-600"
        >
          <ClipLoader color="#ffffff" size={150} css={override} />
          <p
            style={{
              color: "#ffffff",
              marginTop: 20,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Loading case details...
            <br />
            Please wait as we retrieve the latest information.
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        {/* <Navbar /> */}
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
          }}
          className="dark:bg-dark-600"
        >
          <p>Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <Navbar /> */}
      <CourtSelection />
    </>
  );
};

export default MyCases;
