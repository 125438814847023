// SecondaryNavbar.js

import React from "react";
import { Link } from "react-router-dom";

const SecondaryNavbar = () => {
  return (
    <nav className="navbar navbar-light bg-light">
      <div className="">
        <ul className="navbar-nav d-flex flex-row" style={{ width: "100%" }}>
          <li className="nav-item flex-fill">
            <Link to="/">
              <a className="nav-link" href="/">
                Home
              </a>
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link to="/about">
              <a className="nav-link" href="/">
                About
              </a>
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link to="/chatbot">
              <a className="nav-link" href="/chatbot">
                Chatbot
              </a>
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link to="/chatbot">
              <a className="nav-link" href="/chatbot">
              MyCases
              </a>
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <a className="nav-link" href="/case-iq">
              Case IQ
            </a>
          </li>
          <li className="nav-item flex-fill">
            <a className="nav-link" href="/find-me-lawyer">
              Find Me a Lawyer
            </a>
          </li>
          <li className="nav-item flex-fill">
            <a className="nav-link" href="/doc">
              Doc
            </a>
          </li>
          <li className="nav-item flex-fill">
            <a className="nav-link" href="/message">
              Message
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SecondaryNavbar;
