import React from "react";

import { useOutletContext } from "react-router-dom";

const CaseSummary = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  // Formatting Text According To Markdown.
  const formatText = (text) => {
    if (!text) return null;

    const lines = text.split("\n");

    return lines
      .map((line, index) => {
        if (!line.trim()) return null;

        // Handle headings
        const headingMatch = line.match(/^(#+)\s+(.*)/);
        if (headingMatch) {
          //   const level = headingMatch[1].length;
          const content = headingMatch[2].replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <strong className="text-2xl" key={index}>
              {content}
            </strong>
          );
        }

        const numberedMatch = line.match(/^(\d+\.\s+)\*\*(.*?)\*\*$/);
        if (numberedMatch) {
          const numberPart = numberedMatch[1]; // e.g., "1. "
          const content = numberedMatch[2].trim(); // Get the text after ** and remove it
          return (
            <div key={index}>
              {numberPart}
              <strong>{content}</strong>
            </div>
          );
        }

        // Handle list items with bold text
        if (line.startsWith("-**")) {
          const content = line.substring(3, line.length - 2).trim();
          return (
            <ul key={index}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("-**")) {
          const content = line.substring(8).trim();
          return (
            <ul key={index}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("   - **")) {
          const content = line.substring(7).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("  - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("   - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }

        if (line.startsWith("     - ")) {
          const content = line.substring(6).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }

        // Handle regular list items
        if (line.startsWith("-")) {
          const cleanLine = line.substring(1).trim();
          const contentWithoutBold = cleanLine.replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <p key={index} style={{ marginLeft: "10px" }}>
              <li>{contentWithoutBold}</li>
            </p>
          );
        }

        if (line.startsWith("**") && line.endsWith("**")) {
          const cleanLine = line.substring(2, line.length - 2).trim(); // Remove the first and last two asterisks
          return (
            <strong key={index}>
              <p>{cleanLine}</p>
            </strong>
          );
        }

        // Handle bold text within a paragraph
        const parts = line
          .replace(/\*\*$/, "")
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          .split(/(<strong>.*?<\/strong>)/)
          .map((part, idx) => {
            if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
              const cleanText = part.substring(8, part.length - 9);
              return <strong key={idx}>{cleanText}</strong>;
            } else {
              return <span key={idx}>{part}</span>;
            }
          });

        return (
          <p key={index} style={{ textAlign: "justify" }}>
            {parts}
          </p>
        );
      })
      .filter((component) => component !== null);
  };

  return (
    <div className="flex flex-col gap-4 px-6 pb-6 border-b border-b-[#d9d9d9] dark:border-b-gray-600">
      {caseDetails?.complete_summary
        ? formatText(caseDetails.complete_summary)
        : "No Case Summary Found."}
    </div>
  );
};

export default CaseSummary;
