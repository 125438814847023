import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axiosInstance";
import PanjabHarayanaCasedetail from "./PanjabHarayanaCasedetail";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backimg from "../assets/images/mycases/back-image.svg";
import unselected from "../assets/images/mycases/unselected-for-arrow.svg";
import unselecteddelete from "../assets/images/mycases/unselected-delete.svg";
import infoimage from "../assets/images/mycases/info-image.svg";
import search from "../assets/images/mycases/search-vector.svg";
import bottomimage from "../assets/images/mycases/bottom-add-case.svg";
import backbutton from "../assets/images/mycases/back-button.svg";
import { useCaseContext } from "../context/CaseContext";
import mobilePriviledge from "../assets/images/mycases/mobilePriviledge.svg";
import { FaTimes } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../assets/css/supremecourt.css";
import supreme_court_illustration from "../assets/images/supreme_court/supreme_court_illustration.svg";
import supreme_court_illustration2 from "../assets/images/supreme_court/supreme_court_illustration2.svg";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const PanjabAndHaraynaHC = () => {
  const { selectPanjabCourtCase, setSelectPanjabCourtCase } = useCaseContext();
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadingCases, setIsLoadingCases] = useState(true);
  const [showAddCase, setShowAddCase] = useState(false);
  const [caseType, setCaseType] = useState("");
  const [name, setName] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [year, setYear] = useState("");
  const [selectedOption, setSelectedOption] = useState("Diary Number");
  const [dairyNumber, setDairyNumber] = useState("");

  console.log(cases);

  const access_token = sessionStorage.getItem("access_token");

  const storedUserInfo = sessionStorage.getItem("user-info");
  const parsedUserInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;

  const caseTypes = [
    "CWP-:(CIVIL WRIT PETITION)",
    "CRM-M-:(CRIMINAL MAIN)",
    "CR-:(CIVIL REVISION)",
    "RSA-:(REGULAR SECOND APPEAL)",
    "CRR-:(CRIMINAL REVISION)",
    "CRA-S-:(CRIMINAL APPEAL SB)",
    "FAO-:(FIRST APPEAL ORDER)",
    "CRM-:(CRIMINAL MISCELLANEOUS PETITION)",
    "ARB-:(ARBITRATION ACT CASE (WEF 15/10/03))",
    "ARB-DC-:(ARBITRATION CASE (DOMESTIC COMMERCIAL))",
    "ARB-ICA-:(ARBITRATION CASE(INTERNATIONAL COMM. ARBITRATION))",
    "CA-:(CIVIL APPEAL/COMPANY APPLICATION)",
    "CA-CWP-:(COMMERCIAL APPEAL (WRIT))",
    "CA-MISC-:(COMMERCIAL APPEAL (MISC))",
    "CACP-:(CONTEMPT APPEALS)",
    "CAPP-:(COMPANY APPEAL)",
    "CCEC-:(CUSTOM CENTRAL EXCISE CASE)",
    "CCES-:(CCES)",
    "CEA-:(CENTRAL EXCEISE APPEAL (WEF 10-11-2003))",
    "CEC-:(CENTRAL EXCISE CASE)",
    "CEGC-:(CENTRAL EXCISE GOLD CASE)",
    "CESR-:(CENTRAL EXCISE AND SALT REFERENCE)",
    "CLAIM-:(CLAIMS)",
    "CM-INCOMP-:(Misc. Appl. in Incomplete Case)",
    "CMA-:(COMPANY MISC. APPLICATION)",
    "CMM-:(HMA CASES U/S 24)",
    "CO-:(CIVIL ORIGINAL)",
    "CO-COM-:(CIVIL ORIGINAL (COMMERCIAL))",
    "COA-:(COMPANY APPLICATION)",
    "COCP-:(CIVIL ORIGINAL CONTEMPT PETITION)",
    "COMM-PET-M-:(COMMERCIAL PETITION MAIN)",
    "CP-:(COMPANY PETITIONS)",
    "CP-MISC-:(COMMERCIAL PETITON (MISC))",
    "CR-COM-:(CIVIL REVISION (COMMERCIAL))",
    "CRA-:(CRIMINAL APPEAL)",
    "CRA-AD-:(CRIMINAL APPEAL ACQUITTAL DB)",
    "CRA-AS-:(CRIMINAL APPEAL ACQUITTAL SB)",
    "CRA-D-:(CRIMINAL APPEAL DB)",
    "CRACP-:(CRIMINAL APPEAL CONTEMPT PETITION)",
    "CREF-:(CIVIL REFERENCE)",
    "CRM-A-:(AGAINST ACQUITTALS)",
    "CRM-CLT-OJ-:(CRIMINAL COMPLAINT (ORIGINAL SIDE))",
    "CRM-W-:(CRM IN CRWP)",
    "CROCP-:(CRIMINAL ORIGINAL CONTEMPT PETITION)",
    "CRR(F)-:(CRIMINAL REVISION (FAMILY COURT))",
    "CRREF-:(CRIMINAL REFERENCE)",
    "CRWP-:(CRIMINAL WRIT PETITION)",
    "CS-:(CIVIL SUIT)",
    "CS-OS-:(CIVIL SUIT-ORIGINAL SIDE)",
    "CUSAP-:(CUSTOM APPEAL (WEF 17/7/2004))",
    "CWP-COM-:(CIVIL WRIT PETITION (COMMERCIAL))",
    "CWP-PIL-:(CIVIL WRIT PETITION PUBLIC INTEREST LITIGATION)",
    "DP-:(DIVORCE PETITION)",
    "EA-:(EXECUTION APPL.)",
    "EDC-:(ESTATE DUTY CASE)",
    "EDREF-:(ESTATE DUTY REFERENCE)",
    "EFA-:(EXECUTION FIRST APPEAL)",
    "EFA-COM-:(EXECUTION FIRST APPEAL (COMMERCIAL))",
    "EP-:(ELECTION PETITIONS)",
    "EP-COM-:(EXECUTION PETITION (COMMERCIAL))",
    "ESA-:(EXECUTION SECOND APPEAL)",
    "FAO(FC)-:(FAO (FAMILY COURT))",
    "FAO-C-:(FAO (CUS AND MTC))",
    "FAO-CARB-:(FIRST APPEAL FROM ORDER (COMMERCIAL ARBITRATION))",
    "FAO-COM-:(FIRST APPEAL FROM ORDER (COMMERCIAL))",
    "FAO-ICA-:(FIRST APPEAL FROM ORDER(INTERNATIONAL COMM.ARBI.))",
    "FAO-M-:(FIRST APPEAL ORDER-MATRIMONIAL)",
    "FEMA-APPL-:(FEMA APPEAL)",
    "FORM-8A-:(FORM-8A)",
    "GCR-:(GOLD CONTROL REFERENCE)",
    "GSTA-:(GOODS AND SERVICES TAX APPEAL)",
    "GSTR-:(GENERAL SALES TAX REFERENCE)",
    "GTA-:(GIFT TAX APPEAL)",
    "GTC-:(GIFT TAX CASE)",
    "GTR-:(GIFT TAX REFERENCE)",
    "GVATR-:(GENERAL VAT REFERENCES)",
    "INCOMP-:(INCOMPLETE OBJECTION CASE)",
    "INTTA-:(INTEREST TAX APPEAL)",
    "IOIN-:(INTERIM ORDER IN)",
    "ITA-:(INCOME TAX APPEAL)",
    "ITC-:(INCOME TAX CASES)",
    "ITR-:(INCOME TAX REFERENCE)",
    "LPA-:(LATTER PATENT APPEALS)",
    "LR-:(LIQUIDATOR REPORT)",
    "MATRF-:(MATROMONIAL REFERENCE)",
    "MRC-:(MURDER REFERENCE CASE)",
    "O&M-:(ORIGINAL & MISCELLANEOUS)",
    "OLR-:(OFFICIAL LIQUIDATOR REPORT)",
    "PBPT-APPL-:(PROHIBITION OF BENAMI PROPERTY TRANSACTION APPEAL)",
    "PBT-:(PROBATE)",
    "PMLA-APPL-:(PREVENTION OF MONEY LAUNDERING APPEAL)",
    "PVR-:(PB VAT REVISION)",
    "RA-:(REVIEW APPL)",
    "RA-CA-:(REVIEW IN COMPANY APPEAL)",
    "RA-CP-:(REVIEW IN COMPANY PETITION.)",
    "RA-CR-:(REVIEW IN CR)",
    "RA-CW-:(REVIEW IN CWP)",
    "RA-LP-:(REVIEW IN LPA)",
    "RA-RF-:(REVIEW APPLICATION IN RFA)",
    "RA-RS-:(REVIEW IN RSA)",
    "RCRWP-:(REVIEW IN CRCWP)",
    "RERA-APPL-:(RERA APPEAL)",
    "RFA-:(REGULAR FIRST APPEAL)",
    "RFA-COM-:(REGULAR FIRST APPEAL (COMMERCIAL))",
    "RP-:(RECRIMINATION PETITION)",
    "SA-:(SERVICE APPEAL)",
    "SAO-:(SECOND APPEAL ORDER)",
    "SAO(FS)-:(SAO FOOD SAFETY)",
    "SDR-:(STATE DUTY REFERENCE)",
    "STA-:(SALES TAX APPEAL)",
    "STC-:(SALES TAX CASES)",
    "STR-:(SALE TAX REFERENCE)",
    "TA-:(TRANSFER APPLICATION)",
    "TA-COM-:(TRANSFER APPLICATION (COMMERCIAL))",
    "TC-:(TAKENUP CASES)",
    "TCRM-:(TRANSFER CRIMINAL PETITION)",
    "TEST-:(TEST)",
    "UVA-:(UT VAT APPEAL)",
    "UVR-:(UT VAT REVISION)",
    "VATAP-:(VAT APPEAL)",
    "VATCASE-:(VALUE ADDED TAX CASE)",
    "VATREF-:(VAT REFERENCE)",
    "WTA-:(WEALTH TAX APPEAL)",
    "WTC-:(WEALTH TAX CASES)",
    "WTR-:(WEALTH TAX REFERENCE)",
    "XOBJ-:(CROSS OBJECTION)",
    "XOBJC-:(CROSS OBJECTION IN CR)",
    "XOBJL-:(CROSS OBJECTION IN LPA)",
    "XOBJR-:(CROSS OBJECTION IN RFA)",
    "XOBJS-:(CROSS OBJECTION IN RSA)",
  ];

  const currentYear = new Date().getFullYear();
  const years1 = Array.from(
    { length: currentYear - 2000 + 1 },
    (v, k) => 2000 + k
  );
  const years = years1.reverse();

  useEffect(() => {
    fetchCases();
  }, []);

  const fetchCases = async () => {
    try {
      const response = await axiosInstance.get("highcourt/punjabcases/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      setCases(response.data);
    } catch (error) {
      console.error("Failed to fetch cases:", error);
    } finally {
      setIsLoadingCases(false);
    }
  };

  const confirmDeleteCase = (caseId) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are you sure you want to delete this case?",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCase(caseId),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const DeleteCase = async (caseId) => {
    try {
      const response = await axiosInstance.delete(
        `highcourt/punjabcases/${caseId}/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchCases();
      toast("Case Deleted Successfully");
    } catch (err) {
      console.log(err);
    }
  };

  const handleCaseDetails = async (case_number) => {
    try {
      const response = await axiosInstance.get(
        `highcourt/punjabhcbank/${case_number}/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setSelectPanjabCourtCase(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handlecase = async (e) => {
    e.preventDefault();

    if (!year) {
      toast.error("Please fill in all fields");
      return;
    }
    const data = {
      case_type: caseType,
      caseno: caseNumber,
      year: year,
      case_nickname: name,
    };

    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "highcourt/punjabcases/",
        data,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCaseType("");
      setCaseNumber("");
      setYear("");
      setName("");
      setDairyNumber("");
      fetchCases();
      toast.success("Case added successfully. We are processing your case.");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        toast.error(`Failed to submit form: ${err.response.data.detail}`);
      } else {
        toast.error("Failed to submit form");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  if (isLoadingCases) {
    return (
      <div className="loading">
        <Navbar />
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color="#ffffff" size={150} css={override} />
          <p
            style={{
              color: "#ffffff",
              marginTop: 20,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Loading case details...
            <br />
            Please wait as we retrieve the latest information.
          </p>
        </div>
      </div>
    );
  }

  if (selectPanjabCourtCase) {
    return <PanjabHarayanaCasedetail />;
  }

  if (cases.length > 0) {
    return (
      <>
        {/* <Navbar /> */}
        <ToastContainer />
        <div className="main-case-priviledge">
          <div className="cases-privileged-container">
            <div className="add-case-section dark:bg-dark-500">
              <img
                src={backbutton}
                alt=""
                className="back-button"
                onClick={() => {
                  window.location.href = "/mycases";
                }}
              />
              <h2 className="dark:text-gray-400">
                {" "}
                <span className="dark:text-gray-300">Add</span> Case
              </h2>
              <>
                <div className="form-group">
                  <select
                    id="caseType"
                    name="caseType"
                    value={caseType}
                    className="border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:text-gray-400"
                    onChange={(e) => setCaseType(e.target.value)}
                  >
                    <option value="">Select Case Type</option>
                    {caseTypes.map((caseType, index) => (
                      <option key={index} value={caseType}>
                        {caseType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="case-name"
                    placeholder="Case Number"
                    name="Case Number"
                    value={caseNumber}
                    className="border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                    onChange={(e) => setCaseNumber(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <select
                    id="year"
                    name="year"
                    value={year}
                    className="border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:text-gray-400"
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value="">Select Year</option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <input
                    id="year"
                    name="year"
                    placeholder="Case Name"
                    value={name}
                    className="border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
              </>

              <button
                className="submit-btn dark:border px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-450 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[3px_3px_0px_0px_#313134] transition duration-200"
                onClick={handlecase}
              >
                {loading ? <div className="loader"></div> : "Submit"}
              </button>
              <p className="info-text mt-5 dark:text-gray-500">
                <span className="icon">
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={infoimage}
                    alt=""
                    className="dark:invert-[1] dark:opacity-35"
                  />
                </span>{" "}
                You can access a detailed version of your case, complete with
                summary and timeline, by clicking on the tab labeled with the
                Case number.
              </p>
              <img
                src={bottomimage}
                alt="Book"
                className=".case-bottom-right-image dark:opacity-80"
              />
            </div>
            <div className="my-cases-section dark:bg-dark-600 bg-light-600">
              <div className="header-container flex items-center">
                <h2 className="dark:text-gray-400">
                  <span className="dark:text-gray-300">My</span> Cases
                </h2>
                {/* <div className="search-container">
                  <input
                    type="search"
                    placeholder="Search Case"
                    className="search-bar"
                  />
                  <span className="search-icon">
                    <img src={search} alt="Search Icon" className="icon-img" />
                  </span>
                </div> */}
              </div>
              <div className="case-list max-md:h-[65vh]">
                {cases.map((caseItem) => (
                  <div
                    className="cases-item dark:bg-dark-600"
                    style={{ display: "flex" }}
                  >
                    <div
                      key={caseItem.id}
                      className="case-items"
                      onClick={() =>
                        handleCaseDetails(caseItem.punjabhighcourtcase)
                      }
                      style={{ display: "flex", gap: "10px", width: "100%" }}
                    >
                      <div className="case-item dark:bg-dark-400 bg-white dark:text-gray-300">
                        {caseItem.case_type && (
                          <>
                            <span>CASE TYPE: {caseItem.case_type}</span>
                            {/* <span>CASE NO: {caseItem.caseno}</span> */}
                            <span>CASE NAME: {caseItem.case_nickname}</span>
                          </>
                        )}
                        {caseItem.dairy_number && (
                          <>
                            <span>DIARY NUMBER: {caseItem.dairy_number}</span>
                            <span>CASE NAME: {caseItem.case_nickname}</span>
                            {/* <span>CASE NO: {caseItem.caseno}</span> */}
                          </>
                        )}
                        {/* <span>CASE NAME: {caseItem.case_nickname}</span> */}
                      </div>
                    </div>
                    <div className="case-actions ml-2">
                      <button className="action-btn" aria-label="View Case">
                        <img
                          src={unselected}
                          alt="View"
                          className="case-img dark:invert-[1] dark:opacity-35"
                        />
                      </button>
                      <button
                        className="action-btn"
                        aria-label="Delete Case"
                        onClick={(e) => {
                          e.stopPropagation();
                          confirmDeleteCase(caseItem.id);
                        }}
                      >
                        <img
                          src={unselecteddelete}
                          alt="Delete"
                          className="case-img dark:invert-[1] dark:opacity-35"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {/* mobile add case section */}
              <div className="mobilePrivildgeContainer">
                <img src={backimg} alt="" className="back-image mt-20" />
                <div className="addcaseBut dark:bg-sh-dark-500 dark:text-gray-300 bg-gray-300">
                  <button onClick={() => setShowAddCase(true)}>Add Case</button>
                </div>
              </div>
              <div
                className={`mobile-top-privilegde ${showAddCase ? "show" : ""}`}
              >
                <div className="mobile-privileged-main-side dark:bg-dark-600 dark:border-gray-600">
                  <button
                    className="close-button dark:invert-[1] top-4"
                    onClick={() => setShowAddCase(false)}
                  >
                    <FaTimes />
                  </button>
                  <h1 className="dark:text-gray-400">
                    {" "}
                    <span className="dark:text-gray-300">Add </span>Case
                  </h1>

                  <div className="high-court-mobile-privileged-centered-box">
                    <div className="mobile-privileged-container">
                      <div className="high-court-form-container dark:bg-dark-500 bg-light-500">
                        <form onSubmit={handlecase}>
                          <>
                            <div className="form-group">
                              <label className="dark:text-gray-300" htmlFor="">
                                Case Type
                              </label>
                              <select
                                id="caseType"
                                name="caseType"
                                value={caseType}
                                className="border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:text-gray-400"
                                onChange={(e) => setCaseType(e.target.value)}
                              >
                                <option value="">Select Case Type</option>
                                {caseTypes.map((caseType, index) => (
                                  <option key={index} value={caseType}>
                                    {caseType}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group">
                              <label className="dark:text-gray-300" htmlFor="">
                                Case Number
                              </label>
                              <input
                                type="text"
                                id="case-name"
                                placeholder="Case Number"
                                name="Case Number"
                                value={caseNumber}
                                className="border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                                onChange={(e) => setCaseNumber(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label className="dark:text-gray-300" htmlFor="">
                                Year
                              </label>
                              <select
                                id="year"
                                name="year"
                                value={year}
                                className="border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:text-gray-400"
                                onChange={(e) => setYear(e.target.value)}
                              >
                                <option value="">Year</option>
                                {years.map((year, index) => (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group">
                              <label className="dark:text-gray-300" htmlFor="">
                                Case Name
                              </label>

                              <input
                                id="year"
                                name="year"
                                placeholder="Case Name"
                                value={name}
                                className="border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                                onChange={(e) => setName(e.target.value)}
                              ></input>
                            </div>
                          </>

                          <div className="form-group laptop-submit">
                            <button
                              type="submit"
                              disabled={loading}
                              onClick={handlecase}
                            >
                              {loading ? (
                                <div className="loader"></div>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="mobile-footer-content">
                        <div className="mobile-footer-image">
                          <button
                            className="form-group mobile-submit mt-4 dark:border px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-450 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[3px_3px_0px_0px_#313134] transition duration-200"
                            onClick={handlecase}
                          >
                            {loading ? (
                              <div className="loader"></div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <img
                            src={mobilePriviledge}
                            alt=""
                            className="mobileFooterImage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <ToastContainer />
        <div className="supreme-court-container">
          <div className="supreme-court-container-content">
            <div className="img-text">
              <img
                src={backbutton}
                alt="Back"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "/mycases";
                }}
              />
              <h1>
                <span style={{ color: "#5D54F1" }}>Enter the following to</span>{" "}
                know your case status
              </h1>
            </div>
            <p>Add your case using the following options</p>

            <div className="form-container">
              <form onSubmit={handlecase}>
                <>
                  <div className="form-group">
                    <label htmlFor="year">Case Type</label>
                    <select
                      id="caseType"
                      name="caseType"
                      value={caseType}
                      onChange={(e) => setCaseType(e.target.value)}
                    >
                      <option value="">Select Case Type</option>
                      {caseTypes.map((caseType, index) => (
                        <option key={index} value={caseType}>
                          {caseType}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="caseType">Case Number</label>
                    <input
                      id="caseType"
                      name="caseType"
                      placeholder="Case Number"
                      value={caseNumber}
                      onChange={(e) => setCaseNumber(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="year">Year</label>
                    <select
                      id="year"
                      name="year"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value="">Select Year</option>
                      {years.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
                <div className="submit-laptop">
                  <div className="laptop-submit" onClick={handlecase}>
                    <button type="submit" disabled={loading}>
                      {loading ? <div className="loader"></div> : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <button className="form-group mobile-submit" onClick={handlecase}>
              {loading ? <div className="loader"></div> : "Submit"}
            </button>
          </div>
          <div className="supreme_court_bottom_illustration">
            <img src={supreme_court_illustration} alt="New top image" />
          </div>
        </div>
        <div className="supreme_court_bottom_right_illustration">
          <img src={supreme_court_illustration2} alt="" />
        </div>
      </>
    );
  }
};

export default PanjabAndHaraynaHC;
