import React, { useState } from "react";
import SearchLawyerProfile from "../assets/images/profile/searchlawyerprofile.png";
import "../assets/css/Filteredlawyer.css";
import { useLocation } from "react-router-dom";
import lawyerlocation from "../assets/images/Filtered/lawyerlocation.svg";
import lawyerexperience from "../assets/images/Filtered/lawyerexperience.svg";
import lawyerlanguage from "../assets/images/Filtered/lawyerlanguage.svg";
import lawyerrating from "../assets/images/Filtered/lawyerrating.svg";
import { useCaseContext } from "../context/CaseContext";

function LawyerList() {
  const location = useLocation();
  const { lawyersData } = useCaseContext();
  console.log(lawyersData);

  // const lawyersData = location.state?.lawyersData;

  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const toggleBio = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  const lawyers = [
    {
      name: "John Doe",
      bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      location: "Mumbai",
      experience: "5 Years",
      mobileNumber: "766875346",
      languages: "English, Hindi",
      rating: 4.5,
    },
    {
      name: "John Doe",
      bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      location: "Mumbai",
      experience: "5 Years",
      mobileNumber: "766875346",
      languages: "English, Hindi",
      rating: 4.5,
    },
    {
      name: "John Doe",
      bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      location: "Mumbai",
      experience: "5 Years",
      mobileNumber: "766875346",
      languages: "English, Hindi",
      rating: 4.5,
    },
    {
      name: "John Doe",
      bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      location: "Mumbai",
      experience: "5 Years",
      mobileNumber: "766875346",
      languages: "English, Hindi",
      rating: 4.5,
    },

    // Add more lawyer data as needed
  ];

  const isBioExpanded = (index) => expandedIndexes.includes(index);

  const truncateBio = (bio) => {
    const maxLength = 100; // Adjust this length based on how many lines you want to show on mobile
    if (bio.length <= maxLength) return bio;
    return bio.slice(0, maxLength) + "...";
  };

  return (
    <div className="lawyerList">
      {lawyersData.map((lawyer, index) => (
        <div key={index} className="lawyerCard">
          <div className="lawyerInfo">
            <div className="lawyer-img">
              <img src={SearchLawyerProfile} alt="" />
            </div>
            <div className="Lawyer-data">
              <div className="Lawyer-data-content">
                <h3>{lawyer.name}</h3>
                <p>{lawyer.mobileNumber}</p>
                <p>
                  <span className="bio-full">{lawyer.bio}</span>
                  <span className="bio-truncated">
                    {isBioExpanded(index)
                      ? lawyer.bio
                      : truncateBio(lawyer.bio)}
                    {lawyer.bio.length > 100 && (
                      <button
                        onClick={() => toggleBio(index)}
                        className="view-more-button"
                      >
                        {isBioExpanded(index) ? "View Less" : "View More"}
                      </button>
                    )}
                  </span>
                </p>
                <div className="lawyers-info">
                  <div className="mobile-two">
                    <span>
                      <img src={lawyerlocation} alt="" />
                    </span>
                    <p>{lawyer.location}</p>
                    <span>
                      <img src={lawyerexperience} alt="" />
                    </span>
                    <p>{lawyer.experience}</p>
                  </div>
                  <div className="mobile-second">
                    <span>
                      <img src={lawyerlanguage} alt="" />
                    </span>
                    <p>{lawyer.languages}</p>
                    <span>
                      <img src={lawyerrating} alt="" />
                    </span>
                    <p>{lawyer.rating}</p>
                  </div>
                </div>
                <div className="mobile-lawyer-action">
                  <button className="compare">Compare</button>
                  <button className="lawyer-contact">Contact</button>
                </div>
              </div>
            </div>
            <div className="lawyerActions">
              <button>Compare</button>
              <button>Contact</button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default LawyerList;
