import React from 'react'
import Navbar from './Navbar'
import '../assets/css/comingSoon.css'
import soon from '../assets/images/comingSoon/soon.svg'
import inputIcon from '../assets/images/comingSoon/input-icon.svg'
import expertLawyerImage from '../assets/images/ourservices/expert_lawyer_matching.png';


export default function ExpertLawyerMatching() {
    return (
        <div>
            <Navbar />
            <div className="comingSoon-container">
                <div className="comingsoon-container-contain">
                    <div className="coming-soon-upper-section">
                        <h1><span style={{ color: '#5d54f1' }}>Coming</span> Soon</h1>
                        <p>Working on something amazing..</p>
                        <hr className="loader-divider" />
                    </div>
                    <div className="coming-soon-main-content">
                        <div className="coming-soon-content-section">
                            <div className="coming-soon-text-content">
                                <h3>Expert Lawyer Matching</h3>
                                <p>Connect with the ideal legal professional from our extensive database using advanced matching algorithms.</p>
                                <div className="list-item">
                                    <ul>
                                        <li>Smart Matching Tools</li>
                                        <li>Large Database & Detailed Profiles</li>
                                        <li>Personalised Recommendations & Easy Communication</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="img-content">
                                <img src={expertLawyerImage} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="coming-soon-right-section">

                        <div className="input-section">
                            <img src={inputIcon} alt="" />
                            <div className="coming-soon-mobile-section">
                                <input type="text"
                                    placeholder='Enter Email'
                                />
                                <button>Notify Me</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
