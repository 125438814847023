import React from 'react'
import Navbar from './Navbar'
import '../assets/css/comingSoon.css'
import soon from '../assets/images/comingSoon/soon.svg'
import inputIcon from '../assets/images/comingSoon/input-icon.svg'

export default function ComingSoon() {
    return (
        <div>
            <Navbar />  
            <div className="comingSoon-container">
                <div className="comingsoon-container-contain">
                    <div className="coming-soon-upper-section">
                        <h1><span style={{ color: '#5d54f1' }}>Coming</span> Soon</h1>
                        <p>Working on something amazing..</p>
                        <hr className="loader-divider" />

                    </div>
                    <div className="coming-soon-lawyer-section">
                        <div className="lawyer-coming-soon-content">
                            <h3>Find the Right Lawyer, Right When You Need Them!</h3>
                            <ul>
                                <li>Instant access to qualified attorneys in your area</li>
                                <li>Match with lawyers specializing in your specific legal needs</li>
                                <li>User-friendly interface to simplify your legal journey</li>
                            </ul>
                        </div>
                    </div>
                    <div className="lawyer-input-section">
                        <img src={inputIcon} alt="" />
                        <div className="coming-soon-mobile-section">
                            <input type="text"
                                placeholder='Enter Email'
                            />
                            <button>Notify Me</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
