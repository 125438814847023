import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "../assets/css/mycases.css";
import axiosInstance from "../utils/axiosInstance";
import "../assets/css/casedetails.css";
import "react-vertical-timeline-component/style.min.css";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import "react-vertical-timeline-component/style.min.css";
import { useChat } from "../context/ChatContext";
import backbutton from "../assets/images/mycases/back-button.svg";
import mobileInfo from "../assets/images/selectcourt/mobileInfo.svg";
import { format, parseISO } from "date-fns";
import caseDetailswaiting from "../assets/images/comingSoon/CaseWaitingPage.svg";
import chaticon from "../assets/images/mycases/caseDetailsChatButtonIcon.png";
import { useCaseContext } from "../context/CaseContext";
import { SpinningCircles } from "react-loading-icons";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const statuses = [
  { status: "PENDING", label: "Pending" },
  { status: "IN_PROGRESS", label: "In Progress" },
  { status: "COMPLETED_EXTRACTION", label: "Extraction Completed" },
  { status: "COMPLETED", label: "Completed" },
];

const CaseDetails = ({}) => {
  const { selectedCase, setSelectedCase, goBackDistrictCourt } =
    useCaseContext();
  const [details, setDetails] = useState(selectedCase);
  const [hearingDates, setHearingDates] = useState([]);
  const [orderDates, setOrderDates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [columns, setColumns] = useState([[], []]);
  const [caseDetailsFetched, setCaseDetailsFetched] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // State for the selected date
  const [dateInfo, setDateInfo] = useState(""); // State for date-related information
  const [orderPdfs, setOrderPdfs] = useState([]);
  const [caseStatus, setCaseStatus] = useState(selectedCase.status); // State for case status
  const navigate = useNavigate();
  const [soonDetails, setSoonDetails] = useState(selectedCase);
  const access_token = sessionStorage.getItem("access_token");
  const { setPropmtsVisibel, propmtsVisibel } = useCaseContext();

  const fetchCaseDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `casedetail/cnrbank/${selectedCase.cnr_number}/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCaseStatus(response.data.status);
      if (response.data.status === "COMPLETED_EXTRACTION") {
        setSoonDetails(response.data);
        // toast.success('');
      }
      if (response.data.status === "COMPLETED") {
        setDetails(response.data);
        setCaseDetailsFetched(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!caseDetailsFetched) {
        fetchCaseDetails();
      }
    }, 2000);
    return () => clearInterval(intervalId);
  }, [caseDetailsFetched]);

  useEffect(() => {
    try {
      // Assuming caseDetails.hearing_date is a string with dates like 'YYYY-MM-DD' separated by commas
      const hearing_date_json = soonDetails.hearing_date
        .replace(/'/g, '"')
        .replace(/^\[|\]$/g, "");
      const hearing_date_array = JSON.parse("[" + hearing_date_json + "]");

      // Filter out empty strings from the array
      const filteredDates = hearing_date_array.filter(
        (date) => date.trim() !== ""
      );

      const parsedDates = filteredDates.map((date) => {
        const [day, month, year] = date.split("-");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      });

      setHearingDates(parsedDates);
      console.log(parsedDates);
    } catch (err) {
      console.error("Error parsing and formatting hearing_date:", err);
    }

    try {
      console.log("Original order_dates string:", soonDetails.order_date);
      let order_date_json = selectedCase.order_date
        .replace(/'/g, '"')
        .replace(/^\[|\]$/g, "")
        .trim();
      console.log(
        "Formatted order_dates string for JSON.parse:",
        order_date_json
      );

      order_date_json =
        "[" +
        order_date_json
          .split(",")
          .map((date) => `"${date.trim()}"`)
          .join(",") +
        "]";
      console.log("Proper JSON array string for order_dates:", order_date_json);

      const order_date_array = JSON.parse(order_date_json);
      const parsedOrderDates = order_date_array.map((date) => {
        const [day, month, year] = date.split("-");
        return day.padStart(2, "0");
      });
      setOrderDates(parsedOrderDates);
    } catch (err) {
      console.error("Error parsing and formatting order_dates:", err);
    }

    if (selectedCase.complete_summary) {
      const splitParagraphs = selectedCase.complete_summary
        .split(". ")
        .map((sentence, index, array) => {
          if (index < array.length - 1) {
            return sentence + ".";
          }
          return sentence;
        });

      const midIndex = Math.ceil(splitParagraphs.length / 2);
      const firstColumn = splitParagraphs.slice(0, midIndex);
      const secondColumn = splitParagraphs.slice(midIndex);

      setColumns([firstColumn, secondColumn]);
    }
  }, [
    selectedCase,
    selectedCase.complete_summary,
    soonDetails,
    details.complete_summary,
  ]);

  const isHighlighted = (day) => {
    const paddedDay = day.toString().padStart(2, "0");
    const result = hearingDates.some((date) => {
      const dateDay = date.split("-")[2];
      return dateDay === paddedDay;
    });

    if (result) {
      console.log("Highlighting day:", day);
    }

    return result;
  };

  const fetchPdfList = async () => {
    try {
      const response = await axiosInstance.get(
        `casedetail/cnrbank/${selectedCase.cnr_number}/pdflist/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setOrderPdfs(response.data);
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPdfList();
  }, [selectedCase.cnr_number]);

  const handleDateClick = (day) => {
    const selectedDay = day.toString().padStart(2, "0");
    const hearingOccurrences = hearingDates.filter((date) =>
      date.endsWith(`-${selectedDay}`)
    );

    setSelectedDate(hearingOccurrences[0]);

    if (hearingOccurrences.length > 0) {
      const infoElements = hearingOccurrences.map((date) => {
        const formattedDate = format(parseISO(date), "do MMM yyyy");

        if (!orderPdfs) {
          console.error("orderPdfs is not defined");
          return (
            <div key={date} style={{ fontFamily: "raleway" }}>
              <div style={{ fontWeight: 700 }}>{formattedDate}</div>
              <div>Order PDFs data not available.</div>
            </div>
          );
        }

        const orderPdf = orderPdfs.find((pdf) => pdf.order_date === date);
        const pdfLink = orderPdf ? orderPdf.pdf_path : null;

        const isOrderDate = orderPdfs.some((pdf) => pdf.order_date === date);
        const isHearingDate = hearingDates.includes(date);
        let additionalInfo = "";
        if (isHearingDate && isOrderDate) {
          additionalInfo =
            "You can download the order by clicking on the date.";
        } else if (isHearingDate) {
          additionalInfo = "Order is not uploaded";
        } else if (isOrderDate) {
          additionalInfo = "This is an order date.";
        }

        return (
          <div key={date} style={{ fontFamily: "raleway" }}>
            <div style={{ fontWeight: 700 }}>
              {pdfLink ? (
                <a href={pdfLink} target="_blank" rel="noopener noreferrer">
                  {formattedDate}
                </a>
              ) : (
                formattedDate
              )}
            </div>
            <div style={{ fontSize: "12px" }}>{additionalInfo}</div>
          </div>
        );
      });

      setDateInfo(infoElements);
    }
  };

  const daysInMonth = 31;

  const casename = `${selectedCase.petitioner_name} vs ${selectedCase.respondent_and_advocate_information}`;
  console.log(casename);

  // const createChatRoom = async (cnr_number, is_true) => {
  //     try {
  //         const response = await axiosInstance.get('chatapp/chat-room/', {
  //             headers: {
  //                 'Authorization': `Bearer ${access_token}`,
  //                 'Content-Type': 'application/json',
  //             }
  //         });
  //         const existingChatRooms = response.data;
  //         console.log(response.data);

  //         // const cnrString = cnr_number.toString();

  //         // const casename = `${caseDetails.petitioner_name} vs ${caseDetails.respondent_and_advocate_information}`;

  //         // const chatRoomExists = existingChatRooms.some(chatRoom => {
  //         //     console.log(`Comparing ${chatRoom.chat_name} with ${cnrString} and ${chatRoom.chat_type} with "query"`);
  //         //     return chatRoom.chat_name === casename && chatRoom.chat_type === "query";
  //         // });

  //         // if (chatRoomExists) {
  //         //     navigate('/talkaboutcase');
  //         // } else {

  //         const result = await axiosInstance.post('chatapp/chat-room/', {
  //             cnr_number: cnr_number,
  //             is_query: is_true
  //         }, {
  //             headers: {
  //                 'Authorization': `Bearer ${access_token}`,
  //                 'Content-Type': 'application/json',
  //             }
  //         });
  //         console.log("Chat room created:", response.data);
  //         setShowPrompts(true);
  //         console.log(showPrompts);
  //         navigate('/talkaboutcase');
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };
  const createChatRoom = async (cnr_number, is_true) => {
    try {
      const response = await axiosInstance.get("chatapp/chat-room/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      const existingChatRooms = response.data;

      const result = await axiosInstance.post(
        "chatapp/chat-room/",
        {
          cnr_number: cnr_number,
          is_query: is_true,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setPropmtsVisibel(true);

      console.log("Chat room created:", response.data);

      // Update showPrompts in the context here

      console.log("showPrompts set to true");
      navigate("/talkaboutcase");
    } catch (err) {
      console.log(err);
    }
  };

  const formatText = (text) => {
    if (!text) return null;

    const lines = text.split("\n");

    return lines
      .map((line, index) => {
        if (!line.trim()) return null;

        // Handle headings
        const headingMatch = line.match(/^(#+)\s+(.*)/);
        if (headingMatch) {
          const level = headingMatch[1].length;
          const content = headingMatch[2].replace(/\*\*(.*?)\*\*/g, "$1");
          return <strong key={index}>{content}</strong>;
        }

        const numberedMatch = line.match(/^(\d+\.\s+)\*\*(.*?)\*\*$/);
        if (numberedMatch) {
          const numberPart = numberedMatch[1]; // e.g., "1. "
          const content = numberedMatch[2].trim(); // Get the text after ** and remove it
          return (
            <div key={index}>
              {numberPart}
              <strong>{content}</strong>
            </div>
          );
        }

        // Handle list items with bold text
        if (line.startsWith("-**")) {
          const content = line.substring(3, line.length - 2).trim();
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("-**")) {
          const content = line.substring(8).trim();
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("   - **")) {
          const content = line.substring(7).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("  - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("   - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }

        if (line.startsWith("     - ")) {
          const content = line.substring(6).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }

        // Handle regular list items
        if (line.startsWith("-")) {
          const cleanLine = line.substring(1).trim();
          const contentWithoutBold = cleanLine.replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <p style={{ marginLeft: "10px" }}>
              <li key={index}>{contentWithoutBold}</li>
            </p>
          );
        }

        if (line.startsWith("**") && line.endsWith("**")) {
          const cleanLine = line.substring(2, line.length - 2).trim(); // Remove the first and last two asterisks
          return (
            <strong>
              <p>{cleanLine}</p>
            </strong>
          );
        }

        // Handle bold text within a paragraph
        const parts = line
          .replace(/\*\*$/, "")
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          .split(/(<strong>.*?<\/strong>)/)
          .map((part, idx) => {
            if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
              const cleanText = part.substring(8, part.length - 9);
              return <strong key={idx}>{cleanText}</strong>;
            } else {
              return <span key={idx}>{part}</span>;
            }
          });

        return (
          <p key={index} style={{ textAlign: "justify" }}>
            {parts}
          </p>
        );
      })
      .filter((component) => component !== null);
  };

  if (caseStatus === "STARTED") {
    return (
      <>
        {/* <Navbar /> */}
        <div className="casedetails-app-container">
          <div className="casedetails-content-container">
            {/* </div> */}
            <div className="casedetails-lower-section1">
              <img
                src="https://caseminister.blob.core.windows.net/frontend/CaseWaitingPage.svg"
                alt=""
              />
              <div className="casedetails-lower-section">
                <h3>Your Case Is Now in Motion!</h3>
                <p>Thank you for letting us assist you.</p>
              </div>
            </div>
          </div>
          <div className="casedetails-right-section">
            <div className="footer-right">
              <h6>What’s next?</h6>
              <ul>
                <li>
                  1.⁠ ⁠🕵️‍♂️ We're extracting the intricate details of your case.
                </li>
                <li>
                  2.⁠ ⁠🧠 These details are then sent to our state-of-the-art
                  Multimodal AI for thorough analysis.
                </li>
                <li>
                  ⁠3.🔔 You'll receive a prompt notification and an email update
                  very soon.
                </li>
              </ul>
              <h6>While you wait,</h6>
              <p>
                Why not explore our innovative AI Chatbot? It's designed to
                assist you with a wealth of legal knowledge and is trained on
                millions of cases and laws. Click here to dive in!
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* <Navbar /> */}
      <div className="app dark:bg-dark-600 bg-light-600">
        <div className="case-headings">
          <div
            className="view"
            style={{ display: "flex", gap: "10px", marginTopL: "4rem" }}
          >
            <img
              src={backbutton}
              alt=""
              onClick={goBackDistrictCourt}
              style={{ cursor: "pointer" }}
              className="dark:invert-[1]"
            />
            <div className="text-center w-full mr-[40px]">
              <p className="main-head dark:text-gray-400 text-gray-600">
                <span className="dark:text-gray-300 text-gray-900">
                  View status{" "}
                </span>
                of case here
              </p>
            </div>
          </div>
          <div className="case-main-details text-center">
            <p className="dark:text-gray-300 text-gray-700">
              CNR: {selectedCase.cnr_number}
            </p>
            <div className="New-Chatbot-Butoon">
              {details.complete_summary ? (
                <>
                  <button
                    className="chatbot-button flex items-center px-5 py-3 dark:bg-sh-dark-500 dark:text-gray-300 font-raleway tracking-wide font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#000] transition duration-200"
                    onClick={() => createChatRoom(details.cnr_number, "True")}
                  >
                    Let's talk about your case.
                    {/* <img src={chaticon} alt="" /> */}
                  </button>
                  <p className="dark:text-gray-300 text-gray-700">
                    Try our legal chatbot in your preferred language to uncover
                    valuable insights and hidden answers in your case
                  </p>
                </>
              ) : (
                <>
                  <div className="Ai-model">
                    <p>
                      Our AI model is extracting your case details. Please
                      wait...
                    </p>
                    <SpinningCircles />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="table-with-vector">
          <div className="content">
            {/* <img src={tablevec} alt="Vector Graphic" className="table-vector" /> */}
            <div className="case-details">
              <span className="middle-dot"></span>
              <table>
                <tbody>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Case Type
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.case_type}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Registration No.
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.registration_number}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Filing No
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.filing_number}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Nature of Disposal
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.nature_of_disposal}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Case Status
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.case_status}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Petitioner Name
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.petitioner_name}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Respondent &Advocate Information
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.respondent_and_advocate_information}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Court Number &Judge
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.court_number_and_judge}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      Judge Details
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.judge_details}
                    </td>
                  </tr>
                  <tr>
                    <th className="dark:text-light-300 text-gray-900">
                      FIR Details
                    </th>
                    <td className="dark:text-light-300 text-gray-900">
                      {soonDetails.fir_details}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="right-side-dates">
              <h5
                style={{ textAlign: "left" }}
                className="dark:text-light-300 text-gray-900"
              >
                Important Dates & Timeline
              </h5>
              <span className="dark:text-light-300 text-gray-900">
                Click on the highlighted numbers to know more
              </span>
              <div className="important-dates-container">
                <div className="calendar">
                  {Array.from({ length: daysInMonth }, (_, i) => {
                    const day = i + 1;
                    return (
                      <div
                        key={i}
                        className={`day ${
                          isHighlighted(day) ? "highlight" : ""
                        }`}
                        onClick={() => handleDateClick(i + 1)}
                      >
                        {day}
                      </div>
                    );
                  })}
                </div>
                <div className="vertical-line"></div>
                <div className="casedetail-of-side">
                  <div className="case-dates">
                    {/* <p>You can download the orders by clicking on date</p> */}
                    {dateInfo}
                  </div>
                  <div className="corner-next-hearing">
                    <p>Next hearing date :{soonDetails.next_hearing_date}</p>
                  </div>
                </div>
              </div>
              {/* <div className="mobile-instruction">
                                <img src={mobileInfo} alt="" />
                                <p>To discuss about this case and get more information, click on our Chatbot button</p>
                            </div> */}
              {details.complete_summary && (
                <>
                  <div className="case-details-buttons">
                    <button
                      className="don-button"
                      onClick={() => window.open(details.merged_pdf, "_blank")}
                    >
                      DOWNLOAD ORDERS
                    </button>
                    <button
                      className="don-button"
                      onClick={() =>
                        window.open(details.latest_order_pdf_link, "_blank")
                      }
                    >
                      DOWNLOAD LATEST ORDER
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {details.complete_summary ? (
          <div className="order-summary">
            <div
              className="order-summary-header"
              onClick={() => setIsOpen(!isOpen)}
            >
              <h2>Order Summary</h2>
            </div>
            <div className="order-summary-content">
              <p>{formatText(details.complete_summary)}</p>
            </div>
          </div>
        ) : (
          <div className="Ai-model">
            <p>Our AI model is extracting your case details. Please wait...</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CaseDetails;
