import React, { useState } from "react";
import axios from "axios";
import "../assets/css/ContactUs.css";
import Navbar from "./Navbar";
import phone from "../assets/images/contactus/phone.svg";
import email from "../assets/images/contactus/email.svg";
import address from "../assets/images/contactus/address.svg";
import blueObject from "../assets/images/contactus/bblue-object.svg";
import blackObject from "../assets/images/contactus/black-object.svg";
import mobileEmail from "../assets/images/contactus/mobileEmail.svg";
import mobilePhone from "../assets/images/contactus/mobilePhone.svg";
import mobileLocation from "../assets/images/contactus/mobileLocation.svg";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axiosInstance";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    query: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.query ||
      !formData.message
    ) {
      toast.error("Please fill in necessary fields");
      return;
    }
    setLoading(true);
    try {
      const response = await axiosInstance.post("contact/team/", {
        name: formData.name,
        email_id: formData.email,
        reasonforcontact: formData.query,
        message: formData.message,
      });
      setFormData("");
      console.log("Response:", response.data);
      toast("Your query has been submitted successfully!");
      setFormData({ name: "", email: "", query: "" }); // Clear form fields after submission
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast("There was an error submitting your query. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className="contact-us dark:bg-dark-600 h-screen m-0 pt-20 bg-light-600">
        <div className="mobile-contact-us">
          <div className="main-mmobile-contact-content">
            <h5 className="dark:text-gray-400">
              <span className="dark:text-gray-300">Contact</span> Us
            </h5>
            <p>
              We’d love to hear from you! Whether you have questions, need
              support, or want to learn more about our services, our team is
              here to help.
            </p>
          </div>
        </div>
        <div className="content-form ">
          <form className="contact-form talk-bubble tri-right round btm-left dark:bg-dark-450 bg-gray-300">
            <div className="form-group">
              <label className="dark:text-gray-300">Your Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name*"
                required
                className="dark:bg-sh-dark-500 dark:text-gray-300 border-none placeholder:dark:text-gray-400"
              />
            </div>
            <div className="form-group">
              <label className="dark:text-gray-300">Email Address</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Address*"
                className="dark:bg-sh-dark-500 dark:text-gray-300 border-none placeholder:dark:text-gray-400"
                required
              />
            </div>
            <div className="form-group ">
              <label className="dark:text-gray-300">Reason for contact</label>
              <input
                type="text"
                name="query"
                value={formData.query}
                onChange={handleChange}
                placeholder="Reason for contact"
                className="dark:bg-sh-dark-500 dark:text-gray-300 border-none placeholder:dark:text-gray-400"
                required
              />
            </div>
            {/* <button type="submit">Submit</button> */}
          </form>
          <div className="info">
            <p className="flex items-center gap-4 dark:text-gray-300">
              {/* <img src={email} alt="" style={{ height: "20px" }} />{" "} */}
              <IoMdMail className="text-2xl" />
              info@caseminister.com
            </p>
            {/* <p><img src={phone} alt="" style={{ height: '30px' }} /> +44 1234567890</p> */}
            <p className="flex items-center gap-4 dark:text-gray-300">
              {/* <img src={address} alt="" style={{ height: "33px" }} /> Suite 01, */}
              <FaLocationDot className="text-2xl" />
              64 Nile Street, London, N1 7SR
            </p>
          </div>
          {/* <img src={blackObject} alt="" className="blackObject" /> */}
        </div>
        <div className="contact-info">
          <div className="laptop-contactus-content">
            <h2 className="dark:text-gray-400">
              <span className="dark:text-gray-300">Contact </span> Us
            </h2>
            <p className="dark:text-gray-400">
              We’d love to hear from you! Whether you have questions, need
              support, or want to learn more about our services, our team is
              here to help.
            </p>
          </div>
          {/* <img src={blueObject} alt="" className="blueobject " /> */}
          <div className="message-box dark:bg-dark-500 bg-gray-300">
            <label className="text-gray-700 dark:text-gray-300">
              Your message
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Type your message here..."
              required
              className="dark:bg-dark-400 text-black dark:text-gray-300"
            ></textarea>

            <div className="get-in-touch">
              <button
                onClick={handleSubmit}
                className="max-sm:text-xs px-5 py-2 dark:bg-dark-400 mt-6 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm transition duration-200"
              >
                {loading ? "Please Wait..." : "Get in touch!"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-contact-info">
        <div className="contact-content">
          <ul>
            <li>
              <img src={mobileEmail} alt="" /> info@caseminister.com
            </li>
            {/* <li><img src={mobilePhone} alt="" /> +44 1234567890</li> */}
            <li>
              <img src={mobileLocation} alt="" /> Suite 01, 64 Nile Street,
              London, N1 7SR
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
