import React, { useRef, lazy, Suspense, useState, useEffect } from "react";
import Footer from "./Footer";
import Spinner from "./Spinner"; // Import the spinner component
import CardStack from "./CardStack";
import Preloader from "./Preloader"; // Import the Preloader component
import NotificationBar from "./NotificationBar";
import InTheNews from "./InTheNews";
const Landingpage = lazy(() => import("../Component/LandingPage"));
const Ourservices = lazy(() => import("./Ourservices"));
// const BlogComponent = lazy(() => import('./Blog'));
const Testimonial1 = lazy(() => import("./Testimonial1"));

const MainComponent = () => {
  const ourservicesRef = useRef(null);

  const scrollToOurservices = () => {
    if (ourservicesRef.current) {
      ourservicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const cards = [
    {
      id: 1,
      title: "Up-to-date legal system",
      content:
        "Benefit from a system trained with the latest criminal laws and data collected from E-court.",
      video:
        "https://caseminister.blob.core.windows.net/frontend/up to date.mp4",
      backgroundColor: "dark:bg-dark-450 bg-light-600",
      titleColor: "dark:text-gray-300 text-gray-800",
      contentColor: "dark:text-gray-400 text-gray-500",
    },
    {
      id: 2,
      title: "Time-Saving Summaries",
      content:
        "Quickly access relevant case summaries, reducing the time spent on navigating other websites.",
      video:
        "https://caseminister.blob.core.windows.net/frontend/time saving.mp4",
      backgroundColor: "dark:bg-dark-400 bg-light-300",
      titleColor: "dark:text-gray-300 text-gray-800",
      contentColor: "dark:text-gray-400 text-dark-500",
    },
    {
      id: 3,
      title: "Free Advanced Chatbot",
      content:
        "Utilise our free GPT-4 powered chatbot, for enhanced legal assistance.",
      video: "https://caseminister.blob.core.windows.net/frontend/chatbot.mp4",
      backgroundColor: "dark:bg-dark-600 bg-light-400",
      titleColor: "dark:text-gray-300 text-gray-800",
      contentColor: "dark:text-gray-400 text-dark-500",
    },
    {
      id: 4,
      title: "Transparency & Accessibility",
      content:
        "Gain access to live case details, summaries, and updates in your preferred language.",
      video:
        "https://caseminister.blob.core.windows.net/frontend/transparency.mp4",
      backgroundColor: "dark:bg-dark-400 bg-light-300",
      titleColor: "dark:text-gray-300 text-gray-800",
      contentColor: "dark:text-gray-400 text-dark-500",
    },
    {
      id: 5,
      title: "Convenient Case Management",
      content:
        "Download case orders, and upload case information, and documents for personalised assistance from the chatbot.",
      video:
        "https://caseminister.blob.core.windows.net/frontend/case management.mp4",
      backgroundColor: "dark:bg-dark-450 bg-light-600",
      titleColor: "dark:text-gray-300 text-gray-800",
      contentColor: "dark:text-gray-400 text-dark-500",
    },
  ];

  // Initialize the loading state based on sessionStorage
  // const [loading, setLoading] = useState(
  //   !sessionStorage.getItem("hasSeenPreloader")
  // );
  // const [preloaderClass, setPreloaderClass] = useState("");

  // useEffect(() => {
  //   if (loading) {
  //     const timer = setTimeout(() => {
  //       setPreloaderClass("hide");
  //       setTimeout(() => {
  //         setLoading(false);
  //         sessionStorage.setItem("hasSeenPreloader", "true");
  //       }, 2000);
  //     }, 500);

  //     return () => clearTimeout(timer);
  //   }
  // }, [loading]);

  return (
    <div>
      {/* {loading ? (
        <div className={`preloader ${preloaderClass}`}>
          <Preloader />
        </div>
      ) : ( */}
        <>
          {/* <Navbar /> */}
          {/* <NotificationBar /> */}
          <Suspense fallback={<Spinner />}>
            <Landingpage />
          </Suspense>
          <CardStack cards={cards} />
          <InTheNews />
          <div id="ourservices-section" ref={ourservicesRef}>
            <Suspense fallback={<Spinner />}>
              <Ourservices />
            </Suspense>
          </div>
          {/* <Suspense fallback={<Spinner />}>
                        <BlogComponent />
                    </Suspense> */}
          {/* <Suspense fallback={<Spinner />}>
                        <Testimonial1 />
                    </Suspense> */}
          <Footer scrollToOurservices={scrollToOurservices} />
        </>
      {/* )} */}
    </div>
  );
};

export default MainComponent;
