import React from "react";

import { useOutletContext } from "react-router-dom";

import { FaFilePdf } from "react-icons/fa6";

const OrderSheet = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  return (
    <div className="flex flex-col gap-4 px-6 pb-6 border-b border-b-[#d9d9d9] dark:border-b-gray-600">
      <div className="flex flex-col gap-2 border border-[#d9d9d9] dark:border-gray-600 rounded-lg px-3 py-4">
        <div className="flex justify-between items-center">
          <div className="flex gap-4">
            <FaFilePdf className="text-2xl text-red-600 dark:text-red-400" />
            <p className="font-light">Complete Order Sheet</p>
          </div>
          <a
            className="font-raleway px-2 border-b-2 dark:border-gray-600 font-medium"
            href={caseDetails.merged_pdf}
            target="_blank"
            rel="noreferrer"
          >
            Read Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrderSheet;
