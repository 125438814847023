import React from 'react';
import Slider from 'react-slick';
import '../assets/css/blog.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import blogImage from '../assets/images/Blogs/Rectangle 6.png';
import blogImage2 from '../assets/images/Blogs/Rectangle 6 (2).png'
import blogImage3 from '../assets/images/Blogs/Rectangle 6 (1).png'
import image1 from '../assets/images/Blogs/image 1.png'
import image2 from '../assets/images/Blogs/shalini_gupta.webp'
import image3 from '../assets/images/Blogs/Ellipse 4 (1).png'

const BlogComponent = () => {
    // Dummy blog data (replace this with your actual blog data)
    const blogs = [
        {
            id: 1,
            title: 'Navigating the Legal Landscape: A Comprehensive Guide for Small Businesses',
            image: blogImage,
            content: 'Dive into the Legal Lens Chronicles, where our experienced lawyers unravel intricate legal stories, decode complex ...',
            date: 'Sep 3 2023',
            author: {
                name: 'Arvind Kumar',
                Disc: 'Corporate lawyer',
                photo: image1,
            },
        },
        // {
        //     id: 2,
        //     title: 'Digital Dilemmas: Understanding Cybersecurity Laws in an Interconnected World',
        //     image: blogImage3,
        //     content: 'As our world becomes increasingly digital, so do the legal challenges. In this blog, Ill unravel the complexities of...',
        //     date: 'Jun 28 2023',
        //     author: {
        //         name: 'Shalini Gupta',
        //         Disc: 'Cyber Lawyer',
        //         photo: image2,
        //     },
        // },
        {
            id: 3,
            title: 'The Legal Side of Family Matters: A Compassionate Approach to Divorce and Custody Battles',
            image: blogImage2,
            content: 'Divorce and custody battles can be emotionally charged, but they also involve intricate legal processes.....',
            date: 'Aug 13 2023',
            author: {
                name: 'Aman Sharma',
                Disc: 'Family lawyer',
                photo: image3,
            },
        },
        {
            id: 1,
            title: 'Navigating the Legal Landscape: A Comprehensive Guide for Small Businesses',
            image: blogImage,
            content: 'Dive into the Legal Lens Chronicles, where our experienced lawyers unravel intricate legal stories, decode complex ...',
            date: 'Sep 3 2023',
            author: {
                name: 'Arvind Kumar',
                Disc: 'Corporate lawyer',
                photo: image1,
            },
        },
        // {
        //     id: 2,
        //     title: 'Digital Dilemmas: Understanding Cybersecurity Laws in an Interconnected World',
        //     image: blogImage3,
        //     content: 'As our world becomes increasingly digital, so do the legal challenges. In this blog, Ill unravel the complexities of...',
        //     date: 'Jun 28 2023',
        //     author: {
        //         name: 'Shalini Gupta',
        //         Disc: 'Cyber Lawyer',
        //         photo: image2,
        //     },
        // },
        {
            id: 3,
            title: 'The Legal Side of Family Matters: A Compassionate Approach to Divorce and Custody Battles',
            image: blogImage2,
            content: 'Divorce and custody battles can be emotionally charged, but they also involve intricate legal processes.....',
            date: 'Aug 13 2023',
            author: {
                name: 'Aman Sharma',
                Disc: 'Family lawyer',
                photo: image3,
            },
        },
        // Add more blogs as needed
    ];


    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className='blog-container'>
            <h2 className='text-center'><span style={{ color: '#5D54F1' }}>Trending Blogs</span> And News</h2>
            <div className='blogs-container'>
                <div className='blog-section'>
                <Slider {...settings}>
                    {blogs.map((blog) => (
                        <div key={blog.id} className='blog-item'>
                            <img
                                src={blog.image}
                                alt="Blog Thumbnail"
                                style={{ borderRadius: '12px', width: '100%', height: 'auto' }}
                            />
                            <div className='flex mt-3' style={{ display: 'flex', alignItems: 'center' }}>
                                <p className='mr-2'>INTERNET</p>
                                <p className='mr-2'>. {blog.date}</p>
                            </div>
                            <h3>{blog.title}</h3>
                            <p style={{ maxWidth: '300px' }}>{blog.content}</p>
                            <div className='blog' style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={blog.author.photo}
                                    alt={`Author ${blog.author.name}`}
                                    style={{ borderRadius: '50px', marginRight: '10px', width: '50px', height: '50px' }}
                                />
                                <div>
                                    <p style={{ marginTop: '8px', fontWeight: '400' }}>{blog.author.name}</p>
                                    <p style={{ marginTop: '-12px', fontSize: '14px' }}>{blog.author.Disc}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                </div>
            </div>
        </div>
    );
};

export default BlogComponent;
