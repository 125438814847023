import React from 'react'
import Navbar from './Navbar'
import '../assets/css/comingSoon.css'
import soon from '../assets/images/comingSoon/soon.svg'
import inputIcon from '../assets/images/comingSoon/input-icon.svg'
import performanceImage from '../assets/images/ourservices/performance-analysis.png';


export default function PerformanceAnalytics() {
    return (
        <div>
            <Navbar />
            <div className="comingSoon-container">
                <div className="comingsoon-container-contain">
                    <div className="coming-soon-upper-section">
                        <h1><span style={{ color: '#5d54f1' }}>Coming</span> Soon</h1>
                        <p>Working on something amazing..</p>
                        <hr className="loader-divider" />
                    </div>
                    <div className="coming-soon-main-content">
                        <div className="coming-soon-content-section">
                            <div className="coming-soon-text-content">
                                <h3>Performance Analytics</h3>
                                <p>Identifying top-performing lawyers by leveraging advanced data tracking and reporting capabilities to make informed decisions</p>
                                <div className="list-item">
                                    <ul>
                                        <li>Data Tracking & Reports</li>
                                        <li>Dashboard & Real-Time Monitoring</li>
                                        <li>Client Feedback & Predictions</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="img-content">
                                <img src={performanceImage} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="coming-soon-right-section">

                        <div className="input-section">
                            <img src={inputIcon} alt="" />
                            <div className="coming-soon-mobile-section">
                                <input type="text"
                                    placeholder='Enter Email'
                                />
                                <button>Notify Me</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
