export const caseTypes = [
  "ADMIN.REPORT",
  "ARB. A. (COMM.)",
  "ARB.A.",
  "ARB.P.",
  "BAIL APPLN.",
  "C.A.(COMM.IPD-GI)",
  "C.A.(COMM.IPD-PAT)",
  "C.A.(COMM.IPD-PV)",
  "C.A.(COMM.IPD-TM)",
  "C.O.",
  "C.O. (COMM.IPD-TM)",
  "C.O.(COMM.IPD-CR)",
  "C.O.(COMM.IPD-GI)",
  "C.O.(COMM.IPD-PAT)",
  "C.R.P.",
  "C.REF.(O)",
  "C.RULE",
  "CA",
  "CA (COMM.IPD-CR)",
  "CAVEAT(CO.)",
  "CC(ARB.)",
  "CCP(CO.)",
  "CCP(REF)",
  "CEAC",
  "CEAR",
  "CM(M)",
  "CM(M)-IPD",
  "CMI",
  "CO.A(SB)",
  "CO.APP.",
  "CO.APPL.(C)",
  "CO.APPL.(M)",
  "CO.EX.",
  "CO.PET.",
  "CONT.APP.(C)",
  "CONT.CAS(C)",
  "CONT.CAS.(CRL)",
  "CRL.A.",
  "CRL.L.P.",
  "CRL.M.(CO.)",
  "CRL.M.C.",
  "CRL.M.I.",
  "CRL.O.",
  "CRL.O.(CO.)",
  "CRL.REF.",
  "CRL.REV.P.",
  "CRP-IPD",
  "CS(COMM)",
  "CS(OS)",
  "CS(OS) GP",
  "CUS.A.C.",
  "CUS.A.R.",
  "CUSAA",
  "CUSTOM A.",
  "DEATH SENTENCE REF.",
  "DEMO",
  "EDC",
  "EDR",
  "EFA(OS)",
  "EFA(OS) (COMM)",
  "EFA(OS)(IPD)",
  "EL.PET.",
  "ETR",
  "EX.F.A.",
  "EX.P.",
  "EX.S.A.",
  "FAO",
  "FAO (COMM)",
  "FAO(OS)",
  "FAO(OS) (COMM)",
  "FAO(OS)(IPD)",
  "FAO-IPD",
  "GCAC",
  "GCAR",
  "GTA",
  "GTC",
  "GTR",
  "I.P.A.",
  "ITA",
  "ITC",
  "ITR",
  "ITSA",
  "LA.APP.",
  "LPA",
  "MAC.APP.",
  "MAT.",
  "MAT.APP.",
  "MAT.APP.(F.C.)",
  "MAT.CASE",
  "MAT.REF.",
  "MISC. APPEAL(PMLA)",
  "O.M.P.",
  "O.M.P. (COMM)",
  "O.M.P. (E)",
  "O.M.P. (E) (COMM.)",
  "O.M.P. (J) (COMM.)",
  "O.M.P. (MISC.)",
  "O.M.P. (T) (COMM.)",
  "O.M.P.(EFA)(COMM.)",
  "O.M.P.(I)",
  "O.M.P.(I) (COMM.)",
  "O.M.P.(MISC.)(COMM.)",
  "O.M.P.(T)",
  "O.REF.",
  "OA",
  "OCJA",
  "OMP (CONT.)",
  "OMP (ENF.) (COMM.)",
  "RC.REV.",
  "RC.S.A.",
  "RF(OS)(IPD)",
  "RFA",
  "RFA(COMM)",
  "RFA(OS)",
  "RFA(OS)(COMM)",
  "RFA-IPD",
  "RSA",
  "SCA",
  "SDR",
  "SERTA",
  "ST.APPL.",
  "ST.REF.",
  "STC",
  "SUR.T.REF.",
  "TEST.CAS.",
  "TR.P.(C)",
  "TR.P.(C.)",
  "TR.P.(CRL.)",
  "VAT APPEAL",
  "W.P.(C)",
  "W.P.(C)-IPD",
  "W.P.(CRL)",
  "WP(C)(IPD)",
  "WTA",
  "WTC",
  "WTR",
];

export const years = [
  2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012,
  2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000, 1999,
];
