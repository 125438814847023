import { createContext, useState } from "react";

export const ThemeContext = createContext(
  localStorage.getItem("theme") === "dark"
);

export const ThemeProvider = ({ children }) => {
  const [themeToggle, setThemeToggle] = useState(
    localStorage.getItem("theme") === "dark"
  );

  if (themeToggle) {
    // document.body.classList.add("dark");
    let root = document.documentElement;
    root.className = "dark";
  } else {
    // document.body.classList.remove("dark");
    let root = document.documentElement;
    root.className = "";
  }

  const handleThemeToggle = () => {
    if (!themeToggle) {
      setThemeToggle((prev) => !prev);
      localStorage.theme = "dark";
    } else {
      setThemeToggle((prev) => !prev);
      localStorage.theme = "";
    }
  };

  return (
    <ThemeContext.Provider value={{ themeToggle, handleThemeToggle }}>
      {children}
    </ThemeContext.Provider>
  );
};
