import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
// import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./context/Authcontext"; // Import AuthProvider
import "./globals.css";
import { ThemeProvider } from "./context/ThemeContext";

const root = createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="246105724090-95arttgdnp0sohiaqqtvpctr875kassq.apps.googleusercontent.com">
    <AuthProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </GoogleOAuthProvider>
);
