import React from "react";
import caseDetailswaiting from "../assets/images/comingSoon/CaseWaitingPage.svg";
import "../assets/css/waitlist.css";
import Navbar from "./Navbar";
import Waitlistrobo from "../assets/images/comingSoon/waitList.svg";

export default function WaitList() {
  return (
    <div>
      {/* <Navbar/> */}
      <div className="waitLis-app-container dark:bg-dark-600 h-full mt-12 md:h-screen">
        <div className="waitList-container-contain">
          <h1>
            Thank you for signing up! You’re now on the CaseMinister waitlist
          </h1>
          <p>
            Stay tuned for updates and be among the first to experience the
            future of legal technology.
          </p>
        </div>
        <div className="WaitList-Right-Side">
          <img
            src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/waitList.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
