import React from 'react';
import lawerProfileIcon from '../assets/images/profile/mobile-user-icon.png';
import '../assets/css/Filteredlawyer.css';

function LawyerProfile() {
    const lawyer = {
        name: 'John Doe',
        ongoingCases: [
            { name: 'Case Name 1', description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.' },
            { name: 'Case Name 2', description: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.' },
        ],
    };

    return (
        <div className='lawyerProfile'>
            <div className="profileimagetext">
                <img src={lawerProfileIcon} alt="" />
                <h2>{lawyer.name}</h2>
            </div>
            <div className="below-content">
                <h3>Ongoing Cases</h3>
                {lawyer.ongoingCases.map((caseInfo, index) => (
                    <div key={index} className="lawyer-case-contain">
                        <div className='caseInfo'>
                            <p>{caseInfo.name}: {caseInfo.description}</p>
                        </div>
                        <p><span style={{ color: '#5d54f1' }}>Next Hearing Date:</span></p>
                    </div>
                ))}
                <div className="lastUpdate">
                    <h3>Update</h3>
                    <p>case name</p>
                    <p>Quick update</p>
                </div>
            </div>
        </div>
    );
}

export default LawyerProfile;
