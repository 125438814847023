import React, { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const storedAuth = sessionStorage.getItem('isAuthenticated');
        return storedAuth === 'true';
    });

    useEffect(() => {
        if (isAuthenticated) {
            const loginTime = new Date().getTime();
            sessionStorage.setItem('userLoggedInTime', loginTime);
        }
    }, [isAuthenticated]);

    const login = () => {
        sessionStorage.setItem('isAuthenticated', 'true');
        setIsAuthenticated(true);
    };

    const logout = () => {
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('userLoggedInTime');
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
