import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/LandingPage.css"; // Ensure the path is correct
import delhi_high_court from "../assets/images/landingpage/DelhiHC.png";
import delhi_bar_association from "../assets/images/landingpage/BarCouncilDelhi.png";
// import punjab_high_court from "../assets/images/landingpage/punjab_bar.png";
import punjab_bar_association from '../assets/images/landingpage/RajhasthanHC.png'
import punjab_high_court from "../assets/images/landingpage/Punjab&HaryanaHC.png";

const images = [
  {
    id: 2,
    image: delhi_high_court,
    height: "100%",
    margin: "0px",
    content: "NVIDIA",
  },
  {
    id: 1,
    image: delhi_bar_association,
    height: "100%",
    margin: "0px",
    content: "Microsoft",
  },
  {
    id: 3,
    image: punjab_high_court,
    height: "100%",
    margin: "0px",
    content: "NVIDIA",
  },
  {
    id: 4,
    image: punjab_bar_association,
    height: "100%",
    margin: "0px",
    content: "NVIDIA",
  },
];

const SupportrdWith = () => {
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false,
    centerPadding: "0",
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="integrated-with">
      <Slider {...settings}>
        {images.map((img) => (
          <div key={img.id} className="slide">
            <div className="integrated-image-boxp-3 rounded-lg shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.2)] bg-light-600 dark:bg-gray-700 flex justify-center items-center p-4">
              <img
                src={img.image}
                alt={img.content}
                style={{ width:"200px" }}
                className="testimonial-image w-[100%]"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SupportrdWith;
