// React Utils
import React, { useState } from "react";

// Making API Calls
import axiosInstance from "../utils/axiosInstance";

// React Router
import { useNavigate, useParams } from "react-router-dom";

// Toast Library for Notifications
import { toast } from "react-toastify";

// React Icons
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const PasswordReset = () => {
  // Setting Password States
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Getting the OTP and Token for Resetting the Password.
  const { otp, token } = useParams();
  // To navigate to login page after Successful Reset of the Password.
  const navigate = useNavigate();

  // Showing the Passwords onClick.
  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  // Making an API Call for Reseting the Password.
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.trim() === confirmPassword.trim()) {
      try {
        const newPasswordResponse = await axiosInstance.post(
          `api/reset/${otp}/${token}/`,
          { password: password },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        );
        if (newPasswordResponse.status === 200) {
          toast.success(newPasswordResponse.data.detail, {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        navigate("/login");
      } catch (err) {
        console.log(err);
        toast.error(err, {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Passwords does not match.", {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="flex justify-center items-center h-screen dark:bg-dark-600 bg-light-600">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-10 items-center border-sh-dark-500 w-[420px] px-10 py-20 rounded-md dark:bg-sh-dark-500 bg-indigo-100"
      >
        <div className="flex flex-col gap-2 w-full">
          <label
            htmlFor="password"
            className="text-gray-700 dark:text-gray-300"
          >
            New Password
          </label>
          <div className="relative w-full select-none">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Type your new password..."
              required
              className="rounded-md h-10 px-4 py-2 dark:bg-dark-500 dark:text-gray-300 w-full"
            />
            {showPassword ? (
              <FaEyeSlash
                className="absolute top-1/2 right-3 translate-y-[-50%] cursor-pointer dark:invert-[1] text-sm"
                onClick={handleShowPassword}
              />
            ) : (
              <FaEye
                className="absolute top-1/2 right-3 translate-y-[-50%] cursor-pointer dark:invert-[1] text-sm"
                onClick={handleShowPassword}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-2 w-full">
          <label
            htmlFor="confirm-password"
            className="text-gray-700 dark:text-gray-300"
          >
            Confirm New Password
          </label>
          <div className="relative w-full select-none">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirm-password"
              name="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password..."
              required
              className="rounded-md px-4 py-2 dark:bg-dark-500 dark:text-gray-300 w-full"
            />
            {showConfirmPassword ? (
              <FaEyeSlash
                className="absolute top-1/2 right-3 translate-y-[-50%] cursor-pointer dark:invert-[1] text-sm"
                onClick={handleShowConfirmPassword}
              />
            ) : (
              <FaEye
                className="absolute top-1/2 right-3 translate-y-[-50%] cursor-pointer dark:invert-[1] text-sm"
                onClick={handleShowConfirmPassword}
              />
            )}
          </div>
        </div>
        <button
          type="submit"
          className="max-sm:text-xs px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[2px_2px_0px_0px_gray] transition duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default PasswordReset;
