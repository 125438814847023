import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from "../utils/axiosInstance";
import "../assets/css/UserProfile.css"; // Make sure to create this CSS file and import it here
import image from "../assets/images/profile/User_box_duotone.svg";
import mobileImage from "../assets/images/profile/mobile-user-icon.png";
import Navbar from "./Navbar";
import email from "../assets/images/profile/Message_fill.svg";
import phone from "../assets/images/profile/Phone_fill.svg";
import address from "../assets/images/profile/Pin_fill.svg";
import rightcorner from "../assets/images/profile/right-corner-vec.svg";
import firstname from "../assets/images/profile/first_name.svg";
import email1 from "../assets/images/profile/email.svg";
import mobile from "../assets/images/profile/mobile.svg";
import address1 from "../assets/images/profile/addres.svg";
import { toast } from "react-toastify";
import rightedit from "../assets/images/profile/rightofedit.svg";
import LawyerProfile from "./LawyerProfile";
import mobileUploadImage from "../assets/images/profile/mobileUploadImage.png";
import upgradeLawyer from "../assets/images/profile/upgradeToLawyer.svg";

const UserProfile = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    mobileNo: "",
    state: "",
    city: "",
    pinCode: "",
    bio: "",
    is_lawyer: false,
  });

  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const access_token = sessionStorage.getItem("access_token");

  const handleUpgrade = async () => {
    try {
      await axiosInstance.patch(
        "api/user/500/",
        { is_lawyer: true },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success("Successfully upgraded to lawyer profile");
      setUserData((prevState) => ({
        ...prevState,
        is_lawyer: true,
      }));
      fetchUserData();
    } catch (error) {
      console.error("Error upgrading to lawyer profile:", error);
      toast.error("Failed to upgrade to lawyer profile");
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get("api/profile/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const userDetails = response.data[0];
      const user = userDetails.user;

      setUserData({
        firstName: user.first_name || "",
        lastName: user.last_name || "",
        email: user.email || "",
        dateOfBirth: userDetails.dob || "",
        mobileNo: userDetails.mobile_number || "",
        state: userDetails.state || "",
        city: userDetails.city || "",
        pinCode: userDetails.pincode || "",
        bio: userDetails.bio || "",
        is_lawyer: user.is_lawyer || "",
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [access_token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateUserDetails();
    await updateProfileDetails();

    toast.success("Your Profile is updated successfully.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    fetchUserData();
    console.log("User and profile data updated");
  };

  const updateUserDetails = async () => {
    try {
      const updatedUserDetails = {
        email: userData.email,
        first_name: userData.firstName,
        last_name: userData.lastName,
      };
      await axiosInstance.patch("api/user/500/", updatedUserDetails, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const updateProfileDetails = async () => {
    try {
      const updatedProfileDetails = {
        state: userData.state,
        address: userData.address,
        city: userData.city,
        pincode: userData.pinCode,
        mobile_number: userData.mobileNo,
        bio: userData.bio,
      };
      await axiosInstance.patch("api/profile/500/", updatedProfileDetails, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      console.error("Error updating profile details:", error);
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      {userData.is_lawyer ? (
        <LawyerProfile />
      ) : (
        <div className="profile-container bg-light-600 dark:bg-dark-600 h-screen pt-20 m-0">
          <div className="left-section bg-gray-200 dark:bg-dark-400">
            <div className="profile-sidebar">
              <img
                src={image}
                alt=""
                className="laptopImage invert-[1] dark:invert-[0]"
              />
              <img src={mobileImage} alt="" className="mobileIcon" />
            </div>
            <h4 className="laptopName text-gray-700 dark:text-gray-200">
              {userData.firstName} {userData.lastName}
            </h4>
            <div className="mobile-content">
              <div className="contact-details">
                <h4 className="mobileName text-gray-700 dark:text-gray-200">
                  {userData.firstName} {userData.lastName}
                </h4>
                <h6 className="contactName text-gray-700 dark:text-gray-200">
                  Contact Detail
                </h6>
                <div className="image-text">
                  <img src={email} alt="" className="contactImages" />
                  <div className="contact-item text-gray-700 dark:text-gray-200">
                    <span className="label">Email</span>
                    <span className="value">
                      <a href={`mailto:${userData.email}`}>{userData.email}</a>
                    </span>
                  </div>
                </div>
                <div className="contact-item text-gray-700 dark:text-gray-200">
                  <div className="image-text">
                    <img src={phone} alt="" className="contactImages" />
                    <div className="contact-item text-gray-700 dark:text-gray-200">
                      <span className="label">Phone No</span>
                      <span className="value">{userData.mobileNo}</span>
                    </div>
                  </div>
                </div>
                <div className="contact-item text-gray-700 dark:text-gray-200">
                  <div className="image-text">
                    <img src={address} alt="" className="contactImages" />
                    <div className="contact-item text-gray-700 dark:text-gray-200">
                      <span className="label">Address</span>
                      <span className="value">{userData.address}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mobile-second-right-section border rounded-md"
            onClick={handleUpgrade}
          >
            <div className="second-right-section-content">
              <div className="middleContent">
                <div className="firstText">
                  <p className="dark:text-gray-200">
                    If you're a lawyer looking to increase your visibility,{" "}
                    <span className="text-gray-900">
                      Upgrade to Lawyer's Profile
                    </span>
                  </p>
                </div>
                <div className="secondIm">
                  <img src={mobileUploadImage} alt="" className="invert-[1]" />
                </div>
              </div>
            </div>
          </div>
          <div className="right-section bg-gray-300 dark:bg-dark-500 pt-5">
            <h2 className="text-gray-700 dark:text-gray-300 text-3xl">
              User Profile
            </h2>
            <div
              className="edit flex gap-2"
              onClick={() => setIsEditing(!isEditing)}
            >
              <img
                src={rightedit}
                alt=""
                className="editButton dark:invert-[1]"
              />
              <i className="edit-button dark:text-gray-300">
                {isEditing ? "Cancel" : "Edit"}
              </i>
            </div>
            <form onSubmit={handleSubmit} className="dark:text-gray-300">
              <div className="form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    First Name
                  </label>
                </div>
                <input
                  type="text"
                  name="firstName"
                  value={userData.firstName}
                  className="input-with-icon bg-white border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
                <span className="input-icon">
                  {/* <img src={firstname} alt="" /> */}
                </span>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    Last Name
                  </label>
                </div>
                <input
                  type="text"
                  name="lastName"
                  value={userData.lastName}
                  className="input-with-icon bg-white border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
                <span className="input-icon">
                  {/* <img src={firstname} alt="" /> */}
                </span>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    Email
                  </label>
                </div>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  className="input-with-icon bg-white border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
                <span className="input-icon">
                  {/* <img src={email1} alt="" /> */}
                </span>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    Date Of Birth
                  </label>
                </div>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={userData.dateOfBirth}
                  className="input-with-icon bg-white border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    Mobile No
                  </label>
                </div>
                <input
                  type="text"
                  name="mobileNo"
                  value={userData.mobileNo}
                  className="input-with-icon bg-white border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
                <span className="input-icon">
                  {/* <img src={mobile} alt="" /> */}
                </span>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    State
                  </label>
                </div>
                <select
                  className="form-control border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  name="state"
                  value={userData.state}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                >
                  {[
                    "Andaman and Nicobar Islands",
                    "Andhra Pradesh",
                    "Arunachal Pradesh",
                    "Assam",
                    "Bihar",
                    "Chandigarh",
                    "Chhattisgarh",
                    "Dadra and Nagar Haveli and Daman and Diu",
                    "Delhi",
                    "Goa",
                    "Gujarat",
                    "Haryana",
                    "Himachal Pradesh",
                    "Jharkhand",
                    "Karnataka",
                    "Kerala",
                    "Lakshadweep",
                    "Madhya Pradesh",
                    "Maharashtra",
                    "Manipur",
                    "Meghalaya",
                    "Mizoram",
                    "Nagaland",
                    "Odisha",
                    "Puducherry",
                    "Punjab",
                    "Rajasthan",
                    "Sikkim",
                    "Tamil Nadu",
                    "Telangana",
                    "Tripura",
                    "Uttar Pradesh",
                    "Uttarakhand",
                    "West Bengal",
                  ].map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <span className="lawyer-input-icon">
                  {/* <img src={address1} alt="" /> */}
                </span>
              </div>

              <div className="form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    City
                  </label>
                </div>
                <select
                  className="form-control border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  name="city"
                  value={userData.city}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                >
                  {[
                    "Agartala",
                    "Agra",
                    "Ahmedabad",
                    "Ahmednagar",
                    "Aizawl",
                    "Ajmer",
                    "Akola",
                    "Aligarh",
                    "Allahabad",
                    "Alwar",
                    "Ambala",
                    "Ambattur",
                    "Ambernath",
                    "Amravati",
                    "Amritsar",
                    "Anand",
                    "Anantapur",
                    "Anantapuram",
                    "Arrah",
                    "Asansol",
                    "Aurangabad",
                    "Avadi",
                    "Bagasara",
                    "Bahraich",
                    "Ballia",
                    "Bally",
                    "Bangalore",
                    "Baran",
                    "Baranagar",
                    "Barasat",
                    "Bareilly",
                    "Bardhaman",
                    "Bathinda",
                    "Beawar",
                    "Begusarai",
                    "Belgaum",
                    "Bellary",
                    "Bhagalpur",
                    "Bhalswa Jahangir Pur",
                    "Bharatpur",
                    "Bharuch",
                    "Bhavnagar",
                    "Bhilai",
                    "Bhilwara",
                    "Bhiwandi",
                    "Bhiwani",
                    "Bhopal",
                    "Bhubaneswar",
                    "Bhuj",
                    "Bidhannagar",
                    "Bidar",
                    "Bihar Sharif",
                    "Bijapur",
                    "Bikaner",
                    "Bilaspur",
                    "Bilimora",
                    "Bokaro",
                    "Bulandshahr",
                    "Burhanpur",
                    "Chandigarh",
                    "Chandrapur",
                    "Chennai",
                    "Chhatarpur",
                    "Chhindwara",
                    "Chittoor",
                    "Chittorgarh",
                    "Coimbatore",
                    "Cuddalore",
                    "Cuttack",
                    "Dabhoi",
                    "Damoh",
                    "Darbhanga",
                    "Davanagere",
                    "Dewas",
                    "Dhanbad",
                    "Dhule",
                    "Dindigul",
                    "Durg",
                    "Durgapur",
                    "Erode",
                    "Etawah",
                    "Faizabad",
                    "Faridabad",
                    "Farrukhabad",
                    "Fatehpur",
                    "Firozabad",
                    "Gandhidham",
                    "Gandhinagar",
                    "Gaya",
                    "Ghaziabad",
                    "Gopalpur",
                    "Gorakhpur",
                    "Gulbarga",
                    "Guna",
                    "Guntur",
                    "Gurgaon",
                    "Guwahati",
                    "Gwalior",
                    "Haldia",
                    "Haldwani",
                    "Hapur",
                    "Haridwar",
                    "Hazaribagh",
                    "Himatnagar",
                    "Hindupur",
                    "Hisar",
                    "Hospet",
                    "Howrah",
                    "Hubballi-Dharwad",
                    "Hyderabad",
                    "Ichalkaranji",
                    "Imphal",
                    "Indore",
                    "Jabalpur",
                    "Jaipur",
                    "Jalandhar",
                    "Jalgaon",
                    "Jalna",
                    "Jamnagar",
                    "Jamshedpur",
                    "Jhansi",
                    "Jodhpur",
                    "Junagadh",
                    "Kadapa",
                    "Kaithal",
                    "Kakinada",
                    "Kalyan-Dombivli",
                    "Kamarhati",
                    "Kancheepuram",
                    "Kanchrapara",
                    "Kannur",
                    "Kanpur",
                    "Kapurthala",
                    "Karimnagar",
                    "Karnal",
                    "Karawal Nagar",
                    "Katni",
                    "Khammam",
                    "Khandwa",
                    "Khanna",
                    "Kharagpur",
                    "Khora, Ghaziabad",
                    "Kochi",
                    "Kohima",
                    "Kolar",
                    "Kolhapur",
                    "Kolkata",
                    "Kollam",
                    "Korba",
                    "Kota",
                    "Kottayam",
                    "Kozhikode",
                    "Kumbakonam",
                    "Kurnool",
                    "Kurukshetra",
                    "Lakhimpur",
                    "Latur",
                    "Loni",
                    "Lucknow",
                    "Ludhiana",
                    "Madurai",
                    "Mahesana",
                    "Maheshtala",
                    "Malegaon",
                    "Mangalore",
                    "Mathura",
                    "Mau",
                    "Meerut",
                    "Moradabad",
                    "Mormugao",
                    "Morena",
                    "Muzaffarnagar",
                    "Muzaffarpur",
                    "Mysore",
                    "Nadiad",
                    "Nagercoil",
                    "Nagpur",
                    "Naihati",
                    "Nalgonda",
                    "Nanded",
                    "Nashik",
                    "Navsari",
                    "Navi Mumbai",
                    "Nawada",
                    "Nellore",
                    "New Delhi",
                    "Nizamabad",
                    "Ongole",
                    "Orai",
                    "Palanpur",
                    "Palghar",
                    "Pali",
                    "Panaji",
                    "Panipat",
                    "Parbhani",
                    "Patiala",
                    "Patna",
                    "Pilibhit",
                    "Pimpri-Chinchwad",
                    "Pondicherry",
                    "Porbandar",
                    "Proddatur",
                    "Pudukkottai",
                    "Pune",
                    "Puri",
                    "Purnia",
                    "Raebareli",
                    "Raichur",
                    "Raiganj",
                    "Raipur",
                    "Rajahmundry",
                    "Rajapalayam",
                    "Rajgarh",
                    "Rajkot",
                    "Rajpur Sonarpur",
                    "Ramachandrapuram",
                    "Ramagundam",
                    "Rampur",
                    "Ranchi",
                    "Ratlam",
                    "Raurkela",
                    "Rewa",
                    "Rohtak",
                    "Roorkee",
                    "Rourkela",
                    "Sagar",
                    "Saharanpur",
                    "Salem",
                    "Sambalpur",
                    "Sambhal",
                    "Sangli-Miraj & Kupwad",
                    "Satna",
                    "Secunderabad",
                    "Sehore",
                    "Shahdol",
                    "Shahjahanpur",
                    "Shillong",
                    "Shimla",
                    "Shimoga",
                    "Shivpuri",
                    "Siliguri",
                    "Singrauli",
                    "Sitapur",
                    "Solapur",
                    "Sonipat",
                    "South Dumdum",
                    "Sri Ganganagar",
                    "Srinagar",
                    "Surat",
                    "Surendranagar",
                    "Tadepalligudem",
                    "Tenali",
                    "Thane",
                    "Thanjavur",
                    "Thiruvananthapuram",
                    "Thoothukudi",
                    "Thrissur",
                    "Tiruchirappalli",
                    "Tirunelveli",
                    "Tirupati",
                    "Tiruppur",
                    "Tumkur",
                    "Udaipur",
                    "Udupi",
                    "Ujjain",
                    "Ulhasnagar",
                    "Uluberia",
                    "Unnao",
                    "Vadodara",
                    "Valsad",
                    "Varanasi",
                    "Vasai-Virar",
                    "Vellore",
                    "Veraval",
                    "Vidisha",
                    "Vijayawada",
                    "Vijayanagaram",
                    "Vikarabad",
                    "Villupuram",
                    "Visakhapatnam",
                    "Warangal",
                    "Wardha",
                    "Yamunanagar",
                  ].map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                <span className="lawyer-input-icon">
                  {/* <img src={address1} alt="" /> */}
                </span>
              </div>
              <div className="form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    Pin Code
                  </label>
                </div>
                <input
                  type="text"
                  name="pinCode"
                  value={userData.pinCode}
                  className="input-with-icon bg-white border border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </div>
              <div className="user-profile-form-group">
                <div className="label-container">
                  <label className="dark:text-gray-300">
                    <span className="label-icon"></span>
                    Bio
                  </label>
                </div>
                <textarea
                  name="bio"
                  value={userData.bio}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  className="border bg-white border-gray-500 dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                ></textarea>
              </div>
            </form>
            {isEditing && (
              <div className="update-button">
                <button
                  type="submit"
                  className="max-sm:text-xs px-5 py-2 dark:bg-dark-600 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#313134] transition duration-200"
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            )}
          </div>
          <div className="second-right-side">
            <div className="box-parent">
              <div className="border-box border-none dark:bg-dark-400 bg-light-500">
                <p className="dark:text-white">
                  If you're a lawyer looking to increase your visibility, join
                  our platform.
                </p>
              </div>
            </div>
            <div className="button-container" onClick={handleUpgrade}>
              <button className="custom-button max-sm:text-xs px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#313134] transition duration-200">
                <span className="button-text">
                  Upgrade to <br /> Lawyer's Profile
                </span>
                <span className="button-icon">
                  <img
                    src={upgradeLawyer}
                    alt=""
                    className="invert-[1] dark:invert-0"
                  />
                </span>
              </button>
            </div>
          </div>
          <div className="right-corner-image">
            {/* <img src={rightcorner} alt="" /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
