import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import '../assets/css/1.css';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import img1 from '../assets/images/Testimonials/Ellipse 7.png';
import img2 from '../assets/images/Testimonials/Ellipse 7 (1).png';
import img3 from '../assets/images/Testimonials/Ellipse 7 (2).png';
import img4 from '../assets/images/Testimonials/Ellipse 7 (2).png';
import forarrow from '../assets/images/Testimonials/for-arrow.svg';
import backarrow from '../assets/images/Testimonials/back-arrow.svg';
import Startrate from './Startrate';
const images = [
    {
        id: 1,
        image: img1,
        content: 'Case Ministers AI tool has made a big difference in how I handle cases. Its easy to use and helps me make smart decisions quickly, so I can do the best for my clients.',
        author: {
            name: 'Rahul Singh',
            Disc: 'Civil lawyer',
        },
    },
    {
        id: 2,
        image: img2,
        content: 'Case Ministers AI tool has made a big difference in how I handle cases. Its easy to use and helps me make smart decisions quickly, so I can do the best for my clients.',
        author: {
            name: 'Shalini Gupta',
            Disc: 'Cyber Lawyer',
        },
    },
    {
        id: 3,
        image: img3,
        content: 'Case Ministers AI tool has made a big difference in how I handle cases. Its easy to use and helps me make smart decisions quickly, so I can do the best for my clients..',
        author: {
            name: 'Shalini Gupta',
            Disc: 'Cyber Lawyer',
        },
    },
    {
        id: 4,
        image: img4,
        content: 'Case Ministers AI tool has made a big difference in how I handle cases. Its easy to use and helps me make smart decisions quickly, so I can do the best for my clients..',
        author: {
            name: 'Shalini Gupta',
            Disc: 'Cyber Lawyer',
        },
    },
];

function SampleNextArrow({ onClick }) {
    return (
        <div className='arrow arrow-right' onClick={onClick}>
            <img src={forarrow} alt="Next" />
        </div>
    );
}

function SamplePrevArrow({ onClick }) {
    return (
        <div className='arrow arrow-left' onClick={onClick}>
            <img src={backarrow} alt="Previous" />
        </div>
    );
}

function EmptyArrow() {
    // return <div></div>;
}

const Testimonial1 = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [prevSlideIndex, setPrevSlideIndex] = useState(0);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        cssEase: 'ease-in-out',
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setSlideIndex(next),
        afterChange: (current) => setPrevSlideIndex(current),
        centerMode: false, // Disable center mode
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        appendDots: (dots) => (
            <div>
                <ul style={{ margin: '0px' }}>{dots}</ul>
            </div>
        ),
        customPaging: (i) => (
            <div className={i === slideIndex ? 'dot dot-active' : 'dot'}></div>
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    nextArrow: <EmptyArrow />,
                    prevArrow: <EmptyArrow />,
                    centerMode: false, 
                },
            },
        ],
    };


    const getSlideClass = (index) => {
        if (index === slideIndex) {
            return 'no-blur';
        } else if (index === prevSlideIndex - 1 || (prevSlideIndex === 0 && index === images.length - 1)) {
            return 'inactive-slide1';
        } else {
            return 'inactive-slide';
        }
    };

    return (
        <div className="testimonial-container">
            <h2 className="testimonial-header">Testimonials</h2>
            <Slider {...settings}>
                {images.map((img, index) => (
                    <div key={img.id} className={`slide ${index === slideIndex ? 'slide-active' : ''} ${getSlideClass(index)}`}>
                        <div className="testimonial-details">
                            <div className="testimonial-image-box">
                                <img src={img.image} alt="Testimonial" className="testimonial-image" />
                            </div>
                            <div className="testimonial-content">
                                <p>{img.content}</p>
                            </div>
                            <div className='auth-name'>
                                <p>{img.author.name}, {img.author.Disc}</p>
                            </div>
                            <Startrate/>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Testimonial1;
