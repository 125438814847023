// src/ProtectedRoute.js

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./context/Authcontext";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const access_token = sessionStorage.getItem("access_token");

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isAuthenticated && !access_token) {
    return <Navigate to="/wait-list" />;
  }

  return children;
};

export default ProtectedRoute;
