// Spinner.js
import React from 'react';
import '../assets/css/spinner.css'; // Import the CSS for styling
import logo from '../assets/images/navbar/legal-tech-logo.svg'
const Spinner = () => {
    return (
        <div className="spinner-container">
            <img src={logo} alt="Loading..." className="spinner-logo" />
        </div>
    );
};

export default Spinner;
