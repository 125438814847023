import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "../assets/css/mycases.css";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axiosInstance";
import Allhighcourtcasedetail from "./Allhighcourtcasedetail";
import illustration from "../assets/images/selectcourt/select-court-illustration.png";
import "../assets/css/privileged.css";
import "../assets/css/highcourt.css";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backimg from "../assets/images/mycases/back-image.svg";
import unselected from "../assets/images/mycases/unselected-for-arrow.svg";
import unselecteddelete from "../assets/images/mycases/unselected-delete.svg";
import infoimage from "../assets/images/mycases/info-image.svg";
import search from "../assets/images/mycases/search-vector.svg";
import bottomimage from "../assets/images/mycases/bottom-add-case.svg";
import backbutton from "../assets/images/mycases/back-button.svg";
import { useCaseContext } from "../context/CaseContext";
import mobilePriviledge from "../assets/images/mycases/mobilePriviledge.svg";
import { FaTimes } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useLocation } from "react-router-dom";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Allhighcourtprivildge = () => {
  const { selectAllHighCourt, setSelectAllHighCourt } = useCaseContext();
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadingCases, setIsLoadingCases] = useState(true);
  const [showAddCase, setShowAddCase] = useState(false);
  const [caseType, setCaseType] = useState("");
  const [name, setName] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [year, setYear] = useState("");
  const location = useLocation();
  const { court, bench } = location.state || { court: "", bench: "" };
  const [caseTypes, setCaseTypes] = useState([]);

  const access_token = sessionStorage.getItem("access_token");

  // console.log(court, bench);

  const caseTypeMapping = {
    "Allahabad High Court": {
      "Allahabad High Court": [
        [
          "A227 (MATTERS UNDER ARTICLE 227)",
          "A228 (MATTERS UNDER ARTICLE 228)",
          "A340 (Crl. Misc. APPLICATION U/s 340 Cr.P.C.)",
          "A372 (CRL. MISC. APPLICATION U/S 372)",
          "A372D (CRL. MISC. APPLICATION U/S 372 DEFECTIVE)",
          "A378 (CRL. MISC. APPLICATION U/S 378)",
          "A378D (CRL. MISC. APPLICATION U/s 378 DEFECTIVE)",
          "A389 (Crl. Misc. Application U/s 389(2))",
          "A482 (APPLICATION U/s 482)",
          "A483 (APPLICATION U/s 483)",
          "ABAIL (CRIMINAL MISC ANTICIPATORY BAIL APPLICATION U/S 438 Cr.P.C.)",
          "ABAILC (CRIMINAL MISC ANTICIPATORY BAIL CANCELLATION APPLICATION)",
          "AMAL (AMALGAMATION ORDER)",
          "APPL (APPL.)",
          "ARBT (CIVIL MISC. ARBITRATION APPLICATION)",
          "ARCO (ARBITRATION and CONCILI. APPL.U/s11(4))",
          "ARPL (APPEAL UNDER SECTION 37 OF ARBITRATION AND CONCILIATION ACT 1996)",
          "ARPLD (APPEAL UNDER SECTION 37 OF ARBITRATION AND CONCILIATION ACT 1996 DEFECTIVE)",
          "BAIL (CRIMINAL MISC. BAIL APPLICATION)",
          "BAILC (CRIMINAL MISC. BAIL CANCELLATION APPL.)",
          "C14A1 (Appeal against acquittal in SC/ST Act)",
          "C14A1D (Appeal against acquittal in SC/ST Act defective)",
          "C372 (CRIMINAL APPEAL U/S 372 Cr.PC.)",
          "C372D (CRIMINAL APPEAL U/S 372 Cr.PC DEFECTIVE)",
          "C378 (CRIMINAL APPEAL U/S 378 CR.PC.)",
          "C378D (CRIMINAL APPEAL U/S 378 Cr.PC DEFECTIVE)",
          "CAPL (CONTEMPT APPLICATION (CIVIL))",
          "CAPT (CAPITAL CASES)",
          "CAPTD (CAPITAL CASE DEFECTIVE)",
          "CEAP (CENTRAL EXCISE REFERENCE APPLICATION)",
          "CEAPD (CENTRAL EXCISE REFERENCE APPL. DEFECTIVE)",
          "CERA (CENTRAL EXCISE REFERENCE APPLICATION)",
          "CERAD (CENTRAL EXCISE REFERENCE APPL. DEFECTIVE)",
          "CERE (CENTRAL EXCISE REFERENCE)",
          "CERED (CENTRAL EXCESE REFERENCE DEFECTIVE)",
          "CEXA (CENTRAL EXCISE APPEAL)",
          "CEXAD (CENTRAL EXCISE APPEAL DEFECTIVE)",
          "CLRE (CIVIL REVISION)",
          "CLRED (CIVIL REVISION DEFECTIVE)",
          "CMRA (CIVIL MISC REVIEW APPLICATION)",
          "CMRAD (CIVIL MISC REVIEW APPLICATION DEFECTIVE)",
          "COMA (COMPANY APPEAL)",
          "COMAD (COMPANY APPEAL DEFECTIVE)",
          "COMP (COMPANY APPLICATION)",
          "COMPA (COMPANY APPEAL)",
          "CONMT (CONTEMPT APPEAL)",
          "CONMTD (CONTEMPT APPEAL DEFECTIVE)",
          "COCP (CONTEMPT PETITION)",
          "COSU (COMPANY ORIGINAL SUIT)",
          "CRCL (CONTEMPT APPLICATION (CRIMINAL))",
          "CREF (CRIMINAL REFERENCE)",
          "CREFD (CRIMINAL REFERENCE DEFECTIVE)",
          "CRIMINAL (All CRIMINAL Type)",
          "CRLA (CRIMINAL APPEAL)",
          "CRLAD (CRIMINAL APPEAL DEFECTIVE)",
          "CRLP (CRIMINAL MISC. WRIT PETITION)",
          "CRLD (CRIMINAL REVISION)",
          "CRLRD (CRIMINAL REVISION DEFECTIVE)",
          "CRLRC (CRIMINAL REVISION DEFECTIVE)-36",
          "CROB (CROSS OBJECTION)-119",
          "CRPIL (CRIMINAL WRIT-PUBLIC INTEREST LITIGATION)-105",
          "CRRA (CRIMINAL MISC REVIEW APPLICATION)-130",
          "CRRAD (CRIMINAL MISC REVIEW APPLICATION DEFECTIVE)-132",
          "CUSA (CUSTOMS APPEAL)-94",
          "CUSAD (CUSTOM APPEAL DEFECTIVE)-95",
          "DUMCIV (DUMMY CIVIL)-115",
          "DUMCR (DUMMY CRIMINAL)-116",
          "EAPLD (ESTATE DUTY APPEAL DEFECTIVE)-77",
          "EAPLE (ESTATE DUTY APPEAL)-76",
          "ECASE (EXECUTION CASE)-33",
          "EDAP (ESTATE DUTY APPLICATION)-44",
          "EDRAP (ESTATE DUTY REFERENCE APPL. DEFECTIVE)-67",
          "EDRE (ESTATE DUTY REFERENCE)-46",
          "EFAPLD (EQUATION FIRST APPEAL DEFECTIVE)-61",
          "EFAPL (EQUATION FIRST APPEAL)-134",
          "EFAFDO (EQUATION FIRST APPEAL FROM ORDER)-135",
          "ELEP (ELECTION PETITION)-27",
          "FAFO (FIRST APPEAL FROM ORDER)-3",
          "FAFOD (FIRST APPEAL FROM ORDER DEFECTIVE)-25",
          "FAPL (FIRST APPEAL)-1",
          "FAPLD (FIRST APPEAL DEFECTIVE)-23",
          "FEXA (FOREIGN EXCHANGE APPEAL)-96",
          "FEXAD (FOREIGN EXCHANGE APPEAL DEFECTIVE)-97",
          "GAPL (GIFT TAX APPEAL)-78",
          "GAPLD (GIFT TAX APPEAL DEFECTIVE)-79",
          "GOVA (GOVERNMENT APPEAL)-81",
          "GOVAD (GOVERNMENT APPEAL DEFECTIVE)-82",
          "GSTAL (GOODS AND SERVICE TAX APPEAL)-128",
          "GSTALD (GOODS AND SERVICE TAX APPEAL DEFECTIVE)-129",
          "GTAP (GIFT TAX APPLICATION)-57",
          "GTRE (GIFT TAX REFERENCE)-53",
          "GTRED (GIFT TAX REFERENCE DEFECTIVE)-68",
          "GTREE (GIFT TAX REFERENCE DEFECTIVE)-63",
          "HABC (HABEAS CORPUS WRIT PETITION)-88",
          "IAPL (INCOME TAX APPEAL)-70",
          "IAPLD (INCOME TAX APPEAL DEFECTIVE)-71",
          "IAPLTA (INCOME TAX APPLICATION)-10",
          "ITAPD (INCOME TAX APPEAL DEFECTIVE)",
          "ITAP (INCOME TAX APPLICATION)",
          "ITRED (INCOME TAX REFERENCE DEFECTIVE)",
          "ITRE (INCOME TAX REFERENCE)",
          "JAPLD (JAIL APPEAL DEFECTIVE)",
          "JAPL (JAIL APPEAL)",
          "MOCA (MISC. COMPANY APPLICATION)",
          "MTAL (MATRIMONIAL)",
          "NA379 (Criminal Misc. Application U/s 379 BNSS)",
          "NA379D (Criminal Misc. Application U/s 379 BNSS DEFECTIVE)",
          "NA413 (Criminal Misc. Application U/s 413 BNSS)",
          "NA413D (Criminal Misc. Application U/s 413 BNSS DEFECTIVE)",
          "NA419 (Criminal Misc. Application U/s 419 BNSS)",
          "NA419D (Criminal Misc. Application U/s 419 BNSS DEFECTIVE)",
          "NA430 (Application U/s 430(2) BNSS)",
          "NA528 (Application U/s 528 BNSS)",
          "NA529 (Application U/s 529 BNSS)",
          "NA482 (Criminal Misc. Anticipatory Bail Application U/s 482 BNSS)",
          "NC413 (Criminal Appeal U/s 413 BNSS)",
          "NC413D (Criminal Appeal U/s 413 BNSS DEFECTIVE)",
          "NC419 (Criminal Appeal U/s 419 BNSS)",
          "NC419D (Criminal Appeal U/s 419 BNSS DEFECTIVE)",
          "OLR (OFFICIAL LIQUIDATOR REPORTS)",
          "OSUT (ORIGINAL SUIT)",
          "PRCS (PROBATE CASE)",
          "REFC (REFERENCE AGAINST MISC. ACTS.)",
          "REFCRED (REFERRED CASE (CAPITAL CASE))",
          "REFA (REFER APPEAL)",
          "REFAAD (REFER APPEAL DEFECTIVE)",
          "SAFO (SECOND APPEAL FROM ORDER)",
          "SAFOD (SECOND APPEAL FROM ORDER DEFECTIVE)",
          "SAMC (SECOND APPEAL MISC CASES)",
          "SAPL (SECOND APPEAL)",
          "SAPLD (SECOND APPEAL DEFECTIVE)",
          "SCAP (SUPREME COURT APPEAL)",
          "SCAPD (SUPREME COURT APPEAL DEFECTIVE)",
          "SCCR (S.C.C. REVISION)",
          "SCCRD (S.C.C. REVISION DEFECTIVE)",
          "SPLA (SPECIAL APPEAL)",
          "SPLAD (SPECIAL APPEAL DEFECTIVE)",
          "STRE (SALES/TRADE TAX REVISION)",
          "STRED (SALES/TRADE TAX REVISION DEFECTIVE)",
          "TACL (TRANSFER APPLICATION (CIVIL))",
          "TACLD (TRANSFER APPLICATION DEFECTIVE (CIVIL))",
          "TACR (TRANSFER APPLICATION (CRIMINAL))",
          "TEST (TESTAMENTARY CASE)",
          "TESU (TESTAMENTARY SUIT)",
          "WAPL (WEALTH TAX APPEAL)",
          "WAPLD (WEALTH TAX APPEAL DEFECTIVE)",
          "WPIL (WRIT - PUBLIC INTEREST LITIGATION)",
          "WRIA (WRIT - A)",
          "WRIB (WRIT - B)",
          "WRIC (WRIT - C)",
          "WTAP (WEALTH TAX APPLICATION)",
          "WTAPD(WEALTH TAX APPLICATION DEFECTIVE)-58",
          "WTAR(WEALTH TAX REFERENCE)-117",
          "WTARD(WEALTH TAX REFERENCE DEFECTIVE)-64",
          "WTAX(WRIT TAX)-47",
          "WTRE(W.T.R.)-48",
        ],
      ],

      "Allahabad High Court Lucknow Bench": [
        "A227 (MATTERS UNDER ARTICLE 227)",
        "A228 (MATTERS UNDER ARTICLE 228)",
        "A340 (Crl. Misc. APPLICATION U/s 340 Cr.P.C.)",
        "A372 (CRL. MISC. APPLICATION U/S 372)",
        "A372D (CRL. MISC. APPLICATION U/S 372 DEFECTIVE)",
        "A378 (CRL. MISC. APPLICATION U/S 378)",
        "A378D (CRL. MISC. APPLICATION U/s 378 DEFECTIVE)",
        "A389 (Crl. Misc. Application U/s 389(2))",
        "A482 (APPLICATION U/s 482)",
        "A483 (APPLICATION U/s 483)",
        "ABAIL (CRIMINAL MISC ANTICIPATORY BAIL APPLICATION U/S 438 Cr.P.C.)",
        "ABAILC (CRIMINAL MISC ANTICIPATORY BAIL CANCELLATION APPLICATION)",
        "AMAL (AMALGAMATION ORDER)",
        "APPL (APPL.)",
        "ARBT (CIVIL MISC. ARBITRATION APPLICATION)",
        "ARCO (ARBITRATION and CONCILI. APPL.U/s11(4))",
        "ARPL (APPEAL UNDER SECTION 37 OF ARBITRATION AND CONCILIATION ACT 1996)",
        "ARPLD (APPEAL UNDER SECTION 37 OF ARBITRATION AND CONCILIATION ACT 1996 DEFECTIVE)",
        "BAIL (CRIMINAL MISC. BAIL APPLICATION)",
        "BAILC (CRIMINAL MISC. BAIL CANCELLATION APPL.)",
        "C14A1 (Appeal against acquittal in SC/ST Act)",
        "C14A1D (Appeal against acquittal in SC/ST Act defective)",
        "C372 (CRIMINAL APPEAL U/S 372 Cr.PC.)",
        "C372D (CRIMINAL APPEAL U/S 372 Cr.PC DEFECTIVE)",
        "C378 (CRIMINAL APPEAL U/S 378 CR.PC.)",
        "C378D (CRIMINAL APPEAL U/S 378 Cr.PC DEFECTIVE)",
        "CAPL (CONTEMPT APPLICATION (CIVIL))",
        "CAPT (CAPITAL CASES)",
        "CAPTD (CAPITAL CASE DEFECTIVE)",
        "CEAP (CENTRAL EXCISE REFERENCE APPLICATION)",
        "CEAPD (CENTRAL EXCISE REFERENCE APPL. DEFECTIVE)",
        "CERA (CENTRAL EXCISE REFERENCE APPLICATION)",
        "CERAD (CENTRAL EXCISE REFERENCE APPL. DEFECTIVE)",
        "CERE (CENTRAL EXCISE REFERENCE)",
        "CERED (CENTRAL EXCESE REFERENCE DEFECTIVE)",
        "CEXA (CENTRAL EXCISE APPEAL)",
        "CEXAD (CENTRAL EXCISE APPEAL DEFECTIVE)",
        "CLRE (CIVIL REVISION)",
        "CLRED (CIVIL REVISION DEFECTIVE)",
        "CMRA (CIVIL MISC REVIEW APPLICATION)",
        "CMRAD (CIVIL MISC REVIEW APPLICATION DEFECTIVE)",
        "COMA (COMPANY APPEAL)",
        "COMAD (COMPANY APPEAL DEFECTIVE)",
        "COMP (COMPANY APPLICATION)",
        "CONA (CONTEMPT APPEAL)",
        "CONAD (CONTEMPT APPEAL DEFECTIVE)",
        "COPP (COMPANY PETITION)",
        "COSU (COMPANY ORIGINAL SUIT)",
        "CRCL (CONTEMPT APPLICATION (CRIMINAL))",
        "CREF (CRIMINAL REFERENCE)",
        "CREFD (CRIMINAL REFERENCE DEFECTIVE)",
        "CRIMINAL (All CRIMINAL Type)",
        "CRLA (CRIMINAL APPEAL)",
        "CRLAD (CRIMINAL APPEAL DEFECTIVE)",
        "CRLP (CRIMINAL MISC. WRIT PETITION)",
        "CRLR (CRIMINAL REVISION)",
        "CRLRD (CRIMINAL REVISION DEFECTIVE)",
        "CROB (CROSS OBJECTION)",
        "CRPIL (CRIMINAL WRIT-PUBLIC INTEREST LITIGATION)",
        "CRRA (CRIMINAL MISC REVIEW APPLICATION)",
        "CRRAD (CRIMINAL MISC REVIEW APPLICATION DEFECTIVE)",
        "CUSA (CUSTOMS APPEAL)",
        "CUSAD (CUSTOM APPEAL DEFECTIVE)",
        "DUMMYC (DUMMY CIVIL)",
        "DUMMYCR (DUMMY CRIMINAL)",
        "EAPL (ESTATE DUTY APPEAL)",
        "EAPLD (ESTATE DUTY APPEAL DEFECTIVE)",
        "ECASE (EXECUTION CASE)",
        "EDAP (ESTATE DUTY APPLICATION)",
        "EDAPD (ESTATE DUTY REFERENCE APPL. DEFECTIVE)",
        "EDRE (ESTATE DUTY REFERENCE)",
        "EDRED (ESTATE DUTY REFERENCE DEFECTIVE)",
        "EFAPL (EQUATION FIRST APPEAL)",
        "EFAPLD (EQUATION FIRST APPEAL DEFECTIVE)",
        "ELEP (ELECTION PETITION)",
        "FAFO (FIRST APPEAL FROM ORDER)",
        "FAFOD (FIRST APPEAL FROM ORDER DEFECTIVE)",
        "FAPL (FIRST APPEAL)",
        "FAPLD (FIRST APPEAL DEFECTIVE)",
        "FEXA (FOREIGN EXCHANGE APPEAL)",
        "FEXAD (FOREIGN EXCHANGE APPEAL DEFECTIVE)",
        "GAPL (GIFT TAX APPEAL)",
        "GAPLD (GIFT TAX APPEAL DEFECTIVE)",
        "GOVA (GOVERNMENT APPEAL)",
        "GOVAD (GOVERNMENT APPEAL DEFECTIVE)",
        "GSTAL (GOODS AND SERVICE TAX APPEAL)",
        "GSTALD (GOODS AND SERVICE TAX APPEAL DEFECTIVE)",
        "GTAP (GIFT TAX APPLICATION)",
        "GTAPD (GIFT TAX REFERENCE APPL. DEFECTIVE)",
        "GTRE (GIFT TAX REFERENCE)",
        "GTRED (GIFT TAX REFERENCE DEFECTIVE)",
        "HABC (HABEAS CORPUS WRIT PETITION)",
        "IAPL (INCOME TAX APPEAL)",
        "IAPLD (INCOME TAX APPEAL DEFECTIVE)",
        "ITAP (INCOME TAX APPLICATION)",
        "ITAPD (INCOME TAX APPLICATION DEFECTIVE)",
        "ITRE (INCOME TAX REFERENCE)",
        "ITRED (INCOME TAX REFERENCE DEFECTIVE)",
        "JAPL (JAIL APPEAL)",
        "JAPLD (JAIL APPEAL DEFECTIVE)",
        "MCOA (MISC. COMPANY APPLICATION)",
        "MTAL (MATRIMONIAL)",
        "NA379 (Criminal Misc. Application U/s 379 BNSS)",
        "NA413 (Criminal Misc. Application U/s 413 BNSS)",
        "NA413D (Criminal Misc. Application U/s 413 BNSS)",
        "NA419 (Criminal Misc. Application U/s 419 BNSS)",
        "NA419D (Criminal Misc. Application U/s 419 BNSS)",
        "NA430 (Criminal Misc. Application U/s 430(2) BNSS)",
        "NA528 (Application u/s 528 BNSS)",
        "NA529 (Application u/s 529 BNSS)",
        "NABAIL (Criminal Misc. Anticipatory Bail Application U/s 482 BNSS)",
        "NC413 (Criminal Appeal U/s 413 BNSS)",
        "NC413D (Criminal Appeal U/s 413 BNSS)",
        "NC419 (Criminal Appeal U/s 419 BNSS)",
        "NC419D (Criminal Appeal U/s 419 BNSS)",
        "OLR (OFFICIAL LIQUIDATOR REPORTS)",
        "OSUT (ORIGINAL SUIT)",
        "PRCS (PROBATE CASE)",
        "REFC (REFERENCE AGAINST MISC. ACTS.)",
        "REFE (REFERRED CASE (CAPITAL CASE))",
        "RERA (RERA APPEAL)",
        "RERAD (RERA APPEAL DEFECTIVE)",
        "SAFO (SECOND APPEAL FROM ORDER)",
        "SAFOD (SECOND APPEAL FROM ORDER DEFECTIVE)",
        "SAMC (SECOND APPEAL MISC CASES)",
        "SAPL (SECOND APPEAL)",
        "SAPLD (SECOND APPEAL DEFECTIVE)",
        "SCAP (SUPREME COURT APPEAL)",
        "SCAPD (SUPREME COURT APPEAL DEFECTIVE)",
        "SCCR (S.C.C. REVISION)",
        "SCCRD (S.C.C. REVISION DEFECTIVE)",
        "SPLA (SPECIAL APPEAL)",
        "SPLAD (SPECIAL APPEAL DEFECTIVE)",
        "STRE (SALES/TRADE TAX REVISION)",
        "STRED (SALES/TRADE TAX REVISION DEFECTIVE)",
        "TACL (TRANSFER APPLICATION (CIVIL))",
        "TACLD (TRANSFER APPLICATION DEFECTIVE (CIVIL))",
        "TACR (TRANSFER APPLICATION (CRIMINAL))",
        "TEST (TESTAMENTARY CASES)",
        "TPL (TRADE MARK CASES)",
        "TPLA (TRADE TAX APPEAL)",
        "TPLAD (TRADE TAX APPEAL DEFECTIVE)",
        "TRST (TRUSTS)",
        "VPPET (VPP PETITION)",
        "WCC (WORKMEN COMPENSATION CASE)",
        "WPC (Writ Petition (Civil))",
        "WPCD (Writ Petition (Civil) Defective)",
        "WPD (DEFECTIVE WRIT PETITION)",
        "WPPIL (WRIT PETITION PUBLIC INTEREST LITIGATION)",
        "WPSEC (WRIT PETITION SERVICE)",
      ],
    },

    "High Court of Manipur": {
      "High Court of Manipur": [
        "a(s)",
        "AB(Anticipatory Bail)",
        "Adml.S.(Admiralty Suits)",
        "Arb.A(Appeals under Indian Arbitration Act.)",
        "Arb.A(J2)(Arb.Appeal of J-2)",
        "Arb.P.(Petition under Indian Arbitration Act.)",
        "Arb.P.(J2)(Arb.Petn. of Judl-2)",
        "BA(Bail Application)",
        "BAIL APPLN.(Bail Application)",
        "Bkg.p.(Proceedings under Banking Regulation Act.)",
        "CAPL(Contempt Appeal)",
        "CAV(Caveat)",
        "CAV(J2)(Caveat of Judl-2)",
        "Cav.Pet.(Caveat Petn. of J-2)",
        "CC(CIV)(Contempt Of Court (Civil))",
        "CE.REf.(Reference under Central Excise Act)",
        "CMAppl.(Misc Applications eg Leave to Sue as indigent person, restoration application, condonat)",
        "CO(Cross Objection in First Appeal)",
        "Co.App.(Appeals against Judgments/Orders in Company Petitions)",
        "Co. Appl.(Applicationin Pending Proceeding)",
        "COB(FA)(Cross Objection in First Appeal)",
        "COB(MFA)(Cross Objection in Misc. First Appeal)",
        "Co.Case(Matters transferred under Section 446(3))",
        "COJO(Correction of Judgement/Order)",
        "COMAPL(Company Appeal)",
        "COMPET(Company Petition)",
        "Cont.App.(C)(Appeals against orders in Civil Contempt matters.)",
        "Cont.App(C)(Appeal against order in Civil Contempt matters)",
        "Cont.App.(Crl.)(Appeals against Orders in Criminal Contempt matters.)",
        "CONT CAS(C)(Contempt of Court Cases relating to Civil Contempt.)",
        "CONT. CAS(C) J2(Contempt of Court Cases relating to Civil Appeal cases)",
        "Cont.Cas.(Crl.)(Proceedings relating to Criminal Contempt.)",
        "COP(C)(Civil Original Petition( Contempt ))",
        "Co.Pet.(Company Petition)",
        "CR(Civil Rule)",
        "CRAPL(Criminal Appeal)",
        "CR(CC)(Criminal Contempt of Court)",
        "CRDREF(Criminal Death Reference)",
        "C.Ref.(Reference)",
        "CREF(Civil Refrence)",
        "CREV(Civil Revision)",
        "CREV(H)(Civil Revision(hills))",
        "CR(HC)(Civil Rule(Habeas Corpus ))",
        "CRIL. PETN.(Petition/applicants filed under S.482 of Cr.P.C.)",
        "Crl.A.(Appeal against Judgment/Sentence.)",
        "CR(LAB)(Civil Rule(labour))",
        "CR(LAN)(Civil Rule(land))",
        "Crl.J.A(Criminal Jail Appeeal of J-2)",
        "Crl.L.P.(App for Leaving to Appeal under Section 378 Cr.P.C. or under the relevant corresponding)",
        "Crl.M.Appln.(Other Miscellaneous Application)",
        "Crl.M.C.(Application under Section 482 Cr.P.C.)",
        "Crl.Ref.(Reference)",
        "Crl.Rev.P.(Revision)",
        "Crl.Tr.(Original Trial)",
        "CR(MC)(Criminal Misc. Case)",
        "CROA(Criminal Original Application)",
        "CROP(Criminal Original Application)",
        "CROP(C)(Criminal Original Petition(Contempt))",
        "CR(OTH)(Civil Rule(other))",
        "CRP(Revision Petition)",
        "CRP(C.R.P. Art.227)(Writ Petition under Article 227  of the Constitution)",
        "CR(PIL)(Civil Rule Pil)",
        "CRREF(Criminal Reference)",
        "CRREF(H)(Criminal reference(hills))",
        "CRREV(Criminal Revision)",
        "CR(SER)(Civil Rule(service))",
        "CS(Civil Suit)",
        "CS(OS)(Civil Suits)",
        "Cus. App.(Customs Appeal)",
        "Cus.Ref.(Reference under Customs Act.)",
        "DEATH SENTENCE REF.(Confirmation case under Section 336 Cr.P.C.)",
        "EDREF(Estate Duty Reference)",
        "EL.App.(Appeals from judgments in Election)",
        "EL.PET.(Election Petition)",
        "El.Recr.Pet(Eln. Recremination Petn. of J-2)",
        "Ex.Appl.(OS)(Execution Application)",
        "Ex.FA.(Execution Petition)",
        "Ex.P.(Execution Petition)",
        "Ex.SA.(Execution Second Appeal)",
        "FA(First Appeal)",
        "FA(D)(First Appeal(Divorce))",
        "FAO(First Appeal from Orders)",
        "FAO(G)(First Appeal from orders(G))",
        "FAO(Intest)(First Appeal from orders(Intest))",
        "FA(OS)(First Appeal for Judgments in Original Suits.)",
        "FAO(Test)(First Appeal from orders(Test))",
        "FA(T)(First Appeal)",
        "GCA(Government Criminal Appeal)",
        "GTA/WTA/EDA(Application for direction to make a reference)",
        "GTREF(Gift Tax Reference)",
        "GTR/WTR/EDR(Reference to High Court)",
        "Gua.p.(Petitions under Guarduanship and Wards Act.)",
        "IA(Interiocutory applications in pending Suits/Appeals)",
        "ICA.(Intra Court Appeals)",
        "Insurance App.(Appeals under Insurance Act.)",
        "Insurance REf.(Reference under Insurance Act.)",
        "Intest. Cas.(Intestate cases,e.g., Succession Certificates, etc.)",
        "IP(Appl.)(Application submitted after adjudication)",
        "IP(M)(Main Petition)",
        "ITA(Application under Section 256(2))",
        "ITR(Reference under Section 256(1))",
        "ITREF(Income Tax Reference)",
        "LA.App.(Appeals)",
        "LA.Ref.(Reference)",
        "LPA(Latters Patent Appeal)",
        "LPA(J2)(LPA of J-2)",
        "MAC(Motor Accident Claims)",
        "MACApp.(Motor Accident Appeal)",
        "MAF(Miscellaneous Appeal(first))",
        "MAF(T)(Miscellaneous Appeal First(tender))",
        "MAINTAINABILITY(MAINTAINABILITY)",
        "Mat.App.(Appeals)",
        "Mat. Cas.(Suits/Petitions)",
        "Mat. Reg.(Reference)",
        "MC(Miscellaneous Case)",
        "MC(Arb.A.)(Misc. Case of Appeal under Indian Arbitration Act.)",
        "MC(Arb.A.(J2))(Misc. Case of appeal under Indian Arbitration Act.)",
        "MC(Arb.P.)(Misc. Case of Petition under Indian Arbitration Act.)",
        "MC(Arb.P.(J2))(Misc. Case of Petition under Indian Arbitration Act.)",
        "MC(Bail Appln.)(Misc. Case of Bail Application)",
        "MC(CAPL)(Misc. Case of Contempt Appeal)",
        "MC(Cont.App.(C))(Misc. Case of Appeals against orders in Civil Contempt matters)",
        "MC(Cont.App.(Crl.))(Misc. Case of Appeals against orders of Criminal Contempt matters)",
        "MC(CONT.CAS(C))(Misc. Case of Contempt of Court Cases relating to Civil Contempt)",
        "MC(CONT.CAS(C)J2)(Misc Contempt of Court Cases relating to Civil Appeal cases)",
        "MC(CONT.CAS(Crl.))(Misc. Case of Proceedings relating to Criminal Contempt)",
        "MC(CR)(Misc. Case of Civil Rule)",
        "MC(CR(HC))(Misc Case of CR(Habeas Corpus))",
        "MC(Cril. Petn.)(misc. crl Petition/applicants filed under S.482 of Cr.P.C.)",
        "MC(Crl.A.)(Misc. Case of Appeal against Judgment/Sentence)",
        "MC(Crl.J.A.)(Cril Misc of Cril Jail appeal)",
        "MC(Crl.Rev.P.)(Misc. Case of Revision)",
        "MC((CRP)(Misc. Case of Revision Petition)",
        "MC(CRP(CRP Art.227)(Misc Case of CRP(C.R.P. Art.227))",
        "MC(CR(PIL))(Misc Civil Rule Pil)",
        "MC(CS)(Misc. Case of Civil Suit)",
        "MC(Cus. App.)(Misc. Customs Appeal)",
        "MC(El.Pet.)(Misc. Case of Election Petitions)",
        "MC(EP)(Misc. Case of Election Petition)",
        "MC(Ex.P.)(Misc. Case of Execution Petition)",
        "MC(FA)(Misc. Case of First Appeal)",
        "MC(FAO)(Misc. Case of First Appeal from Orders)",
        "MC(FAO(G))(Misc.Case first Appeal from orders(G))",
        "MC(FAO(Intest))(Misc.Case first Appeal from orders(Intest))",
        "MC(FAO(test))(Misc.Case first Appeal from orders(Test))",
        "MC(GCA)(Misc. Case of Government Criminal Appeal)",
        "MC(LA.App.)(Misc. Land Acquisition Appeal)",
        "MC(LPA)(Misc. Case of Letters Patent Appeal)",
        "MC(LPA(J2))(Misc case of LPA, judl 2)",
        "MC(MAC)(Misc. Case of Motor Accident Claims)",
        "MC(MACApp.)(Misc. Case of Motor Accident Appeal)",
        "MC(MAF)(Misc. Case of Miscellaneous Appeal (first))",
        "MC(Mat.App.)(Misc. Case of Appeals)",
        "MC(Mat.Cas.)(Misc. Case of Suits/Petitions)",
        "MC(Mat.Ref.)(Misc. Case of References)",
        "MC(MFA)(Misc. Case of First Appeal against judgments in Special jurisdiction)",
        "MC(OC)(Misc. Case of Original Case)",
        "MC(PIL)(Misc. Case of Public Interest Litigation)",
        "MC(Review.Pet.)(Misc. Case of Review Petition)",
        "MC(Review Pet.(Crl.))(Misc case Review petition (Cril))",
        "MC(Rev.Pet(J2))(MISC OF REVIEW PETITION J2)",
        "MC(RFA)(Misc. Case of First Appeal from Judgment and Decree in Suit)",
        "MC(RP(FAM.CT))(Misc. Case Of Revisions Under Section 19 Of The Family Courts Act.)",
        "MC(RSA)(Misc. Case of Second Appeal from Judgment and Decree)",
        "MC(RSA(Rev.))(Misc. Case of Review in Second Appeal)",
        "MC(SA)(Misc.Case in Second Appeal)",
        "MC(SA)(Misc Case Second Appeal)",
        "MC(SAO)(Misc of SAO)",
        "MC(Test.Cas.)(Misc. Case of Testamentary cases,e.g.,Probate or Letters of Administration)",
        "MC(Tr.P.(C))(Misc.Case of Transfer Petition under Section 24 C.P.C.)",
        "MC(Tr.P.(Crl.))(Misc. Case of Transfer Petition for transfer a Criminal Proceeding)",
        "MC(WA)(Misc.Case of Appeal before Division Bench against judgment or order)",
        "MC(WP(C))(Misc.Case of Writ Petition under Article 226 of the Constitution)",
        "MC(W.P.(Crl.))(Misc. Case of Petition under Article 226 for writ and Haveas Corpus)",
        "MFA(First Appeal against judgments in Special Jurisdiction cases.)",
        "MSA(Second Appeal from judgments in miscellaneous cases.)",
        "N.M.(Notice of Motion)",
        "Or.Pet.(Original Petition)",
        "OTA(Other Tax Application)",
        "OT.Appl.(Other Tax Application)",
        "OTC(Other Tax Cases)",
        "OTR(Other Tax Reference cases)",
        "PIL(Public Interest Litigation)",
        "RA(CR)(Review Application in Civil Rule)",
        "RA(FA)(Review Application in First Appeal)",
        "RA(MFA)(Review Application in Misc. First Appeal)",
        "RAT(Review Application (Tender))",
        "RCC(Original Suit/Petition)",
        "RCFA(First Appeal)",
        "RCSA(Second Appeal)",
        "RECREv.(Revision)",
        "REP(Recrimination Case in Election Petition)",
        "Review.Pet.(Review Petition)",
        "Review Pet.(Crl.)(Review Petition (Cril))",
        "Rev.Pet(J2)(Review Petn. of J-2)",
        "RFA(First Appeal from Judgment Decree in Suit)",
        "RLPA(Review in Latters Patent Appeal)",
        "RP(FAM.CT.)(Revisions under section 19 of the Family Courts Act.)",
        "RSA(Second Appeal from Judgment Decree)",
        "RSA(Rev)(Review in Second Appeal)",
        "SA(Second Appeal)",
        "SAO(Appeal from Appellate Order)",
        "SA(T)(Second Appeal(tender))",
        "SCLP(Petition for Leave to Appeal to Supreme Court)",
        "ST.Appl.(Application for direction to make a reference)",
        "ST.REf.(Reference)",
        "STREF(Sales Tax Refrence)",
        "ST.Rev.(Revision)",
        "Test.Cas.(Testamentary cases, e.g., Probate or Letters of Administration, etc.)",
        "Tr.P.(C)(Transfer Petition under Section 24 C.P.C.)",
        "Tr.P.(Crl.)(Transfer Petition for transfer a Criminal Proceeding)",
        "WA(Writ Appeal)",
        "WA(FA)(WRIT APPEAL IN FA)",
        "WA(LAB)(Writ Appeal(labour))",
        "WA(LAN)(Writ Appeal(land))",
        "WA(OTH)(Writ Appeal(others))",
        "WA(SER)(Writ Appeal(service))",
        "WA(T)(Writ Appeal Tender)",
        "WP(C)(Writ Petition under Article 226 of the Constitution)",
        "W.P.(Crl.)(Petition under Art226 for Writ and Habeas Corpus and other relief in relation to a Crimina)",
        "WTREF(Wealth Tax Refrence)",
      ],
    },

    "Bombay High Court": {
      "Appellate Side,Bombay": [
        "ABA(Cr. Anticipatory Bail Appln.)-332",
        "ALP(Appln for Leave to Appeal(PVT.))-335",
        "ALS(Appln For Leave to Appeal(STATE))-334",
        "AO(Appeal from Order)-3",
        "APEAL(Cr. Appeal)-310",
        "APL(Criminal Appln. U/s 482)-330",
        "APPA(Cr. Application in Appeal)-321",
        "APPCO(Application in Cr. Conf.)-327",
        "APPCP(Application in Cr. Cont. Petn.)-326",
        "APPCR(Application In Cr. Reference)-324",
        "APPI(Application in Cr. PIL)-325",
        "APPLN(Cr. Application)-302",
        "APPP(Cr. Application in Application)-323",
        "APPR(Cr. Application in Revision)-322",
        "APPSC(Application In Cr. SUO-MOTO CONP)-329",
        "APPW(Cr. Application in Writ Petition)-320",
        "ARA(Arb. Apeal u/s 37 of Arb. Conc. 96)-15",
        "ARP(Arbitration Petition)-13",
        "AULPA(LPA(AURANGABAD))-99",
        "BA(Criminal Bail Appln.)-331",
        "CA(Civil Application)-18",
        "CAA(Civil Application in AO)-32",
        "CAC(Civil Application in CRA)-35",
        "CAE(Civil Application in C.REF)-50",
        "CAF(Civil Application in FA)-31",
        "CAFM(Civil Application In FEMA)-67",
        "CAI(Civil Application in PIL)-49",
        "CAL(Civil Application in LPA)-34",
        "CAM(Civil Application in FCA)-36",
        "CAN(Civil Application in CP)-37",
        "CAO(CA in Oth.(MCA/TXA/CA/FERA))-41",
        "CAP(Civil Appl. in ARP)-39",
        "CAPL(Contempt Appeal)-12",
        "CAR(Civil Appln. in ARA)-40",
        "CAS(Civil Application in SA)-33",
        "CAT(Civil Application in CAPL)-38",
        "CAW(Civil Application in WP)-30",
        "CAY(Civil Application in FERA)-60",
        "CCAPL(COMMERCIAL CONTEMPT APPEAL)-344",
        "CCNO(Supreme Ct. Cr. C. C. NO.)-318",
        "CCNO(Supreme Ct. C. C. NO.)-57",
        "CCPDJ(CIVIL CONTEMPT PETITION BY DISTRICT JUDICIARY)-348",
        "COARA(COMMERCIAL ARBITRATION APPEAL)-340",
        "COARP(COMMERCIAL ARBITRATION PETITION)-342",
        "COMAO(COMMERICAL AO)-341",
        "COMCP(COMMERCIAL CONTEMPT PETITION)-343",
        "COMFA(COMMERCIAL FA)-339",
        "COMRP(REVIEW PETITION IN COMMERCIAL FA/ARA/AO/ARP/CP/CA)-345",
        "CONF(Cr. Confirmation Case)-301",
        "CONP(Cr. Contempt Petition)-312",
        "COXOB(CROSS OBJECTION IN COMMERCIAL FA/ARA/AO/ARP/CP/CA)-346",
        "CP(Cont. Petition)-10",
        "CRA(Civil Revision Application)-7",
        "C.REF(Civil References)-9",
        "CRPIL(Public Interest Litigation)-319",
        "CRR(Court Receiver Report)-63",
        "FA(First Appeal)-2",
        "FAG(First Appeal (GOA))-98",
        "FCA(Family Court Appeal)-8",
        "FEMA(FEMA APPEAL)-66",
        "FERA(FERA Appeal)-58",
        "IA(CRI-INTERIM APPLICATION)-337",
        "IA(Supreme Ct. Cr. Introlocatory Applicaton)-316",
        "IA(INTERIM APPLICATION)-69",
        "IA(Supreme Ct. Intolocatory Application)-55",
        "LPA(Letter Patent Appeal)-5",
        "MCA(Misc.Civil Application)-11",
        "MPA(Marraige Petition(A))-62",
        "PIL(Public Interest Litigation)-6",
        "RAP(Review Petition in ARA)-29",
        "RC(Rejected Case)-43",
        "REF(Cr. Reference)-311",
        "REVN(Cr. Revision Appln.)-309",
        "REVW(Criminal Review)-328",
        "RPA(Review Petn. in AO)-21",
        "RPC(Review Petn. in CRA)-24",
        "RPF(Review Pent. in FA)-20",
        "RPFM(Review Petition In FEMA Appeal)-68",
        "RPI(Review Petition In PIL)-51",
        "RPIA(REVIEW PETITION IN IA)-70",
        "RPIA(REVIEW PETITION IN IA)-338",
        "RPL(Review Petn. in LPA)-23",
        "RPM(Review Petn. in FCA)-25",
        "RPN(Review Petn. in CP)-26",
        "RPR(Review Petn. in ARP)-28",
        "RPS(Review Petn. in SA)-22",
        "RPT(Review Petn. in CAPL)-27",
        "RPV(Review Petition in MCA)-48",
        "RPW(Review Petn. in WP)-19",
        "SA(Second Appeal)-4",
        "SCAP(Supreme Ct. Civil Apeal)-53",
        "SCAP(Supreme Ct. Criminal Apeal)-314",
        "SCCA(CA for Leave to Appeal to Supreme Court)-16",
        "SCCP(Supreme Ct. Cr. Contempt Petition)-317",
        "SCCP(Supreme Ct. Contempt Petition)-56",
        "SCMP(Supreme Ct. Misc. Petition)-54",
        "SCMP(Supreme Ct. Cr. Misc. Petition)-315",
        "SCTP(Supreme Ct. Transfer Petition)-61",
        "SCTP(Supreme Ct. Transfer Petition(CR))-333",
        "SLP(Supreme Ct. Special Leave Petition)-52",
        "SLP(Supreme Ct. Cr. Special Leave Petition)-313",
        "SMAP(Cr. Suo-Motu Application)-304",
        "SMCP(Cr. Suo-Motu Contempt Petn.)-307",
        "SMCPC(Civil Suo Motu Contempt Petition)-347",
        "SMP(Suo-Moto Petition)-59",
        "SMP(Suo-Motu Cr. PIL)-336",
        "SMPIL(Suo Motuo PIL)-65",
        "SMRN(Cr. Suo-Motu Revision Appln.)-306",
        "SMWP(Cr. Suo-Motu Writ Petition)-305",
        "SMWP(Suo Motu Writ Petition)-64",
        "SOMO(Cr. Suo-Motu Petition)-303",
        "SPLCA(Special Civil Application)-17",
        "TXA(Tax Appeal)-14",
        "WP(Cr. Writ Petition)-308",
        "WP(Writ Petition)-1",
        "WPA(WRIT PETITION - AURANGABAD)-45",
        "WPG(WRIT PETITION - GOA)-46",
        "WPN(WRIT PETITION - NAGPUR)-47",
        "XFER(TRANSFER MATTER)-44",
        "XOB(Cross Objection Stamp)-42",
      ],
      "Bench at Aurangabad": [
        "ABA(ANTICIPATORY BAIL APPLICATION)-319",
        "ACB(APPLICATION FOR CANCELLATION OF BAIL)-320",
        "ALP(Appln. for leave to Appeal by Pvt. Party)-317",
        "ALS(Appln. for leave to Appeal by State)-316",
        "AO(Apeal from Order)-6",
        "APEAL(criminal Appeal)-305",
        "APPLN(Criminal Application)-302",
        "ARB(ARBITRATION APPLICATION)-44",
        "ARBA(Arbitration Appeal)-62",
        "BA(BAIL APPLICATION)-318",
        "CA(Civil Application)-8",
        "CAP(Company Appeal)-58",
        "CAPL(Contempt Appeal)-9",
        "CARAP(Commercial Arbitration Application)-72",
        "CARBA(Commercial Arbitration Appeal)-71",
        "CEA(CENTRAL EXCISE APPEAL)-65",
        "CMA(Company Application)-56",
        "CMP(COMPANY PETITION)-53",
        "COMAP(Commercial Appeal)-73",
        "CONF(Criminal Confirmation Case)-306",
        "CONP(Criminal Contempt Petition)-309",
        "CP(Cont. Petition)-7",
        "CRA(CRA)-22",
        "CRF(Civil References)-24",
        "CRPIL(Criminal Public Interest Litigation)-315",
        "CRSMP(Criminal SUO-MOTO PUBLIC INTEREST LITIGATION)-321",
        "CSA(CUSTOMS APPEAL)-64",
        "EP(Election petition)-32",
        "EPAP(Application in EP)-61",
        "FA(First Appeal)-2",
        "FCA(Family Court Appeal)-11",
        "ITA(INCOME TAX APPEAL)-66",
        "LPA(Letter Patent Appeal)-3",
        "MCA(Misc.Civil Application)-10",
        "MP(Marraige Petition)-41",
        "MVXA(MAH. VALUE ADDED TAX APPEAL)-70",
        "PIL(Public Interest Litigation)-59",
        "RA(Review Application (Civil))-40",
        "RC(Rejected Case)-30",
        "REC(RECRIMINATION NO)-47",
        "REF(Criminal Reference)-307",
        "REVN(Criminal Revision Application)-304",
        "REVW(Criminal Review Application)-314",
        "SA(Second Appeal)-4",
        "SCCA(Supreme Court Civil Appeal)-25",
        "SMAP(Criminal Suo-Motu Application)-310",
        "SMC(SUO MOTO (CP))-38",
        "SMCP(Criminal Suo-Motu Contempt Petition)-313",
        "SMPIL(SUO MOTO (PIL))-69",
        "SMR(SUO MOTO REVESION)-49",
        "SMRN(Criminal Suo-Motu Revision Application)-312",
        "SMW(SUO MOTO (WP))-36",
        "SMWP(Criminal Suo-Motu Writ Petition)-311",
        "SN(Stamp Number)-301",
        "SOMO(Criminal Suo-Motu Petition)-308",
        "SPLCA(Special Civil Application)-26",
        "STA(Sales Tax Application)-63",
        "STAPL(SALES TAX APPEAL)-68",
        "TA(Tax Appeal)-50",
        "WP(Criminal Writ Petition)-303",
        "WP(Writ Petition)-1",
        "WTA(Wealth Tax Apeal)-67",
        "XAP(Cross Appeal)-60",
        "X-APL(Cross Appeal)-31",
        "XFER(TRANSFER MATTER)-33",
        "X-OBJ(X-Objection)-27",
      ],
      "Bench at Nagpur": [
        "AA(Arbitration Apeals)-40",
        "ABA(Cr. Anticipatory Bail Applications)-332",
        "AO(Appeal from Order)-1",
        "APEAL(Criminal Appeal)-301",
        "APL(Cr. Application U/s 482)-333",
        "APPA(Cr. Application in Appeal)-321",
        "APPCO(Application in Cr. Conf.)-327",
        "APPCP(Application in Cr. Cont. Petn.)-326",
        "APPCR(Application in Cr. Reference)-324",
        "APPLB(Criminal Appln. of Bail)-330",
        "APPLN(Criminal Application)-302",
        "APPP(Cr. Application in Application)-323",
        "APPR(Cr. Application in Revision)-322",
        "APPW(Cr.Application in Writ Petition)-320",
        "ARA(Arbitration application)-2",
        "ARP(Arbitration Petition)-3",
        "BA(Cr. Bail Applications)-331",
        "CA(Civil Application)-5",
        "CAA(Civil Application in AO)-43",
        "CAC(Civil Application in CRA)-46",
        "CAE(Civil Application in Civil Reference)-47",
        "CAF(Civil Application in FA)-42",
        "CAL(Company Application)-6",
        "CALCR(Company Applications (Criminal))-7",
        "CAM(Civil Application in AA)-49",
        "CAN(Civil Application in CP)-48",
        "CAO(CA IN OTHERS (MCA/EP/CA/XOB/CMP))-52",
        "CAP(Company Appeal)-8",
        "CAPL(CUSTOM APPEAL)-36",
        "CAS(Civil Application in SA)-44",
        "CAT(Civil Application in Tax Matters)-50",
        "CAW(Civil Application in WP)-41",
        "CAZ(Civil Application in LPA)-45",
        "CEL(CENTRAL EXCISE APEAL)-9",
        "CER(Central Excise Reference)-10",
        "CMP(Company Petition)-11",
        "COMAP(Commercial Appeal)-51",
        "CONF(Criminal Confirmation Case)-303",
        "CONP(Criminal Contempt Petition)-304",
        "CP(Cont. Petition)-12",
        "CPL(Contempt Appeal)-13",
        "CRA(Civil Revision Application)-14",
        "C.REF(Civil References)-4",
        "CRPIL(Cr. Public Interest Litigation)-353",
        "CS(Civil Suits (Transfer Civil Suit))-15",
        "EDR(Estate Duty Reference)-16",
        "EP(Election petition)-17",
        "FA(First Appeal)-18",
        "FCA(Family Court Appeal)-19",
        "GTA(Gift Tax application)-20",
        "GTR(Gift Tax Reference)-21",
        "ITA(Income tax application)-22",
        "ITL(INCOME TAX APPEAL)-23",
        "ITR(Income tax reference)-24",
        "LPA(Letter Patent Appeal)-25",
        "MCA(Misc.Civil Application)-26",
        "OLR(Official Liquidator Report)-27",
        "PIL(Public Interest Litigation)-53",
        "RAP(Review Petn. in ARA)-65",
        "RC(Rejected Case)-30",
        "REF(Criminal Reference)-305",
        "REVN(Criminal Revision Application)-306",
        "RPA(Review Petn. in AO)-57",
        "RPC(Review Petn. in CRA)-60",
        "RPC.(Review Petn. in FCA)-61",
        "RPF(Review Petn. in FA)-56",
        "RPL(Review petn. in LPA)-59",
        "RPN(Review Petn. in CP)-62",
        "RPR(Review Petn. in ARP)-64",
        "RPS(Review Petn. in SA)-58",
        "RPT(Review Petn. in CAPL)-63",
        "RPW(Review petin.in WP)-55",
        "SA(Second Appeal)-29",
        "SMAP(Cr. Suo Motu Application)-338",
        "SMAP(Suo Motu Application)-70",
        "SMC(Suo Motu Contempt Petition)-69",
        "SMCP(Cr. Suo Motu Contempt Petition)-337",
        "SMP(Suo Motu Cr PIL)-335",
        "SMPIL(Suo Motu PIL)-67",
        "SMWP(SM - W.P.)-310",
        "SMWP(Suo Motu Writ Petition)-68",
        "SMWP(Suo Motu Cr. Writ Petition)-336",
        "SN(Stamp Number (Crim))-311",
        "SPLCA(Special Civil Application)-31",
        "STA(SALES TAX APPLICATION)-38",
        "STR(SALES TAX REFERENCE)-37",
        "STXA(Sales Tax Appeal)-39",
        "WP(Criminal Writ Petition)-313",
        "WP(Writ Petition)-32",
        "WTA(Wealth Tax application)-33",
        "WTL(Wealth Tax Apeal)-28",
        "WTR(Wealth Tax reference)-34",
        "XOB(Cross Objection)-35",
      ],
      "High court of Bombay at Goa": [
        "AAR(Appln. for Appointment of Arbitrator)-1",
        "AELEP(Application in Election Petition)-2",
        "AO(Appeal From Order)-3",
        "ARA(Arbitration Application)-4",
        "ARP(Arbitration Petition)-5",
        "AS(Admiralty Suit)-43",
        "AUA(Appeal Under Arbitration Act)-6",
        "AUCPC(Appeal Under City of Panaji Corporation Act 2002)-46",
        "AUE(Appeal Under E.S.I.)-7",
        "AUEC(Appeal Under Employees Compensation)-322",
        "AUFEM(Appeal Under Foreign Exchange Management Act)-54",
        "AUPML(Appeal U/S 42 of Prevention of Money Laundering Act)-56",
        "AUSFC(Appeal Under State Financial Corporation Act)-45",
        "AUW(Appeal Under Workmens Compensation Act)-8",
        "CA(Civil Application)-9",
        "CAPL(Contempt Appeal)-10",
        "CAREV(Civil Application (Review))-11",
        "COA(Company Appeal)-12",
        "COAP(Company Application)-13",
        "COAPM(Company Application (Main))-40",
        "COMAP(Commercial Appeal)-53",
        "COMP(COMPLAINTS (IN COMPANY PETITION))-55",
        "CONF(Confirmation Case)-301",
        "CONP(Criminal Contempt Petition)-302",
        "COP(Company Petition)-14",
        "CP(Contempt Petition)-15",
        "CPCRM(Criminal Contempt Petition (Main))-319",
        "CPM(Contempt Petition (Main))-41",
        "CRA(Civil Revision Application)-16",
        "CREF(Civil References)-17",
        "CREVA(Criminal Review Application)-320",
        "CRIA(Criminal Appeal)-304",
        "CRIR(Criminal Revision Application)-305",
        "CRMA(Criminal Misc. Application)-306",
        "CRMAB(Criminal Application ( Bail ))-310",
        "CRMAM(Criminal Application ( Main ))-311",
        "CRREF(Criminal Reference Application)-312",
        "CRXFA(Criminal transfer application)-316",
        "CS(Civil Suit)-18",
        "CUSA(Custom Appeal)-36",
        "EA(Execution Application)-38",
        "ELEP(Election Petition)-19",
        "EXA(Excise Appeal)-37",
        "EXAP(Excise Application)-20",
        "FA(First Appeal)-21",
        "ITA(Income Tax Appeal)-22",
        "LPA(Letter Patent Appeal)-23",
        "MCA(Misc.Civil Application)-24",
        "MCAM(Misc.Civil Application (Main))-42",
        "PILCW(PIL Criminal Writ Petition)-318",
        "PILSM(Public Interest Litigation (Suo Motu))-44",
        "PILWP(Public Interest Litigation WP)-25",
        "PROP(Probate Petition)-48",
        "RUCA(Reference Application Under Customs Act)-26",
        "RUIT(Reference Under Income Tax Act)-27",
        "RUSTA(REFERENCE UNDER SALES TAX ACT)-51",
        "SA(Second Appeal)-28",
        "SMCMA(SUO MOTU CRIMINAL MISC. APPLICATION)-315",
        "SMCMM(SUO MOTU CRIMINAL MISCELLANEOUS (MAIN))-317",
        "SMCP(Suo Motu Contempt Petition)-29",
        "SMCR(Suo Motu Criminal Revision)-313",
        "SMCRC(Suo Motu Criminal Contempt Petition)-309",
        "SMCWP(SUO MOTU CRIMINAL WRIT PETITION)-314",
        "SMWP(Suo Motu Writ Petition)-30",
        "STA(Stamp Number (Appln.))-31",
        "STM(Stamp Number Main)-50",
        "STXA(Service Tax Appeal)-49",
        "TACR(Criminal Transfer Appeal)-307",
        "TMA(Trade Marks Appeal)-323",
        "TP(Testamentary Petition)-321",
        "TRA(Tax Revision Application)-52",
        "TXA(Tax Appeal)-32",
        "WP(Writ Petition)-33",
        "WPCR(Criminal Writ Petition)-308",
        "WTR(Wealth Tax Reference)-47",
        "XFA(Transfer Application)-39",
        "XFER(Transfer Matter)-34",
        "XOB(Cross Objection)-35",
      ],
      "Original Side,Bombay": [
        "ADMS(ADMIRALTY SUITS)-502",
        "AEP(APPLN. IN ELECTION PETITION)-606",
        "AITR(AGRICULTURAL INCOME TAX REFERENCE)-544",
        "APP(APPEALS)-565",
        "ARAPP(ARBITRATION APPEAL)-620",
        "ARBAP(ARBITRATION APPLICATION)-511",
        "ARBP(ARBITRATION PETITION)-510",
        "ARBS(ARBITRATION SUITS)-508",
        "ASWP(APPELLATE SIDE WRIT PETITION)-621",
        "AURWP(WRIT PETITION (AURANGABAD))-622",
        "AW(AWARDS)-608",
        "BIFR(BIFR)-656",
        "CA(COMPANY APPLICATION)-564",
        "CAA(COMPANY APPLICATION IN APEAL)-701",
        "CAPP(CHARTERED ACCOUNTANT APPEAL)-671",
        "CAR(CHARTERED A/C REFERENCE)-545",
        "CARAP(Commercial Arbitration Application)-738",
        "CARBA(Commercial Arbitration Appeal)-745",
        "CARBP(Commercial Arbitration Petition)-743",
        "CASE(CASE)-623",
        "CC(COUNTER CLAIM)-507",
        "CCP(COUNTER CLAIM(PARSI))-711",
        "CCPC(Criminal Cont. Petn.(Company))-714",
        "CEA(CENTRAL EXCISE APPLICATION)-536",
        "CER(CENTRAL EXCISE REFERENCE)-543",
        "CEXA(CENTRAL EXCISE APPEAL)-624",
        "CHOCL(CHAMBER ORDER LODG.(COND.OF DELAY))-611",
        "CHOIN(CHAMBER ORDER IN INSOLVECY)-909",
        "CHOL(CHAMBER ORDER)-610",
        "CHS(CHAMBER SUMMONS)-586",
        "CHSCA(Chamber Summons in Commercial Appellate Division matters)-750",
        "CHSCD(Chamber Summons in Commercial Division matters)-749",
        "CHSP(CHAMBER SUMMONS (PROTHONOTARY))-625",
        "CHSPM(CHAMBER SUMMONS(PARSI SUITS))-626",
        "CHSW(CHAMBER SUMMONS IN WRIT PETITION.)-597",
        "CN(CONTEMPT NOTICE)-589",
        "CNPCP(CONTEMPT PETITION(COMPANY PETN.))-576",
        "CNW(CONTEMPT NOTICE(WRIT PETITION))-600",
        "COAPP(COMPANY APPEAL)-577",
        "COEX(CONTEMPT PETITION IN EXECUTION)-583",
        "COI(Chamber Order(Insolvency))-719",
        "COMAP(Commercial Appeal)-739",
        "COMAS(COMMERCIAL ADMIRALTY SUITS)-758",
        "COMEX(COMMERCIAL EXECUTION APPLICATION)-755",
        "COMIP(COMMERCIAL INTELLECTUAL PROPERTY RIGHTS SUITS)-759",
        "COMMP(COMMERCIAL MISCELLANEOUS PETITION)-761",
        "COMP(COMPLAINTS (IN COMPANY PETITION))-604",
        "COMS(Commercial Suit)-742",
        "COMSS(COMMERCIAL SUMMARY SUITS)-757",
        "CONP(CONTEMPT PETITION)-569",
        "CONPP(CONTEMPT PETITION IN PIL)-729",
        "CONPW(CONTEMPT PETITION IN WRIT PETITION)-574",
        "CONTP(CONTEMPT PETITION (TP))-674",
        "COPP(CONTEMPET PETITION IN PARSI)-627",
        "CP(COMPANY PETITION)-563",
        "CPCAD(Contempt Petition in Commercial Appellate Division Matters)-741",
        "CPCAD(Contempt Petition in Commercial Appellate Division matters)-754",
        "CPCD(Contempt Petition in Commercial Division Matters)-740",
        "CPCD(Contempt Petition in Commercial Division matters)-753",
        "CPIN(Civil Contempt Petition(INPT))-705",
        "CPP(Company Partnership Petition)-736",
        "CPTP(Criminal Contempt Petition in TP)-697",
        "CRCP(CRIMINAL CONTEMPT PETITION)-657",
        "CRE(Court Receiver Execution)-676",
        "CROL(CROSS OBJECTION LODGING)-559",
        "CRPIN(Criminal Contempt Petition(INPT))-706",
        "CRR(Court Receiver Report)-675",
        "CSD(COMPANY SUMMONS FOR DIRECTION)-699",
        "CSP(COMPANY SCHEME PETITION)-700",
        "CSR(COMPANY SECRETARY REFERENCE)-555",
        "CST(CHAMBER SUMMONS (TESTAMENTARY))-666",
        "CTDA(CHARTERED ACCOUNTANTS APPLICATION.)-534",
        "CTS(Caveat In Testamentry)-725",
        "CUAPP(CUSTOM APPEAL)-628",
        "CUSA(CUSTOMS APPLICATION LODGING)-629",
        "CUSAL(CUSTOM APPLICATION LODGING.)-535",
        "CUSTA(CUSTOMS ACT APPLICATION.)-533",
        "CUSTD(CUSTOM DUTY APPLICATION)-538",
        "CUSTP(CUSTODY PETITION)-522",
        "CUSTR(CUSTOM DUTY REFERENCE)-546",
        "CVT(CEVEAT)-630",
        "DM(DISCIPLINARY MATTERS)-631",
        "DNMS(DRAFT NOTICE OF MOTION (SUITS))-632",
        "DNMW(DRAFT NOTICE OF MOTION (WRIT PETITION))-633",
        "EDA(ESTATE DUTY TAX APPLICATION)-634",
        "EDR(ESTATE DUTY TAX REFERENCE)-527",
        "EP(ELECTION PETITION)-509",
        "EPTR(EXCESS PROFIT TAX REFEENCE)-553",
        "ETR(EXPENDITURE TAX REFERENCE)-554",
        "EXA(EXECUTION APPLICATION)-609",
        "EXDA(EXCISE DUTY APPLICATION)-539",
        "EXDR(EXCISE DUTY REFERENCE)-549",
        "FAP(FOREIGN ADOPTION PETITION)-512",
        "FEMA(FEMA APPEAL)-670",
        "GCA(GOLD CONTROL APPLICATION)-528",
        "GCR(GOLD CONTROL REFERENCE)-548",
        "GP(GUARDIANSHIP PETITION.)-516",
        "GRN(GUARNISHEE NOTICE)-591",
        "GTA(GIFT TAX APPLICATION)-532",
        "GTR(GIFT TAX REFERENCE)-635",
        "GTXA(GIFT TAX APPEAL)-602",
        "IA(INTERIM APPLICATION)-907",
        "IAAP(INTERIM ARBITRATION APPLICATION)-582",
        "IAP(INDIAN ADOPTION PETITION)-526",
        "IARBP(INTERIM ARBITRATION PETITION)-521",
        "IC(Indian Companies)-737",
        "ICONP(INTERIM CONTEMPT PETITION)-570",
        "ICP(INT.CUST.PETITION.)-517",
        "IDA(INSOLVENCY DISCHARGE APPLICATION)-660",
        "IGP(INDIAN GUARDANSHIP PETITION)-658",
        "ILP(INTERIM LUNANCY PETITION)-636",
        "IMGP(INTERIM GUARDIAN PETITION)-523",
        "IMHA(INTERIM MENTAL HEALTH ACT PETITION)-525",
        "IMP(INTERIM MISC. PETITION)-520",
        "INAP(Intervention Application)-702",
        "INOT(INSOLVENCY NOTICE)-567",
        "INPT(INSOLVENCY PETITION)-514",
        "INSA(INSURANCE ACT)-637",
        "INSP(INSOLVENCY PETITION)-566",
        "IP(INT.PETITION.)-638",
        "IPA(In Person Application)-734",
        "IPMP(INTERIM PARSI MATRIMONIAL PETITION)-639",
        "IRN(INSOLVENCY RULE NISI)-659",
        "IRXA(INTEREST TAX APPEAL)-617",
        "ITA(INCOME TAX APPLICATION)-530",
        "ITR(INCOME TAX REFERENCE)-542",
        "ITXA(INCOME TAX APPEAL(IT))-601",
        "JO(JUDGES ORDER)-664",
        "JOI(Judges Order(Insolvency))-721",
        "JOT(JUDGES ORDER (TESTAMENTARY))-552",
        "JOW(JUDGES ORDER (WRIT PETITION))-672",
        "LAR(LAND ACQUISITION REFERENCE)-613",
        "LP(LUNANCY PETITION)-519",
        "LPETN(LEAVE PETITION)-556",
        "LPW(LEAVE PETITION(WRIT))-713",
        "MAPP(MISC.APPLICATION)-614",
        "MCA(MISC.COM.APP)-640",
        "MERC(MAHA. ELECTRICITY REGULATORY COMM.)-615",
        "MHA(MENTAL HEALTH ACT PETITION)-524",
        "MJS(MATRIMONIAL SUITS)-503",
        "MP(MISC.PETITION.)-515",
        "MPIN(Misc. Petition for leave)-703",
        "MPP(MISC. PARSI PETITION)-641",
        "MPT(MISC PETITION IN T and I J)-562",
        "MS(MISFEASONS SUMMONSES)-642",
        "MTA(MISC. TESTAMENTARY APPLN.)-616",
        "MVXA(Mah. Value Added Tax Appeal)-722",
        "N(NOTICE)-587",
        "NAGWP(WRIT PETITION (NAGPUR))-643",
        "NMA(NOTICE OF MOTION (APPEALS))-585",
        "NMC(NOTICE OF MOTION  (COMPANY))-644",
        "NMCA(Notice of Motion in Commercial Appellate Division matters)-748",
        "NMCD(Notice of Motion in Commercial Division matters)-747",
        "NMIS(NOTICE OF MOTION (INSOLVENCY))-645",
        "NMISA(NM FOR SETTING ASIDE INSOL.NOTICE)-661",
        "NMS(NOTICE OF MOTION (SUITS))-584",
        "NMSP(NOTICE OF MOTION (PARSI SUIT))-646",
        "NMT(NOTICE OF MOTION (TESTAMENTARY))-665",
        "NMW(NOTICE OF MOTION  (WRIT PETITION))-596",
        "OAR(OFFICIAL ASSIGNEES REPORT)-662",
        "OLR(Official Liquidator Report)-716",
        "ORGS(ORGINATING SUMMONS)-590",
        "OTS(Obj. to Scheme(Company))-715",
        "PCN(PARSI CONTEMPT NOTICE OF MOTION)-647",
        "PEXA(PARSI EXECUTION APPLICATION)-731",
        "PIL(PUBLIC INTEREST LITIGATION)-668",
        "PMA(PARSI MISC. APPLICATION)-730",
        "PMP(PARSI MATRIMONIAL PETITION)-648",
        "PMTP(PARSI  MAINTENANCE  PETITION)-669",
        "PN(PERJURY NOTICE)-592",
        "PNW(PERJURY NOTICE ( WRIT PETITION))-599",
        "PP(PAUPER PETITION)-501",
        "PPA(PAUPER PETITION (APPEAL))-580",
        "PPET(PARSI PETITION)-710",
        "PPIN(Protection Petition(INPT))-723",
        "PPT(PAUPER PETITION (TESTAMENTARY))-667",
        "PRJP(PERJURY PETITION)-581",
        "PS(PARSI SUITS)-505",
        "PSC(PETI.FOR LEAVE TO APPEAL TO THE SUP.CRT)-607",
        "RA(RESTORATION APPLICATION)-727",
        "RAL(REFERENCE APPLICATION LODGING)-649",
        "RCW(Rejected Case(OS-WP))-677",
        "REP(REPORT)-650",
        "RP(REVIEW PETITION)-557",
        "RPA(REVIEW PETITION(APPEAL))-558",
        "RPC(REVIEW PETITION (COMPANY))-579",
        "RPCA(Review Petition in Commercial Appellate Division matters)-752",
        "RPCD(Review Petition in Commercial Division matters)-751",
        "RPE(Recrimination Petition)-712",
        "RPIA(REVIEW PETITION IN IA)-908",
        "RPIN(Review Petition for Leave(INPT))-704",
        "RPP(REVIEW PETITION(PIL))-732",
        "RPPS(Review Petition in Parsi Suit)-718",
        "RPW(REVIEW PETITION (WRIT PETITION).)-568",
        "RTP(REVIEW PETITION (TESTAMENTARY))-578",
        "S(SUITS)-504",
        "SAPP(SEBI APPEAL)-612",
        "SCN(SHOW CAUSE NOTICE)-588",
        "SCNCP(SHOW CAUSE NOTICE (COMPANY))-619",
        "SCNIN(Show Cause Notice(INPT))-707",
        "SCNP(SHOW CAUSE NOTICE IN CPTP)-698",
        "SCNTP(SHOW CAUSE NOTICE (TESTA.PETN.))-618",
        "SCNW(SHOW CAUSE NOTICE (WRIT PETITION))-598",
        "SJ(SUMMONSES FOR JUDGEMENT)-595",
        "SMC(SUO MOTO CONTEMPT PETITION)-573",
        "SMCN(SUO MOTO CONTEMPT NOTICE)-593",
        "SMCP(SUO MOTO CRIMINAL CONTEMPT PETITION)-571",
        "SMCR(SUO MOTO CIVIL CONTEMPT REFERENCE)-572",
        "SMN(SUO MOTO NOTICE)-594",
        "SMP(SUO MOTO PIL)-726",
        "SMSN(SUO MOTO SHOW CAUSE NOTICE)-724",
        "SMWP(SUO MOTO IN WRIT PETITION)-575",
        "SPAR(SPECIAL CASE IN ARBITRATION PETN/APPL)-605",
        "SPTA(SUPER PROFIT TAX APPLICATIONS)-540",
        "SPTR(SUPER PROFIT TAX REFERENCE)-550",
        "SS(SUMMARY SUITS)-506",
        "STA(SALES TAX APPLICATIONS)-531",
        "STAPP(SUR TAX APPEAL)-663",
        "STR(SALES TAX REFERENCE)-551",
        "STXA(SALES TAX APPEAL)-673",
        "SURTA(SUR TAX APPLICATION)-537",
        "SURTR(SUR TAX REFERENCE)-651",
        "TC(COMPLAINTS IN TESTAMENTARY MATTERS)-760",
        "TD(Transfer Decree)-709",
        "TP(TESTAMENTARY PETITION)-561",
        "TPAC(Third Party Applications for CC)-733",
        "TPN(THIRD PARTY NOTICE)-652",
        "TRP(TRUST PETITION)-518",
        "TRS(TRANSFERRED SUIT FROM OTHER COURTS)-653",
        "TS(TESTAMENTARY SUITS)-654",
        "WILL(Will)-735",
        "WP(WRIT PETITION)-560",
        "WSCCL(WRITTEN STATMENT amp COUNTER-CLAIM)-655",
        "WTA(WEALTH TAX APPLICATION)-529",
        "WTR(WEALTH TAX REFERENCE)-541",
        "WTXA(WEALTH TAX APPEAL)-603",
      ],
    },

    "Calcutta High Court": {
      "Appellate side": [
        "AST(TEMPORARY NUMBER)-41",
        "CCGAT(CUSTOMS/EXC/GOLD227)-38",
        "CO(CIVIL ORDER/MISC.CAS)-15",
        "CO.CT(CO(CENTRAL ADMIN TRI)-34",
        "COLRT(WB LAND REFORMS ANDTEN.227)-40",
        "CO.ST(CO(STATE ADMIN TRIBN)-35",
        "COT(CROSS OBJECTION APPE)-27",
        "CO.TT(CO(W.B. TAX TRIBUNAL)-36",
        "CPAN(CONTEMPT APPLICATION)",
        "CR(CIVIL REVISION)-14",
        "CRA(CRIMINAL APPEAL)-11",
        "CRA (DB)(Criminal appeal arising out of sentence for a period exceeding 7 years)-55",
        "CRA (SB)(Criminal appeals in respect of sentence of any criminal court up to 7 years)-54",
        "CRC(CIVIL REVISION CONTEMPT)-42",
        "CRLCP(CRIMINAL(CONTEMPT))-46",
        "CRM(CRIMINAL MISC. CASE(BAIL APPLICATION))-17",
        "CRM (A)(Anticipatory bail applications under Section 438 Cr. P.C)-58",
        "CRM (DB)(Bail applications where sentence may exceed imprisonment for seven years)-57",
        "CRM (FEMA)(All bail applications pertaining to FEMA)-62",
        "CRM (FERA)(All bail applications pertaining to FERA)-61",
        "CRM (NDPS)(All bail applications pertaining to NDPS Act)-60",
        "CRM (SB)(Bail applications where sentence does not exceed imprisonment for seven years)-56",
        "CRMSPL(CRIMINAL MISC. CASE(SPL. LEAVE PETITION))-3",
        "CRM (TADA)(All bail applications pertaining to TADA Act)-59",
        "CRR(CRIMINAL REVISION)-16",
        "DR(DEATH REFERENCE)-18",
        "DVW(PROTECTION OF WOMEN AGAINST DOMESTIC VIOLENCE ACT, 2005)-1",
        "FA(CIVIL FIRST APPEAL)-9",
        "FAT(TENDER FIRST APPEAL)-6",
        "FCA(FAMILY COURT APPEAL)-30",
        "FMA(CIVIL APPEAL FROM ORDER)-19",
        "FMAT(ADMISSION OF CIVIL APPEAL FROM ORDER)-8",
        "FMAT (ARBAWARD)(Appeals preffered under Section 37(1)(c) thereby setting aside an arbitral award under Sec)-53",
        "FMAT (IR)(An Appeal under the Indian Railways Act, 1890)-51",
        "FMAT (MV)(An Appeal under the Motor Vehicles Act, 1988)-52",
        "FMAT (WC)(APPEAL UNDER THE WORKMENS COMPENSATION ACT)-50",
        "GA(GOVT. APPEAL)-26",
        "IRD(TEMP D)-22",
        "IRE(TEMP E)-23",
        "IRH(TEMP H)-24",
        "LPA(LETTERS PATENT APPEAL)-2",
        "MA(MANDAMUS APPEAL)-21",
        "MAT(TENDER OF MAND APPL)-25",
        "RVW(REVIEW)-20",
        "SA(CIVIL SECOND APPEAL)-10",
        "SAT(TENDER SECOND APPEAL)-7",
        "SMA(SECOND MISCELLANEOUS APPEAL)-29",
        "SMAT(SECOND MISCELLANEOUS APPEAL TENDER)-28",
        "SRC(SPECIAL REFERENCE CASE(C))-4",
        "SRCR(SPECIAL REFERENCE CASE(CR))-5",
        "TRP(COMM)(TRANSFER PETITION RELATING TO COMMERCIAL DIVISION)-63",
        "WCGAT(CUSTOMS/EXC/GOLD226)-37",
        "WPA(WRIT PETITION)-12",
        "WPA(H)(WRIT (HABEAS CORPUS))-49",
        "WPA(P)(WRIT (PIL MATTER))-48",
        "WPCR(WRIT PETITION(CIVIL RULE))-44",
        "WPCRC(WP CIVIL RULE CONT.)-43",
        "WP.CT(WP(CENTRAL ADMIN TRIBUNAL))-31",
        "WPDRT(DEBT APPELLATE TRIBUNAL)-47",
        "WPLRT(WB LAND REFORMS AND TEN.226)-39",
        "WP.ST(WP(STATE ADMIN TRIBUNAL))-32",
        "WP.TT(WP(WB TAX TRIBUNAL))-33",
        "WP.WT(WAKF TRIBUNAL (227))-45",
      ],
      "Circuit Bench At Jalpaiguri": [
        "AST(TEMPORARY NUMBER)-41",
        "CCGAT(CUSTOMS/EXC/GOLD227)-38",
        "CO(CIVIL ORDER/MISC.CAS)-15",
        "CO.CT(CO(CENTRAL ADMIN TRI)-34",
        "COLRT(WB LAND RE ANDTEN.227)-40",
        "CO.ST(CO(STATE ADMIN TRIBN)-35",
        "COT(CROSS OBJECTION APPE)-27",
        "CO.TT(CO(W.B. TAX TRIBUNAL)-36",
        "CPAN(CONTEMPT APPLICATION)-13",
        "CR(CIVIL REVISION)-14",
        "CRA(CRIMINAL APPEAL)-11",
        "CRA(DB)(CRIMINAL APPEALS ARISING OUT OF THE SENTENCE OF DEATH, EXCEEDING 7 YEARS.)-56",
        "CRA(DB-AB)(APPLICATION FOR ANTICIPATORY BAIL)-66",
        "CRA(DB-B)(APPEAL BEFORE THE HON'BLE DIVISION BENCH FOR BAIL)-64",
        "CRA(DB-CB)(APPEAL BEFORE THE HON'BLE DIVISION BENCH FOR CANCELLATION OF BAIL)-65",
        "CRA(SB)(ANY CRIMINAL COURT ARISING OUT OF SENTENCE UPTO 7 YEARS)-55",
        "CRA(SB-B)(APPEAL BEFORE THE HON'BLE SINGLE BENCH FOR BAIL)-67",
        "CRA(SB-CB)(APPEAL BEFORE THE HON'BLE SINGLE BENCH FOR CANCELLATION OF BAIL)-68",
        "CRC(CIVIL REVI. CONTEMPT)-42",
        "CRLCP(CRIMINAL(CONTEMPT))-46",
        "CRM(CRIMINAL MISC. CASE(BAIL APPLICATION))-17",
        "CRM(A)(ANTICIPATORY BAIL APPLICATIONS UNDER SECTION 438 Cr.P.C)-59",
        "CRM(DB)(BAIL APPLICATIONS A THE PRE CONVICTION STAGE WHERE SENTENCE MAY EXCEED IMPRISONMENT)-58",
        "CRM(FEMA)(ALL BAIL APPLICATIONS PERTAINING TO FOREIGN EXCHANGE MANAGEMENT ACT)-63",
        "CRM(FERA)(ALL BAIL APPLICATIONS PERTAINING TO FOREIGN EXCHANGE REGULATION ACT)-62",
        "CRM(NDPS)(ALL BAIL APPLICATIONS PERTAINING TO NARCOTIC DRUGS AND PSYCHOTROPIC SUBSTANCE ACT)-61",
        "CRM(SB)(BAIL APPLICATIONS A THE PRE CONVICTION STAGE WHERE SENTENCE DOES NOT EXCEED IMPRISONMENT)-57",
        "CRMSPL(CRIMINAL MISC. CASE(SPL. LEAVE PETITION))-3",
        "CRM(TADA)(ALL BAIL APPLICATIONS PERTAINING TO TERRORIST AND DISRUPTIVIES ACTIVITIES ACT)-60",
        "CRR(CRIMINAL REVISION)-16",
        "DR(DEATH REFERENCE)-18",
        "DVW(PROTECTION OF WOMEN AGAINST DOMESTIC VIOLENCE ACT, 2005)-1",
        "FA(CIVIL FIRST APPEAL)-9",
        "FAT(TENDER FIRST APPEAL)-6",
        "FCA(FAMILY COURT APPEAL)-30",
        "FMA(C. APPEAL FROM ORDER)-19",
        "FMAT(ADMS. C. APPL ORDER)-8",
        "FMAT(ARBAWARD)(APPEALS PREFERRED UNDER SECTION 37(1)(C) THEREBY SETTING ASIDE AN ARBITRAL AWARD)-54",
        "FMAT(IR)(AN APPEAL UNDER THE INDIAN RAILWAYS ACT, 1890)-52",
        "FMAT(MV)(AN APPEAL UNDER THE MOTOR VEHICLES ACT, 1988)-53",
        "FMAT(WC)(APPEAL UNDER THE WORKMENS COMPENSATION ACT)-51",
        "GA(GOVT. APPEAL)-26",
        "IRD(TEMP D)-22",
        "IRE(TEMP E)-23",
        "IRH(TEMP H)-24",
        "LPA(LETTERS PATENT APPEAL)-2",
        "MA(MANDAMUS APPEAL)-21",
        "MAT(TENDER OF MAND APPL)-25",
        "RVW(REVIEW)-20",
        "SA(CIVIL SECOND APPEAL)-10",
        "SAT(TNDER SECOND APPEAL)-7",
        "SMA(SECOND MISC APPEAL)-29",
        "SMAT(SEC.MISC.APPEAL TEND)-28",
        "SPL. CASE(SPECIAL CASE)-48",
        "SRC(SPECIAL REFERENCE CASE(C))-4",
        "SRCR(SPECIAL REFERENCE CASE(CR))-5",
        "WCGAT(CUSTOMS/EXC/GOLD226)-37",
        "WPA(WRIT PETITION)-12",
        "WPA(H)(WRIT (HABEAS CORPUS))-49",
        "WPA(P)(WRIT (PIL MATTER))-50",
        "WPCR(WRIT PETITION(CIVIL RULE))-44",
        "WPCRC(WP CIVIL RULE CONT.)-43",
        "WP.CT(WP(CENTRAL ADMIN TRIBUNAL))-31",
        "WPDRT(DEBT APPL. TRIBUNAL)-47",
        "WPLRT(WB LAND RE ANDTEN.226)-39",
        "WP.ST(WP(STATE ADMIN TRIBUNAL))-32",
        "WP.TT(WP(WB TAX TRIBUNAL))-33",
        "WP.WT(WAKF TRIBUNAL (227))-45",
      ],
      "Circuit Bench At Port Blair": [
        "AP(ARBITRATION PETITION)-50",
        "AST(TEMPORARY NUMBER)-41",
        "CCGAT(CUSTOMS/EXC/GOLD227)-38",
        "CO(CIVIL ORDER/MISC.CAS)-15",
        "CO.CT(CO(CENTRAL ADMIN TRI)-34",
        "COLRT(WB LAND REF AND TEN.227)-40",
        "CO.ST(CO(STATE ADMIN TRIBN)-35",
        "COT(CROSS OBJECTION APPE)-27",
        "CO.TT(CO(W.B. TAX TRIBUNAL)-36",
        "CPAN(CONTEMPT APPLICATION)-13",
        "CR(CIVIL REVISION)-14",
        "CRA(CRIMINAL APPEAL)-11",
        "CRA(DB)(CRIMINAL APPEALS EXCEEDING 7 YEARS)-64",
        "CRAN(CRIMINAL APPLICATION)-51",
        "CRA(SB)(CRIMINAL APPEAL ARISING OUT OF THE SENTENCE UPTO 7 YEARS)-63",
        "CRC(CIVIL REVISION CONTEMPT)-42",
        "CRLCP(CRIMINAL(CONTEMPT))-46",
        "CRM(CRIMINAL MISC. CASE(BAIL APPLICATION))-17",
        "CRM(A)(ANTICIPATORY BAIL UNDER SECTION 438 Cr.PC)-52",
        "CRM(DB)(BAIL APPLICATIONS MAY EXCEED IMPRISONMENT FOR 07 YEARS)-54",
        "CRM(FEMA)(BAIL APPLICATIONS TO FOREIGN EXCHANGE MANAGEMENT ACT)-58",
        "CRM(FERA)(BAIL APPLICATIONS TO FOREIGN EXCHANGE REGULATIONS ACT)-57",
        "CRM(NDPS)(BAIL APPLICATIONS IN NARCOTICS DRUGS AND PSYCHOTROPIC SUBSTANCES ACT)-56",
        "CRM(SB)(BAIL APPLICATION DOES NOT EXCEED IMPRISONMENT FOR 07 YEARS)-53",
        "CRMSPL(CRIMINAL MISC. CASE(SPL. LEAVE PETITION))-3",
        "CRM(TADA)(BAIL APPLICATIONS FOR TERRORIST AND DISRUPTIVE ACTIVITIES ACT)-55",
        "CRR(CRIMINAL REVISION)-16",
        "DR(DEATH REFERENCE)-18",
        "DVW(PROTECTION OF WOMEN AGAINST DOMESTIC VIOLENCE ACT, 2005)-1",
        "FA(CIVIL FIRST APPEAL)-9",
        "FAT(TENDER FIRST APPEAL)-6",
        "FCA(FAMILY COURT APPEAL)-30",
        "FMA(CIVIL APPEAL FROM ORDER)-19",
        "FMAT(ADMISSION OF CIVIL APPEAL FROM ORDER)-8",
        "FMAT(ARBAWARD)(APPEALS UNDER SECTION 37(1)(C) SETTING ASIDE UNDER SECTION 34 ARBITRATION AND CONCILATION)-62",
        "FMAT(IR)(APPEAL UNDER INDIAN RAILWAYS ACT, 1890)-60",
        "FMAT(MV)(APPEAL UNDER MOTOR VEHICLE ACT, 1988)-61",
        "FMAT(WC)(APPEAL UNDER WORKMENS COMPENSATION ACT)-59",
        "GA(GOVT. APPEAL)-26",
        "IRD(TEMP D)-22",
        "IRE(TEMP E)-23",
        "IRH(TEMP H)-24",
        "LPA(LETTERS PATENT APPEAL)-2",
        "MA(MANDAMUS APPEAL)-21",
        "MAT(TENDER OF MAND APPL)-25",
        "RVW(REVIEW)-20",
        "SA(CIVIL SECOND APPEAL)-10",
        "SAT(TENDER SECOND APPEAL)-7",
        "SMA(SECOND MISCELLANEOUS APPEAL)-29",
        "SMAT(SECOND MISCELLANEOUS APPEAL TENDER)-28",
        "SRC(SPECIAL REFERENCE CASE(C))-4",
        "SRCR(SPECIAL REFERENCE CASE(CR))-5",
        "WCGAT(CUSTOMS/EXC/GOLD226)-37",
        "WPA(WRIT PETITION)-12",
        "WPA(H)(WRIT (HABEAS CORPUS))-49",
        "WPA(P)(WRIT (PIL MATTER))-48",
        "WPCR(WRIT PETITION(CIVIL RULE))-44",
        "WPCRC(WP CIVIL RULE CONT.)-43",
        "WP.CT(WP(CENTRAL ADMIN TRIBUNAL))-31",
        "WPDRT(DEBT APPELLATE TRIBUNAL)-47",
        "WPLRT(WB LAND REF AND TEN.226)-39",
        "WP.ST(WP(STATE ADMIN TRIBUNAL))-32",
        "WP.TT(WP(WB TAX TRIBUNAL))-33",
        "WP.WT(WAKF TRIBUNAL (227))-45",
      ],
      "Original Side": [
        "AA(ARBRITATION APPL.)-1",
        "ABWA(APPL.UND.BENGAL WAKFS ACT)-2",
        "AC(AWARD CASES)-3",
        "ACA(APPL.UNDER CHARTERED ACCOUNTANTS ACT, 1949)-4",
        "ACO(PET. IN COMP. APPL.)-5",
        "ACR(APPL.UND.CHARITABLE AND RELIGIOUS TRUST ACT)-6",
        "ACRP(APPL.UND.SEC.151 OF THE CR.P.C.)-7",
        "ACWA(APPL.UND.SEC 21 COST AND WORKS ACCOUNTACTS ACT, 1959)-8",
        "AD-COM(APPEAL FROM DECREES (COMMERCIAL))-134",
        "AED(APPL. U/S. 64 OF ESTATE DUTY ACT, 1953)-9",
        "AET(APPL. U/S 25 OF EXPENDITURE TAX ACT, 1957)-10",
        "AFP(APPL.FOR LEAVE TO SUE AS AN INDIGENT PERSON)-11",
        "AGA(APPL.UND.SEC.25 OF ADMINISTRATOR GENERALS ACT)-12",
        "AGL(APPL.FOR APPOINTMENT OF GUARDIAN-AD-LITEM)-13",
        "AGT(APPL. U/S 26 GIFT TAX ACT, 1958)-14",
        "AGWA(APPL.UND.GUARDIANS AND WARDS ACT)-15",
        "AHC(HABEAS CORPUS)-16",
        "AID(APPL. UNDER PATENTS AND DESIGNS ACT, 1970)-17",
        "AIDR(APPL.UND.INDUSTRIAL DEVELOPMENT REGULATION ACT)-18",
        "AIFC(APPL.UND.INDUSTRIAL FINANCE CORP. ACT)-19",
        "AIRB(APPN.RECON.BANK OF I)-20",
        "ALP(APPL.UND.CLAUSE 13 OF LETTERS PATENT)-21",
        "ALPII(APPL.UND.CLAUSE 17 L)-23",
        "ALPITL(APPL.CLAUSE-17 LP)-24",
        "ALP.ITL(APPL. UNDER CLAUSE 17 OF THE LETTERS PATENT)-22",
        "AO-COM(APPEAL FROM ORDERS (COMMERCIAL))-119",
        "AOR(CALCUTTA OFFICIAL RECEIVER'S ACT 1938)-25",
        "AORC(APPLICATION OMNIBUS RESIDUARY CLASS)-26",
        "AOT(APPL.UND.OFFICIAL TRUSTEES ACT)-27",
        "AP(ARBITRATION PETITION)-28",
        "AP-COM(ARBITRATION PETITION (COMMERCIAL))-118",
        "APD(APPEAL FROM DECREE)-29",
        "APDT(APP. FR. DECR. TEND.)-30",
        "APML(APPEAL UNDER PREVENTION OF MONEY LAUNDERING)-100",
        "APMLT(TENDER FOR APPEAL UNDER PREVENTION OF MONEY LAUNDERING)-101",
        "APO(APPEAL FROM ORDER)-31",
        "APOT(TEMP APO)-32",
        "AS(ADMIRALITY SUITS)-33",
        "ASCI(APPL.FOR LEAVE TO APPEAL TO THE SUPREME COURT OF INDIA)-34",
        "AS-COM(ADMIRALTY SUITS (COMMERCIAL))-120",
        "ASFC(APPL.UND.STAE FINANCIAL CORP. ACT)-35",
        "ASM(APPL.SANCHAITA MATTERS)-36",
        "ATA(APPL.UNDER TRUST ACT)-37",
        "ATM(APPL.UND.TRADE AND MERCHANDISE MARKS, ACT)-38",
        "AUDA(APPL.UND.UNCLAIMED DEPOSITS ACT)-39",
        "AWT(APPL. U/S 27 OF WEALTH TAX ACT, 1957)-40",
        "BBA(APPL.UND.BANKERS BOOKS EVIDENCE ACT, 1891)-41",
        "BIFR(BIFR)-42",
        "CA(COMPANY APPLICATION)-43",
        "CAA(COMMISSIONER OF PATENT APPEAL)-44",
        "CC(CONTEMPT OF COURT CASES)-45",
        "CC-COM(CONTEMPT CASE (COMMERCIAL))-124",
        "CEXA(APPL.UND.CENTRAL EXCISE ACT)-46",
        "CHS(CHAMBER SUMMONS)-47",
        "CP(COMPANY PETITIONS)-48",
        "CPA(COMMISSIONER OF PATE)-49",
        "CRA(COPYRIGHT APPEAL)-50",
        "CRCC(CRIMINAL CONTEMPT)-51",
        "CS(CIVIL SUITS)-52",
        "CS-COM(CIVIL SUIT (COMMERCIAL))-117",
        "CS(O)(CIVIL SUIT(OLD))-53",
        "CSOS(CIVIL SUIT ORIGINATING SUMMONS)-54",
        "CUSTA(APPL.UND.CUSTOMS ACT)-55",
        "DM(UND.CHAPTER V OF O.S. RULES)-56",
        "EC(EXECUTION CASES)-57",
        "EC-COM(EXECUTION CASE (COMMERCIAL))-122",
        "EOPLA(EXTRA ORDINARY PROBATE PROCEEDINGS)-58",
        "EOS(EXTRA ORDINARY SUITS)-59",
        "EP(ELEC.PET.(PEOPLES REPRESENTATION ACT, 1951))-60",
        "FEA(FOREIGN EXCHANGE APPEAL)-61",
        "FERA(APPL.UND.FOREIGN EXCHANGE REGULATION ACT)-62",
        "GA-COM(GENERAL APPLICATION (COMMERCIAL))-123",
        "GCAL(APPL.UND.GOLD CONTROL ACT)-63",
        "IC(INSOLVENCY CASES)-64",
        "ICA(APPL.UNDER INDIAN COMPANIES ACT, 1913)-65",
        "IP(IT.PET.256(2)IT'61)-66",
        "IP-COM(INTELLECTUAL PROPERTY MATTERS (COMMERCIAL))-121",
        "IPDAID(APPEALS UNDER THE DESIGNS ACT, 2000)-110",
        "IPDATM(APPLICATIONS UNDER SECTION 47, 57, 125 OF TRADE MARKS ACT, 1999)-112",
        "IPDCR(APPLICATIONS UNDER SCETION 50 OF THE COPYRIGHT ACT, 1957)-113",
        "IPDCRA(APPEALS UNDER THE COPYRIGHT ACT, 1957)-105",
        "IPDGI(APPL. U/S 27/58 OF THE GEOGRAPHICAL INDICATIONS OF GOODS (REG And PROT), ACT 1999)-115",
        "IPDGIA(APPEALS UNDER THE GI OF GOODS (REGISTRATION AND PROTECTION) ACT, 1999)-107",
        "IPDIT(APPLICATIONS UNDER SECTION 46 (1A) OF THE INFORMATION TECHNOLOGY ACT, 2000)-116",
        "IPDITA(APPEALS UNDER THE INFORMATION TECHNOLOGY ACT, 2000)-111",
        "IPDPAT(APPLICATIONS UNDER SECTION 58/64/71/103 OF THE PATENTS ACT, 1970)-114",
        "IPDPTA(APPEALS UNDER PATENTS ACT, 1970)-106",
        "IPDPVA(APPEALS UNDER THE PLANT VARIETIES AND FARMERS RIGHT ACT, 2001)-108",
        "IPDSCD(APPEALS UNDER THE SEMICONDUCTOR INTEGRATED CIRCUITS LAYOUT-DESIGN ACT, 2000)-109",
        "IPDTMA(APPEALS UNDER THE TRADE MARKS ACT, 1999)-104",
        "ITA(INCOME TAX APPEAL)-67",
        "ITAT(INCOME TAX APPEAL TENDERED)-68",
        "ITP(INCOME TAX PET.(U/S. 256 (2) OF I.T. ACT, 1961))-69",
        "ITR(INCOMETAX REF.SEC.256 (1) OF I.T. ACT, 1961)-70",
        "LM(LUNATIC MATTERS)-71",
        "LPA(LETTERS PATENT APPEAL)-72",
        "MEX-COM(MONEY EXECUTION (COMMERCIAL))-133",
        "MISCA-COM(MISCELLANEOUS APPEAL (COMMERCIAL))-132",
        "MISC. ARB - COM(MISCELLANEOUS ARBITRATION (COMMERCIAL))-128",
        "MISC. CASE - COM(MISCELLANEOUS CASES (COMMERCIAL))-127",
        "MISCEX-COM(MISCELLANEOUS EXECUTION (COMMERCIAL))-130",
        "MM(MISFEAOUCE MATTERS)-73",
        "MS(MATRIMONIAL SUITS)-74",
        "MS-COM(MONEY SUIT (COMMERCIAL))-126",
        "NM(NOTICE OF MOTION)-75",
        "OCO(ORIGINAL SIDE CROSS OBJECTION)-76",
        "OCOT(ORIGINAL SIDE CROSS OBJECTION TENDER)-77",
        "OTS(ORDINANCE TRANSFER SUITS)-78",
        "PLA(APPL.FOR PROBATE AND LETTERS OF ADMINISTRATION)-79",
        "PLR(UND.CHPT.XXXI-A OF O.S. RULES)-80",
        "PMS(PARSI MATRIMONIAL SUITS)-81",
        "REF(REFERENCE CASE)-82",
        "RVWO(MEMORANDUM OF REVIEWS)-83",
        "SALT(APPL.UND.SALT ACT)-84",
        "SANA(SANCHAITA APPEAL)-85",
        "SCO(SPECIAL CASE FOR OPINION OF COURT)-86",
        "SESS(SESSIONS CASES)-87",
        "SS(SPECIAL SUITS(SEC 20 OF THE ARBITRATION ACT, 1940)-88",
        "T(TEMP A(1))-89",
        "TA-COM(TITLE APPEAL (COMMERCIAL))-131",
        "TBCS(TRANSFERRED BANKING COMPANIES SUITS)-90",
        "TCS(TRANSFERRED COMPANY SUITS)-91",
        "TEX-COM(TITLE EXECUTION (COMMERCIAL))-129",
        "TMA(TRADE MARK APPEAL)-92",
        "TS(TESTAMENTERY SUITS)-93",
        "TS-COM(TITLE SUIT (COMMERCIAL))-125",
        "TUA(TRADE UNION APPEAL)-94",
        "WPCR(WRIT PETITION(CIVIL RULE))-99",
        "WP.CT(WP(CENTRAL ADMIN TRIBUNAL))-96",
        "WPO(WRIT PETITION)-95",
        "WPO(H)(WRIT (HABEAS CORPUS))-103",
        "WPO(P)(WRIT (PIL MATTER))-102",
        "WP.ST(WP(STATE ADMIN TRIBUNAL))-97",
        "WP.TT(WP(WB TAX TRIBUNAL))-98",
      ],
    },

    "Gauhati High Court": {
      "Aizawl Bench": [
        "AB(Anticipatory Bail)-49",
        "abc()-211",
        "Adml.S.(Admiralty Suits)-164",
        "AIR(REF)()-201",
        "Arb.A.(Appeals under Indian Arbitration Act.)-139",
        "Arb.Appln.(Application under Indian Arbitration Act.)-219",
        "Arb.P.(Petition under Indian Arbitration Act.)-138",
        "BA(Bail Application)-57",
        "Bail Appln.(Bail_Application)-171",
        "Bkg.p.(Proceedings under Banking Regulation Act.)-118",
        "CA()-187",
        "CAC()-183",
        "CAPL(Contempt_Appeal)-47",
        "C.APPLN(Company Applications)-181",
        "CAVT(Caveat)-178",
        "CC(CIV)(Contempt Of Court (Civil))-7",
        "CE.Ref.(Reference under Central Excise Act.)-152",
        "CEREF(CENTRAL EXCISE REFERENCE)-83",
        "C.Ex.App.(Central Excise Appeal)-213",
        "CMAppl.(Miscellaneous Applications,e.g.,Leave to Suo as indigent person,restoration application,co)-109",
        "CO(Cross Objection in First Appeal)-97",
        "Co.App.(Appeals against Judgments/Orders in Company Petitions / Company Appeal.)-117",
        "Co.Appl.(Application in Pending Proceeding.)-115",
        "COB(FA)(Cross Objection in First_Appeal)-6",
        "COB(MFA)(Cross Objection in Misc. First Appeal)-5",
        "COB(T)(CROSS OBJECTION (TENDER))-81",
        "COJO(Correction of Judgement/Order)-34",
        "COMAPL(Company Appeal)-4",
        "COMPET(Company Petition)-3",
        "CONTAPP(Contempt Appeal)-94",
        "Cont.App.(C)(Appeals against orders in Civil Contempt matters.)-143",
        "Cont.App.(Crl.)(Appeals against Orders in Criminal Contempt matters)-175",
        "Cont.Cas(C)(Contempt of Court Cases relating to Civil Contempt.)-142",
        "Cont.Cas.(Crl.)(Proceedings relating to Criminal Contempt.)-174",
        "COP(C)(Civil Original Petition( Contempt ))-2",
        "Co.Pet.(Original Petition)-114",
        "CP(Condonation Petition)-68",
        "C.PTN.(Company Petitions)-180",
        "CR(Civil Rule)-1",
        "CRAPL(Criminal Appeal)-52",
        "CRAPPL(Cross Appeal)-80",
        "CR(CC)(Criminal Contempt of Court)-50",
        "CRDREF(Criminal Death Reference)-58",
        "C.Ref.(Reference.)-104",
        "CREF(Civil Reference)-13",
        "CREV(Civil Revision)-14",
        "CREV(H)(Civil Revision(hills))-15",
        "C.REVN()-188",
        "CR(HC)(Civil Rule(Habeas Corpus))-8",
        "Crl.A.(Appeal against Judgment/Sentence.)-166",
        "CR(LAB)(Civil Rule(labour))-9",
        "CRL.A(H)(NULL)-189",
        "CRL.A(J)(appeal jail)-190",
        "CR(LAN)(Civil Rule(land))-10",
        "Crl.L.P.(Criminal Leave Petition)-208",
        "Crl.M.Appl.(Other Miscellaneous Application)-172",
        "Crl.M.C.(Application under Section 482 Cr.P.C.)-170",
        "CRL.O.APPN()-192",
        "CRL.OP(C)()-179",
        "CRL.O.REF(NULL)-202",
        "Crl.P.(Criminal_Petition)-210",
        "Crl.Pet.(Criminal Petition)-209",
        "Crl.Pet.(Suo Moto)(Criminal Petition (Suo Moto))-224",
        "Crl.Ref.(Reference_)-169",
        "CRL.REF(H)(Criminal Hill reference)-206",
        "CRL.REV(H)(NULL)-194",
        "CRL.REVN(NULL)-191",
        "Crl.Rev.P.(Revision_)-168",
        "Crl.Tr.(Original Trial)-165",
        "CR(M)()-196",
        "CR(MC)(Criminal Misc. Case)-51",
        "CROA(Criminal Original Application)-62",
        "CROA(C)(Criminal Original Application Contempt)-69",
        "CROP(Criminal Original Petition)-63",
      ],
      "Itanagar Bench": [
        "AB(Anticipatory Bail u/s 438 CrPC)-49",
        "Adml.S.(Admiralty Suits)-164",
        "AIR(REF)(Reference under the Agriculture Income-Tax Act to the High Court.)-201",
        "Arb.A.(Appeals under Indian Arbitration Act.)-139",
        "Arb.P.(Petition under Indian Arbitration Act.)-138",
        "BA(Bail Application U/S 439 CrPC wef 15/11/2010)-57",
        "Bkg.p.(Proceedings under Banking Regulation Act.)-118",
        "CA(Appeals under the Companies Act, 1956)-187",
        "CAPL(Contempt Appeal)-47",
        "C.APPLN(Company Applications)-181",
        "CAVT(Caveat)-178",
        "CC(CIV)(Contempt Of Court (Civil))-7",
        "Cen.Excise Appeal(Appeal U/S 35G,Central Excise Act,1944)-234",
        "CE.Ref.(Reference under Central Excise Act.)-152",
        "CEREF(CENTRAL EXCISE REFERENCE)-83",
        "CMAppl.(Misc Applications,e.g.,Leave to Suo as indigent person,restoration application,condonation)-109",
        "CO(Cross Objection in First Appeal)-97",
        "Co.App.(Appeals against Judgments/Orders in Company Petitions.)-117",
        "Co.Appl.(Application in Pending Proceeding.)-115",
        "COB(FA)(Cross Objection in First Appeal)-6",
        "COB(MFA)(Cross Objection in Misc. First Appeal)-5",
        "COB(T)(CROSS OBJECTION (TENDER))-81",
        "Co.Case(Matters transfered under Section 446(3))-116",
        "COJO(Correction of Judgement/Order)-34",
        "COMAPL(Company Appeal)-4",
        "COMPET(Company Petition)-3",
        "CONTAPP(Contempt Appeal)-94",
        "Cont.App.(C)(Appeals against orders in Civil Contempt matters.)-143",
        "Cont.App.(Crl.)(Appeals against Orders in Criminal Contempt matters)-175",
        "Cont.Cas(C)(Contempt of Court Cases relating to Civil Contempt.)-142",
        "Cont.Cas.(Crl.)(Proceedings relating to Criminal Contempt.)-174",
        "Cont.Cas.(Crl)(Suo Moto)(Contempt Case (Crl.)(Suo Moto))-241",
        "Cont.Cas(C)(Suo Moto)(Contempt Case (Civil)(Suo Moto))-240",
        "Co.Pet.(Original Petition)-114",
        "CP(Condonation Petition)-68",
        "C.PTN.(Company Petitions)-180",
        "CR(Civil Rule)-1",
        "CRAPL(Criminal Appeal)-52",
        "CRAPPL(Cross Appeal)-80",
        "CR(CC)(Criminal Contempt of Court)-50",
        "CRDREF(Criminal Death Reference)-58",
        "C.Ref.(Reference)-104",
        "CREF(Civil Refrence)-13",
        "CREV(Civil Revision)-14",
        "CREV(H)(Civil Revision(hills))-15",
        "C.REVN(Civil Revisions)-188",
        "CR(HC)(Civil Rule(Habeas Corpus ))-8",
        "Crl.A.(Appeal against Judgment/Sentence.)-166",
        "CR(LAB)(Civil Rule(labour))-9",
        "CRL.A(H)(Criminals Appeal (Hills))-189",
        "CRL.A(J)(Appeal by the convict from Jail)-190",
        "CR(LAN)(Civil Rule(land))-10",
        "Crl.L.P.(Application for Leave to Appeal under Section 378 Cr.P.C.)-176",
        "Crl.M.Appln.(Other Miscellaneous Application)-172",
        "Crl.M.Case(Application under Section 482 Cr.P.C.)-170",
        "CRL.O.APPN(Bails Application under Section 439 Cr.P.C and 438 Cr.P.C)-192",
        "CRL.OP(C)(Contempt Petition (Criminal))-179",
        "CRL.O.REF()-202",
        "Crl.Petn.(Application u/S 482 CrPC for quashing of FIR/proceeding.)-220",
        "Crl.Ref.(Criminal Reference)-169",
        "CRL.REF(H)(Criminal Reference(Hill))-206",
        "CRL.REV(H)(Criminal Revision (Hill))-194",
        "Crl.Rev.P.(Criminal Revision Petition U/S 397/399/482 CrPC)-168",
        "Crl.Tr.(Criminal Trial)-165",
        "CR(M)(Reference under Section 27 (3) of the Wealth-Tax Act, 1957)-196",
        "CR(MC)(Criminal Misc. Case)-51",
        "CROA(Criminal Original Application)-62",
        "CROA(C)(Criminal Original Application Contempt)-69",
        "CROP(Criminal Original Petition)-63",
        "CROP(C)(Criminal Original Petition(Contempt))-53",
        "CR(OTH)(Civil Rule(other))-11",
        "CRP(Revision Petition)-102",
        "CRP (I/O)(Civil Revision Petition filed against interim orders of Civil Courts under Article 227)-236",
        "CR.REF(Criminal Reference)-54",
        "CRREVAP(Criminal Review Application)-75",
        "CR(SER)(Civil Rule(service))-12",
        "CS(Civil Suit)-95",
        "CS(OS)(Civil Suits)-159",
        "Cus.Ref.(Reference under Customs Act.)-151",
        "Death Sentence Ref.(Confirmation case under Section 336 Cr.P.C.)-167",
        "DS(Divorce Suit)-72",
        "EC(Execution Cases in Succession matters etc.)-182",
        "EDREF(Estate Duty Reference)-16",
        "ED(REF)(Reference under Section 64 (1) of the Estate Duty, 1953)-199",
        "El.App.(Appeals from judgments in Election Petition)-135",
        "Election Pet.(Election Petitions)-134",
        "Ex.Appl.(OS)(Execution Application)-161",
        "Ex.FA.(Execution First Appeal)-106",
        "Ex.P.(Execution Petition)-105",
        "Ex.SA.(Execution Second Appeal)-107",
        "FA(First Appeal)-18",
        "FA(D)(First Appeal(Divorce))-60",
        "FA/MA(F)(Other First Appeals)-186",
        "FAO(First Appeal from Orders)-98",
        "FA(OS)(First Appeal for Judgments in Original Suits.)-160",
        "FA/SA(Appeals under the Local Rent Control Act)-185",
        "GCA(Government Criminal Appeal)-20",
        "GTA/WTA/EDA(Application for direction to make a reference)-150",
        "GTREF(Gift Tax Reference)-21",
        "GT(REF)(Under Section 26 (1) of the Gift-Tax Act, 1958)-198",
        "GTR/WTR/EDR(Reference to High Court)-149",
        "Gua.P.(Petitions under Gurdianship and Wards Act.)-125",
        "IA.(Interlocutory applications in pending Suits/Appeals)-108",
        "IA(C)(Interlocutory Application in Civil)-230",
        "IA(Crl)(Interlocutory Application in Criminal)-231",
        "Insurance App.(Appeals under Insurance Act.)-141",
        "Insurance Ref.(Reference under Insurance Act.)-140",
        "Intest.Cas.(Intestate cases,e.g.,Succession Certificates,etc.)-124",
        "IP(Appl.)(Application submitted after adjudication)-137",
        "IP(M)(Main Petition)-136",
        "ITA(Application under Section 256(2))-148",
        "ITR(Reference under Section 256(1).)-147",
        "ITREF(Income Tax Reference)-22",
        "IT(REF)(Reference under Section 256 (1) of the Income-Tax Act, 1961)-195",
        "ITREF(A)(AGRI IT REFERENCE)-88",
        "LA.App.(Appeals)-127",
        "L.A.Appl.(Land Acquisation Appeal)-227",
        "LA.Ref.(Reference)-126",
        "LPA(Latters Patent Appeal)-23",
        "LPA/Sp.A(Letters Patent Appeal or SpecialAppeal Before Division Bench against a Judgment or Order)-101",
        "LPA(T)(LEAVE PATTERN APPEAL (TENDER))-87",
        "MAC(Motor Accident Claims)-132",
        "MACApp.(Motor Accident Claims Appeal)-133",
        "MAF(Miscellaneous Appeal(first))-24",
        "MA(F)(First Appeals under Order 43 rule 1 CPC relating to temporary injunction, other appeals)-184",
        "MAF(T)(Miscellaneous Appeal First(tender))-25",
        "MA(S)(Misc. Appeal (Second))-65",
        "MAS(T)(MISC. APPEAL SECOND (TENDER))-86",
        "Mat.App.(Matrimonial Appeals)-121",
        "Mat. Appeal(Matrimonial Appeal)-232",
        "Mat.Cas.(Matrimonial Suits/Petitions)-119",
        "Mat.Ref.(References)-120",
        "MC(AB)(Miscellaneous case in Anticipatory Bail Petition)-223",
        "MC(Arb.A.)(Miscellaneous case in Arbitration appeal)-221",
        "MC[Arb.Ptn](Misc Case in Arbitation Petition)-228",
        "MC(BA)(Miscellaneous Case in Bail Application)-210",
        "MC[Cont.App(C)](Misc. case in civil Contempt Appeal)-225",
        "MC[Cont(C)](Misc. case in Cont. Case(C))-207",
        "MC[Cont(Crl)](Misc. Case in Cont. Case(Crl))-233",
        "MC(CR)(Misc. Case in Civil Rule)-26",
        "MC(Crl.A)(Misc. Case in Criminal Appeal)-76",
        "MC(Crl.L.P.)(Misc. case in Criminal Leave Petition)-222",
        "MC(Crl.M.Appl.)(Misc. case in Criminal Misc. Application)-218",
        "MC(Crl.Petn.)(Miscellaneous case in Criminal Petition U/S 482 CrPC)-224",
        "MC(Crl.Rev.P)(Misc. Case in Criminal Revision)-27",
        "MC(CRP)(Misc. Case in Civil Revision Petition)-205",
        "MC(EP)(Misc. Case in Election Petition)-29",
        "MC(F)(Miscellaneous Case (First))-71",
        "MC(FAO)(Misc. Case in First Appeal from order)-79",
        "MC(Intest)(Miscellaneous cases filed in Intest Case)-209",
        "MC[LA Appl.](Misc. case in Land Acquisition Appeal)-226",
        "MC(MACApp)(Misc. case in MAC Appeals)-208",
        "MC(Mat. Appeal)(Restoration against Matrimonial Appeal)-239",
        "MC(MFA)(Misc. Case in MFA)-28",
        "MC(PIL)(Misc. Case in Public Interest Litigation)-219",
        "MC(RA)(MISC. CASE (R.A.))-85",
        "MC(REP)(Miscellaneous and Interlocatory Application in Recrimination Petition)-235",
        "MC[Review Pet.](Misc case in Review Petition)-212",
        "MC[RFA](Misc case in first appeal from judgment & Decree in suit)-213",
        "MC[RSA](Misc. case in Regular Second Appeal)-216",
        "MC(SA)(Misc. Case in Second Appeal)-30",
        "MC[Tr.P.(C)](Misc. case in Transfer Petition under section)-211",
        "MC(WA)(Misc. Case in Writ Appeal)-74",
        "MC(WP(C))(Miscellaneous Case in Writ Petition(Civil))-59",
        "MFA(First Appeal against judgments in Special jurisdiction cases.)-144",
        "MSA(Second appeal from judgments in miscellaneous cases.)-145",
        "M.S.(S.J.,C.S. and so on)(Miscellaneous Summons e.g., Summons for Judgments,Chamber Summons etc.)-162",
        "N.M.(Notice of Motion)-163",
        "OC(ORIGINAL CASE)-90",
        "OJ(Original Jurisdiction)-92",
        "OTA(Other Tax Application)-31",
        "OT.Appl(Other Tax Application)-158",
        "OTC(Other Tax cases)-157",
        [
          "OTR(Other Tax Reference cases)-156",
          "PIL(Public Interest Litigation)-93",
          "RA(Review Application)-70",
          "RA(CR)(Review Application in Civil Rule)-32",
          "RA(FA)(Review Application in First Appeal)-33",
          "RA(MFA)(Review Application in Misc. First Appeal)-35",
          "RAT(Review Application (Tender))-66",
          "RCC(Original Suit/Petition)-128",
          "RCFA(First Appeal)-129",
          "RCRev.(Revision)-131",
          "RCSA(Second Appeal)-130",
          "REP(Recrimination Case in Election Petition)-36",
          "Review Pet.(Review Petition)-103",
          "RFA(First Appeal fron Judgment and Decree in Suit)-96",
          "RLPA(Review in Latters Patent Appeal)-37",
          "RP(FAM.CT.)(Revisions under section 19 of the Family Courts Act.)-122",
          "RPT(Review Petition (Tender))-67",
          "RSA(Second Appeal from Judgment and Decree)-99",
          "SA(Second Appeal)-39",
          "SAO(Appeal from Appellate Order)-100",
          "SA(T)(Second Appeal(tender))-40",
          "SCA(SUPREME COURT APPEAL)-84",
          "SCLP(Petition for Leave to Appeal to Supreme Court)-113",
          "SLP(Special Leave Petition)-203",
          "SP.JC(Name of Act)(Special Jurisdiction cases assigned to High Courts e.g. Trust Act,Lunacy Act,Trade a Merc)-146",
          "ST.Appl.(Application for direction to make a reference)-154",
          "ST.Ref.(Reference)-153",
          "STREF(Sales Tax Refrence)-41",
          "ST.Rev.(Revision)-155",
          "Test.Cas.(Testamentary cases,e.g.,Probate or Letters of Administration,etc.)-123",
          "Tr.P.(C).(Transfer Petition under Section 24 C.P.C.)-204",
          "Tr.P.(Crl.)(Transfer Petition for transfer a Criminal Proceeding.)-177",
          "TS(Testamentory Suit)-77",
          "WA(Appeal before Division Bench against judgment or order of Single Judge in a Writ Petition.)-112",
          "WA(LAB)(Writ Appeal(labour))-42",
          "WA(LAN)(Writ Appeal(land))-43",
          "WA(OTH)(Writ Appeal(others))-45",
          "WA(SER)(Writ Appeal(service))-44",
          "WA(T)(Writ Appeal Tender)-64",
          "WP(C)(Writ Petition under Article 226 and 227 of the Constitution)-111",
          "W.P.(Crl.)(Petition under Article 226 for Writ and Habeas Corpus and other relief)-173",
          "WP(Crl)(Suo Moto)(WP(Crl)(Suo Moto))-238",
          "WP(C) (Suo Moto)(Suo Moto Writ Petition (C))-237",
          "WTREF(Wealth Tax Refrence)-46",
          "WT(REF)(Reference under Section 27 (1) of the Wealth-Tax Act, 1981)-197",
        ],
      ],
      "Kohima Bench": [
        "AB(Anticipatory Bail)-49",
        "Adml.S.(Admiralty Suits)-164",
        "AIR(REF)()-201",
        "Arb.A.(Appeals under Indian Arbitration Act.)-139",
        "Arb.Appln.(Application under Indian Arbitration Act.)-219",
        "Arb.P.(Petition under Indian Arbitration Act.)-138",
        "Bail Application(Bail Application)-57",
        "Bail Appln.(Application Bail)-171",
        "Bkg.p.(Proceedings under Banking Regulation Act.)-118",
        "CA()-187",
        "CAC()-183",
        "CAPL(Contempt Appeal)-47",
        "C.APPLN(Company Applications)-181",
        "CAVT(Caveat)-178",
        "CC(CIV)(Contempt Of Court (Civil))-7",
        "CE.Ref.(Reference under Central Excise Act.)-152",
        "CEREF(CENTRAL EXCISE REFERENCE)-83",
        "C.Ex.App.(Central Excise Appeal)-213",
        "CIVIL.REVN(Civil)-188",
        "CMAppl.(Miscellaneous Applications,e.g.,Leave to Suo as indigent person,restoration application,condonation)-109",
        "CMC(CMC)-226",
        "CMC (Clr)(CMC (Clr))-227",
        "CO(Cross Objection in First Appeal)-97",
        "Co.App.(Appeals against Judgments/Orders in Company Petitions / Company Appeal.)-117",
        "Co.Appl.(Application in Pending Proceeding.)-115",
        "COB(FA)(Cross Objection in First Appeal)-6",
        "COB(MFA)(Cross Objection in Misc. First Appeal)-5",
        "COB(T)(CROSS OBJECTION (TENDER))-81",
        "COJO(Correction of Judgement/Order)-34",
        "COMAPL(Company Appeal)-4",
        "COMPET(Company Petition)-3",
        "CONTAPP(Contempt Appeal)-94",
        "Cont.App.(C)(Appeals against orders in Civil Contempt matters.)-143",
        "Cont.App.(Crl.)(Appeals against Orders in Criminal Contempt matters)-175",
        "Cont.Cas(C)(Contempt of Court Cases relating to Civil Contempt.)-142",
        "Cont.Cas.(Crl.)(Proceedings relating to Criminal Contempt.)-174",
        "COP(C)(Civil Original Petition( Contempt ))-2",
        "Co.Pet.(Original Petition)-114",
        "CP(Condonation Petition)-68",
        "C.PTN.(Company Petitions)-180",
        "CR(Civil Rule)-1",
        "CRAPL(Criminal Appeal)-52",
        "CRAPPL(Cross Appeal)-80",
        "CR(CC)(Criminal Contempt of Court)-50",
        "CRDREF(Criminal Death Reference)-58",
        "C.Ref.(Reference)-104",
        "CREF(Civil Refrence)-13",
        "CREV(H)(Civil Revision(hills))-15",
        "C. REVN(Civil Revision)-14",
        "CR(HC)(Civil Rule(Habeas Corpus ))-8",
        "Crl.A.(Appeal against Judgment/Sentence.)-166",
        "CR(LAB)(Civil Rule(labour))-9",
        "CRL.A(H)(NULL)-189",
        "CRL.A(J)(Criminal Appeal Jail)-190",
        "CR(LAN)(Civil Rule(land))-10",
        "Crl.L.P.(Criminal Leave Petition)-208",
        "Crl.M.Appl.(Other Miscellaneous Application)-172",
        "Crl.M.C.(Application under Section 482 Cr.P.C.)-170",
        "CRL.O.APPN()-192",
        "CRL.OP(C)()-179",
        "CRL.O.REF(NULL)-202",
        "Crl. Pet.(Criminal Petition)-210",
        "Crl.Pet.(Suo Moto)(Criminal Petition (Suo Moto))-224",
        "Crl.Ref.(Reference)-169",
        "CRL.REF(H)(Criminal Hill reference)-206",
        "CRL.REV(H)(NULL)-194",
        "CRL.REVN(Criminal Revision)-56",
        "Crl.Rev.P.(Revision)-168",
        "Crl.Tr.(Original Trial)-165",
        "CR(M)()-196",
        "CR(MC)(Criminal Misc. Case)-51",
        "CROA(Criminal Original Application)-62",
        "CROA(C)(Criminal Original Application Contempt)-69",
        "CROP(Criminal Original Petition)-63",
        "CROP(C)(Criminal Original Petition(Contempt))-53",
        "CR(OTH)(Civil Rule(other))-11",
        "CRP(Revision Petition)-102",
        "CrPetn.(Crl. Petition)-209",
        "CRP(I/O)(Civil Revision Petition (I/O))-216",
        "CRREF(Criminal Reference)-54",
        "CRREF(H)(Criminal reference(hills))-55",
        "CRREVAP(Criminal Review Application)-75",
        "CR(SER)(Civil Rule(service))-12",
        "CS(Civil Suit)-95",
        "CS(OS)(Civil Suits)-159",
        "Cus.Ref.(Reference under Customs Act.)-151",
        "Death Sentence Ref.(Confirmation case under Section 336 Cr.P.C.)-167",
        "DS(Divorce Suit)-72",
        "EC()-182",
        "EDREF(Estate Duty Reference)-16",
        "ED(REF)()-199",
        "El.App.(Appeals from judgments in Election Petition)-135",
        "El.P(Election Petn.)-134",
        "El. Pet.(Election Petition)-17",
        "Ex.Appl.(OS)(Execution Application)-161",
        "Ex.FA.(Execution First Appeal)-106",
        "Ex.P.(Execution Petition)-105",
        "Ex.SA.(Execution Second Appeal)-107",
        "FA(First Appeal)-18",
        "FA(D)(First Appeal(Divorce))-60",
        "FA/MA(F)()-186",
        "FAO(First Appeal from Orders)-98",
        "FA(OS)(First Appeal for Judgments in Original Suits.)-160",
        "FA/SA()-185",
        "FA(T)(First Appeal (Tender))-212",
        "GCA(Government Criminal Appeal)-20",
        "GTA/WTA/EDA(Application for direction to make a reference)-150",
        "GTREF(Gift Tax Reference)-21",
        "GT(REF)()-198",
        "GTR/WTR/EDR(Reference to High Court)-149",
        "Gua.P.(Petitions under Gurdianship and Wards Act.)-125",
        "I.A.(Interlocutory Application.)-218",
        "I.A.(Civil)(Interlocutory Application (Civil).)-220",
        "I.A.(Crl.)(Interlocutory Application (Criminal).)-221",
        "Insurance App.(Appeals under Insurance Act.)-141",
        "Insurance Ref.(Reference under Insurance Act.)-140",
        "Intest.Cas.(Intestate cases,e.g.,Succession Certificates,etc.)-124",
        "IP(Appl.)(Application submitted after adjudication)-137",
        "IP(M)(Main Petition)-136",
        "ITA(Application under Section 256(2))-148",
        "ITR(Reference under Section 256(1).)-147",
        "ITREF(Income Tax Reference)-22",
        "IT(REF)()-195",
        "ITREF(A)(AGRI IT REFERENCE)-88",
        "LA.App.(Appeals)-127",
        "LA.Ref.(Reference)-126",
        "LPA(Latters Patent Appeal)-23",
        "LPA/Sp.A(Letters Patent Appeal or Special Appeal Before Division Bench against a Judgment or Order of Single)-101",
        "LPA(T)(LEAVE PATTERN APPEAL (TENDER))-87",
        "MAC(Motor Accident Claims)-132",
        "MACApp.(Motor Accident Appeal)-133",
        "MAF(Miscellaneous Appeal(first))-24",
        "MA(F)(NULL)-184",
        "MAF(T)(Miscellaneous Appeal First(tender))-25",
        "MA(S)(Misc. Appeal ( Second ))-65",
        "MAS(T)(MISC. APPEAL SECOND (TENDER))-86",
        "Mat.App.(Matrimonial Appeals)-121",
        "Mat.Cas.(Suits/Petitions)-119",
        "Mat.Ref.(References)-120",
        "MC(C)(Miscellaneous Case Civil)-59",
        "MC(COP)(MISC. CASE (COP))-82",
        "MC(CR)(Misc. Case of Civil Rule)-26",
        "MC(CRAPL)(Misc. Case in Criminal Appeal)-76",
        "MC(CREV)(Misc. Case of Civil Revision)-27",
        "MC(CRL)(Miscellaneous Case Criminal)-228",
        "MCCRL. REVN()-193",
        "MC(CRREV)(Misc. Case in Criminal Revision)-73",
        "MC(EP)(Misc. Case of Election Petition)-29",
        "MC(F)(Miscellaneous Case (First))-71",
        "MC(FA)(Misc. Case (First Appeal))-79",
        "MC(MAF)(Misc. Case of Misc. Appeal(First))-28",
        "MC(N)()-205",
        "MC(RA)(MISC. CASE (R.A.))-85",
        "MC(SA)(Misc. Case in Second Appeal)-30",
        "MC(WA)(Misc. Case in Writ Appeal)-74",
        "MFA(First Appeal against judgments in Special jurisdiction cases.)-144",
        "MSA(Second appeal from judgments in miscellaneous cases.)-145",
        "M.S.(S.J.,C.S. and so on)(Miscellaneous Summons e.g., Summons for Judgments,Chamber Summons etc.)-162",
        "N.M.(Notice of Motion)-163",
        "OC(ORIGINAL CASE)-90",
        "OJ(Original Jurisdiction)-92",
        "OTA(Other Tax Application)-31",
        "OT.Appl(Other Tax Application)-158",
        "OTC(Other Tax cases)-157",
        "OTR(Other Tax Reference cases)-156",
        "PIL(Public Interest Litigation)-93",
        "PIL(Suo Moto)(Public Interest Litigation (Suo Moto))-222",
        "RA(Review Application)-70",
        "RA(CR)(Review Application in Civil Rule)-32",
        "RA(FA)(Review Application in First Appeal)-33",
        "RA(MFA)(Review Application in Misc. First Appeal)-35",
        "RAT(Review Application (Tender))-66",
        "RA(WP)(Review Application in Writ Petition)-211",
        "RCC(Original Suit/Petition)-128",
        "RCFA(First Appeal)-129",
        "RCRev.(Revision)-131",
        "RCSA(Second Appeal)-130",
        "REP(Recrimination Case in Election Petition)-36",
        "Review.Pet.(Review Petition)-103",
        "REVN(Crl)(Revision Crl)-191",
        "Revn.Pet.(Revision Petition)-217",
        "RFA(First Appeal from Judgment and Decree in Suit)-96",
        "RLPA(Review in Latters Patent Appeal)-37",
        "RP(FAM.CT.)(Revisions under section 19 of the Family Courts Act.)-122",
        "RPT(Review Petition (Tender))-67",
        "RSA(Second Appeal from Judgment and Decree)-99",
        "SA(Second Appeal)-39",
        "SAO(Appeal from Appellate Order)-100",
        "SA(T)(Second Appeal(tender))-40",
        "SCA(SUPREME COURT APPEAL)-84",
        "SCLP(Petition for Leave to Appeal to Supreme Court)-113",
        "SLP(Special Leave Petition)-203",
        "SP.JC(Name of Act)(Special Jurisdiction cases assigned to High Courts e.g. Trust Act,Lunancy Act,Trade & Merchandise Ac)-146",
        "ST.Appl.(Application for direction to make a reference)-154",
        "ST.Ref.(Reference)-153",
        "STREF(Sales Tax Refrence)-41",
        "ST(REF)()-200",
        "ST.Rev.(Revision)-155",
        "Test.App.(Testamentary Appeal (Other First Appeal))-214",
        "Test.Cas.(Testamentary cases,e.g.,Probate or Letters of Administration,etc.)-123",
        "Tr.P.(C).(Transfer Petition under Section 24 C.P.C.)-204",
        "Tr.P.(Crl.)(Transfer Petition for transfer a Criminal Proceeding.)-177",
        "Tr. P.(Suo Moto)(Transfer Petition Suo Moto)-229",
        "TS(Testamentory Suit)-77",
        "W(Application for Leave to Appeal under Section 378 Cr.P.C. or under the relevant corresponding procee)-176",
        "WA(Appeal before Division Bench against judgment or order of Single Judge in a Writ Petition.)-112",
        "WA(LAB)(Writ Appeal(labour))-42",
        "WA(LAN)(Writ Appeal(land))-43",
        "WA(OTH)(Writ Appeal(others))-45",
        "WA(SER)(Writ Appeal(service))-44",
        "WA(T)(Writ Appeal Tender)-64",
        "WP(Matters transfered under Section 446(3))-116",
        "WP(C)(Writ Petition under Article 226 and 227 of the Constitution)-111",
        "W.P.(Crl.)(Petition u/a 226 for Writ and Habeas Corpus and other relief in relation to a Cr P)-173",
        "WP(C)(Suo Moto)(Writ Petition Suo Moto)-225",
        "WP(C)(Taken up)(Writ Petition (Suo Moto))-223",
        "WTREF(Wealth Tax Refrence)-46",
        "WT(REF)()-197",
      ],
      "Principal Seat at Guwahati": [
        "AB(Anticipatory Bail)-49",
        "abc()-211",
        "Adml.S.(Admiralty Suits)-164",
        "AIR(REF)()-201",
        "Arb.A.(Appeals under Indian Arbitration Act.)-139",
        "Arb.Appln.(Application under Indian Arbitration Act.)-219",
        "Arb.P.(Petition under Indian Arbitration Act.)-138",
        "BA(Bail Application)-57",
        "Bail Appln.(Bail_Application)-171",
        "Bkg.p.(Proceedings under Banking Regulation Act.)-118",
        "CA()-187",
        "CAC()-183",
        "CAPL(Contempt_Appeal)-47",
        "C.APPLN(Company Applications)-181",
        "CAVT(Caveat)-178",
        "CC(CIV)(Contempt Of Court (Civil))-7",
        "CE.Ref.(Reference under Central Excise Act.)-152",
        "CEREF(CENTRAL EXCISE REFERENCE)-83",
        "C.Ex.App.(Central Excise Appeal)-213",
        "CMAppl.(Miscellaneous Applications,e.g.,Leave to Suo as indigent person,restoration application,co)-109",
        "CO(Cross Objection in First Appeal)-97",
        "Co.App.(Appeals against Judgments/Orders in Company Petitions / Company Appeal.)-117",
        "Co.Appl.(Application in Pending Proceeding.)-115",
        "COB(FA)(Cross Objection in First_Appeal)-6",
        "COB(MFA)(Cross Objection in Misc. First Appeal)-5",
        "COB(T)(CROSS OBJECTION (TENDER))-81",
        "COJO(Correction of Judgement/Order)-34",
        "COMAPL(Company Appeal)-4",
        "COMPET(Company Petition)-3",
        "CONTAPP(Contempt Appeal)-94",
        "Cont.App.(C)(Appeals against orders in Civil Contempt matters.)-143",
        "Cont.App.(Crl.)(Appeals against Orders in Criminal Contempt matters)-175",
        "Cont.Cas(C)(Contempt of Court Cases relating to Civil Contempt.)-142",
        "Cont.Cas.(Crl.)(Proceedings relating to Criminal Contempt.)-174",
        "Cont.Cas(C)(Suo Moto)(Contempt Case (Civil)(Suo Moto))-229",
        "Cont.Case(Crl)(Suo Moto)(Cont.Case(Crl.)(Suo Moto))-228",
        "COP(C)(Civil Original Petition( Contempt ))-2",
        "Co.Pet.(Original Petition)-114",
        "CP(Condonation Petition)-68",
        "C.PTN.(Company Petitions)-180",
        "CR(Civil Rule)-1",
        "CRAPL(Criminal Appeal)-52",
        "CRAPPL(Cross Appeal)-80",
        "CR(CC)(Criminal Contempt of Court)-50",
        "CRDREF(Criminal Death Reference)-58",
        "C.Ref.(Reference.)-104",
        "CREF(Civil Refrence)-13",
        "CREV(Civil Revision)-14",
        "CREV(H)(Civil Revision(hills))-15",
        "C.REVN()-188",
        "CR(HC)(Civil Rule(Habeas Corpus ))-8",
        "Crl.A.(Appeal against Judgment/Sentence.)-166",
        "CR(LAB)(Civil Rule(labour))-9",
        "CRL.A(H)(NULL)-189",
        "CRL.A(J)(NULL)-190",
        "CR(LAN)(Civil Rule(land))-10",
        "Crl.L.P.(Criminal Leave Petition)-208",
        "Crl.M.Appl.(Other Miscellaneous Application)-172",
        "Crl.M.C.(Application under Section 482 Cr.P.C.)-170",
        "CRL.O.APPN()-192",
        "CRL.OP(C)()-179",
        "CRL.O.REF(NULL)-202",
        "Crl.P.(Criminal_Petition)-210",
        "Crl.Pet.(Criminal Petition)-209",
        "Crl.Pet.(Suo Moto)(Criminal Petition (Suo Moto))-224",
        "Crl.Ref.(Reference_)-169",
        "CRL.REF(H)(Criminal Hill reference)-206",
        "CRL.REV(H)(NULL)-194",
        "CRL.REVN(NULL)-191",
        "Crl.Rev.P.(Revision_)-168",
        "Crl.Tr.(Original Trial)-165",
        "CR(M)()-196",
        "CR(MC)(Criminal Misc. Case)-51",
        "CROA(Criminal Original Application)-62",
        "CROA(C)(Criminal Original Application Contempt)-69",
        "CROP(Criminal Original Petition)-63",
        "CROP(C)(Criminal Original Petition(Contempt))-53",
        "CR(OTH)(Civil Rule(other))-11",
        "CRP(Revision_Petition)-102",
        "CRP(IO)(Civil Revision Petition (I/O))-216",
        "CRP (Suo Moto)(Civil Revision Petition (Suo Moto))-227",
        "CRREF(Criminal Reference)-54",
        "CRREF(H)(Criminal reference(hills))-55",
        "CRREV(Criminal Revision)-56",
        "CRREVAP(Criminal Review Application)-75",
        "CR(SER)(Civil Rule(service))-12",
        "CS(Civil Suit)-95",
        "CS(OS)(Civil Suits)-159",
        "Cus.Ref.(Reference under Customs Act.)-151",
        "Death Sentence Ref.(Confirmation case under Section 336 Cr.P.C.)-167",
        "DS(Divorce Suit)-72",
        "EC()-182",
        "EDREF(Estate Duty Reference)-16",
        "ED(REF)()-199",
        "El.App.(Appeals from judgments in Election Petition)-135",
        "El.Pet.(Election Petitions)-134",
        "EP(Election Petition)-17",
        "Ex.Appl.(OS)(Execution Application)-161",
        "Ex.FA.(Execution First Appeal)-106",
        "Ex.P.(Execution Petition)-105",
        "Ex.SA.(Execution Second Appeal)-107",
        "FA(First_Appeal)-18",
        "FA(D)(First Appeal(Divorce))-60",
        "FA/MA(F)()-186",
        "FAO(First Appeal from Orders)-98",
        "FA(OS)(First Appeal for Judgments in Original Suits.)-160",
        "FA/SA()-185",
        "FA(T)(First Appeal (Tender))-212",
        "GCA(Government Criminal Appeal)-20",
        "GTA/WTA/EDA(Application for direction to make a reference.)-150",
        "GTREF(Gift Tax Reference)-21",
        "GT(REF)()-198",
        "GTR/WTR/EDR(Reference to High Court)-149",
        "Gua.P.(Petitions under Gurdianship and Wards Act.)-125",
        "I.A.(Interlocutory Application.)-218",
        "I.A.(Civil)(Interlocutory Application (Civil).)-220",
        "I.A.(Crl.)(Interlocutory Application (Criminal).)-221",
        "Insurance App.(Appeals under Insurance Act.)-141",
        "Insurance Ref.(Reference under Insurance Act.)-140",
        "Intest.Cas.(Intestate cases,e.g.,Succession Certificates,etc.)-124",
        "IP(Appl.)(Application submitted after adjudication)-137",
        "IP(M)(Main Petition)-136",
        "ITA(Application under Section 256(2))-148",
        "ITR(Reference under Section 256(1).)-147",
        "ITREF(Income Tax Reference)-22",
        "IT(REF)()-195",
        "ITREF(A)(AGRI IT REFERENCE)-88",
        "LA.App.(Appeals)-127",
        "LA.Ref.(Reference .)-126",
        "LPA(Latters Patent Appeal)-23",
        "LPA/Sp.A(Letters Patent Appeal or Special AppealBefore Division Bench against a Judgment or Order)-101",
        "LPA(T)(LEAVE PATTERN APPEAL (TENDER))-87",
        "MAC(Motor Accident Claims)-132",
        "MACApp.(Motor Accident Appeal)-133",
        "MAF(Miscellaneous Appeal(first))-24",
        "MA(F)(NULL)-184",
        "MAF(T)(Miscellaneous Appeal First(tender))-25",
        "MA(S)(Misc. Appeal ( Second ))-65",
        "MAS(T)(MISC. APPEAL SECOND (TENDER))-86",
        "Mat.App.(Appeals.)-121",
        "Mat.Cas.(Suits/Petitions)-119",
        "Mat.Ref.(References)-120",
        "MC(Miscellaneous Case)-59",
        "MC(COP)(MISC. CASE (COP))-82",
        "MC(CR)(Misc. Case of Civil Rule)-26",
        "MC(CRAPL)(Misc. Case in Criminal Appeal)-76",
        "MC(CREV)(Misc. Case of Civil Revision)-27",
        "MCCRL. REVN()-193",
        "MC(CRREV)(Misc. Case in Criminal Revision)-73",
        "MC(EP)(Misc. Case of Election Petition)-29",
        "MC(F)(Miscellaneous Case (First))-71",
        "MC(FA)(Misc. Case (First Appeal))-79",
        "MC(MAF)(Misc. Case of Misc. Appeal(First))-28",
        "MC(N)()-205",
        "MC(RA)(MISC. CASE (R.A.))-85",
        "MC(SA)(Misc. Case in Second Appeal)-30",
        "MC(WA)(Misc. Case in Writ Appeal)-74",
        "MFA(First Appeal against judgments in Special jurisdiction cases.)-144",
        "MSA(Second appeal from judgments in miscellaneous cases.)-145",
        "M.S.(S.J.,C.S. and so on)(Miscellaneous Summons e.g., Summons for Judgments,Chamber Summons etc.)-162",
        "N.M.(Notice of Motion)-163",
        "OC(ORIGINAL CASE)-90",
        "OJ(Original Jurisdiction)-92",
        "OTA(Other Tax_Application)-31",
        "OT.Appl(Other_Tax Application)-158",
        "OTC(Other Tax cases)-157",
        "OTR(Other Tax Reference cases)-156",
        "PIL(Public Interest Litigation)-93",
        "PIL(Suo Moto)(Public Interest Litigation (Suo Moto))-222",
        "RA(Review Application)-70",
        "RA(CR)(Review Application in Civil Rule)-32",
        "RA(FA)(Review Application in First Appeal)-33",
        "RA(MFA)(Review Application in Misc. First Appeal)-35",
        "RAT(Review Application (Tender))-66",
        "RCC(Original Suit/Petition)-128",
        "RCFA(First Appeal)-129",
        "RCRev.(Revision.)-131",
        "RCSA(Second Appeal)-130",
        "REP(Recrimination Case in Election Petition)-36",
        "RERA APPEAL(RERA APPEAL)-231",
        "Review.Pet.(Review Petition)-103",
        "Revn.Pet.(Revision Petition.)-217",
        "RFA(First Appeal fron Judgment and Decree in Suit)-96",
        "RLPA(Review in Latters Patent Appeal)-37",
        "RP(FAM.CT.)(Revisions under section 19 of the Family Courts Act.)-122",
        "RPT(Review Petition (Tender))-67",
        "RSA(Second Appeal from Judgment and Decree)-99",
        "SA(Second_Appeal)-39",
        "SAO(Appeal from Appellate Order)-100",
        "SA(T)(Second Appeal(tender))-40",
        "SCA(SUPREME COURT APPEAL)-84",
        "SCLP(Petition for Leave to Appeal to Supreme Court)-113",
        "SLP(Special Leave Petition)-203",
        "SP.JC(Name of Act)(Special Jurisdiction cases assigned to High Courts e.g. Trust Act,Lunancy Act,Trade a Merc)-146",
        "ST.Appl.(Application for direction to make a reference .)-154",
        "ST.Ref.(Reference  .)-153",
        "STREF(Sales Tax Refrence)-41",
        "ST(REF)()-200",
        "ST.Rev.(Revision .)-155",
        "Test.App.(Testamentary Appeal (Other First Appeal))-214",
        "Test.Cas.(Testamentary cases,e.g.,Probate or Letters of Administration,etc.)-123",
        "Tr.P.(C).(Transfer Petition under Section 24 C.P.C.)-204",
        "Tr.P.(Crl.)(Transfer Petition for transfer a Criminal Proceeding.)-177",
        "Tr.P(Crl)(Suo moto)(Transfer Petition (Crl)(Suo-moto))-230",
        "TS(Testamentory Suit)-77",
        "W(Application for Leave to Appeal under Section 378 Cr.P.C. or under the relevant correspond)-176",
        "WA(Appeal before Division Bench against judgment or order of Single Judge in a Writ Petition.)-112",
        "WA(LAB)(Writ Appeal(labour))-42",
        "WA(LAN)(Writ Appeal(land))-43",
        "WA(OTH)(Writ Appeal(others))-45",
        "WA(SER)(Writ Appeal(service))-44",
        "WA(T)(Writ Appeal Tender)-64",
        "WP(Matters transfered under Section 446(3))-116",
        "WP(C)(Writ Petition under Article 226 and 227 of the Constitution)-111",
        "W.P.(Crl.)(Petition u/a 226 for Writ and Habeas Corpus and other relief in relation to a Cr P)-173",
        "WP(Crl)(Suo Moto)(WP(Crl)(Suo Moto))-226",
        "WP(C)(Suo Moto)(Writ Petition Suo Moto)-225",
        "WP(C)(Taken up)(Writ Petition (Suo Moto))-223",
        "WTREF(Wealth Tax Refrence)-46",
        "WT(REF)()-197",
      ],
    },

    "High Court  for State of Telangana": {
      "Principal Bench at Hyderabad": [
        "APPL(APPLICATION IN CIVIL SUIT)-1",
        "ARBAPPL(ARBITRATION APPLICATION)-2",
        "AS(FIRST APPEAL)-3",
        "CA(CONTEMPT APPEAL)-4",
        "CAPPEAL(COMPANY APPEAL)-5",
        "CC(CONTEMPT CASE)-6",
        "CCCA(CITY CIVIL COURT APPEAL)-7",
        "CC(CRL)(CRIMINAL CALENDER CASE)-86",
        "CC(TR)(CONTEMPT CASE TRANSFERED)-8",
        "CEA(CENTRAL EXCISE APPEALS)-9",
        "CERC(CUSTOMS EXCISE REVISION CASE)-10",
        "CETC(CENTRAL EXCISE TAX CASE)-11",
        "CMA(CIVIL MISCELLANEOUS APPEAL)-12",
        "CMSA(CIVIL MISCELLANEOUS SECOND APPEAL)-13",
        "COMAA(COMMERCIAL ARBITRATION APPEAL)-79",
        "COMAOA(COMMERCIAL ARBITRATION ORIGINAL APPLICATION)-78",
        "COMCA(COMMERCIAL COURT APPEAL)-14",
        "COMDA(COMMERCIAL  APPEAL)-76",
        "COMOP(COMMERCIAL ORIGINAL PETITION)-68",
        "COMPA(COMPANY APPL. IN COMPANY PETITION)-15",
        "COMS(COMMERCIAL SUIT)-16",
        "COMTA(COMMERCIAL TRANSFER APPLICATION)-17",
        "COMTOA(TRANSFER APPLICATION BEFORE COMMERCIAL APPELLATE DIVISION)-77",
        "CP(COMPANY PETITION)-18",
        "CRLA(CRIMINAL APPEAL)-19",
        "CRLA(FSSA)(CRIMINAL APPEAL(Food Safety and Standard Act))-89",
        "CRLATR(CRIMINAL APPEAL TRANSFER)-72",
        "CRLMP(CRIMINAL MISCELLANEOUS PETITION)-20",
        "CRLP(CRIMINAL PETITION)-21",
        "CRLRC(CRIMINAL REVISION CASE)-22",
        "CRP(CIVIL REVISION PETITION)-23",
        "CS(CIVIL SUIT)-24",
        "CS(9BY1)(CIVIL SUIT (9BY1))-90",
        "EC(ENQUIRY CELL)-71",
        "EDC(ESTATE DUTY CASE)-25",
        "EP(ELECTION PETITION)-26",
        "EPTC(EXCESS PROFIT TAX CASE)-27",
        "EXEP(EXECUTION PETITION)-28",
        "FCA(FAMILY COURT APPEAL (FCA))-29",
        "GTC(GIFT TAX CASE)-30",
        "ICOMAA(INTERNATIONAL COMMERCIAL ARBITRATION APPEAL)-75",
        "ICOMAOA(INTERNATIONAL COMMERCIAL ARBITRATION ORIGINAL APPLICATION)-69",
        "ITC(INCOME TAX CASE)-31",
        "ITTA(INCOME TAX TRIBUNAL APPEAL)-32",
        "LAAS(LAND ACQUISITION FIRST APPEAL)-33",
        "LGA(LAND GRABBING APPEAL)-34",
        "LPA(LETTER PATENT APPEAL)-35",
        "MACMA(MOTOR ACCIDENT CIVIL MISCELLANEOUS APPEAL)-36",
        "OP(ORIGINAL PETITION)-37",
        "OSA(ORIGINAL SIDE APPEAL)-38",
        "PIL(PUBLIC INTEREST LITIGATION (OLD))-39",
        "RC(REFERRED CASE)-40",
        "RCC(REFERRED COMPANY CASE)-41",
        "RECR(RECRIMINATION PETITION)-42",
        "REFCRLA(REFERRED CRLA)-43",
        "RESLGAIA(RESTORATION LAND GRABBING APPLICATION)-74",
        "RESWPMP(TR)(WPMP TRANSFERRED)-44",
        "REVWP(TR)MP(REVIEW WP(TR))-45",
        "RT(REFERRED TRIAL)-46",
        "RTP(RECEIVE AND TRANSMIT PETITION)-48",
        "SA(SECOND APPEAL)-49",
        "SCLP(SUPREME COURT LEAVE PETITION)-50",
        "SPLA(SPECIAL APPEAL)-51",
        "STA(SPECIAL TRIBUNAL APPEAL)-52",
        "SUOMOTUWP(SUOMOTU WRIT PETITION)-87",
        "SUOMOTUWP(PIL)(SUOMOTU WRIT PETITION(PUBLIC INTEREST LITIGATION))-88",
        "SUOMOTUWPUR(SUOMOTU WRIT PETITION URGENT)-81",
        "TRAS(TRANSFER APPEAL)-53",
        "TRC(TAX REVISION CASE (OLD))-54",
        "TRCCCA(TRANSFER CCCA)-55",
        "TRCMA(TRANSFER .CIVIL MISC.APPEAL)-56",
        "TRCMP(TRANS. CIVIL MISC.PETITION)-57",
        "TRCOS(TRANSFER COMMERCIAL ORIGINAL SUIT)-80",
        "TRCRLA(TRANSFER CRIMINAL APPEAL)-58",
        "TRCRLMP(TRANS. CRIMINAL MISC.PETITION)-59",
        "TRCRLP(TRANSFER CRIMINAL PETITION)-60",
        "TREVC(TAX REVISION CASE)-61",
        "TRICOMAOA(TRANSFER INTERNATIONAL COMMERCIAL ARBITRATION ORIGINAL APPLICATION)-73",
        "TRLGA(TRANSFER LAND GRABBING APPEAL)-85",
        "TRSUMMARYSUIT(TRANSFER SUMMARY SUIT)-82",
        "TUPWP(TAKEN UP WRIT PETITION)-83",
        "TUPWP(PIL)(TAKEN UP PUBLIC INTEREST LITIGATION)-84",
        "WA(WRIT APPEAL)-62",
        "WP(WRIT PETITION)-63",
        "WP(PIL)(PUBLIC INTEREST LITIGATION)-64",
        "WP(TR)(WRIT PETITION TRANSFERED)-65",
        "WTA(WEALTH TAX APPEAL)-66",
        "WTC(WEALTH TAX CASE)-67",
        "X-OBJ(Cross Objection)-70",
      ],
    },
    "High Court of Andhra Pradesh": {
      "Principlal Bench at Andhra Pradesh": [
        "APPL(APPLICATION IN CIVIL SUIT)-1",
        "ARBAPPL(ARBITRATION APPLICATION)-2",
        "AS(FIRST APPEAL)-3",
        "CA(CONTEMPT APPEAL)-4",
        "CAPPEAL(COMPANY APPEAL)-5",
        "CC(CONTEMPT CASE)-6",
        "CC(AT)(CONTEMPT CASE TRANSFERED FROM APAT)-82",
        "CCCA(CITY CIVIL COURT APPEAL)-7",
        "CC(TR)(CONTEMPT CASE TRANSFERED)-8",
        "CEA(CENTRAL EXCISE APPEALS)-9",
        "CERC(CUSTOMS EXCISE REVISION CASE)-10",
        "CETC(CENTRAL EXCISE TAX CASE)-11",
        "CMA(CIVIL MISCELLANEOUS APPEAL)-12",
        "CMSA(CIVIL MISCELLANEOUS SECOND APPEAL)-13",
        "COMAA(COMMERCIAL ARBITRATION APPEAL)-79",
        "COMAOA(COMMERCIAL ARBITRATION ORIGINAL APPLICATION)-78",
        "COMCA(COMMERCIAL COURT APPEAL)-14",
        "COMDA(COMMERCIAL  APPEAL)-76",
        "COMOP(COMMERCIAL ORIGINAL PETITION)-68",
        "COMPA(COMPANY APPLICATION)-15",
        "COMS(COMMERCIAL SUIT)-16",
        "COMTA(COMMERCIAL TRANSFER APPLICATION)-17",
        "COMTOA(TRANSFER APPLICATION BEFORE COMMERCIAL APPELLATE DIVISION)-77",
        "CP(COMPANY PETITION)-18",
        "CRLA(CRIMINAL APPEAL)-19",
        "CRLATR(CRIMINAL APPEAL TRANSFER)-72",
        "CRLMP(CRIMINAL MISCELLANEOUS PETITION)-20",
        "CRLP(CRIMINAL PETITION)-21",
        "CRLRC(CRIMINAL REVISION CASE)-22",
        "CRP(CIVIL REVISION PETITION)-23",
        "CS(CIVIL SUIT)-24",
        "EC(ENQUIRY CELL)-71",
        "EDC(ESTATE DUTY CASE)-25",
        "EP(ELECTION PETITION)-26",
        "EPTC(EXCESS PROFIT TAX CASE)-27",
        "EXEP(EXECUTION PETITION)-28",
        "FCA(FAMILY COURT APPEAL (FCA))-29",
        "GTC(GIFT TAX CASE)-30",
        "ICOMAA(INTERNATIONAL COMMERCIAL ARBITRATION APPEAL)-75",
        "ICOMAOA(INTERNATIONAL COMMERCIAL ARBITRATION ORIGINAL APPLICATION)-69",
        "ITC(INCOME TAX CASE)-31",
        "ITTA(INCOME TAX TRIBUNAL APPEAL)-32",
        "LAAS(LAND ACQUISITION FIRST APPEAL)-33",
        "LGA(LAND GRABBING APPEAL)-34",
        "LPA(LETTER PATENT APPEAL)-35",
        "MACMA(MOTOR ACCIDENT CIVIL MISCELLANEOUS APPEAL)-36",
        "OP(ORIGINAL PETITION)-37",
        "OSA(ORIGINAL SIDE APPEAL)-38",
        "PIL(PUBLIC INTEREST LITIGATION)-39",
        "RC(REFERRED CASE)-40",
        "RCC(REFERRED COMPANY CASE)-41",
        "RECR(RECRIMINATION PETITION)-42",
        "REFCRLA(REFERRED CRLA)-43",
        "RESLGAIA(RESTORATION LAND GRABBING APPLICATION)-74",
        "RESWPMP(TR)(WPMP TRANSFERRED)-44",
        "REV.CMP(REV.CMP)-80",
        "REVWP(TR)MP(REVIEW WP(TR))-45",
        "RT(REFERRED TRIAL)-46",
        "RTP(RECEIVE AND TRANSMIT PETITION)-48",
        "SA(SECOND APPEAL)-49",
        "SCLP(SUPREME COURT LEAVE PETITION)-50",
        "SPLA(SPECIAL APPEAL)-51",
        "STA(SPECIAL TRIBUNAL APPEAL)-52",
        "TRAS(TRANSFER APPEAL)-53",
        "TRC(TAX REVISION CASE)-54",
        "TRCCCA(TRANSFER CCCA)-55",
        "TRCMA(TRANSFER .CIVIL MISC.APPEAL)-56",
        "TRCMP(TRANS. CIVIL MISC.PETITION)-57",
        "TRCRLA(TRANSFER CRIMINAL APPEAL)-58",
        "TRCRLMP(TRANS. CRIMINAL MISC.PETITION)-59",
        "TRCRLP(TRANSFER CRIMINAL PETITION)-60",
        "TREVC(TAX REVISION CASE)-61",
        "TRICOMAOA(TRANSFER INTERNATIONAL COMMERCIAL ARBITRATION ORIGINAL APPLICATION)-73",
        "WA(WRIT APPEAL)-62",
        "WP(WRIT PETITION)-63",
        "WP(AT)(WRIT PETITION TRANSFERED FROM APAT)-81",
        "WP(PIL)(WP(PIL))-64",
        "WP(TR)(WRIT PETITION TRANSFERED)-65",
        "WTA(WEALTH TAX APPEAL)-66",
        "WTC(WEALTH TAX CASE)-67",
        "X-OBJ(Cross Objection)-70",
      ],
    },

    "High Court Of Chhattisgarh": {
      "Principlal Bench Chhattisgarh": [
        "ACQA(ACQUITTAL APPEAL [ APPEAL U/S 378 ])-67",
        "ARBA(ARBITRATION APPEAL)-28",
        "ARBAP(Arbitration Application)-68",
        "ARBR(Arbitration Request)-69",
        "AW(AWARD)-13",
        "CA(COMPANY APPLICATION)-11",
        "CEA(CENTRAL EXCISE APPEAL)-20",
        "CER(CENTRAL EXCISE REFERENCE)-19",
        "CESR(CENTRAL EXCISE SALT REFERENCE)-32",
        "COMA(COMPANY APPEAL)-34",
        "COMP(COMPANY PETITION)-12",
        "CONC(CONTEMPT CASE)-25",
        "CONT(CONTEMPT.PETITION)-17",
        "CONTR(CRIMINAL CONTEMPT)-57",
        "CONTS(CONTEMPT.PETITION FROM SAT)-41",
        "CP(CIVIL PETITION)-1",
        "CR(CIVIL REVISION)-8",
        "CRA(CRIMINAL APPEAL)-50",
        "CRMP(CRIMINAL MISC. PETITION)-59",
        "CRR(CRIMINAL REVISION)-51",
        "CRREF(CRIMINAL REFERENCE)-58",
        "CS(CIVIL SUIT)-35",
        "CVLREF(CIVIL REFERENCE)-71",
        "EA(EXECUTION APPEAL)-10",
        "EDR(ESTATE DUTY REFERENCE)-30",
        "EP(ELECTION PETITION)-9",
        "FA(FIRST APPEAL)-5",
        "FAM(FIRST APPEAL UNDER OTHERS LAW)-24",
        "FA(MAT)(FIRST APPEALS RELATING TO MATRIMONIAL MATTERS)-70",
        "ITA(INCOME TAX APPEAL)-18",
        "ITR(INCOME-TAX REFERENCE)-15",
        "LPA(LETTER PATENT APPEAL)-2",
        "MA(MISC. APPEAL)-3",
        "MAC(MISC.APPEAL OF COMPENSATION)-26",
        "MCA(MISC. APPEALS)-7",
        "MCC(MISC. CIVIL CASES)-4",
        "MCCS(MISC. CIVIL CASES FROM SAT)-21",
        "MCP(MISC. CIVIL PETITION)-22",
        "MCRC(MISC. CRIMINAL CASE)-52",
        "MCRCA(MISC.CRIMINAL CASE (ANTICIPATORY BAIL))-64",
        "MCRP(MISC. CRIMINAL PETITION)-65",
        "MP(MISC. PETITION)-49",
        "MWP(MISC. WRIT PETITION)-38",
        "OD(ORIGINAL DOCUMENT)-14",
        "REVP(REVIEW PETITION)-23",
        "SA(SECOND APPEAL)-6",
        "STR(SALES TAX REFERENCE)-33",
        "TAXC(TAX MATTER)-27",
        "TPC(TRANSFER PETITION CIVIL)-29",
        "TPCR(TRANSFER PETITION CRIMINAL)-66",
        "WA(WRIT APPEAL)-42",
        "WP(WRIT PETITION)-36",
        "WP227(WRIT PETITION 227)-37",
        "WPC(WRIT PETITION ALL ORDERS CIVIL RELATED TO OTHER MATTER)-46",
        "WPCR(WRIT PETITION CRIMINAL OTHER MATTER)-47",
        "WPHC(WRIT PETITION HABEAS CORPUS MATTERS)-48",
        "WPL(WRIT PETITION LABOUR INDUSTRIES RELATED TO SERVICE MATTER AND OTHER MATTER)-43",
        "WPPIL(WRIT PETITION PUBLIC INTEREST LITIGATION MATTER)-45",
        "WPS(WRIT PETITON SERVICE MATTER)-39",
        "WPT(WRIT PETITION TAX MATTER RELATED TO OTHER MATTER)-44",
        "WTA(WEALTH TAX)-31",
        "WTR(WEALTH-TAX REFERENCE)-16",
      ],
    },

    "High Court of Gujarat": {
      "Gujarat High Court": [
        "AO(APPEAL FROM ORDER)-1",
        "ARBI.P(PETN. UNDER ARBITRATION ACT)-68",
        "AS(ADMIRALTY SUIT)-51",
        "CA(CIVIL APPLICATION)-3",
        "CAR(CHARTERED ACCOUNTANT REFERENCE)-52",
        "CC(CRIMINAL CONFIRMATION CASE)-41",
        "CCASE(CRIMINAL CASE)-42",
        "CECGA(CENTRAL EXCISE & CUSTOMS GOLD CONTROL APPLICATION)-54",
        "CECGR(CE-CUSTOMS GOLD CONTROLL REFERENCE)-55",
        "CIA(CIVIL APPEAL)-32",
        "CMP(PTN. UNDER CHRISTIAN MARRIAGE ACT)-56",
        "COA(COMMERCIAL APPLICATION)-30",
        "COMA(COMPANY APPLICATION)-57",
        "COMP(COMPANY PETITION)-58",
        "CPTA(APPEAL IN CONTEMPT PROCEEDINGS)-29",
        "CR(CIVIL REFERENCES)-8",
        "CRA(CIVIL REVISION APPLICATION)-9",
        "CR.A(CRIMINAL APPEAL)-43",
        "CR.MA(CRIMINAL MISC.APPLICATION)-44",
        "CR.RA(CRIMINAL REVISION APPLICATION)-45",
        "CRREF(CRIMINAL REFERENCE)-46",
        "CR.REVIEW(CRIMINAL REVIEW APPLICATION)-98",
        "CS(CIVIL SUITS)-59",
        "EA(ELECTION APPLICATION)-60",
        "EDA(ESTATE DUTY APPLICATION)-61",
        "EDR(ESTATE DUTY REFERENCE)-62",
        "EP(ELECTION PETITION)-63",
        "EXTA(EXPENDITURE TAX APPLICATION)-64",
        "EXTR(EXPENDITURE TAX REFERENCE)-65",
        "FA(FIRST APPEAL)-12",
        "GTA(GIFT TAX APPLICATION)-66",
        "GTR(GIFT TAX REFERENCE)-67",
        "ITA(INCOME TAX APPLICATION)-69",
        "ITR(INCOME TAX REFERENCE)-70",
        "LPA(LETTERS PATENT APPEAL)-14",
        "MCA(MISC. CIVIL APPLICATION)-16",
        "MCACP(MISC. CIVIL APPLN. (CONTEMPT PETITION))-17",
        "OJ109(APPEAL UNDER SECTION 109)-71",
        "OJA(O.J.APPEAL)-72",
        "OJCAN(CANCELLATION APPLICATION)-74",
        "OJCR(CRIMINAL CASE)-75",
        "OJMP(MISC. PETITION)-78",
        "OJRP(REVIEW PETITION)-79",
        "OLR(OFFICIAL LIQUDATOR REPORT)-80",
        "RA(RECTIFICATION APPLICATION)-81",
        "RERAA(RERA APPEAL)-97",
        "RP(REVOCATION PETITION)-82",
        "SA(SECOND APPEAL)-19",
        "SCA(SPECIAL CIVIL APPLICATION)-21",
        "SCR.A(SPECIAL CRIMINAL APPLICATION)-47",
        "SJC(SPECIAL JURISDICTION CASES)-83",
        "SPTA(SUPER PROFIT TAX APPLICATION)-84",
        "SPTR(SUPER PROFIT TAX REFERENCE)-85",
        "SR(STAMP REFERENCE)-86",
        "STA(SALES TAX APPLICATION)-88",
        "ST(OJ)(ST(OJ))-87",
        "STR(SALES TAX REFERENCE)-89",
        "SUTA(SUR TAX APPLICATION)-90",
        "SUTR(SUR TAX REFERENCE)-91",
        "TAXAP(TAX APPEAL)-92",
        "TEP(TESTAMENTARY PETITION)-93",
        "WPPIL(WRIT PETITION (PIL))-26",
        "WTA(WEALTH TAX APPLICATION)-94",
        "WTR(WEALTH TAX REFERENCE)-95",
        "X-OBJ(CROSS OBJECTION)-23",
        "X-OBJ(O)(CROSS OBJECTION)-96",
      ],
    },

    "High Court of Himachal Pradesh": {
      "High Court of Himachal Pradesh": [
        "ARB.A(Arbitration Appeal)-49",
        "ARB.C(Arbirtration Case)-48",
        "ARB.P(Arbirtration Petition)-16",
        "CAC(Chartered Accountants)-68",
        "CA(FEMA)(Civil Appeal Foreign Exchange Management Act)-321",
        "CA(PMLA)(Civil Appeal Prevention of Money Laundering Act)-320",
        "CARAP(Commercial Arbitration Application)-71",
        "CARAP(M)(Commercial Arbitration Application (Main))-323",
        "CARBA(Commercial Arbitration Appeal)-72",
        "CARBC(Commercial Arbitration Case)-70",
        "CEA(Central Excise Appeal)-53",
        "CHBR.A(Chamber Appeal)-324",
        "CMP(Civil Misc. Petition)-30",
        "CMP.M(Civil Misc. Petition(Main))-26",
        "CMPMO(Civil Misc. Petition (Main)(u/a 227  amp u/s 24 CPC))-7",
        "CMP-T(Civil Miscellaneous Applications)-316",
        "CNACR(Contempt Appeal (Criminal))-308",
        "CO(Cross Objection)-32",
        "CO.A(Company Appeal)-27",
        "COAPP(Company Application)-4",
        "COMAP(Commercial Appeal)-73",
        "COMS(Commercial Suit)-69",
        "CONTA(Contempt Appeal(C))-28",
        "CO.P(Company Petition)-3",
        "COPC(Civil Original Petition (Contempt))-2",
        "COPCT(Civil Original Petition Contempt (Tribunal))-58",
        "CR(Civil Revision Petition)-25",
        "CR.A(Criminal Appeal)-304",
        "CRCC(Criminal Complaint Case)-311",
        "C.REF(Civil Reference)-31",
        "C.REV(Civil Review Petition)-29",
        "CREW(Civil Review (Tribunal))-59",
        "CRMMO(Criminal Misc. Petition(Main) u/s 482 CRPC)-302",
        "CRMP(Criminal Misc. Petition)-309",
        "CRMPM(Criminal Misc. Petition (Main))-306",
        "CROPC(Criminal Original Petition (Contempt))-303",
        "CR.R(Criminal Revision Petition)-305",
        "CRREF(Criminal Reference)-307",
        "CRWP(Criminal Writ Petition)-301",
        "CRWPL(Criminal Writ Petition Public Interest Litigation)-312",
        "CS(Civil Suit)-5",
        "CVT(Caveat)-62",
        "CWP(Civil Writ Petition)-1",
        "CWPIL(Civil Writ Petition Public Interest Litigation)-51",
        "CWPOA(Civil Writ Petition OA (Tribunal))-56",
        "CWP-T(Civil Writ Petition (Transfer))-55",
        "CWPTA(Civil Writ Petition TA (Tribunal))-57",
        "DSR(Death Sentence Reference)-310",
        "EDR(Estate Duty Reference)-10",
        "EL.P(Election Petition)-15",
        "EMP(Election Misc. Petition)-19",
        "EXC.P(Excise Petition)-47",
        "EX-P(Execution Petition (in RSA))-64",
        "EX-P.(Execution Petition (in FAO))-65",
        "EX.P(Execution Petition)-6",
        "EX.P.(Execution Petition (in CWP))-63",
        "EX.PC(Execution Petition(in Civil Revision))-67",
        "EX.P(RFA)(Execution Petition (in RFA))-313",
        "EX.PT(Execution Petition (Tribunal))-60",
        "EXREF(Excise Reference)-45",
        "EXRP(Execution Petition (in RP))-322",
        "FAO(First Appeal From Order)-20",
        "FAO(FC)(Family Courts)-318",
        "FAO(OS)(First Appeal Original Side)-325",
        "GTA(Gift Tax Appeal)-54",
        "ITA(Income Tax Appeal)-44",
        "ITAPP(Income Tax Application)-18",
        "ITR(Income Tax Reference)-8",
        "LPA(Letter Patent Appeal)-22",
        "MP(Metrimonial Petition)-66",
        "MSA(Misc. Second Appeal)-24",
        "OMP(Original Misc. Petition)-17",
        "OMPM(Original Misc. Petition (Main))-12",
        "O.P.(Original Petition)-317",
        "OSA(Original Side Appeal)-33",
        "PA(Pauper Application)-13",
        "PP(Probate Petition)-14",
        "RECRPTN(Recrimination Petition)-315",
        "RFA(Regular First Appeal)-21",
        "RP(Review Petition)-61",
        "RPT(Civil Review (Transfer))-319",
        "RSA(Regular Second Appeal)-23",
        "SA(Succession Application)-314",
        "STR(Sales Tax Reference)-9",
        "WTA(Wealth Tax Appeal)-46",
        "WTR(Wealth Tax Reference)-11",
      ],
    },

    "High Court of Jammu and Kashmir": {
      "Jammu Wing": [
        "417_A(417 CRIMINAL PC)-72",
        "491(491 CRIMINAL PROCEDURE)-191",
        "491_A(491_A CRIMINAL PROCEDURE CODE)-44",
        "561_B(561_B CRIMINAL PROCEDURE CODE)-148",
        "AA(ARBITRATION APPLICATION)-32",
        "AATA(ARBITRATION APPLICATION TRANSFER)-112",
        "AP(Arbitration Petitions)-206",
        "AP104(APPL. IN PET. U/S 104)-186",
        "APAA(APPLICATION IN ARB.APPLICATION)-150",
        "APCIV(APPLICATION (CIVIL))-94",
        "APCOS(APPLICATION (COS))-96",
        "APEXP(APPLICATION IN EXCISE APPEAL)-194",
        "APHCP(APPLICATION (HCP))-93",
        "APITA(Application (ITA))-167",
        "APLC(APPLICATION IN LPA(C))-156",
        "APLPA(APPLICATION (LPA))-97",
        "APOWP(APPLICATION (OWP))-92",
        "APPCR(APPLICATION (CRIMINAL))-95",
        "APPIL(APPLICATION IN PIL)-147",
        "APPLE(CORRECTION IN A DISPOSED CASE (DECISION))-89",
        "APSOW(APPL. TO FILE SUIT IN THE OTHER WING)-77",
        "APSWP(APPLICATION (SWP))-91",
        "Arb App(ARBITRATION APPEAL-DB)-304",
        "Arb P(ARBITRATION PETITIONS -SB)-329",
        "AUCTA(TRANSFER APPLICATION (AUCA))-152",
        "BA(BAIL APPLICATION)-50",
        "Bail App(BAIL APPlICATION -SB)-327",
        "C2MA(CIVIL 2ND MISC. APPEALS)-24",
        "CCP(D)(Contempt Petition-DB)-301",
        "CCP(S)(CONTEMPT PETITION-S.B)-303",
        "CCROS(CROSS APPEAL (CIVIL))-59",
        "CD(CONDONATION OF DELAY)-63",
        "CDLED(COND.OF DELAY (LPA E.D.))-135",
        "CDLHC(Condonation of Delay in Habeous Corpus)-163",
        "CDLIT(COND.OF DELAY (LPA I.T.))-123",
        "CDLOW(COND.OF DELAY (LPAOW))-138",
        "CDLST(COND.OF DELAY (LPA S.T.))-124",
        "CDLSW(COND.OF DELAY (LPASW))-137",
        "CDLTT(COND.OF DELAY (LPA T.T.))-126",
        "CDLXT(COND.OF DELAY (LPA X.T.))-125",
        "CDPIL(CONDONATION IN PIL)-146",
        "CDR(CUSTOM DUTY REFERENCE)-202",
        "CEA(CENTRAL EXCISE ACT APPEAL)-208",
        "CEC(Central Excise Cases)-338",
        "CER(CENTRAL EXCISE REFERENCE)-162",
        "CFA(CIVIL IST APPEALS)-21",
        "CIMIT(CIVIL Ist MISC.APPEAL (I.T.))-136",
        "CM(CIVIL MISC)-298",
        "CM(M)(Petitions u/s 104 of the Constitution of Jammu and Kashmir)-350",
        "CO104(COND. IN PET. U/S 104)-187",
        "COA(CIVIL ORIGINAL APPL.)-71",
        "COAAA(CIVIL ORIGINAL APPL.(AA))-133",
        "COACO(CIVIL ORIGINAL APPL.(COS))-134",
        "CONAA(COND.IN ARBITRATION APPLICATION)-157",
        "ConApp(Appeal in Contempt Petitions)-344",
        "CONC(CONDONATION CIVIL)-67",
        "CONCO(CONDONATION COS)-69",
        "CONCR(CONDONATION CRIMINAL)-68",
        "CONEP(COND.OF DELAY IN ELECTION PETITION)-174",
        "CONF(CONFIRMATION)-45",
        "CONHC(CONDONATION IN HCP)-192",
        "CONIL(CONDONATION OF DELAY IN WPPIL)-180",
        "CONIT(COND.OF DELAY IN ITA)-176",
        "CONOW(CONDONATION OWP)-66",
        "Con Ref(Reference u/s 130 of Constitution of JK -DB)-325",
        "CONSS(CONDONATION SSWP)-65",
        "CONSW(CONDONATION SWP)-64",
        "CONXP(CONDONATION IN EXCISE APPEAL)-195",
        "COSTA(TRANSFER APPLICATION (COS))-154",
        "CP(PETITION UNDER COMPANIES ACT)-38",
        "CP104(CONTEMPT IN PET. U/S 104)-183",
        "CPAA(CONTEMPT ARBITRATION APPL.)-62",
        "CPAUC(CONTEMPT IN (AUCA))-189",
        "CPC(CONTEMPT PETITION (CIVIL))-28",
        "CPCOS(CONTEMPT PETITION (COS))-37",
        "CPCR(CONTEMPT PETITION (CRIMINAL))-47",
        "CPCTA(CONTEMPT (CIVIL TRANSFER APLLICATION))-81",
        "CPHCP(CONTEMPT HABEUS CORPUS)-57",
        "CPLPA(CONTEMPT PETITION (LPA))-6",
        "CPLR(CONTEMPT IN LP CROSS REVIEW)-178",
        "CPOTA(CONTEMPT AND TRANSFER (OTHER WRIT))-84",
        "CPOWP(CONTEMPT PETITION (OWP))-16",
        "CPPIL(CONTEMPT PUBLIC INTEREST LITIGATION(OWP))-102",
        "CPRTA(CONTEMPT (CRIMINAL TRANSFER APPLICATION))-82",
        "CPSSW(CONTEMPT PETITION (SSWP))-7",
        "CPSTA(CONTEMPT AND TRANSFER (SERVICE))-83",
        "CPSW(CONTEMPT PETITION (SWP))-11",
        "CR(CIVIL REVISIONS)-25",
        "CRA(CRIMINAL APPEALS)-39",
        "CRAA(CRIMINAL ACQIUTTAL APPEALS)-40",
        "CRCH(CRIMINAL CHALLAN)-140",
        "CREF(CIVIL REFERENCE)-26",
        "CrlA(AD)(Criminal Acquittal Appeal-DB)-307",
        "CrlA(AS)(Criminal Acquittal Appeal-SB)-308",
        "Crl A(D)(CRIMINAL APPEAL-DB)-305",
        "Crl A(S)(CRIMINAL APPEAL-SB)-306",
        "Crl CP(Contempt Petition Crl-DB)-302",
        "Crl LP(Special Leave App.)-323",
        "Crl LP(D)(Special Leave Application -DB)-347",
        "Crl LP(S)(Special Leave Application -SB)-348",
        "CrlM(CRIMINAL MISC)-299",
        "Crl R(CRIMINAL REVISION -SB)-333",
        "Crl Ref(D)(CRIMINAL REFERENCE-Death)-310",
        "Crl Ref(L)(CRIMINAL REFERENCE-Life Imprisonment)-309",
        "CRMC(561_A CRIMINAL PROCEDURE CODE)-43",
        "CRM(M)(MISC CRIMINAL CASES-SB)-335",
        "CROA(CRIMINAL APPLICATION)-73",
        "Cr.Obj(1st APPEAL-Cross Appeal)-337",
        "CRR(CRIMINAL REVISIONS)-42",
        "CRREF(CRIMINAL REFERENCE)-46",
        "CRTA(CRIMINAL TRANSFER APPLICATION)-49",
        "CSA(CIVIL 2ND APPEALS)-22",
        "CS(OS)(CIVIL ORIGINAL SUIT -SB)-334",
        "CTA(CIVIL TRANSFER APPLICATION)-30",
        "CTP(CONTEMPT PETITION)-196",
        "CTP.(CONTEMPT PETITION.)-197",
        "Cus TR(TAX REFERENCE-DB Custom Duty Reference)-321",
        "EA(EXCISE TAX APPEAL -DB)-322",
        "EFA(Execution First Appeal)-345",
        "EP(ELECTION PETITION)-76",
        "EPTA(TRANSFER APPLICATION (ELECTION PETITION))-149",
        "ESA(Execution Second Appeal)-346",
        "ETA(EXCISE TAX APPEAL)-201",
        "EXA(EXECUTION APPLICATION)-35",
        "EXAA(EX. PET. IN ARB. APPL/ AWARD)-190",
        "EXAP(EXCISE APPEAL)-177",
        "EXOWP(Execution of Order/Judgment in OWP)-166",
        "EXP(EXECUTION PETITION)-198",
        "EXREF(EXCISE REFERENCE)-143",
        "EXSWP(Execution of Order/Judgment in SWP)-164",
        "FAO(1st MISC. APPEAL-SB Family Law)-316",
        "FAO(CP)(1st MISC. APPEAL- SB Consumer Protection Act)-315",
        "FAO(D)(Civil 1st Misc. Appeal-DB)-312",
        "FAO(FSS)(Appeals under Food Safety and Standards Act)-349",
        "FAO(MAT)(1st MISC. APPEAL- SB Family Court Act)-317",
        "FAO(OS)(ORIGINAL SIDE APPEAL -DB Against interim)-319",
        "FAO(WC)(1st MISC. APPEAL-SB Workmen Compensation Act)-314",
        "GSTA(GST Appeal)-341",
        "HCP(HABEAS CORPUS PETITION)-17",
        "ITA(INCOME TAX APPEAL)-111",
        "ITC(Income Tax Cases)-339",
        "ITR(INCOME TAX REFERENCE)-34",
        "LASCL(LEAVE TO APPEAL TO SUPREME COURT)-8",
        "LASCO(LEAVE TO APPEAL TO SUPREME COURT (OWP))-18",
        "LASCR(LEAVE TO APPEAL TO S.C (CRIMINAL))-48",
        "LCROS(CROSS APPEAL (LPAW))-87",
        "LPA(LETTERS PATENT APPEAL)-1",
        "LPAC(LETTERS PATENT APPEALS (CIVIL))-20",
        "LPAED(LPA (E.D.))-129",
        "LPAHC(LETTERS PATENT APPEAL IN HABEUS CORPUS)-161",
        "LPAIT(LPA (I.T.))-127",
        "LPAOW(LATTERS PATENT APPEAL (OW))-109",
        "LPAST(LPA (S.T.))-128",
        "LPASW(LATTERS PATENT APPEAL (SW))-108",
        "LPATA(TRANSFER APPLICATION (LPA))-113",
        "LPATT(LPA (T.T.))-131",
        "LPAXT(LPA (EXCISE TAX))-130",
        "LPPIL(LPA (PIL))-132",
        "LSC(LEAVE TO APPEAL TO SUP. COURT)-29",
        "MA(CIVIL IST MISC. APPEALS)-23",
        "Mac App(1st MISC. APPEAL-SB Motor Accident Claim)-313",
        "MCC(MISC. CIVIL CASES)-207",
        "MOWP(MISC OTHER WRIT PETITION)-78",
        "OS(CIVIL ORIGINAL SUIT)-31",
        "OSA(ORIGINAL SIDE APPEAL AGAINST FINAL)-19",
        "OSMA(ORGINAL SIDE APPEAL AGAINST INTERIM)-200",
        "OW104(PETITION U/S 104)-181",
        "OWP(OTHER WRIT PETITION)-12",
        "OWPCD(OWP CUSTOM DUTY)-117",
        "OWPED(OWP EXCISE DUTY)-120",
        "OWPET(OWP ENT. TAX)-116",
        "OWPIT(OWP INCOME TAX)-115",
        "OWPLC(OTHER WRIT PETITION (LAND COURT))-13",
        "OWPLR(OTHER WRIT PETITION (LAND REFORMS))-14",
        "OWPPD(OWP PASSENGER DUTY)-118",
        "OWPPT(OWP PROPERTY TAX)-119",
        "OWPST(OWP SALES TAX)-122",
        "OWPTA(TRANSFER APPLICATION (OWP))-61",
        "OWPTT(OWP TOLL TAX)-121",
        "PA(GRANT OF PROBATE)-33",
        "PAPC(PAUPER APPLICATION (CIVIL))-74",
        "PAPCO(PAUPER APPLICATION (COS))-75",
        "PERC(PERMISSION TO FILE CIVIL SUIT)-90",
        "PERCR(PERMISSION TO FILE Cr.APPEAL)-171",
        "PERIL(PERMISSION IN WPPIL)-179",
        "PERIT(PERMISSION TO FILE I.T.APPEAL)-155",
        "PERLP(PERMISSION FOR FILING LPA)-80",
        "PIL(PUBLIC INTEREST LITIGATION)-153",
        "PPIL(PUBLIC INTEREST LITIGATION (OWP)-101",
        "PRLPC(PERM. TO FILE LPA WITH CERT. COPY)-141",
        "PRLPW(PERM. TO FILE LPA WITHOUT CERT. COPY)-142",
        "RCPIL(REST. IN CONTEMP PIL)-188",
        "RE104(REST. IN PET. U/S 104)-185",
        "REF(REFERENCE U/S 130 OF CONSTITUTION OF J AND K)-204",
        "Ref (C)(CIVIL REFERENCE -SB)-332",
        "Ref(Crl)(CRIMINAL REFERENCE-SB)-311",
        "RES(Restoration Application)-205",
        "RESAA(RESTORATION APPLICATION (AA))-110",
        "RESC(RESTORATION APPL. (CIVIL))-55",
        "RESCO(RESTORATION APPL. (COS))-56",
        "RESCP(Restoration in Contempt Petition)-158",
        "RESCR(RESTORATION CRIMINAL)-70",
        "RESEX(RESTORATION IN EXCISE)-173",
        "RESHC(RESTORATION (HCP))-98",
        "RESIT(RESTORATION APPL.IN ITA)-169",
        "RESLP(RESTORATION APPLICATION (LPA))-51",
        "RESOW(RESTORATION APPL.  (OWP))-54",
        "RESPL(RESTORATION (PIL))-139",
        "RESRP(RESTORATION (REVIEW PETITION))-99",
        "RESSS(RESTORATION APPL.  (SSWP))-52",
        "RESSW(RESTORATION APPL. (SWP))-53",
        "RFA(1st APPEAL-S.B)-336",
        "RFA(OS)(ORIGINAL SIDE APPEAL -DB Against final)-318",
        "RIA(REVENUE IST APPEAL)-36",
        "ROBCI(ROBAKAR (CIVIL))-104",
        "ROBCR(ROBKAR (CRIM.))-105",
        "ROBHC(ROBKAR (HCP))-172",
        "ROBLP(ROBKAR (LPA))-107",
        "ROBOW(ROBKAR (OWP))-100",
        "ROBOW(ROBKAR(SWP))-106",
        "RP(REVIEW PETITION)-199",
        "RP104(REVIEW IN PET. U/S 104)-184",
        "RPAA(REVIEW PETITION (ARBITRATION APPL.))-88",
        "RPC(REVIEW PETITIONS (CIVIL))-27",
        "RPCDO(Review Petition in Con. of Delay LPAOWP)-160",
        "RPCDS(Review in Con. of Delay in LPASW)-159",
        "RPCOS(REVIEW IN COS)-182",
        "RPCPO(REVIEW PETITION IN CONTEMPT (OWP))-85",
        "RPCPS(REVIEW PETITION IN CONTEMPT (SWP))-86",
        "RPCR(REVIEW PETITION (CRIMINAL))-41",
        "RPHCP(REVIEW IN HCP)-170",
        "RPITA(REVIEW IN ITA)-175",
        "RPITR(REVIEW PETITION(INCOME TAX REF))-103",
        "RPLPA(REVIEW PETITIONS (LPA))-4",
        "RPOWP(REVIEW PETITION (OWP))-15",
        "RPPL(REVIEW IN PIL)-145",
        "RPSW(REVIEW PETITION (SSWP))-5",
        "RPSW(REVIEW PETITION (SWP))-10",
        "RSA(CIVIL 2ND APPEAL -SB)-326",
        "RSPIL(RESTORATION IN PIL)-144",
        "SLA(SPECIAL LEAVE APPLICATION)-79",
        "SLX(SPECIAL)-168",
        "SSWP(SERVICE SELECTION WRIT PETITION)-2",
        "STC(Sales Tax Cases)-340",
        "STR(Sales Tax Reference)-165",
        "SWP(SERVICE WRIT PETITION)-9",
        "SWPO(SERVICE WRIT PETITION ( OLD ))-3",
        "SWPTA(TRANSFER APPLICATION (SWP))-60",
        "TA(Transfer Application)-324",
        "TP(TRANSFER APPLICATION U/S 105 OF CONSTITUTION OF J AND K)-203",
        "TrP (C)(CIVIL TRANSFER APPLICATION -SB)-330",
        "TrP (Cr)(CRIMINAL TRANSFER APPLICATION -SB)-331",
        "VAT(C)(VAT Cases)-342",
        "VAT(R)(VAT References)-343",
        "WP(C)(WRIT PETITION)-300",
        "WP(CPI)(PUBLIC INTEREST LITIGATION -DB)-320",
        "WP(Crl)(WRIT PETITION CRIMINAL)-328",
      ],
      "Srinagar Wing": [
        "491(CRIMINAL)-158",
        "491_A(491_A CRIMINAL PROCEDURE CODE)-144",
        "561(CRIMINAL)-157",
        "561_B(561_B CRIMINAL PROCEDURE CODE)-156",
        "AA(ARBITRATION APPLICATION)-111",
        "AA-11(ARB. APPL. U/S 11)-180",
        "AAPL-37(ARB. APEAL U/S 37)-181",
        "AATA(ARBITRATION APPLICATION TRANSFER)-128",
        "AP(ARBITRATION PETITION)-182",
        "APAA(APPLICATION IN ARB.APPLICATION)-135",
        "APCIV(APPLICATION (CIVIL))-117",
        "APCOS(APPLICATION IN COS)-169",
        "APCOS(APPLICATION (COS))-126",
        "APHCP(APPLICATION (HCP))-174",
        "APLC(APPLICATION IN LPA (CIVIL))-41",
        "APLPA(APPLICATION (LPA))-5",
        "APOWP(APPLICATION (OWP))-80",
        "APPCR(APPLICATION (CRIMINAL))-153",
        "APPIL(APPLICATION IN PIL)-28",
        "APPLE(CORRECTION IN A DISPOSED CASE (DECISION))-124",
        "APSOW(APPL. TO FILE SUIT IN THE OTHER WING)-122",
        "APSWP(APPLICATION (SWP))-65",
        "Arb App(ARBITRATION APPEAL-DB)-304",
        "Arb P(ARBITRATION PETITIONS -SB)-329",
        "AUCTA(TRANSFER APPLICATION (AUCA))-132",
        "BA(BAIL APPLICATION)-149",
        "Bail App(BAIL APPlICATION -SB)-327",
        "C2MA(CIVIL 2ND MISC. APPEALS)-103",
        "CCP(Contempt suo moto)-349",
        "CCP(D)(Contempt Petition-DB)-301",
        "CCP(S)(CONTEMPT PETITION-S.B)-303",
        "CCROS(CROSS APPEAL (CIVIL))-167",
        "CDLED(COND.OF DELAY (LPA E.D.))-17",
        "CDLHCP(COND.OF DELAY (LPAHCP))-179",
        "CDLIT(COND.OF DELAY (LPA I.T.))-7",
        "CDLOW(COND.OF DELAY (LPAOW))-24",
        "CDLST(COND.OF DELAY (LPA S.T.))-8",
        "CDLSW(COND.OF DELAY (LPASW))-23",
        "CDLTT(COND.OF DELAY (LPA T.T.))-10",
        "CDLXT(COND.OF DELAY (LPA X.T.))-9",
        "CDPIL(CONDONATION IN PIL)-27",
        "CDR(CUSTOM DUTY REFERENCE)-186",
        "CEA(CENTRAL EXCISE ACT APPEAL)-185",
        "CEC(Central Excise Cases)-338",
        "CER(CENTRAL EXCISE REFERENCE)-137",
        "CFA(CIVIL IST APPEALS)-100",
        "CIMA(CIVIL IST MISC. APPEALS)-102",
        "CIMIT(CIVIL Ist MISC.APPEAL (I.T.))-129",
        "CM(CIVIL MISC)-298",
        "CMAM(MOTOR ACCIDENTAL CASE)-118",
        "CM(M)(WRIT PETITION u/s 104 OF CONSTITUTION OF J AND K)-348",
        "CMP(APPLICATION)-77",
        "CMP(LPA)(APPLICATION (LPA))-40",
        "COA(CIVIL ORIGINAL APPL.)-98",
        "COAAA(CIVIL ORIGINAL APPL.(AA))-130",
        "COACO(CIVIL ORIGINAL APPL.(COS))-168",
        "CONAA(COND.IN ARBITRATION APPLICATION)-133",
        "ConApp(Appeal in Contempt Petitions)-344",
        "CONC(CONDONATION CIVIL)-37",
        "CONCO(CONDONATION COS)-165",
        "CONCold(CONDONATION CIVIL old)-110",
        "CONCR(CONDONATION CRIMINAL)-150",
        "CONDL(CONDONATION OF DELAY)-21",
        "CONEP(COND.OF DELAY IN ELECTION PETITION)-136",
        "CONIL(CONDONATION OF DELAY IN WPPIL)-47",
        "CONIT(COND.OF DELAY IN ITA)-92",
        "CONOW(CONDONATION OWP)-76",
        "Con Ref(Reference u/s 130 of Constitution of JK -DB)-325",
        "CONSS(CONDONATION SSWP)-60",
        "CONSW(CONDONATION SWP)-59",
        "COS(CIVIL ORIGINAL SUITS)-161",
        "COSTA(TRANSFER APPLICATION (COS))-170",
        "CPAA(CONTEMPT ARBITRATION APPL.)-99",
        "CPC(CONTEMPT PETITION (CIVIL))-107",
        "CPCOS(CONTEMPT PETITION (COS))-162",
        "CPCOS(CONTEMPT COS)-163",
        "CPCR(CONTEMPT PETITION (CRIMINAL))-146",
        "CPCTA(CONTEMPT (CIVIL TRANSFER APLLICATION))-125",
        "CPHCP(CONTEMPT HABEUS CORPUS)-172",
        "CPLPA(CONTEMPT PETITION (LPA))-4",
        "CPLR(CONTEMPT IN LP CROSS APPEAL)-131",
        "CPOTA(CONTEMPT AND TRANSFER (OWP))-81",
        "CPOWP(CONTEMPT PETITION (OWP))-73",
        "CPPIL(CONTEMPT PUBLIC INTEREST LITIGATION(OWP))-177",
        "CPRTA(CONTEMPT (CRIMINAL TRANSFER APPLICATION))-152",
        "CPSSW(CONTEMPT PETITION (SSWP))-54",
        "CPSTA(CONTEMPT AND TRANSFER (SERVICE))-64",
        "CPSW(CONTEMPT PETITION (SWP))-55",
        "CR(CIVIL REVISIONS)-104",
        "CRA(CRIMINAL APPEALS)-139",
        "CRAA(CRIMINAL ACQIUTTAL APPEALS)-140",
        "CRCH(CRIMINAL CHALLAN)-155",
        "CREF(CIVIL REFERENCE)-105",
        "CrlA(AD)(Criminal Acquittal Appeal-DB)-307",
        "CrlA(AS)(Criminal Acquittal Appeal-SB)-308",
        "CrlA(D)(CRIMINAL APPEAL-DB)-305",
        "CrlA(S)(CRIMINAL APPEAL-SB)-306",
        "Crl CP(Contempt Petition Crl-DB)-302",
        "Crl LP(Special Leave Application -DB)-323",
        "Crl LP(D)(Special Leave Application -DB)-350",
        "Crl LP(S)(Special Leave Application -SB)-351",
        "CrlM(CRIMINAL MISC)-299",
        "Crl R(CRIMINAL REVISION -SB)-333",
        "Crl Ref(D)(CRIMINAL REFERENCE-Death)-310",
        "Crl Ref(L)(CRIMINAL REFERENCE-Life Imprisonment)-309",
        "CRMC(MISC CRIMINAL CASES)-143",
        "CRM(M)(MISC CRIMINAL CASES-SB)-335",
        "Cr.Obj(1st APPEAL-Cross Appeal)-337",
        "CRR(CRIMINAL REVISIONS)-142",
        "CRREF(CRIMINAL REFERENCE)-145",
        "CRTA(CRIMINAL TRANSFER APPLICATION)-148",
        "CSA(CIVIL 2ND APPEALS)-101",
        "CS(OS)(CIVIL ORIGINAL SUIT -SB)-334",
        "CTA(CIVIL TRANSFER APPLICATION)-109",
        "Cus TR(TAX REFERENCE-DB Custom Duty Reference)-321",
        "EA(EXCISE TAX APPEAL -DB)-322",
        "EFA(Execution First Appeal)-345",
        "EP(ELECTION PETITION)-121",
        "EPTA(TRANSFER APPLICATION (ELECTION PETITION))-134",
        "ESA(Execution Second Appeal)-346",
        "ETA(EXCIS TAX APPEAL)-184",
        "EXA(EXECUTION APPLICATION)-114",
        "EXAP(EXCISE APPEAL)-93",
        "EXOWP(Execution of Order/Judgment in OWP)-96",
        "EXP(EXECUTION PETITION)-190",
        "EXREF(EXCISE REFERENCE)-45",
        "EXSWP(EXECUTION OF ORDER/JUDGMENT IN SWP)-66",
        "FAO(1st MISC. APPEAL-SB Family Law)-316",
        "FAO(CP)(1st MISC. APPEAL- SB Consumer Protection Act)-315",
        "FAO(D)(Civil 1st Misc. Appeal-DB)-312",
        "FAO(FSS)(APPEALS UNDER FOOD SAFETY AND STANDARDS ACT)-347",
        "FAO(MAT)(1st MISC. APPEAL- SB Family Court Act)-317",
        "FAO(OS)(ORIGINAL SIDE APPEAL -DB Against interim)-319",
        "FAO(WC)(1st MISC. APPEAL-SB Workmen Compensation Act)-314",
        "GSTA(GST Appeal)-341",
        "HCP(HABEAS CORPUS PETITION)-171",
        "ITA(INCOME TAX APPEAL IN LPA)-11",
        "ITC(Income Tax Cases)-339",
        "ITR(INCOME TAX REFERENCE)-113",
        "ITREF(INCOME TAX REFERENCE IN LPA)-42",
        "LASCC(LEAVE TO APPEAL TO SUP. COURT (CIVIL))-108",
        "LASCL(LEAVE TO APPEAL TO SUPREME COURT)-56",
        "LASCO(LEAVE TO APPEAL TO SUPREME COURT (OWP))-75",
        "LASCR(LEAVE TO APPEAL TO S.C (CRIMINAL))-147",
        "LCROS(CROSS APPEAL (LPAW))-39",
        "LPA(LPA)-297",
        "LPAC(LETTERS PATENT APPEALS (CIVIL))-19",
        "LPACR(LETTERS PATENT APPEAL CRIMINAL)-46",
        "LPAED(LPA (E.D.))-13",
        "LPAHC(LETTERS PATENT APPEALS IN HABEOUS CORPUS)-35",
        "LPAOW(LETTERS PATENT APPEALS (WRIT PETITION OTHER))-3",
        "LPAST(LPA (S.T.))-12",
        "LPASW(LETTERS PATENT APPEALS (WRIT PETITION))-2",
        "LPATA(TRANSFER APPLICATION (LPA))-6",
        "LPATT(LPA (T.T.))-15",
        "LPAW(LETTERS PATENT APPEAL)-36",
        "LPAXT(LPA (EXCISE TAX))-14",
        "LPPIL(LPA (PIL))-16",
        "LSC(LSC)-296",
        "MA(REDRESSAL COMMISSION)-38",
        "Mac App(1st MISC. APPEAL-SB Motor Accident Claim)-313",
        "MCC(Misc. Civil Cases)-178",
        "OS(APPLICATION UNDER COMPANIES ACT)-119",
        "OSA(ORIGINAL SIDE APPEALS)-160",
        "OSMA(OSMA)-183",
        "OWP(OTHER WRIT PETITION)-69",
        "OWPCD(OWP CUSTOM DUTY)-85",
        "OWPED(OWP EXCISE DUTY)-88",
        "OWPET(OWP ENT. TAX)-84",
        "OWPIT(OWP INCOME TAX)-68",
        "OWPLC(OTHER WRIT PETITION (LAND COURT))-70",
        "OWPLR(OTHER WRIT PETITION (LAND REFORMS))-71",
        "OWPPD(OWP PASSENGER DUTY)-86",
        "OWPPT(OWP PROPERTY TAX)-87",
        "OWPST(OWP SALES TAX)-90",
        "OWPTA(TRANSFER APPLICATION (OWP))-79",
        "OWPTT(OWP TOLL TAX)-89",
        "PA(GRANT OF PROBATE)-112",
        "PAPC(PAUPER APPLICATION (CIVIL))-97",
        "PAPCO(PAUPER APPLICATION (COS))-166",
        "PERCR(PERMMISSION TO FILE Cr.APPEAL)-154",
        "PERLP(PERMISSION FOR FILING LPA)-43",
        "PIL(PUBLIC INTEREST LITIGATION (OWP))-32",
        "PRLPC(PERM. TO FILE LPA WITH CERT. COPY)-29",
        "PRLPW(PERM. TO FILE LPA WITHOUT CERT. COPY)-30",
        "REF(REFRENCE)-188",
        "Ref (C)(CIVIL REFERENCE -SB)-332",
        "Ref(Crl)(CRIMINAL REFERENCE-SB)-311",
        "RESAA(RESTORATION APPLICATION (AA))-127",
        "RESC(RESTORATION APPL. (CIVIL))-120",
        "RESCO(RESTORATION APPL. (COS))-164",
        "RESCP(RESTORATION APPL. (CONTEMPT SWP))-62",
        "RESCR(RESTORATION CRIMINAL)-151",
        "RESEX(RESTORATION IN EXCISE)-138",
        "RESHC(RESTORATION (HCP))-173",
        "RESIT(RESTORATION APPL.IN ITA)-83",
        "RESLP(RESTORATION APPLICATION (LPA))-20",
        "RESOW(RESTORATION APPL. (OWP))-78",
        "RESPL(RESTORATION (PIL))-22",
        "RESRP(RESTORATION (REVIEW PETITION))-49",
        "RESSW(RESTORATION APPL. (SWP))-61",
        "RFA(1st APPEAL-S.B)-336",
        "RFA(OS)(ORIGINAL SIDE APPEAL -DB Against final)-318",
        "RIA(REVENUE IST APPEAL)-115",
        "ROBC(ROBAKAR (CIVIL))-116",
        "ROBCR(ROBKAR (CRIM.))-159",
        "ROBHC(ROBKAR (HCP))-176",
        "ROBLP(ROBKAR (LPA))-18",
        "ROBOW(ROBKAR(OWP))-74",
        "ROBOW(ROBKAR (OWP))-94",
        "ROBSW(ROBKAR (SWP))-58",
        "RP(RP)-295",
        "RPAA(REVIEW PETITION (ARBITRATION APPL.))-123",
        "RPC(REVIEW PETITIONS (CIVIL))-106",
        "RPCDO(REVIEW IN CONDONATION OF DELAY IN LPAOW)-34",
        "RPCDS(REVIEW IN CONDONATION OF DELAY IN LPASW)-33",
        "RPCPO(REVIEW PETITION IN CONTEMPT (OWP))-82",
        "RPCPS(REVIEW PETITION IN CONTEMPT (SWP))-67",
        "RPCPS(REVIEW PETITION (CONTEMPT S.))-48",
        "RPCR(REVIEW PETITION (CRIMINAL))-141",
        "RPHCP(REVIEW IN HCP.)-175",
        "RPITA(REVIEW IN ITA)-91",
        "RPTIR(REVIEW PETITION(INCOME TAX REF))-44",
        "RPLPA(REVIEW PETITIONS (LPA))-1",
        "RPOWP(REVIEW PETITION (OWP))-72",
        "RPPIL(REVIEW IN PIL)-26",
        "RPSWP(REVIEW PETITION (SSWP))-53",
        "RPPSW(REVIEW PETITION (SWP))-57",
        "RSA(CIVIL 2nd APPEAL -SB)-326",
        "RSPLP(RESTORATION IN PIL)-25",
        "SLA(SPECIAL LEAVE TO APPEAL U/S 417(4)CR.P.C)-189",
        "SSWP(SERVICE SELECTION WRIT PETITION)-51",
        "STC(Sales Tax Cases)-340",
        "STR(SALES TAX REFERENCE)-95",
        "SWP(SERVICE WRIT PETITION)-50",
        "SWPO(SERVICE WRIT PETITION ( OLD ))-52",
        "SWPTA(TRANSFER APPLICATION (SWP))-63",
        "TA(Transfer Application u/s 105 of Constitution of JK -DB)-324",
        "TP (T)(TRANSFER APPLICATION)-187",
        "TrP (C)(CIVIL TRANSFER APPLICATION -SB)-330",
        "TrP (Crl)(CRIMINAL TRANSFER APPLICATION -SB)-331",
        "VATA(VAT APPLICATION)-191",
        "VAT(C)(VAT Cases)-342",
        "VTR(VAT References)-343",
        "WP(C)(WRIT PETITION)-300",
        "WP(PIL)(PUBLIC INTEREST LITIGATION -DB)-320",
        "WP(Crl)(HABEAS CORPUS PETITION -SB)-328",
        "WP(PIL)(WRIT PETITION IN PUB BLIC LITIGATION(SWP))-31",
      ],
    },

    "High Court of Jharkhand": {
      "Principal Bench Jharkhand": [
        "A.APPL(ARBITRATION APPLICATION)-61",
        "A.B.A.(ANTICIPATORY BAIL)-57",
        "A.C.(D.B.)(APPEAL CASE (DIVISION BENCH))-65",
        "Acq. App.(ACQUITTAL APPEAL)-67",
        "Acq. App.(C)(ACQUITTAL APPEAL (COMPLAINANT))-82",
        "Acq. App.(V)(ACQUITTAL APPEAL (VICTIM))-84",
        "A.C.(S.B.)(APPEAL CASE (SINGLE BENCH))-66",
        "Arb. Appeal(ARBITRATION APPEAL)-62",
        "B.A.(REGULAR BAIL)-56",
        "C.A.(COMPANY APPEAL)-20",
        "C.M.P.(CIVIL MISCELLENOUS PETITION)-58",
        "C.O.(CROSS OBJECTIONS)-52",
        "COM.APPEAL(COMMERCIAL APPEAL)-81",
        "Comp.Ap.DB(COMPANY APPEAL (DIVISION BENCH))-38",
        "COMP. APL.(COMPENSATION APPEAL)-34",
        "Comp.Appeal(COMPANY APPEAL)-13",
        "COMP.AP.SJ(COMPANY APPEAL (SINGLE JUDGE))-37",
        "CONT.APP.(CONTEMPT APPEAL)-39",
        "Cont.(Crl)(CRIMINAL CONTEMPT)-54",
        "Cont.(Cvl)(CIVIL CONTEMPT)-55",
        "Cop.Lnt(COMPLAINT APPEAL)-42",
        "C.P.(COMPANY PETITION/APPLICATION)-21",
        "C.R.(CIVIL REVISION)-8",
        "Cr.A(DB)(CRIMINAL APPEAL (DIVISION BENCH))-5",
        "Cr.App(341)(CRIMINAL APPEAL 341 CODE OF CRIMINAL PROCEDURE)-35",
        "CrApp(U/S)(CRIMINAL APPEAL (UNDER SECTION))-27",
        "Cr.App.(V)(CRIMINAL APPEAL (VICTIM))-83",
        "Cr.A(SJ)(CRIMINAL APPEAL (SINGLE JUDGE))-24",
        "C Ref(CIVIL REFERENCE)-12",
        "C.Rev.(CIVIL REVIEW)-11",
        "CR. EXE.(CRIMINAL EXECUTION CASE)-78",
        "Cr.Misc.(CRIMINAL MISCELLENOUS)-6",
        "Cr.M.P.(CRIMINAL MISCELLENOUS PETITION)-53",
        "Cr. Ref.(CRIMINAL REFERENCE)-29",
        "Cr.Rev.(CRIMINAL REVISION)-7",
        "Cr.WJC(Cr.WJC(CRIMINAL WRIT))-16",
        "CWJC(CWJC(CIVIL WRIT))-15",
        "D. Ref.(DEATH REFERENCE)-28",
        "E.P.(ELECTION PETITION)-14",
        "FA(FIRST APPEAL)-1",
        "G.App.(DB)(GOVERNMENT APPEAL (DIVISION BENCH))-25",
        "G.App.(SJ)(GOVERNMENT APPEAL (SINGLE JUDGE))-31",
        "L.A.(LETTER ADMIN)-59",
        "LPA(LETTER PATENTS APPEAL)-3",
        "MA(MISCELLENOUS APPEAL)-2",
        "M A C CASE(Motor Accident Claims)-77",
        "Mat Ref(MATRIMONIAL REFERENCE)-19",
        "Mat.Suit(MATRIMONIAL SUIT)-33",
        "MJC(MISCELLANEOUS JURISDICTION CASES)-10",
        "Money SUIT(MONEY SUIT)-41",
        "OCrMisc(DB(ORIGINAL CRIMINAL MISCELLENOUS (DIVISION BENCH))-32",
        "Or.Cr.Misc(ORIGINAL CRIMINAL MISCELLENOUS)-23",
        "PATENT CAS(PATENT CASE)-30",
        "PROB.CASE(PROB.CASE)-60",
        "REQ.APPEAL(REQUEST APPEAL)-74",
        "Req. Case(REQUEST CASE)-36",
        "SA(SECOND APPEAL)-9",
        "SCA(SUPREME COURT APPEAL)-40",
        "S.C.L.P(Cr.)(SUPREME COURT LEAVE PETITION (CRIMINAL))-85",
        "S.C.L.P(Cvl)(SUPREME COURT LEAVE PETITION (CIVIL))-68",
        "SLA(SPECIAL LEAVE TO APPEAL (CRIMINAL))-26",
        "T.A.(TAX APPEAL)-43",
        "Tax(TAX CASES)-4",
        "TAX APPLI.(TAX APPLICATION)-73",
        "Test Case(TEST CASE)-17",
        "Test Suit(TEST SUIT)-18",
        "Tr.Pet.Crl(CRIMINAL TRANSFER PETITION)-64",
        "Tr.Pet.CVL(CIVIL TRANSFER PETITION)-63",
        "T.S.(TESTAMENTARY SUIT)-22",
        "WPC(WRIT PETITIONS)-50",
        "W.P.(Cr.)(CRIMINAL WRIT PETITIONS)-51",
      ],
    },

    "High Court of Karnataka": {
      "Bench at Dharwad": [
        "AP.EFA(Arbitration Petition(Enforcement of Foreign Arbitral Award))-170",
        "AP.IM(Arbitration Petition-Interim Measure)-168",
        "CA(Company Application Matter)-101",
        "CAV_RSA(CAVEAT IN RSA)-157",
        "CAV_WP(Caveat Writ Petition)-158",
        "CCC(Civil Contempt Petition)-143",
        "CC(CIA)(Criminal Complaint (Commissions of Inquiry Act))-169",
        "CEA(Central Excise Appeal)-104",
        "CMP(CIVIL MISC PETITION)-105",
        "COA(U/s 10(f) of the Companies Act)-155",
        "COMAP(Commercial Appeals)-171",
        "COMAP.CROB(Commercial Appeals Cross Objection)-176",
        "COM.APLN(Commercial Application)-173",
        "COM.OS(COM.OS)-178",
        "COMPA(Company Appeal)-103",
        "COM.S(Commercial Suit)-177",
        "COP(Company Petition)-102",
        "CP(Civil Petition)-106",
        "CP.KLRA(CP On Karnataka Land Reforms Act)-159",
        "CRA(CROSS APPEALS)-160",
        "CRC(Civil Referred Case)-107",
        "CRL.A(Criminal Appeal)-110",
        "CRL.CCC(Criminal Contempt Petition)-111",
        "CRL.P(Criminal Petition)-112",
        "CRL.RC(Criminal Referred Case)-113",
        "CRL.RP(Criminal Revision Petition)-114",
        "CROB(Cross Objection)-161",
        "CRP(Civil Revision Petition)-108",
        "CSTA(Customs Appeal)-115",
        "EP(Election Petition)-116",
        "EX.FA(EXECUTION FIRST APPEAL)-117",
        "EX.SA(EXECUTION SECOND APPEAL)-118",
        "GTA(Gift Tax Appeal)-148",
        "HRRP(House Rent Rev. Petition)-119",
        "ITA(Income Tax Appeal)-120",
        "ITA.CROB(I.T Appeal CROSS Objection)-162",
        "ITRC(Income-tax referred case)-121",
        "LRRP(Land Reforms Revision Petition)-122",
        "LTRP(LUXURY TAX REVISION PETN.)-123",
        "MFA(Miscellaneous First Appeal)-124",
        "MFA.CROB(MFA Cross Obj)-125",
        "MISC(MISC)-175",
        "MISC.CRL(Miscellaneous Case for Crml)-164",
        "MISC.CVL(Miscellaneous Case for Civil)-165",
        "MISC.P(Miscellaneous Petition)-166",
        "MISC.W(Miscellaneous Case for Writ)-167",
        "MSA(Miscellaneous Second Appeal)-126",
        "MSA.CROB(MSA Cross Obj)-127",
        "OLR(Official Liquidator Report)-128",
        "OS(Original Suit)-154",
        "OSA(Original Side Appeal)-129",
        "OSA.CROB(OSA Cross Objection)-130",
        "PROB.CP(Probate Civil Petition)-131",
        "RA(Regular Appeal)-153",
        "RERA.A(RERA APPEALS)-172",
        "RERA.CRB(RERA Appeals cross-objection)-179",
        "RFA(Regular First Appeal)-132",
        "RFA.CROB(RFA Cross Obj)-133",
        "RP(Review Petition)-134",
        "RPFC(Rev.Pet Family Court)-135",
        "RSA(Regular Second Appeal)-136",
        "RSA.CROB(RSA Cross Obj)-137",
        "SA(Second Appeal)-174",
        "SCLAP(SUPREME COURT LEAVE APPLICATION)-152",
        "STA(Sales Tax Appeal)-138",
        "STRP(Sale Tax Revision Petition)-139",
        "TAET(Tax Appeal on Entry Tax)-151",
        "TOS(Testamentory Original Suit)-141",
        "TRC(Tax referred cases)-142",
        "WA(Writ Appeal)-145",
        "WA.CROB(WA Cross Objection)-147",
        "WP(Writ Petition)-144",
        "WPCP(Civil Pet in Writ Side)-150",
        "WPHC(Habeas Corpus)-146",
        "WTA(Wealth Tax Appeal)-149",
      ],
      "Bench at Kalburagi": [
        "AC(Arbitration Case)-156",
        "AP.EFA(Arbitration Petition(Enforcement of Foreign Arbitral Award))-170",
        "AP.IM(Arbitration Petition-Interim Measure)-168",
        "CA(Company Application)-101",
        "CAV_RSA(CAVEAT IN RSA)-157",
        "CAV_WP(Caveat Writ Petition)-158",
        "CCC(Civil Contempt Petition)-143",
        "CC(CIA)(Criminal Complaint (Commissions of Inquiry Act))-169",
        "CEA(Central Excise Appeal)-104",
        "CMP(CIVIL MISC PETITION)-105",
        "COA(U/s 10(f) of the Companies Act)-155",
        "COMAP(Commercial Appeals)-171",
        "COMAP.CROB(Commercial Appeals Cross Objection)-176",
        "COM.APLN(Commercial Application)-173",
        "COM.OS(COM.OS)-178",
        "COMPA(Company Appeal)-103",
        "COM.S(Commercial Suit)-177",
        "COP(Company Petition)-102",
        "CP(Civil Petition)-106",
        "CP.KLRA(CP On Karnataka Land Reforms Act)-159",
        "CRA(CROSS APPEALS)-160",
        "CRC(Civil Referred Case)-107",
        "CRL.A(Criminal Appeal)-110",
        "CRL.CCC(Criminal Contempt Petition)-111",
        "CRL.P(Criminal Petition)-112",
        "CRL.RC(Criminal Referred Case)-113",
        "CRL.RP(Criminal Revision Petition)-114",
        "CROB(Cross Objection)-161",
        "CRP(Civil Revision Petition)-108",
        "EP(Election Petition)-116",
        "EX.FA(EXECUTION FIRST APPEAL)-117",
        "EX.SA(EXECUTION SECOND APPEAL)-118",
        "GTA(Gift Tax Appeal)-148",
        "HRRP(House Rent Rev. Petition)-119",
        "ITA(Income Tax Appeal)-120",
        "ITA.CROB(I.T Appeal CROSS Objection)-162",
        "ITRC(Income-tax referred case)-121",
        "LRRP(Land Reforms Revision Petition)-122",
        "LTRP(LUXURY TAX REVISION PETN.)-123",
        "MFA(Miscl. First Appeal)-124",
        "MFA.CROB(MFA Cross Obj)-125",
        "MISC(MISC)-175",
        "MISC.CRL(Miscellaneous Case for Crml)-164",
        "MISC.CVL(Miscellaneous Case for Civil)-165",
        "MISC.P(Misc Petition)-166",
        "MISC.W(Miscellaneous Case for Writ)-167",
        "MSA(Miscl Second Appeal)-126",
        "MSA.CROB(MSA Cross Obj)-127",
        "OLR(Official Liquidator Report)-128",
        "OS(Original Suit)-154",
        "OSA(Original Side Appeal)-129",
        "OSA.CROB(OSA Cross Objection)-130",
        "PROB.CP(Probate Civil Petition)-131",
        "RA(Regular Appeal)-153",
        "RERA.A(RERA APPEALS)-172",
        "RERA.CRB(RERA Appeals cross-objection)-179",
        "RFA(Regular First Appeal)-132",
        "RFA.CROB(RFA Cross Obj)-133",
        "RP(Review Petition)-134",
        "RPFC(Rev.Pet Family Court)-135",
        "RSA(Regular Second Appeal)-136",
        "RSA.CROB(RSA Cross Obj)-137",
        "SA(Second Appeal)-174",
        "SCLAP(SUPREME COURT LEAVE APPLICATION)-152",
        "STA(Sales Tax Appeal)-138",
        "STRP(Sale Tax Revision Petition)-139",
        "TAET(Tax Appeal on Entry Tax)-151",
        "TOS(Testamentory Original Suit)-141",
        "TRC(Tax referred cases)-142",
        "WA(Writ Appeal)-145",
        "WA.CROB(WA Cross Objection)-147",
        "WP(Writ Petition)-144",
        "WPCP(Civil Pet in Writ Side)-150",
        "WPHC(Writ Petition Habeas Corpus)-146",
        "WTA(Wealth Tax Appeal)-149",
      ],
      "Principal Bench at Bengaluru": [
        "AC(Arbitration Case)-156",
        "AP.EFA(Arbitration Petition(Enforcement of Foreign Arbitral Award))-170",
        "AP.IM(Arbitration Petition-Interim Measure)-168",
        "CA(Company Application)-101",
        "CAV_RSA(CAVEAT IN RSA)-157",
        "CAV_WP(Caveat Writ Petition)-158",
        "CCC(Civil Contempt Petition)-143",
        "CC(CIA)(Criminal Complaint (Commissions of Inquiry Act))-169",
        "CEA(Central Excise Appeal)-104",
        "CMP(CIVIL MISC. PETITION)-105",
        "COA(U/s 10(f) of the Companies Act)-155",
        "COMAP(Commercial Appeals)-171",
        "COMAP.CR(Commercial Appeals Cross Objection)-176",
        "COM.APLN(Commercial Application)-173",
        "COM.OS(COM.OS)-178",
        "COMPA(Company Appeal)-103",
        "COM.S(Commercial Suit)-177",
        "COP(Company Petition)-102",
        "CP(Civil Petition)-106",
        "CP.KLRA(CP On Karnataka Land Reforms Act)-159",
        "CRA(CROSS APPEALS)-160",
        "CRC(Civil Referred Case)-107",
        "CRL.A(Criminal Appeal)-110",
        "CRL.CCC(Criminal Contempt Petition)-111",
        "CRL.P(Criminal Petition)-112",
        "CRL.RC(Criminal Referred Case)-113",
        "CRL.RP(Criminal Revision Petition)-114",
        "CROB(Cross Objection)-161",
        "CRP(Civil Revision Petition)-108",
        "CSTA(Customs Appeal)-115",
        "EP(Election Petition)-116",
        "EX.FA(EXECUTION FIRST APPEAL)-117",
        "EX.SA(EXECUTION SECOND APPEAL)-118",
        "GTA(Gift Tax Appeal)-148",
        "HRRP(House Rent Rev. Petition)-119",
        "ITA(Income Tax Appeal)-120",
        "ITA.CROB(I.T Appeal CROSS Objection)-162",
        "ITRC(Income-tax referred case)-121",
        "LRRP(Land Reforms Revision Petition)-122",
        "LTRP(LUXURY TAX REVISION PETN.)-123",
        "MFA(Miscellaneous First Appeal)-124",
        "MFA.CROB(MFA Cross Objection)-125",
        "MISC(MISC)-175",
        "MISC.CRL(Miscellaneous Case for Crml)-164",
        "MISC.CVL(Miscellaneous Case for Civil)-165",
        "MISC.P(Misc Petition)-166",
        "MISC.W(Miscellaneous Case for Writ)-167",
        "MSA(Miscellaneous Second Appeal)-126",
        "MSA.CROB(MSA Cross Objection)-127",
        "OLR(Official Liquidator Report)-128",
        "OS(Original Suit)-154",
        "OSA(Original Side Appeal)-129",
        "OSA.CROB(OSA Cross Objection)-130",
        "PROB.CP(Probate Civil Petition)-131",
        "RA(Regular Appeal)-153",
        "RERA.A(RERA APPEALS)-172",
        "RERA.CRB(RERA Appeals cross-objection)-179",
        "RFA(Regular First Appeal)-132",
        "RFA.CROB(RFA Cross Objection)-133",
        "RP(Review Petition)-134",
        "RPFC(Rev.Pet Family Court)-135",
        "RSA(Regular Second Appeal)-136",
        "RSA.CROB(RSA Cross Objection)-137",
        "SA(Second Appeal)-174",
        "SCLAP(SUPREME COURT LEAVE APPLICATION)-152",
        "STA(Sales Tax Appeal)-138",
        "STRP(Sales Tax Revision Petition)-139",
        "TAET(Tax Appeal on Entry Tax)-151",
        "TOS(Testamentory Original Suit)-141",
        "TRC(Tax referred cases)-142",
        "WA(Writ Appeal)-145",
        "WA.CROB(WA Cross Objection)-147",
        "WP(Writ Petition)-144",
        "WPCP(Civil Pet in Writ Side)-150",
        "WPHC(Habeas Corpus)-146",
        "WTA(Wealth Tax Appeal)-149",
      ],
    },

    "High Court of Kerala": {
      "High Court of Kerala": [
        "Adml.S.(ADMIRALTY SUIT)-1",
        "AFA(APPEAL FROM FIRST APPEAL)-2",
        "AFFD(AFFIDAVIT)-3",
        "APPEAL (ICA)(APPEAL (INTERNATIONAL COMMERCIAL ARBITRATION))-323",
        "AR(ARBITRATION REQUEST)-4",
        "Arb.A(ARBITRATION APPEALS)-5",
        "Arb.P.(ARBITRATION PETITION)-6",
        "AS(APPEAL SUITS)-7",
        "Bail Appl.(BAIL APPLICATION)-8",
        "Bkg.P(BANKING PETITION)-9",
        "Cal. Case(CALENDAR CASE)-12",
        "CC(COMPANY CLAIMS)-13",
        "CCC(CONTEMPT OF COURT CASE)-14",
        "CDAR(COCHIN DEVASWOM ANNUAL REPORT)-15",
        "CDB(COCHIN DEWASWOM BOARD)-16",
        "CDIR(COCHIN DEVASWOM INTERIM REPORT)-17",
        "CDSR(COCHIN DEVASWOM SPECIAL REPORT)-18",
        "C.E.Appeal(CENTRAL EXICISE APPEAL)-19",
        "CE.Ref.(CENTRAL EXCISE REFERENCE)-20",
        "CMA(CIVIL MISC. APPEAL)-21",
        "CMC(CIVIL MISC.CASES)-22",
        "CMCP(CIVIL MISC. CASE(PAUPER))-23",
        "CMP(CIVIL MISC. PETITION)-24",
        "CMR(CIVIL MISC. REFERENCE)-25",
        "CO(CROSS OBJECTION/CROSS APPEAL)-26",
        "CO.ADJ.APPEAL(COMPANY ADJUDICATION APPEAL)-28",
        "Co.Appeal(COMPANY APPEAL)-27",
        "Co.Appl.(COMPANY APPLICATION)-10",
        "Co.Case(COMPANY SUIT)-31",
        "Coml.A(COMMERCIAL APPEAL)-291",
        "COMPLAINT NO(COMPLAINT NO)-318",
        "Con.APP(C)(CONTEMPT APPEALS (CIVIL))-11",
        "Con.Case(C)(CONTEMPT OF COURT CASE (CIVIL))-29",
        "Cont.Cas.(Crl.)(CONTEMPT OF CASE (CRIMINAL))-30",
        "Co.Pet(COMPANY PETITION)-32",
        "CRA(V)(CRL.A BY DEFACTO COMPLAINANT/VICTIM)-34",
        "CRL.A(CRIMINAL APPEAL)-33",
        "Crl.Compl.(CRIMINAL COMPLAINT)-35",
        "Crl.L.P.(CRIMINAL LEAVE PETITION)-36",
        "Crl.MC(CRIMINAL MISC. CASE)-37",
        "Crl.RC(CRIMINAL REVISION  CASE)-43",
        "CRL.REF(CRIMINAL REFERENCE)-42",
        "Crl.Rev.Pet(CRIMINAL REVISION PETITION)-44",
        "CRP(CIVIL REVISION PETITION)-38",
        "CRP(LR)(CRP (LAND REFORMS ACT))-39",
        "CRP(UTY)(CRP (UNIVERSITY ACT))-40",
        "CRP(WAKF)(CRP (WAKF ACT))-41",
        "CS(CIVIL SUIT)-45",
        "CSDA(CIVIL SUIT (DESIGNS ACT))-46",
        "Cus.Appeal(CUSTOMS APPEAL)-47",
        "Cus.Ref.(CUSTOMS REFERENCE)-48",
        "DB(DEVASWOM BOARD)-49",
        "DBA(DEVASWOM BOARD APPLICATION)-50",
        "DBAR(DEVASWOM BOARD AUDIT REPORT)-51",
        "DBC(DEVASWOM BOARD CASE)-52",
        "DBP(DEVASWOM BOARD PETITION)-53",
        "DIR(DEVASWOM BOARD INTERIM REPORT)-54",
        "DSR(DEATH SENTENCE REFERENCE)-55",
        "EDA(ESTATE DUTY APPLICATION)-56",
        "EDR(ESTATE DUTY REFERENCE)-57",
        "EFA(EXECUTION  FIRST APPEAL)-58",
        "El.Pet.(ELECTION PETITION)-59",
        "EP(ICA)(EXECUTION PETITION INTERNATIONAL COMMERC)-60",
        "ESA(EXECUTION  SECOND APPEAL)-61",
        "Ex.Appl.(EXECUTION APPLICATIONS)-62",
        "Ex.FA(EXECUTION FIRST APPEAL)-63",
        "Ex.P(EXECUTION PETITION)-64",
        "Ex.SA(EXECUTION SECOND APPEAL)-65",
        "FA(FIRST APPEAL)-66",
        "F.A (ADMIRALTY)(FIRST APPEAL (ADMIRALTY))-317",
        "FAO(FIRST APPEAL FROM ORDERS)-67",
        "F.A.O (ADMIRALTY)(FIRST APPEALS FROM ORDERS (ADMIRALTY))-316",
        "FAO (RO)(FIRST APPEAL FROM ORDER - REMAND ORDER)-68",
        "Gen.Report(GENERAL REPORT)-70",
        "GTA(GIFT TAX APPEAL)-71",
        "GTR(GIFT TAX REFERENCE)-72",
        "Gua.P.(GUARDIANSHIP PETITIONS)-69",
        "HPCR(S)(HIGH POWER COMMITTEE REPORT (SABARIMALA))-289",
        "IA(Interim Application)-73",
        "Ins.APP(INSURANCE APPEAL)-75",
        "Intest.Cas.(INTESTATE CASES)-74",
        "ITA(INCOME TAX APPEAL)-76",
        "ITR(INCOME TAX REFERENCE)-77",
        "JPP(JUDICIAL PRACTICE AND PROCEDURE)-322",
        "LAAP(LAA PAUPER)-79",
        "LA.App.(LAND ACQUISITION APPEAL)-78",
        "MAC(MOTOR ACCIDENTS CLAIMS)-81",
        "MACA(MOTOR ACCIDENT CLAIMS APPEAL)-82",
        "MA (EXE.)(MAT APPEAL (EXECUTION))-83",
        "Mat.Appeal(MATRIMONIAL APPEAL)-80",
        "Mat.Cas(MATRIMONIAL CASES)-84",
        "Mat.Ref.(MATRIMONIAL REFERENCE)-93",
        "MCA(MISC. COMPANY APPLICATION)-85",
        "MEMO(MEMO)-86",
        "MFA(MISC. FIRST APPEAL)-87",
        "MFA (ADL)(MFA (ADMIRALTY))-88",
        "MFA (ADR)(MFA (ADR))-303",
        "MFA (COPYRIGHT)(MFA (COPYRIGHT))-315",
        "MFA (ECC)(MFA (ECC))-293",
        "MFA (ELECTION)(MFA (ELECTION))-311",
        "MFA (ELECTRICITY)(MFA (ELECTRICITY))-312",
        "MFA (FCRA)(MFA (FCRA))-302",
        "MFA (FEMA)(MFA (FEMA))-321",
        "MFA (FERA)(MFA (FERA))-295",
        "MFA (FINANCE)(MFA (FINANCE))-298",
        "MFA (FOREST)(MFA (FOREST))-292",
        "MFA (GST)(MFA (GST))-296",
        "MFA (G & W)(MFA (G & W))-294",
        "MFA (HRCE)(MFA (HRCE))-300",
        "MFA (IDPA)(MFA (IDPA))-305",
        "MFA (INSOLVENCY)(MFA (INSOLVENCY))-304",
        "MFA (KEA)(MFA (KEA))-308",
        "MFA(KME)(MFA (KERALA MEDICAL EDUCATION))-89",
        "MFA (LUNACY)(MFA (LUNACY))-299",
        "MFA (MHA)(MFA (MHA))-306",
        "MFA MT (OP)(MFA (MUNNAR TRIBUNAL))-90",
        "MFA (PANCHAYAT)(MFA (PANCHAYAT))-310",
        "MFA (PATENTS)(MFA (PATENTS))-320",
        "MFA (PROBATE)(MFA (PROBATE))-309",
        "MFA (RCT)(MFA (RCT))-301",
        "MFA (SCSTCC)(MFA (SCSTCC))-307",
        "MFA (SEBI)(MFA (SEBI))-314",
        "MFA (SUCCESSION)(MFA (SUCCESSION))-297",
        "MFA (TCRA)(MFA (TCRA))-313",
        "MFA (TRADE MARKS)(MFA (TRADE MARKS))-319",
        "MFCA(MISFEASANCE APPLICATION)-91",
        "MJC(MISCELLANEOUS JURISDICTION CASE)-92",
        "MSA(MISC. SECOND APPEAL)-94",
        "MSA(FS)(MISC.SECOND APPEAL(FOOD AND SAFETY))-95",
        "NO(NO NUMBER)-96",
        "OP(ORIGINAL PETITION)-97",
        "OP(AFT)(OP (ARMED FORCES TRIBUNAL - ART.227))-98",
        "OP(ATE)(OP(Arbitration Time Extension))-290",
        "OP(C)(OP (CIVIL))-99",
        "OP (CAT)(OP (CAT))-100",
        "OP(Crl.)(OP (CRIMINAL))-101",
        "OP (DRT)(OP (DEBT RECOVERY TRIBUNAL))-102",
        "OP (FC)(OP (FAMILY COURT))-103",
        "OP(FT)(OP (FOREST TRIBUNAL))-104",
        "OP(ICA)(ORIGINAL PETITION INTERNATIONAL COMMERCI)-105",
        "OP(KAT)(OP KERALA ADMINISTRATIVE TRIBUNAL)-106",
        "OP(LC)(OP (LABOUR COURT))-107",
        "OP (MAC)(OP (MAC))-108",
        "OP (RC)(OP (RENT CONTROL))-109",
        "OP STAT(OP STAT)-110",
        "OP (TAX)(OP TAX)-111",
        "OP (WAKF)(OP (WAKF))-112",
        "OS(ORIGINAL SUIT)-113",
        "OT.Appeal(OTHER TAX APPEAL)-115",
        "OT.Appl.(OTHER TAX APPLICATION)-114",
        "OTC(OTHER TAX CASES)-116",
        "OTR(OTHER TAX REFERENCE)-117",
        "OT.Rev(OTHER TAX REVISION (VAT))-118",
        "PA(PATENT APPLICATION)-119",
        "RC(REFERRED CASE)-120",
        "RCRev.(RENT CONTROL REVISION)-121",
        "Report(REPORT)-128",
        "RFA(REGULAR FIRST APPEAL)-122",
        "RFA (MISC.)(REGULAR FIRST APPEAL(MISCELLANEOUS))-123",
        "RP(REVIEW PETITION)-124",
        "RPAR(RP(ARBITRATION REQUEST))-125",
        "RPFC(REV.PETITION(FAMILY COURT))-126",
        "RPJJ(REV.PETITION(JUVENILE JUSTICE))-127",
        "RRPT(RECEIVERS REPORT)-129",
        "RSA(REGULAR SECOND APPEAL)-130",
        "RT(REFERRED TRIAL)-131",
        "SA(SECOND APPEAL)-132",
        "SCA(SUPREME COURT APPEAL)-133",
        "SCLP(SUPREME COURT LEAVE PETITION)-134",
        "SLP(SPECIAL LEAVE PETITION)-135",
        "SP.JC(SPECIAL JURISDICTION CASES)-137",
        "SRDB(SPECIAL REPORT IN DEVASWOM BOARD)-138",
        "SSCR(SABARIMALA SPECIAL COMMISSIONER REPORT)-139",
        "ST.Appl.(SALES TAX APPEAL)-140",
        "ST.Ref.(SALES TAX REFERENCE)-141",
        "ST.Rev.(SALES TAX REVISION)-142",
        "Suo Motu Ref.(SUO MOTU REFERENCE)-136",
        "TDAR(TRAVANCORE DEVASWOM ANNUAL REPORT)-146",
        "TDB(TRAVANCORE DEWASVOM BOARD)-147",
        "TDIR(TRAVANCORE DEVASWOM INTERIM REPORT)-148",
        "TDSR(TRAVANCORE DEVASWOM SPECIAL REPORT)-149",
        "Test.Cas(TESTAMENTARY CASES)-145",
        "TIP(TRANSFERRED INSOLVENCY PETITION)-150",
        "Tr.Appeal(C)(TRANSFER APPEAL(CIVIL))-143",
        "Tr.Appl. (CR)(TRANSFER APPEAL CRIMINAL)-144",
        "TRC(TAX REVISION CASE)-153",
        "Tr.P(C)(TRANSFER PETITION (CIVIL))-151",
        "Tr.P(Crl.)(TRANSFER PETITION (CRIMINAL))-152",
        "UNN.ADML.S(UNN. ADMIRALTY SUIT)-161",
        "Unn.CRP Uty(UNNUMB.CRP (UNIVERSITY ACT))-193",
        "UNN.FAO (RO)(UNN.FIRST APPEAL FROM ORDER - REMAND ORD)-214",
        "UNNUMB.AFA(UNNUMBERED APPEAL FROM FIRST APPEAL)-162",
        "UNNUMB.AR(UNNUMBERED ARBITRATION REQUEST)-163",
        "UNNUMB.Arb.A.(UNNUMBERED ARBA)-164",
        "UNNUMB.AS(UNNUMBERED AS)-165",
        "UNNUMB.Bail Appl.(UNNUMBERED BAIL APPLICATION)-166",
        "UNNUMB.CB(UNNUMBERED CB)-169",
        "UNNUMB.CC(UNNUMBERED CC)-170",
        "UNNUMB.CCC(UNNUMBERED CCC)-171",
        "UNNUMB.CDB(UNNUMBERED CDB)-172",
        "UNNUMB.CEA(UNNUMBERED CEA)-173",
        "UNNUMB.Ce.Ref(UNNUMBERED CER)-174",
        "UNNUMB.CMA(UNNUMBERED CMA)-175",
        "UNNUMB.CMC(UNNUMBERED CMC)-176",
        "UNNUMB.CMCP(UNNUMBERED CMCP)-177",
        "UNNUMB.CMP(UNNUMBERED CIVIL MISC. PETITION)-178",
        "UNNUMB.CMR(UNNUMBERED CMR)-179",
        "UNNUMB.CO(UNNUMBERED CROSS OBJECTION/CROSS APPEAL)-180",
        "UNNUMB.COA(UNNUMBERED COA)-181",
        "UNNUMB.Co.Appl.(UNNUMBERB.COMPANY APPLICATION)-167",
        "UNNUMB.Con.Case(C)(UNNUMBERED COC)-183",
        "UNNUMB.Cont.App(C)(UNNUMBERED CAC)-168",
        "UNNUMB.Cont.Cas.(Crl(UNNUMBERED CONTEMPT OF CASE (CRIMINAL))-184",
        "UNNUMB.CP(UNNUMBERED CP)-185",
        "UNNUMB.CRA(V)(UNNUMBERED CRL.A BY DEFACTO COMPLAINANT/)-187",
        "UNNUMB.CRL.A(UNNUMBERED CRIMINAL APPEAL)-186",
        "UNNUMB.Crl.Compl.(UNNUMBERED CRIMINAL COMPLAINT)-188",
        "UNNUMB.Crl.L.P.(UNNUMBERED CRIMINAL LEAVE PETITION)-189",
        "UNNUMB.Crl.MC(UNNUMBERED CRIMINAL MISC. CASE)-190",
        "UNNUMB.Crl.RC(UNNUMBERED CRIMINAL REVISION CASE)-195",
        "UNNUMB.Crl.Rev.Pet(UNNUMBERED CRIMINAL REVISION PETITION)-196",
        "UNNUMB.CRP(UNNUMBERED CRP)-191",
        "UNNUMB.CS(UNNUMBERED CS)-197",
        "UNNUMB.CUA(UNNUMBERED CUA)-198",
        "UNNUMB.Cus.Ref.(UNNUMBERED CUR)-199",
        "UNNUMB.DBA(UNNUMBERED DBA)-200",
        "UNNUMB.DBC(UNNUMBERED DBC)-201",
        "UNNUMB.DBP(UNNUMBERED DBP)-202",
        "UNNUMB.EDA(UNNUMBERED EDA)-203",
        "UNNUMB.EDR(UNNUMBERED EDR)-204",
        "UNNUMB.EFA(UNNUMBERED EFA)-205",
        "UNNUMB.EP(UNNUMBERED EP)-206",
        "UNNUMB.EP(ICA)(UNNUMBERED EXECUTION PETITION INTERNATIO)-207",
        "UNNUMB.ESA(UNNUMBERED ESA)-208",
        "UNNUMB.Ex.FA(UNNUMBERED EXECUTION FIRST APPEAL)-209",
        "UNNUMB Ex.P(UNN.EXECUTION PETITION)-210",
        "UNNUMB.EXSA(UNNUMBERED EXSA)-211",
        "UNNUMB.FA(UNNUMBERED FA)-212",
        "UNNUMB.FAO(UNMMBERED FAO)-213",
        "UNNUMB.GTA(UNNUMBERED GTA)-216",
        "UNNUMB.GTR(UNNUMBERED GTR)-217",
        "UNNUMB.Gua.P.(UNNUMBERED GP)-215",
        "UNNUMB.Ins.APP(UNNUMBERED INSURANCE APPEAL)-219",
        "UNNUMB.Intest.Cas.(UNNUMBERED IC)-218",
        "UNNUMB.ITA(UNNUMBERED INCOME TAX APPEAL)-220",
        "UNNUMB.ITR(UNNUMBERED ITR)-221",
        "UNNUMB.LAA(UNNUMBERED LAA)-222",
        "UNNUMB.LAAP(UNNUMBERED LAAP)-223",
        "UNNUMB.LC(UNNUMBERED OPLC)-246",
        "UNNUMB.MACA(UNNUMBERED MACA)-225",
        "UNNUMB.MA (EXE.)(UNNUMBERED MAT APPEAL (EXECUTION))-226",
        "UNNUMB.Mat.App.(UNNUMBERED MA)-224",
        "UNNUMB.Mat.Ref.(UNNUMBERED MR)-233",
        "UNNUMB.MC(UNNUMBERED MC)-227",
        "UNNUMB.MCA(UNNUMBERED MCA)-228",
        "UNNUMB.MFA(UNNUMBERED MFA)-229",
        "UNNUMB.MFA(KME)(UNNUMBERED MFA (KERALA MEDICAL EDUCATION))-230",
        "UNNUMB.MFA MT (OP)(UNNUMBERED MFA (MUNNAR TRIBUNAL))-231",
        "UNNUMB.MJC(UNNUMBERED MISCELLANEOUS JURISDICTION CA)-232",
        "UNNUMB.MSA(UNNUMBERED MSA)-234",
        "UNNUMB.MSA(FS)(UNNUMBERED MISC.SECOND APPEAL(FOOD AND S)-235",
        "UNNUMB.OP(UNNUMBERED OP)-236",
        "UNNUMB.OP(AFT)(UNNUMB.OP(ARMED FORCES TRIBUNAL-Art.227))-237",
        "UNNUMB.OPC(UNNUMBERED OPC)-238",
        "UNNUMB.OPCAT(UNNUMBERED OPCAT)-239",
        "UNNUMB.OP(Crl.)(UNNUMBERED OP (CRIMINAL))-240",
        "UNNUMB.OP DRT(UNNUMBERED OPDRT)-241",
        "UNNUMB.OPFC(UNNUMBERED OPFC)-242",
        "UNNUMB.OPI(CA)(UNNUMBERED ORIGINAL PETITION INTERNATION)-244",
        "UNNUMB.OP MACT(UNNUMBERED OP MACT)-247",
        "UNNUMB.OP(RC)(UNNUMBERED OP (RENT CONTROL))-248",
        "UNNUMB.OP (TAX)(UNNUMBERED OP TAX)-250",
        "UNNUMB.OS(UNNUMBERED OS)-252",
        "UNNUMB.OTAP(UNNUMBERED OTAP)-254",
        "UNNUMB.OT.App(UNNUMBERED OTA)-253",
        "UNNUMB.OTC(UNNUMBERED OTC)-255",
        "UNNUMB.OTRV(UNNUMBERED OTRV)-256",
        "UNNUMB.RC(UNNUMBERED REFERRED CASE)-257",
        "UNNUMB.RC.Rev.(UNNUMBERED RCR)-258",
        "UNNUMB.RFA(UNNUMBERED REGULAR FIRST APPEAL)-259",
        "UNNUMB.RP(UNNUMBERED REVIEW PETITION)-261",
        "UNNUMB.RPAR(UNNUMBERED RP (ARBITRATION REQUEST))-262",
        "UNNUMB.RPFC(UNNUMBERED REV.PETITION(FAMILY COURT))-263",
        "UNNUMB.RPJJ(UNNUMBERED REV.PETITION(JUVENILE JUSTICE))-264",
        "UNNUMB.RSA(UNNUMBERED RSA)-265",
        "UNNUMB.RT(UNNUMBERED RT)-266",
        "UNNUMB.SA(UNNUMBERED SA)-267",
        "UNNUMB.SCA(UNNUMBERED SCA)-268",
        "UNNUMB.SCLP(UNNUMBERED SUPREME COURT LEAVE PETITION)-269",
        "UNNUMB.SMR(UNNUMBERED SMR)-270",
        "UNNUMB.SPJC.(UNNUMBERED SPJC)-271",
        "UNNUMB.ST.App.(UNNUMBERED STA)-272",
        "UNNUMB.ST.Ref.(UNNUMBERED STRF)-273",
        "UNNUMB.ST.Rev.(UNNUMBERED STRV)-274",
        "UNNUMB.TAC(UNNUMBERED TAC)-275",
        "UNNUMB.TDB(UNNUMBERED TDB)-278",
        "UNNUMB.Test Cas.(UNNUMBERED TC)-277",
        "UNNUMB.Tr.Appl.(CR)(UNNUMBERED TRANSFER APPEAL CRIMINAL)-276",
        "UNNUMB.TRC(UNNUMBERED TRC)-281",
        "UNNUMB.TP(C)(UNNUMBERED TPC)-279",
        "UNNUMB.Tr.P(Crl.)(UNNUMBERED TRANSFER PETITION (CRIMINAL))-280",
        "UNNUMB.UNNUMB.Co.App(UNNUMBERED UNNUMB.COMPANY APPLICATION)-288",
        "UNNUMB.WA(UNNUMBERED WA)-282",
        "UNNUMB.WAKF(UNNUMBERED OPWT)-251",
        "UNNUMB.WP(AFT)(UNNUMB.WP(ARMED FORCES TRIBUNAL-Art.226))-283",
        "UNNUMB.WP(C)(UNNUMBERED WRIT PETITION (CIVIL))-284",
        "UNNUMB.WP(Crl.)(UNNUMBERED WRIT PETITION (CRIMINAL))-285",
        "UNNUMB.WTA(UNNUMBERED WTA)-286",
        "UNNUMB.WTR(UNNUMBERED WTR)-287",
        "WA(WRIT APPEAL)-154",
        "W.C.C.Ref.(WORKMENS COMPENSATION REFERENCE)-155",
        "WP(AFT)(WP (ARMED FORCES TRIBUNAL - Art.226))-156",
        "WP(C)(WRIT PETITION (CIVIL))-157",
        "WP(Crl.)(WRIT PETITION (CRIMINAL))-158",
        "WTA(WEALTH TAX APPEAL)-159",
        "WTR(WEALTH TAX REFERENCE)-160",
        "ZCOAA(UNN.COMPANY ADJ.APPEAL)-182",
        "ZCRP(LR)(UNN.CRP (LAND REFORMS ACT))-192",
        "ZCRP(WK)(UNNUMB.CRP(WAKF))-194",
        "ZOP(FT)(UNNUMBERD OP (FOREST TRIBUNAL))-243",
        "ZOP(KAT)(UNNUMB.OP KERALA ADMINISTRATIVE TRIBUNAL)-245",
        "ZOP STAT(ZOP STAT)-249",
        "ZRFA(MISC.)(UN.NU. REGULAR FIRST APPEAL (MISCELLANEO)-260",
      ],
    },
    "High Court of Madhya Pradesh": {
      "High Court Of Madhya Pradesh Gwalior": [
        "AA(Arbitration Appeal)-63",
        "AC(Arbitration Case)-64",
        "AR(Arbitration Revision)-65",
        "ARBA(arbitration appeal)-61",
        "ARBC(arbitration cases)-60",
        "CEA(Central Excise Appeal)-74",
        "CER(central excise ref.)-27",
        "CESR(central excise sales)-30",
        "COMA(Company Appeal)-69",
        "COMP(company petition)-21",
        "COMPA(company appeals)-31",
        "CONA(Contempt Appeal)-66",
        "CONC(Contempt Petition Civil)-67",
        "CONCR(Contempt Petition Criminal)-73",
        "CONT(contempt. petition)-15",
        "CONTR(Criminal Contempt)-54",
        "CR(civil revision)-19",
        "CRA(criminal appeal)-51",
        "CRR(criminal revision)-53",
        "CRRE(criminal reference)-55",
        "CRRF(Criminal Reference)-71",
        "CRRFC(Criminal Reference Capital)-72",
        "CS(civil suit)-34",
        "EP(election petition)-20",
        "FA(first appeal)-13",
        "FEMA(Foriegn Exchange Management Appeal)-75",
        "ITA(income tax appeal)-26",
        "ITR(income-tax reference)-22",
        "LPA(letter patent appeal)-17",
        "MA(misc. appeal)-12",
        "MACE(misc. appeal (c. excise))-35",
        "MACOM(Misc.Appeal(Company))-59",
        "MACTR(Commercial Tax Ref.)-62",
        "MAIT(misc. appeal (i.t.))-28",
        "MAVAT(Misc. Appeal(VAT))-58",
        "MCC(misc. civil cases)-18",
        "MCOMA(Misc. Company Appeal)-70",
        "MCP(misc. civil petition)-24",
        "MCRC(misc. criminal case)-52",
        "MCRP(misc. cri. petition)-56",
        "MP(misc. petitions)-16",
        "MSA(Miscellaneous Second Appeal)-90",
        "MWP(misc. writ petition)-25",
        "OA(Original Application)-80",
        "OTA(OTHER TAX APPEALS)-79",
        "RP(Review Petition)-68",
        "SA(second appeal)-14",
        "STR(sales tax reference)-29",
        "TA(Transfer Application)-81",
        "TR(Tax Reference)-76",
        "VATA(Value Added Tax Appeal)-77",
        "WA(Writ Appeal)-57",
        "WP(writ petition)-11",
        "WPS(writ pet. (service))-32",
        "WTA(Wealth Tax Appeal)-78",
        "WTR(wealth-tax reference)-23",
      ],
      "High Court Of Madhya Pradesh Indore": [
        "AA(Arbitration Appeal)-63",
        "AC(Arbitration Case)-64",
        "AR(Arbitration Revision)-65",
        "ARBA(arbitration appeal)-61",
        "ARBC(arbitration cases)-60",
        "CEA(Central Excise Appeal)-74",
        "CER(central excise ref.)-27",
        "CESR(central excise sales)-30",
        "COMA(Company Appeal)-69",
        "COMP(company petition)-21",
        "COMPA(company appeals)-31",
        "CONA(Contempt Appeal)-66",
        "CONC(Contempt Petition Civil)-67",
        "CONCR(Contempt Petition Criminal)-73",
        "CONT(contempt. petition)-15",
        "CONTR(Criminal Contempt)-54",
        "CR(civil revision)-19",
        "CRA(criminal appeal)-51",
        "CRR(criminal revision)-53",
        "CRRE(criminal reference)-55",
        "CRRF(Criminal Reference)-71",
        "CRRFC(Criminal Reference Capital)-72",
        "CS(civil suit)-34",
        "EP(election petition)-20",
        "FA(first appeal)-13",
        "FEMA(Foriegn Exchange Management Appeal)-75",
        "ITA(income tax appeal)-26",
        "ITR(income-tax reference)-22",
        "LPA(letter patent appeal)-17",
        "MA(misc. appeal)-12",
        "MACE(misc. appeal (c. excise))-35",
        "MACOM(Misc.Appeal(Company))-59",
        "MACTR(Commercial Tax Ref.)-62",
        "MAIT(misc. appeal (i.t.))-28",
        "MAVAT(Misc. Appeal(VAT))-58",
        "MCC(misc. civil cases)-18",
        "MCOMA(Misc. Company Appeal)-70",
        "MCP(misc. civil petition)-24",
        "MCRC(misc. criminal case)-52",
        "MCRP(misc. cri. petition)-56",
        "MP(misc. petitions)-16",
        "MSA(Miscellaneous Second Appeal)-90",
        "MWP(misc. writ petition)-25",
        "OA(Original Application)-80",
        "OTA(OTHER TAX APPEALS)-79",
        "RP(Review Petition)-68",
        "SA(second appeal)-14",
        "STR(sales tax reference)-29",
        "TA(Transfer Application)-81",
        "TR(Tax Reference)-76",
        "VATA(Value Added Tax Appeal)-77",
        "WA(Writ Appeal)-57",
        "WP(writ petition)-11",
        "WPS(writ pet. (service))-32",
        "WTA(Wealth Tax Appeal)-78",
        "WTR(wealth-tax reference)-23",
      ],
      "High Court Of Madhya Pradesh Jabalpur": [
        "AA(Arbitration Appeal)-63",
        "AC(Arbitration Case)-64",
        "AR(Arbitration Revision)-65",
        "ARBA(arbitration appeal)-61",
        "ARBC(arbitration cases)-60",
        "CEA(Central Excise Appeal)-74",
        "CER(central excise ref.)-27",
        "CESR(central excise sales)-30",
        "COMA(Company Appeal)-69",
        "COMP(company petition)-21",
        "COMPA(company appeals)-31",
        "CONA(Contempt Appeal)-66",
        "CONC(Contempt Petition Civil)-67",
        "CONCR(Contempt Petition Criminal)-73",
        "CONT(contempt. petition)-15",
        "CONTR(Criminal Contempt)-54",
        "CR(civil revision)-19",
        "CRA(criminal appeal)-51",
        "CRR(criminal revision)-53",
        "CRRE(criminal reference)-55",
        "CRRF(Criminal Reference)-71",
        "CRRFC(Criminal Reference Capital)-72",
        "CS(civil suit)-34",
        "EP(election petition)-20",
        "FA(first appeal)-13",
        "FEMA(Foriegn Exchange Management Appeal)-75",
        "ITA(income tax appeal)-26",
        "ITR(income-tax reference)-22",
        "LPA(letter patent appeal)-17",
        "MA(misc. appeal)-12",
        "MACE(misc. appeal (c. excise))-35",
        "MACOM(Misc.Appeal(Company))-59",
        "MACTR(Commercial Tax Ref.)-62",
        "MAIT(misc. appeal (i.t.))-28",
        "MAVAT(Misc. Appeal(VAT))-58",
        "MCC(misc. civil cases)-18",
        "MCOMA(Misc. Company Appeal)-70",
        "MCP(misc. civil petition)-24",
        "MCRC(misc. criminal case)-52",
        "MCRP(misc. cri. petition)-56",
        "MP(misc. petitions)-16",
        "MSA(Miscellaneous Second Appeal)-90",
        "MWP(misc. writ petition)-25",
        "OA(Original Application)-80",
        "OTA(OTHER TAX APPEALS)-79",
        "RP(Review Petition)-68",
        "SA(second appeal)-14",
        "STR(sales tax reference)-29",
        "TA(Transfer Application)-81",
        "TR(Tax Reference)-76",
        "VATA(Value Added Tax Appeal)-77",
        "WA(Writ Appeal)-57",
        "WP(writ petition)-11",
        "WPS(writ pet. (service))-32",
        "WTA(Wealth Tax Appeal)-78",
        "WTR(wealth-tax reference)-23",
      ],
    },

    "High Court of Meghalaya": {
      "High Court of Meghalaya": [
        "AB(Anticipatory Bail)",
        "Adml.S.(Admiralty Suits)",
        "AIR(REF)(Air Ref)",
        "Arb.A.(Appeals Under Indian Arbitration Act.)",
        "Arb.P.(Petition Under Indian Arbitration Act.)",
        "BA(Bail Application)",
        "Bkg.p.(Proceedings Under Banking Regulation Act.)",
        "CA(Appeal Under The Companies Act 1956)",
        "CAPL(Contempt Appeal.)",
        "C.APPLN(Company Applications)",
        "CAVT(Caveat)",
        "CC(CIV)[SH](Contempt Of Court  Civil)",
        "CentralExciseAp(Central Excise Appeal)",
        "CE.Ref.(Reference Under Central Excise Act.)",
        "CEREF(Central Excise Reference)",
        "CMAppl.(Miscelleneous Applications)",
        "CO(Cross Objection In First Appeal.)",
        "Co.App.(Appeals Against Judgments Orders In Company Petitions.)",
        "Co.Appl.(Application In Pending Proceeding.)",
        "COB(FA)[SH](Cross Objection in First Appeal)",
        "COB(MFA)[SH](Cross Objection in Misc. First Appeal)",
        "COB(T)(Cross Objection Tender)",
        "Co.Case(Matters Transfered Under Section 446 3)",
        "COJO(Correction Of Judgement Order)",
        "COMAPL(Company Appeal)",
        "COMPET(Company Petition)",
        "CONTAPP(Contempt Appeal)",
        "Cont.App.(C)(Appeals Against Orders In Civil Contempt Matters.)",
        "Cont.App.(Crl.)(Appeals Against Orders In Criminal Contempt Matters)",
        "Cont.Cas(C)(Contempt Of Court Cases Relating To Civil Contempt.)",
        "Cont.Cas.(Crl.)(Proceedings Relating To Criminal Contempt.)",
        "Co.Pet.(Original Petition)",
        "CP(Condonation Petition)",
        "C.PTN(Company Petitions)",
        "CR(Civil Rule)",
        "CRAPL(Criminal Appeal)",
        "CRAPPL(Cross Appeal)",
        "CR(CC)(Criminal Contempt Of Court)",
        "CRDREF(Criminal Death Reference)",
        "C.Ref.(Reference.)",
        "CREF(Civil Refrence)",
        "CREV(Civil Revision)",
        "CREV(H)(Civil Revision Hills)",
        "C.REVN(Civil Revision.)",
        "CR(HC)[SH](Civil Rule Habeas Corpus)",
        "Crl.A.(Appeal Against Judgment Sentence)",
        "CR(LAB)(Civil Rule Labour)",
        "CRL.A(H)(Criminal Appeals Hills)",
        "CRL.A(J)(Criminal Appeals Jail)",
        "CR(LAN)(Civil Rule Land)",
        "Crl.L.P.(Application For Leave To File Appeal)",
        "Crl.M.Appl.(Crl.m.appl.)",
        "Crl.M.C.(Crl.m.c)",
        "CRL.O.APPN(Crl.o.appn)",
        "Crl.OP(C)(Contempt Petition Suo Motu)",
        "CRL.OP(C)[SH](Fff)",
        "CRL.O.REF(Crl.o.ref)",
        "Crl.Petn.(Criminal Petition)",
        "Crl.Ref.(Reference)",
        "CRL.REF(H)(Criminal  Reference Hill)",
        "CRL.REVN(H)(Criminal Revision Hill)",
        "Crl.Rev.P.(Revision)",
        "Crl.Tr.(Original Trial)",
        "CR(M)(Cr M)",
        "CR(MC)(Criminal Misc. Case)",
        "CROA(Criminal Original Application)",
        "CROA(C)(Criminal Original Application Contempt)",
        "CROP(Criminal Original Petition)",
        "CROP(C)(Criminal Original Petition Contempt)",
        "CROSSAPP(Cross Appl)",
        "CR(OTH)(Civil Rule Other)",
        "CRP(Revision Petition)",
        "CRREF(Criminal Reference)",
        "CRREVAP(Criminal Review Application)",
        "CR(SER)(Civil Rule Service)",
        "CS(Civil Suit)",
        "CS(OS)(Civil Suits)",
        "Cus.Appl(Custom Appeal)",
        "Cus.Ref.(Reference Under Customs Act.)",
        "DS(Divorce Suit)",
        "D S Ref.(Confirmation Case Under Section 336 Cr.p.c.)",
        "EC[SH](Deef)",
        "EDREF(Estate Duty Reference)",
        "EDREF[SH](Estate Duty Reference)",
        "El.App.(Appeals From Judgments In Election Petition)",
        "El.Pet.(Election Petitions)",
        "Ex.Appl.(Execution Application)",
        "Ex.FA.(Execution First Appeal)",
        "Ex.P.(Execution Petition)",
        "Ex.SA.(Execution Second Appeal)",
        "f(Company Applications.)",
        "FA(First Appeal)",
        "FA(D)(First Appeal Divorce)",
        "FA/MA(F)(Fa Ma F)",
        "FAO(First Appeal From Orders)",
        "FA(OS)(First Appeal For Judgments In Original Suits.)",
        "FA/SA(Fa Or Sa)",
        "GCA[SH](Government Criminal Appeal)",
        "GTA/WTA/EDA(Application For Direction To Make A Reference)",
        "GT(REF)(.)",
        "GTREF[SH](Gift Tax Reference)",
        "GTR/WTR/EDR(Reference To High Court)",
        "Gua.P(Petitions Under Gurdianship And Wards Act.)",
        "IA(Interlocutory Applications In Pending Suits Appeals)",
        "Insurance App.(Appeals Under Insurance Act.)",
        "Insurance Ref.(Reference Under Insurance Act.)",
        "Intest.Cas.(Intestate.cas.)",
        "IP(Appl.)(Application Submitted After Adjudication)",
        "IP(M)(Main Petition)",
        "ITA(Application Under Section 256 2)",
        "ITREF(Income Tax Reference)",
        "IT(REF)(..)",
        "ITREF(A)(Agri It Reference)",
        "ITR[SH](Reference under Section 256(1).)",
        "LA.App.(Appeals)",
        "LA.Ref.(Land Reference)",
        "LPA(Latters Patent Appeal)",
        "LPA/Sp.A(Letters Patent Appeal Or Special Appeal)",
        "LPA(T)(Leave Pattern Appeal Tender)",
        "M(Company Petitions.)",
        "MAC(Motor Accident Claims)",
        "MACApp.(Macapp.)",
        "MAF(Miscellaneous Appeal First)",
        "MA(F)[SH](Try)",
        "MAF(T)(Miscellaneous Appeal First Tender)",
        "MA(S)(Misc. Appeal  Second)",
        "MAS(T)(Misc. Appeal Second Tender)",
        "Mat.App.(Appeals.)",
        "Mat.Cas.(Suits Petitions)",
        "Mat.Ref.(References)",
        "MC(AB)(Misc Case In Anticipatory Bail)",
        "MC(Arb.A.)(Miscellaneous Case In Arbitration Appeal)",
        "MC(ARB.P)(Misc Case In Arb.p)",
        "MC(BA)(Miscellaneous Case In Bail Application)",
        "MC(CA)(Misc Case In Company Appeal)",
        "MC(C.APPLN)(Misc Case In Company Applications)",
        "MC (CentralExciseAp)(Misc case in Central Exicise Appeal)",
        "MC(Cont. App)(Misc. Case Contempt Appeal)",
        "MC(Cont(C))(Misc. Case In Cont. Case C)",
        "MC CONT(CRL)(Misc Case In Criminal Contempt)",
        "MC(C.PTN)(Misc Case In Company Petition)",
        "MC(CR)(Misc. Case  In Civil Rule)",
        "MC(CRAPPL)(Misc. Case In Cross Appeal)",
        "MC(Crl.A)(Misc. Case In Criminal Appeal)",
        "MC(Crl.L.P.)(Misc. Case For Leave To Appeal Under Section 378 Cr.p.c Or Under The)",
        "MC(Crl.M.Appl.)(Misc. Case In Other Misc. Application)",
        "MCCrl.O.P(C)(Misc Case Criminal Contempt Petition Suo Motu)",
        "MC(Crl.Rev.P)(Misc. Case In Criminal Revision)",
        "MC(CRP)(Misc. Case In Civil Revision Petition)",
        "MC(Cus.Appl)(Misc. Case In Custom Appeal)",
        "MC(EP)(Misc. Case In Election Petition)",
        "MC (F)(Miscellaneous Case First)",
        "MC(FA)(Misc Case In First Appeal)",
        "MC(FAO)(Misc. Case In First Appeal From Order)",
        "MC IN CRL PTN(Misc Case In Criminal Petition)",
        "MC(Intest)(Miscellaneous Cases Filed In Intestate Case)",
        "MC(ITA)(Income Tax Appeal Misc Case)",
        "MC(MAC)(Misc Case In Motor Accident Claims)",
        "MC(MACApp)(Misc. Case In Motor Accidents Appeal)",
        "MC(MAF)(Misc. Case In Maf)",
        "MC(PIL)(Misc. Case In Public  Interest Litigation)",
        "MC(RA)(Misc. Case  In R.a.)",
        "MC(Review Pet.)(Misc Case In Review Petition)",
        "MC(RFA)(Misc Case In First Appeal From Judgment And Decree In Suit)",
        "MC(RSA)(Misc. Case In Second Appeal From Judgment And Decree)",
        "MC(SA)(Misc. Case In Second Appeal)",
        "MC(Test. Cas.)(Misc. Test Case)",
        "MCTr.P.(C)(Misc. Case In Transfer Petition Under Section 24 C.p.c)",
        "MC (Tr.P.Crl)(Misc. Case in Transfer Petition for transfer a Criminal Proceeding)",
        "MC(WA)(Misc Case In Writ Appeal)",
        "MC(WPC)(Miscellaneous Case In Writ Petition Civil)",
        "MC(WPC)HC(Mc Wpc Habeas Corpus)",
        "MC(W.P.Crl)(Mc In Wp.crl)",
        "MFA(Mfa)",
        "MSA(Msa)",
        "M.S.(S.J.C.S.)(Miscellaneous Summons E.g. Summons For Judgments Chamber Summons Etc.)",
        "N.M(Notice Of Motion)",
        "OC(Original Case)",
        "OJ SH(Original Jurisdiction)",
        "OTA(Other Tax Application.)",
        "OT.Appl(Other Tax Application)",
        "OTC(Other Tax Cases)",
        "OTR(Other Tax Reference Cases)",
        "PIL(Public Interest Litigation)",
        "RA(Review Application)",
        "RA(CR)(Review Application In Civil Rule)",
        "RA(FA)(Review Application In First Appeal)",
        "RA(MFA)(Review Application In Misc. First Appeal)",
        "RAT(Review Application Tender)",
        "RCC(Original Suit Petition)",
        "RCFA(First Appeal.)",
        "RCSA(Second Appeal)",
        "RECRev.(Revision.)",
        "REP(Recrimination Case In Election Petition)",
        "Review Pet(Review Petition)",
        "RFA(First Appeal Fron Judgment And Decree In Suit)",
        "RLPA(Review In Latters Patent Appeal)",
        "RP(FAM.CT.)(Revisions Under Section 19 Of The Family Courts Act.)",
        "RPT(Review Petition Tender)",
        "RSA(Second Appeal From Judgment And Decree)",
        "SA(Second Appeal.)",
        "SAO(Appeal From Appellate Order)",
        "SA(T)(Second Appeal Tender)",
        "SCA(Supreme Court Appeal)",
        "SCLP(Petition For Leave To Appeal To Supreme Court)",
        "SLP(Special Leave Petition)",
        "SP.JC(Special Jurisdiction Cases Assigned To High Courts)",
        "ST.Appl.(Application For Direction To Make A Reference)",
        "ST.Ref.(St.ref.)",
        "STREF(Sales Tax Refrence)",
        "ST.Rev.(St.rev.)",
        "Test.Cas.(Testamentary Cases)",
        "Tr.P.(C).(Transfer Petition Under Section 24 C.p.c.)",
        "Tr.P.(Crl.)(Transfer Petition For Transfer A Criminal Proceeding.)",
        "TS(Testamentory Suit)",
        "WA(Appeal Before Division Bench Against Judgment Or Order Of Single Judge)",
        "WA(LAB)(Writ Appeal Labour)",
        "WA(LAN)(Writ Appeal Land)",
        "WA(OTH)(Writ Appeal Others)",
        "WA(SER)(Writ Appeal Service)",
        "WA(T)(Writ Appeal Tender)",
        "WP(C)(Writ Petition Under Article 226 And 227 Of The Constitution)",
        "WPCHabeasCorpus(Wpc Habeas Corpus)",
        "W.P.(Crl.)(Writ And Habeas Corpus And Other Relief In Relation To A Criminal Pro)",
        "WTREF(Wealth Tax Refrence)",
        "WT(REF)(Wt Ref)",
      ],
    },

    "Patna High Court": {
      "Principlal Bench Patna": [
        "ADM. SUIT(ADMIRALITY SUIT)",
        "C.Misc.(CIVIL MISCELLANEOUS JURISDICTION)",
        "COMMERCIAL APP(COMMERCIAL APPEAL)",
        "OMPAN. APP(Compensation Appeal)",
        "COMP. APP(COMPANY APPEAL)",
        "OMP. APP.(DB)(COMPANY APPEAL(DB))",
        "COMP. APPLIC(COMPANY APPLICATION)",
        "COMP. APP. (SJ)(COMPANY APPEAL (SJ))",
        "COMP PET(COMPANY PETITION)",
        "C.R.(CIVIL REVISION)",
        "CR. APP(341(CR. APP. 341 CR. P.C.)",
        "CR. APP (DB)(CRIMINAL APPEAL (DB))",
        "CR. APP (SJ)(CRIMINAL APPEAL (SJ))",
        "CR.APP(U/S)(CRIMINAL APPEAL (U/S))",
        "C.REF.(CIVIL REFERENCE)",
        "C. REV.(CIVIL REVIEW)",
        "CR. MISC.(CRIMINAL MISCELLANEOUS)",
        "CR. REF.(CRIMINAL REFERENCE)",
        "CR. REV.(CRIMINAL REVISION)",
        "CRT. APPEAL(CERTIFICATE APPEAL)",
        "CR. WJC(Criminal Writ Jurisdiction Case)",
        "CWJC(Civil Writ Jurisdiction Case)",
        "D. REF.(DEATH REFERENCE)",
        "E.P.(ELECTION PETITION)",
        "FA(FIRST APPEAL)",
        "G. APP. (DB)(GOVT. APPEAL (DB))",
        "G.APP.(SJ)(GOVT. APPEAL (SJ))",
        "L.P.A(Letters Patent Appeal)",
        "MA(Miscellaneous Appeal)",
        "MAT. REF.(MATRIMONIAL REF.)",
        "MAT. SUIT(MATRIMONIAL SUIT)",
        "MJC(Miscellaneous Jurisdiction Case)",
        "MONEY SUIT(MONEY SUIT)",
        "OCR.MISC(DB)(Or. Criminal Miscellaneous (DB))",
        "OR. CR. MISC(Or. Criminal Miscellaneous)",
        "PATENT CASE(PATENT CASE)",
        "REQ. CASE(REQUEST CASE)",
        "SA(SECOND APPEAL)",
        "SCA(SUPREME COURT APPEAL)",
        "SLA(SPECIAL LEAVE APPLICATION)",
        "SLP(SPECIAL LEAVE PETITION)",
        "TAX(TAX CASES)",
        "TEST CASE(TEST CASE)",
        "TEST SUIT(TEST SUIT)",
        "T.S.(TITLE SUIT)",
      ],
    },

    "High Court of Orissa": {
      "High Court of Orissa": [
        "ABLAPL (ABLAPL- Anticipatory Bail) - 130",
        "ADMLS (ADMLS-Admirality Suits) - 1",
        "AHO (AHO-Appeal Against High Court Order) - 2",
        "ARBA (ARBA-Appeals under Arbitration Conciliation Act) - 3",
        "ARBA(ICA) (ARBA(ICA)-Appeals preferred US37 of the Act involving International Commercial Arbitration) - 154",
        "Arbitration Appeal (Arbitration Appeal) - 160",
        "ARBP (ARBP-Petitions under Arbitration and Conciliation Act) - 4",
        "ARBP(ICA) (ARBP(ICA)-Application Us 34 of the Act involving International Commercial Arbitration) - 153",
        "AS (AS-Admirality Suit) - 5",
        "BKGP (BKGP-Proceedings under Banking Regulation Act) - 6",
        "BLAPL (BLAPL-Bail Application) - 7",
        "CEREF (CEREF-Reference under Central Excise Act) - 8",
        "CMAPL (CMAPL-Miscellaneous Applications) - 9",
        "CMP (CMP-Civil Miscellaneous Petition) - 10",
        "CMPA (CMPA-Company Appeal) - 11",
        "CMPAT (CMPAT-Company Act) - 12",
        "CMPMC (CMPMC-Company Misc. Case) - 13",
        "CO (CO-Cross Objections in First Appeal) - 14",
        "COA (COA-Appeals against judgement in Comp. Pet) - 15",
        "COAPL (COAPL-Appl. in pending Proceeding (Comp. Act.)) - 16",
        "COCAS (COCAS-Matters transferred US 446(3)(Comp. Act.)) - 17",
        "CONTAC (CONTAC-Appeals against orders in Civil Contempt) - 18",
        "CONTAR (CONTAR-Appeals against orders in Crl. Cont. matter) - 19",
        "CONTC (CONTC-Cotempt of Court Cases (Civil contempt)) - 20",
        "CONTC(CP) (CONTC(CP)-Contempt Petition in Bhubaneswar Bench) - 144",
        "CONTC(CPB) (CONTC(CPB)-Contempt Petition in Berhampur Bench) - 146",
        "CONTC(CPC) (CONTC(CPC)-Contempt Petition in Cuttack Bench) - 145",
        "CONTC(CPS) (CONTC(CPS)-Contempt Petition in Sambalpur Bench) - 147",
        "CONTR (CONTR-Proceeding relating to Criminal Contempt) - 21",
        "COPET (COPET-Original Petition (Companies Act)) - 22",
        "CR (Civil Revision) - 131",
        "CRA (CRA-Criminal Appeals) - 23",
        "CRLA (CRLA-Appeal against Judgement/Sentence) - 24",
        "CRLLP (CRLLP-Appl. for leave to appeal US 378 Cr.P.C) - 25",
        "CRLMA (CRLMA-Other Miscellaneous Application) - 26",
        "CRLMC (CRLMC-Appl. under Sec.482 Cr.P.C) - 27",
        "CRLMP (CRLMP-Criminal Miscellaneous Petition) - 28",
        "CRLREF (CRLREF-Criminal Reference) - 29",
        "CRLREV (CRLREV-Criminal Revision) - 30",
        "CRLTR (CRLTR-Original Trial) - 31",
        "CRMC (CRMC-Criminal Miscellaneous Case) - 32",
        "CRP (CRP-Revision Petition) - 33",
        "CRREF (CRREF-Criminal Reference) - 34",
        "CRREV (CRREV-Criminal Revision) - 35",
        "CS (CS-Civil Suit) - 36",
        "CS(OS) (CS(OS)-Civil suits) - 37",
        "CUSREF (CUSREF-Reference under Customs Act) - 38",
        "CVA (CVA-Civil Appeals) - 39",
        "CVREF (CVREF-Civil Reference) - 40",
        "CVREV (CVREV-Civil Revision) - 41",
        "CVRVW (CVRVW-Civil Review) - 42",
        "DREF (DREF-Death Reference) - 43",
        "DSREF (DSREF-Confirmation under Sec.336 Cr.P.C) - 44",
        "EC (EC-Execution Case) - 45",
        "EDA (EDA-Appl. for reference under Estate Duty Act) - 46",
        "EDR (EDR-Reference to High Court (Estate Tax Act)) - 47",
        "ELA (ELA-Appeals from Judgement in Election Pet) - 48",
        "ELPET (ELPET-Election Petitions) - 49",
        "EP (EP-Election Petition Case) - 50",
        "EXFA (EXFA-Execution First Appeal) - 51",
        "EXOS (EXOS-Execution Application) - 52",
        "EXP (EXP-Execution Petition) - 53",
        "EXSA (EXSA-Execution Second Appeal) - 54",
        "FA (FA-First Appeal) - 55",
        "FAO (FAO-First Appeal from Orders) - 57",
        "FA(OS) (FA(OS)-FA from Judgement in original suits) - 56",
        "GA (GA-Government Appeals) - 58",
        "GCRLA (GCRLA-Government Appeals) - 59",
        "GTA (GTA-Appl. for reference under Gift Tax Act) - 60",
        "GTR (GTR-Reference to High Court (Gift Tax Act)) - 61",
        "GUAP (GUAP-Pet. under Guardianship and Wards Act) - 62",
        "IA (IA-Interlocutory Appl. in suits/appeals) - 63",
        "INSA (INSA-Appeals under Insurance Act) - 64",
        "INSREF (INSREF-Reference under Insurance Act) - 65",
        "INTEST (INTEST-Intestate cases) - 66",
        "IPAPL (IPAPL-Appls. submitted after adjudication) - 68",
        "IP(M) (IP(M)-Main Petition (Insolvency Act)) - 67",
        "ITA (ITA-Appeals Income-tax) - 69",
        "ITR (ITR-Reference under Income-tax Act) - 70",
        "JCRA (JCRA-Jail Criminal Appeals) - 71",
        "JCRLA (JCRLA-Jail Criminal Appeal) - 72",
        "JCRLMC (JCRLMC-Jail Criminal Misc Case) - 73",
        "JCRLRV (JCRLRV-Jail Criminal Revision) - 74",
        "JCRMC (JCRMC-Jail Criminal Misc Case) - 75",
        "JCRREV (JCRREV-Jail Criminal Revision) - 76",
        "LAA (LAA-Appeals (Land Acquisition Act)) - 77",
        "LAREF (LAREF-References (Land Acquisition Act)) - 78",
        "LPA (LPA-Letters Patent Appeals) - 79",
        "MA (MA-Miscellaneous Appeals) - 80",
        "MAC (MAC-Motor Accident Claims) - 81",
        "MACA (MACA-Motor Accident Appeals) - 82",
        "MATA (MATA-Matrimonial Cases (Appeals)) - 83",
        "MATCAS (MATCAS-Matrimonial Case (Suits/Petitions)) - 84",
        "MATREF (MATREF-Matrimonial Case (References)) - 85",
        "MFA (MFA-FA against Spl. Jurisdiction Cases) - 86",
        "MJC (MJC-Misc. Judicial Case) - 87",
        "MREF (MREF-Matrimonial Reference) - 88",
        "MSA (MSA-SA from judgements in Misc. Cases) - 89",
        "MSREF (MSREF-Misc. summons/summons for Judgment) - 90",
        "NM (NM-Notice of Motion) - 91",
        "OCRMC (OCRMC-Original Crl. Miscellaneous Case) - 92",
        "OJC (OJC-Original Jurisdiction case (Writ)) - 93",
        "OREF (OREF-References) - 94",
        "OS (OS-Original Suit) - 95",
        "OTAPL (OTAPL-Other Tax Applications) - 96",
        "OTC (OTC-Other Tax Case) - 97",
        "OTR (OTR-Other Tax References Cases) - 98",
        "OVTA (OVTA-Tax Appeal Under OVAT Act) - 99",
        "RCC (RCC-Original Suits/Pet. (Rent Control Matters)) - 100",
        "RCFA (RCFA-First Appeals (Rent Control Matters)) - 101",
        "RCREV (RCREV-Revision (Rent Control Matters)) - 102",
        "RCSA (RCSA-Second Appeals (Rent Control Matters)) - 103",
        "RFA (RFA-FA from Judgement and Decree in Suit) - 104",
        "RMC (RMC) - 152",
        "RPFAM (RPFAM-Revision US 19 of Family Court Act) - 105",
        "RSA (RSA-Second Appeal from Judgement and Decree) - 106",
        "RVWPET (RVWPET-Review Petition) - 107",
        "RVWPET(RP) (RVWPET(RP)-Review Petition in Bhubaneswar Bench) - 148",
        "RVWPET(RPB) (RVWPET(RPB)-Review Petition in Berhampur Bench) - 150",
        "RVWPET(RPC) (RVWPET(RPC)-Review Petition in Cuttack Bench) - 149",
        "RVWPET(RPS) (RVWPET(RPS)-Review Petition in Sambalpur Bench) - 151",
        "SA (SA-Second Appeal) - 108",
        "SAO (SAO-Second Appeal from Appellate Order) - 109",
        "SCA (SCA-Supreme Court Appeal) - 110",
        "SCLP (SCLP-Pet. for leave to appeal to Supreme Court) - 111",
        "SJC (SJC-Special Jurisdiction Case (Tax Matters)) - 112",
        "SM (SM-Suo Motu) - 113",
        "SPA (SPA-Sp.Appeal before DB) - 114",
        "SPJC (SPJC-Special Jurisdiction Cases) - 115",
        "STAPL (STAPL-Appl. for direction to make a reference) - 116",
        "STREF (STREF-Sales Tax reference) - 117",
        "STREV (STREV-Sales Tax Revision) - 118",
        "TA (TA-Tax Appeal) - 119",
        "TEST (TEST-Testamentary Cases) - 120",
        "TMC (TMC-Testamentary Misc. Case) - 121",
        "TREV (TREV-Tax Revision) - 122",
        "TRP(C) (TRP(C)-Transfer Petition under Sec.24 CPC) - 123",
        "TRPCRL (TRPCRL-Transfer Petition for transfer of Crl. Proceeding) - 124",
        "WA (WA-Appeals before DB against SB Judgement) - 125",
        "WP(C) (WP(C)-Writ Petition under Art.226 and 227) - 126",
        "WPC(OA) (WPC(OA)-Original Application in Bhubaneswar Bench) - 132",
        "WPC(OAB) (WPC(OAB)-Original Application in Berhampur Bench) - 134",
        "WPC(OAC) (WPC(OAC)-Original Application in Cuttack Bench) - 133",
        "WPC(OAP) (WPC(OAP)-Original Application (Provisional) Bhubaneswar Bench) - 136",
        "WPC(OAPB) (WPC(OAPB)-Original Application (Provisional) in Berhampur Bench) - 138",
        "WPC(OAPC) (WPC(OAPC)-Original Application (Provisional) in Cuttack Bench) - 137",
        "WPC(OAPP) (WPC(OAPP)-Original Application Permission Petition in Bhubaneswar) - 155",
        "WPC(OAPPB) (WPC(OAPPB)-Original Application Permission Petition in Berhampur) - 157",
        "WPC(OAPPC) (WPC(OAPPC)-Original Application Permission Petition in Cuttack) - 156",
        "WPC(OAPPS) (WPC(OAPPS)-Original Application Permission Petition in Sambalpur) - 158",
        "WPC(OAPS) (WPC(OAPS)-Original Application (Provisional) in Sambalpur Bench) - 139",
        "WPC(OAS) (WPC(OAS)-Original Application in Sambalpur Bench) - 135",
        "WPCRL (WPCRL-Pet.UA.226 for Writ of Habeas Corpus) - 127",
        "WPC(T) (WPC(T)-Temporary Number in Bhubaneswar) - 159",
        "WPC(TA) (WPC(TA)-Transfer Application in Bhubaneswar Bench) - 140",
        "WPC(TAB) (WPC(TAB)-Transfer Application in Berhampur Bench) - 142",
        "WPC(TAC) (WPC(TAC)-Transfer Application in Cuttack Bench) - 141",
        "WPC(TAS) (WPC(TAS)-Transfer Application in Sambalpur Bench) - 143",
        "WTA (WTA-Appl. for reference under Wealth Tax Act) - 128",
        "WTR (WTR-Reference to High Court (Wealth Tax Act)) - 129",
      ],
    },

    "High Court of Rajasthan": {
      "High Court Bench at Jaipur": [
        "APPLC (MISC APPLICATION (CIVIL)) - 1",
        "APPLR (MISC APPLICATION (CRIMINAL)) - 2",
        "APPLW (MISC APPLICATION (WRIT)) - 3",
        "ARBAP (ARBITRATION APPLICATION) - 4",
        "ARBS (ARBITRATION SUIT) - 5",
        "CAVTC (CAVEAT (CIVIL)) - 6",
        "CAVTW (CAVEAT (WRIT)) - 7",
        "CCP (CIVIL CONTEMPT PETITION) - 8",
        "CFA (CIVIL FIRST APPEAL) - 9",
        "CLA (CIVIL LEAVE TO APPEAL) - 10",
        "CMA (CIVIL MISCELLANEOUS APPEAL) - 11",
        "CMAP (CIVIL MISCELLANEOUS APPLICATION) - 12",
        "CMCC (CIVIL MISCELLANEOUS CASE CIVIL) - 13",
        "CMCR (CIVIL MISCELLANEOUS CASE CRIMINAL) - 14",
        "CMCW (CIVIL MISCELLANEOUS CASE WRIT) - 15",
        "CMEA (CIVIL MISCELLANEOUS EXCISE APPEAL) - 16",
        "CMLAP (CIVIL MISC LEAVE TO APPLICATION) - 95",
        "CMP (CIVIL MISCELLANEOUS PETITION) - 17",
        "CMS (CIVIL MISC (WRIT) STAY APPLICATION) - 18",
        "COA (COMPANY APPEAL) - 19",
        "COAP (COMPANY APPLICATION) - 20",
        "COEXP (COMPANY EXECUTION PETITION) - 21",
        "COP (COMPANY PETITION) - 22",
        "COS (COMPANY SUIT) - 23",
        "CPA (CIVIL PAUPER APPLICATION) - 24",
        "CR (CIVIL REVISION PETITION) - 25",
        "CREF (CIVIL REFERENCE) - 26",
        "CREP (CIVIL RECRIMINATION PETITION) - 27",
        "CRES (CIVIL RESTORATION APPLICATION) - 28",
        "CRLA (CRIMINAL APPEAL) - 29",
        "CRLAD (CRIMINAL APPEAL (DB)) - 30",
        "CRLAS (CRIMINAL APPEAL (SB)) - 31",
        "CRLBC (CRIMINAL BAIL CANCELLATION APPLICATION) - 32",
        "CRLCP (CRIMINAL CONTEMPT PETITION) - 33",
        "CRLCR (CRIMINAL CONTEMPT REFERENCE) - 34",
        "CRLDR (CRIMINAL DEATH REFERENCE) - 35",
        "CRLLA (CRIMINAL LEAVE TO APPEAL) - 36",
        "CRLMA (CRIMINAL MISCELLANEOUS APPLICATION) - 37",
        "CRLMB (CRIMINAL MISCELLANEOUS BAIL APPLICATION) - 38",
        "CRLMP (CRIMINAL MISCELLANEOUS (PETITION)) - 39",
        "CRLO (CRIMINAL ORIGINAL) - 40",
        "CRLR (CRIMINAL REVISION PETITION) - 41",
        "CRLRF (CRIMINAL REFERENCE) - 42",
        "CRLRW (CRIMINAL REVIEW) - 43",
        "CRLSA (CRIMINAL MISC STAY APPLICATION) - 44",
        "CRLTP (CRIMINAL MISCELLANEOUS TRANSFER PETITION) - 45",
        "CRLW (CRIMINAL WRIT PETITION) - 46",
        "CRW (CIVIL REVIEW PETITION) - 47",
        "CSA (CIVIL SECOND APPEAL) - 48",
        "CSTAY (CIVIL MISC STAY APPLICATION) - 49",
        "CTA (CIVIL TRANSFER APPLICATION) - 50",
        "CUSTA (CUSTOM APPEAL) - 51",
        "CW (CIVIL WRIT PETITION) - 52",
        "EA (ELECTION APPLICATION) - 53",
        "EAR (EXCISE ACT REFERENCE) - 54",
        "EDR (ESTATE DUTY REFERENCE) - 55",
        "EGCAP (EXCISE & GOLD CONTROL REFERENCE APPLICATION) - 56",
        "EGCR (EXCISE & GOLD CONTROL REFERENCE) - 57",
        "EP (ELECTION PETITION) - 58",
        "ERP (ELECTION RECRIMINATION PETITION) - 59",
        "EXCIA (CENTRAL/EXCISE APPEAL) - 60",
        "EXFA (EXECUTION FIRST APPEAL) - 61",
        "EXSA (EXECUTION SECOND APPEAL) - 62",
        "GTA (GIFT TAX APPEAL) - 63",
        "GTAP (GIFT TAX APPLICATION) - 64",
        "GTR (GIFT TAX REFERENCE) - 65",
        "HC (HABEAS CORPUS PETITION) - 66",
        "ITA (INCOME TAX APPEAL) - 67",
        "ITAP (INCOME TAX APPLICATION) - 68",
        "ITR (INCOME TAX REFERENCE) - 69",
        "MATR (MATRIMONIAL REFERENCE) - 70",
        "OOA (OTHER ORIGINAL APPLICATION) - 71",
        "OOS (OTHER ORIGINAL SUIT) - 72",
        "OTRC (OTHER TAX REFERENCE CIVIL) - 73",
        "RSTAY (CRIMINAL STAY) - 74",
        "SAC (SPECIAL APPEAL (CIVIL)) - 75",
        "SAW (SPECIAL APPEAL WRIT) - 76",
        "SCLA (SUPREME COURT LEAVE TO APPEAL) - 77",
        "SOSA (CRIMINAL MISC SUSPENSION OF SENTENCE APPLICATION (APPEAL)) - 78",
        "SOSP (SUSP OF SENTENCE (PETITION)) - 79",
        "SOSR (SUSP OF SENTENCE (REVISION)) - 80",
        "STAP (SALES TAX REVISION) - 81",
        "STR (SALES TAX REVISION / REFERENCE) - 82",
        "TESTC (TESTAMENTARY CASE) - 83",
        "TRAP (TRANSFER PETITION) - 84",
        "WCP (WRIT CONTEMPT PETITION) - 85",
        "WMAP (WRIT MISCELLANEOUS APPLICATION) - 86",
        "WRES (WRIT RESTORATION APPLICATION) - 87",
        "WRW (REVIEW PETITION (WRIT)) - 88",
        "WTA (WEALTH TAX APPEAL) - 89",
        "WTAP (WEALTH TAX REFERENCE APPLICATION) - 90",
        "WTC (WEALTH TAX CASES) - 91",
        "WTR (WEALTH TAX REFERENCE) - 92",
        "XOBJC (CROSS OBJECTION (CIVIL)) - 93",
        "XOBJW (CROSS OBJECTION WRIT) - 94",
      ],
      "Rajasthan High Court Principal Seat Jodhpur": [
        "APPLC(MISC APPLICATION (CIVIL))-1",
        "APPLR(MISC APPLICATION (CRIMINAL))-2",
        "APPLW(MISC APPLICATION (WRIT))-3",
        "ARBAP(ARBITRATION APPLICATION)-4",
        "CAVTC(CAVEAT (CIVIL))-5",
        "CAVTW(CAVEAT (WRIT))-6",
        "CCOCE(CIV.CROS.OBJ.CEN.EXC)-7",
        "CCOCR(CIV.CROS.OBJ.CIV.REV)-8",
        "CCOFA(CIV.CROS.OBJ.FIR APP)-9",
        "CCOIT(CIV.CROS.OBJ.IT APP)-10",
        "CCOMA(CIV.CROS.OBJ.MISCAPP)-11",
        "CCOSA(CIV.CROS.OBJ.SEC APP)-12",
        "CCOSP(CIV.CROS.OBJ. SPL.AP)-13",
        "CCP(CIV. CONTEMPT PET.)-14",
        "CEC(CIVIL EXECUTION CASE)-15",
        "CFA(CIVIL FIRST APPEAL)-16",
        "CLA(CIV. LEAVE TO APPEAL)-17",
        "CMA(CIVIL MISC. APPEAL)-18",
        "CMAP(MISC. APPLICATION)-19",
        "CMCC(LIMITATION CIVIL)-20",
        "CMCR(LIMITATION CRIMINAL)-21",
        "CMCW(LIMITATION WRIT)-22",
        "CMEA(CIV. MISC. EX. APPL.)-23",
        "CMS(CIVIL MISC(WRIT) STAY APPLICATION)-24",
        "COA(COMPANY APPEAL)-25",
        "COAP(COMPANY APPLICATION)-26",
        "COEXP(COMPANY EXECUTION PETITION)-27",
        "COITA(CROSOBJ.I.TAX APPEAL)-28",
        "COL(COMPANY LIQUIDATION)-29",
        "COP(COMPANY PETITION)-30",
        "COS(COMPANY SUIT)-31",
        "COSAW(CROS.OBJ.SPL.AP.WRIT)-32",
        "CPA(CIVIL PAUPER APPLI.)-33",
        "CPFA(CIV. PAUPER I APPEAL)-34",
        "CPSA(CIV. PAUPER II APPEL)-35",
        "CR(CIVIL REVISION PETITION)-36",
        "CREF(CIVIL REFERENCE)-37",
        "CREP(CIVIL RECRIMINATION PETITION)-38",
        "CRES(CIVIL RESTORATION)-39",
        "CRF(CIVIL REF.)-40",
        "CRLA(CRIMINAL APPEAL)-41",
        "CRLAB(CRL APP BAIL)-42",
        "CRLAD(CRIMINAL APPEAL (DB))-43",
        "CRLAS(CRIMINAL APPEAL (SB))-44",
        "CRLBC(CRIMINAL BAIL CANCELLATION APPLICATION)-45",
        "CRLCP(CRIMINAL CONTEMPT PETITION)-46",
        "CRLCR(CRL. CONTEMPT REF.)-47",
        "CRLDR(CRL DEATH REFERENCE)-48",
        "CRLJA(CRIMINAL JAIL APPEAL)-49",
        "CRLJR(CRIM. JAIL REVISION)-50",
        "CRLLA(CRML LEAVE TO APPEAL)-51",
        "CRLMA(CRIMINAL MISC. APPLI)-52",
        "CRLMB(CRIMINAL MISCELLANEOUS BAIL APPLICATION)-53",
        "CRLMP(CRIMINAL MISC(PET.))-54",
        "CRLO(CRIMINAL ORIGINAL)-55",
        "CRLR(CRIMINAL REVISION PETITION)-56",
        "CRLRB(CRL REV BAIL)-57",
        "CRLRF(CRL. REF.)-58",
        "CRLRW(CRIMINAL REVIEW)-59",
        "CRLSA(CRIMINAL MISC STAY APPLICATION)-60",
        "CRLTP(CRL.MISC.TRNFR.PET.)-61",
        "CRLW(CRIMINAL WRIT PETITION)-62",
        "CRW(CIVIL REVIEW PETITION)-63",
        "CSA(CIVIL SECOND APPEAL)-64",
        "CSTAY(CIVIL MISC STAY APPLICATION)-65",
        "CTA(CIVIL TRANSFER APPL.)-66",
        "CUSTA(CUSTOM APPEAL)-67",
        "CW(CIVIL WRIT PETITION)-68",
        "EA(ELECTION APPLICATION)-69",
        "EAR(EXCISE ACT REF.)-70",
        "EDAP(ESTATE DUTY APP.)-71",
        "EDC(ESTATE DUTY CASES)-72",
        "EDR(ESTATE DUTY REF.)-73",
        "EGCAP(Ex. and GOLD Ctrl Ref APP)-74",
        "EGCR(Ex. and GOLD Ctrl Ref.)-75",
        "EP(ELECTION PETITION)-76",
        "ERP(ELECTION RECRIMINATION PETITION)-77",
        "EXCIA(CENTRAL/EXCISE APPEAL)-78",
        "EXFA(EXECUTION FIRST APPEAL)-79",
        "EXSA(EXECUTION SECOND APPEAL)-80",
        "GTA(GIFT TAX APPEAL)-81",
        "GTAP(GIFT TAX APPL.)-82",
        "GTR(GIFT TAX REF.)-83",
        "HC(HABEAS CORPUS PETITION)-84",
        "IA(INTERLOCUTORY APPLI.)-85",
        "ITA(INCOME TAX APPEAL)-86",
        "ITAP(INCOME TAX APPLICA.)-87",
        "ITC(INCOME TAX CASES)-88",
        "ITR(INCOME TAX REF.)-89",
        "LPA(LETTER PATENT APPEAL)-90",
        "MATR(MATRIMONIAL REF.)-91",
        "MREF(MURDER REFENCE)-92",
        "OOA(OTHER ORIGINAL APPLICATION)-93",
        "OOS(OTHER ORIGINAL SUIT)-94",
        "OTRC(OTHER TAX REF. CIVIL)-95",
        "RAP(RESTORATION APPL.)-96",
        "RSTAY(CRIMINAL STAY)-97",
        "SAC(SPECIAL APPEAL (CIVIL))-98",
        "SAW(SPL. APPL. WRIT)-99",
        "SCLA(SC LEAVE TO APPEAL)-100",
        "SOSA(CRIMINAL MISC SUSPENSION OF SENTENCE APPLICATION (APPEAL))-101",
        "SOSP(SUSPENSION OF SENTENCE(Petition))-102",
        "SOSR(SUSPENSION OF SENTENCE(Revision))-103",
        "STAP(SALES TAX REVISION)-104",
        "STR(SALES TAX REF./REV.)-105",
        "TESTC(TESTAMENTARY CASE)-106",
        "TRAP(TRANSFER PETITION)-107",
        "WCP(WRIT CONTEMPT)-108",
        "WMAP(WRIT MISC APPLICATION)-109",
        "WRES(WRIT RESTORATION)-110",
        "WRW(REVIEW PETITION (WRIT))-111",
        "WTA(WEALTH TAX APPEAL)-112",
        "WTAP(WEALTH TAX REFERENCE APPLICATION)-113",
        "WTC(WEALTH TAX CASES)-114",
        "WTR(WEALTH TAX REF.)-115",
        "XOBJC(CROSS OBJECTION (CIVIL))-116",
        "XOBJW(CROSS OBJECTION WRIT)-117",
      ],
    },

    "Madras High Court": {
      "Madras High Court": [
        "A(APPLICATION)",
        "AF(AFFIDAVIT)",
        "APPEAL(CAD)(Appeal Commercial Appellate Division)",
        "APPEAL(CAD)(MD)(Appeal Commercial Appellate Division(MD))",
        "APPL.(APPLICATION (APPL.))",
        "APPLN(APPLICATION (IP))",
        "Arb Appeal(Arbitration Appeal)",
        "Arb Appln(Arbitration Application)",
        "Arb. E.P(Arbitration Execution Petition)",
        "Arb O.P(Arbitration Original Petition)",
        "Arb O.P(COM.DIV.)(Arbitration Original Petition (Commercial Division))",
        "AS(FIRST APPEAL)",
        "AS B(FIRST APPEAL BENCH)",
        "AS(MD)(FIRST APPEAL(MD))",
        "AS S(FIRST APPEAL SINGLE)",
        "B(BATTA CIVIL)",
        "B(C)(BATTA CRIMINAL)",
        "CMA(CIVIL MISC. APPEAL)",
        "CMA(CAD)(CIVIL MISC. APPEAL(COMMERCIAL APPELLATE DIVISION))",
        "CMA(CR)(CIVIL MISCELLANEOUS APPEAL COPY RIGHTS ACT)",
        "CMA DB(CIVIL MISC. APPEAL(DB))",
        "CMA(GI)(CIVIL MISCELLANEOUS APPEAL GEOGRAPHICAL INDICATIONS ACT)",
        "CMA(MD)(CI. MISC. APPEAL(MD))",
        "CMA NPD(CMA Non Pending)",
        "CMA PD(CMA Pending)",
        "CMA(PT)(CIVIL MISCELLANEOUS APPEAL PATENTS ACT)",
        "CMA(PV)(CIVIL MISCELLANEOUS APPEAL PROTECTION OF PLANTS VARIETIES AND FARMERS RIGHTS ACT)",
        "CMA(TM)(CIVIL MISCELLANEOUS APPEAL TRADE MARK ACT)",
        "CMP(CIVIL MISC. PETITION)",
        "CMP(CR)(CIVIL MISC. PETITION (COPY RIGHTS))",
        "CMP(GI)(CIVIL MISC. PETITION GEOGRAPHICAL INDICATIONS ACT)",
        "CMP(MD)(CIVIL MISC. PETITION (MD))",
        "CMP(PT)(CIVIL MISC. PETITION (PATENTS))",
        "CMP(PV)(CIVIL MISC. PETITION PROTECTION OF PLANTS VARIETIES AND FARMERS RIGHTS ACT)",
        "CMP(TM)(CIVIL MISC. PETITION (TRADE MARK))",
        "CMSA(CIVIL MISC SECOND APPEAL)",
        "COM APEL(Company Appeal)",
        "COMP.A(COMPANY APPLICATIONS)",
        "CONT A(CONTEMPT APPEAL)",
        "CONT APP(CONTEMPT APPLICATION)",
        "CONT P(CONTEMPT PETITION)",
        "CONT P (MD)(CONTEMPT PETITION (MD))",
        "CP(COMPANY PETITION)",
        "(CR) A(COPY RIGHTS APPLICATION)",
        "CRL A(CRIMINAL APPEAL)",
        "CRL A(MD)(CRIMINAL APPEAL(MD))",
        "CRL MP(CRIMINAL MISC. PETITION)",
        "CRL MP(MD)(CRIMINAL MISC.PETITION(MD))",
        "CRL OP(CRIMINAL ORIGINAL PETITION)",
        "CRL OP(MD)(CRIMINAL ORIGINAL PETITION(MD))",
        "CRL RC(CRIMINAL REVISION CASE)",
        "CRL RC(MD)(CRIMINAL REVISION CASE(MD))",
        "CRL REF(CRIMINAL REFERENCE)",
        "CROS.OBJ(CROSS OBJECTION)",
        "CRP(CIVIL REVISION PETITION)",
        "CRP(IPD)(Civil Revision Petition Intellectual Property Division)",
        "CRP(MD)(CIVIL REVISION PETITION(MD))",
        "CRP NPD(CIVIL REV.PETN.(NPD))",
        "CRP PD(CIVIL REV.PETN.(PD))",
        "CS(CIVIL SUITS)",
        "C.S(COMM DIV)(Civil Suit (Commercial Division))",
        "(Des)A(Design Act Application)",
        "ELP(ELECTION PETITION)",
        "EP(EXECUTION PETITION)",
        "(GI) A(GEOGRAPHICAL INDICATIONS APPLICATION)",
        "HCMP(HABEAS CORPUS MISC. PETITION)",
        "HCP(HABEAS CORPUS PETITION)",
        "HCP(MD)(HABEAS CORPUS PETITION(MD))",
        "IA(INSOLVENCY APPLICATION)",
        "IC(INSOLVENCY CASES)",
        "IN(INSOLVENCY NOTICE)",
        "IP(INSOLVENCY PETITION)",
        "LPA(LETTERS PATENT APPEAL)",
        "LTS(LEAVE TO SUE)",
        "MC(MATRIMONIAL CAUSES)",
        "MEMO(C)(MEMO)",
        "OA(ORIGINAL APPLICATION)",
        "OMS(ORIGINAL MATRIMONIAL)",
        "OP(ORIGINAL PETITION)",
        "O.P(COMM DIV)(Original Petition (Commercial Division))",
        "OP(CR)(ORIGINAL PETITION COPY RIGHTS ACT)",
        "OP(Des)(Original Petition Designs Act)",
        "OP(GI)(ORIGINAL PETITION GEOGRAPHICAL INDICATIONS ACT)",
        "OP(PT)(ORIGINAL PETITION PATENTS ACT)",
        "OP(PV)(ORIGINAL PETITION PROTECTION OF PLANTS VARIETIES AND FARMERS RIGHTS ACT)",
        "OP(TM)(ORIGINAL PETITION TRADE MARK ACT)",
        "OSA(ORIGINAL SIDE APPEAL)",
        "OSA(CAD)(Original Side AppealCommercial Appellate Division)",
        "(PT) A(PATENTS APPLICATION)",
        "(PV) A(PROTECTION OF PLANTS VARIETIES APPLICATION)",
        "RC(REFERRED CASES)",
        "RCMP(RCP MISC. PETITION)",
        "RCP(REFERRED CASE PETITION)",
        "REV.APLC(REVIEW APPLN.(Civil))",
        "REV.APLC(MD)(REVIEW APPLICATION CIVIL(MD))",
        "REV.APLO(REVIEW APPLN.(OS))",
        "REV.APLW(REVIEW APPLN.(Writ))",
        "REV.APLW(MD)(REV. APPLICATION WRIT (MD))",
        "REV.APPL(REVIEW APPLICATIONS)",
        "REV.PET(REVIEW PETITION(O))",
        "REV.PET(IPD)(Review Petition Intellectual Property Division)",
        "REV.STP(REVIEW SPL.TRIBUNAL)",
        "REVW(MD)(REVW(MD))",
        "RP(Reference Petition)",
        "RT(REFERRED TRIAL)",
        "SA(SECOND APPEAL)",
        "SA(MD)(SECOND APPEAL(MD))",
        "SCMP(SUPREME COURT MISC.PETITION)",
        "SCP(SUPREME COURT PETITION)",
        "STA(SPECIAL TRIBUNAL APPEAL)",
        "STP(SPECIAL TRIBUNAL PETITION)",
        "SUB A(SUB APPLICATION)",
        "SUB A(M)(SUB APPLICATION(MD))",
        "SUB APPL(SUB APPLICATIONS(OS))",
        "TC(TAX CASES)",
        "TCA(TAX CASE APPEAL)",
        "(T)CMA(CR)(TRANSFERRED CIVIL MISCELLANEOUS APPEAL COPY RIGHTS ACT)",
        "(T)CMA(GI)(TRANSFERRED CIVIL MISCELLANEOUS APPEAL GEOGRAPHICAL INDICATIONS ACT)",
        "(T)CMA(PT)(TRANSFERRED CIVIL MISCELLANEOUS APPEAL PATENTS ACT)",
        "(T)CMA(PV)(TRANSFERRED CIVIL MISC. APPEAL PROTECTION OF PLANTS VARIETIES AND FARMERS RIGHTS ACT)",
        "(T)CMA(TM)(TRANSFERRED CIVIL MISCELLANEOUS APPEAL TRADE MARK ACT)",
        "TCMP(TAX CMP)",
        "(T)CMP(CR)(TR. CIVIL MISC. PETITION (COPY RIGHTS))",
        "(T)CMP(GI)(TRANSFERRED CIVIL MISC. PETITION GEOGRAPHICAL INDICATIONS ACT)",
        "(T)CMP(PT)(TR. CIVIL MISC. PETITION (PATENTS))",
        "(T)CMP(PV)(TRANSFERRED CIVIL MISC. PETITION PROTECTION OF PLANTS VARIETIES AND FARMERS RIGHTS ACT)",
        "(T)CMP(TM)(TR. CIVIL MISC. PETITION (TRADE MARK))",
        "TCP(TAX CASE PETITION)",
        "TCR(TAX CASE REFERENCE)",
        "(T)(CR) A(TRANSFERRED COPY RIGHTS APPLICATION)",
        "TCTOP(MD)(Transferred City Tenant Original Petition(MD))",
        "(T)(Des)A(Transferred Designs Act Application)",
        "(T)(GI) A(TR. GEOGRAPHICAL INDICATIONS APPLICATION)",
        "TMA(TRADE MARKS APPEAL)",
        "(TM) A(TRADE MARKS APPLICATION)",
        "TMSA(TRADE MARKS SECOND A)",
        "(T)OP(CR)(TRANSFERRED ORIGINAL PETITION COPY RIGHTS ACT)",
        "(T)OP(Des)(Transferred Original Petition Designs Act)",
        "(T)OP(GI)(TRANSFERRED ORIGINAL PETITION GEOGRAPHICAL INDICATIONS ACT)",
        "(T)OP(PT)(TRANSFERRED ORIGINAL PETITION PATENTS ACT)",
        "(T)OP(PV)(TRANSFERRED ORIGINAL PETITION PROTECTION OF PLANTS VARIETIES AND FARMERS RIGHTS ACT)",
        "(T)OP(TM)(TRANSFERRED ORIGINAL PETITION TRADE MARK ACT)",
        "TOS(TESTAMENTARY ORIGINAL SUIT)",
        "TOS(MD)(Transferred Original Suit(MD))",
        "(T)(PT) A(TRANSFERRED PATENTS APPLICATION)",
        "(T)(PV) A(TR. PROTECTION OF PLANTS VARIETIES APPLICATION)",
        "TR APPL(TRANSFER APPLICATION)",
        "TR AS(TRANSFER FIRST APPEA)",
        "TR CMA(TRANSFER CIVIL MISC. APPEAL)",
        "TR CMP(TRANSFER CIVIL MISC. PETITION)",
        "TR CMP(MD)(TRANSFER CIVIL MISC(MD).)",
        "TRCS(TRANSFER CIVIL SUIT)",
        "(T)Rev.Pet(IPD)(Transferred Review Petition (IPD))",
        "(T)(TM) A(TRANSFERRED TRADE MARK APPLICATION)",
        "V(Vakalat Civil)",
        "V(C)(VAKALAT CRIMINAL)",
        "VCMP(VACATING ORDER MISC.)",
        "WA(WRIT APPEAL)",
        "WA(MD)(WRIT APPEAL(MD))",
        "WAMP(W.A. MISCELLANEOUS PETITION)",
        "WMP(WRIT MISC. PETITION)",
        "WMP(IPD)(WRIT MISC. PETITION IPD)",
        "WMP(MD)(WRIT MISC. PETITION (MD))",
        "WP(WRIT PETITION)",
        "WP Crl.(Writ Petition Criminal)",
        "WP(IPD)(Writ Petition IPD)",
        "WP(MD)(Writ Petition(MD))",
        "WPMP(W.P. MISCELLANEOUS PETITION)",
        "WPMP(IPD)(W.P. MISCELLANEOUS PETITION IPD)",
        "WPMP(MD)(W.P. MISC. PET.(MD))",
        "WVMP(VACATING ORDERMISC.)",
      ],
      "Madurai Bench of Madras High Court": [
        "A(MD)(APPLICATION(MD))",
        "APPEAL(CAD)(Appeal  Commercial Appellate Division)",
        "APPEAL(CAD)(MD)(Appeal  Commercial Appellate Division (MD))",
        "APPL.(MD)(APPLICATION(MD))",
        "APPLN(MD)(APPLICATION(OS2)(MD))",
        "Arb Appeal(MD)(Arbitration Appeal(MD))",
        "Arb Appln(Arbitration Application)",
        "Arb O.P(Arbitration Original Petition)",
        "AS(Appeal Suit)",
        "AS B(MD)(I APPEAL BENCH(MD))",
        "AS(MD)(FIRST APPEAL(MD))",
        "AS S(MD)(I APPEAL SINGLE(MD))",
        "CMA(CIVIL MISCELLANEOUS APPEAL)",
        "CMA DB(MD)(CMA BENCH(MD))",
        "CMA(MD)(CI. MISC. APPEAL(MD))",
        "CMA NPDB(MD)(CI MISC.APPL NPD(MD))",
        "CMA NPD(MD)(CMA Non Pending(MD))",
        "CMA NPDS(MD)(CI.MIS.APPL NPDS(MD))",
        "CMA PDB(MD)(CI.MIS. APPL.PDB(MD))",
        "CMA PD(MD)(CMA Pending(MD))",
        "CMA PDS(MD)(CI.MIS.APPL. PDS(MD))",
        "CMP(CMP)",
        "CMP(MD)(CIVIL MISC. PETITION (MD))",
        "CMSA(CMSA)",
        "CMSA(MD)(CI.MISC. II AP. (MD))",
        "COM APEL(MD)(COMPANY APPEAL(MD))",
        "COMP.A(MD)(CO. APPLICATION (MD))",
        "CONT A(CONT A)",
        "CONT AL(MD)(CONTEMPT APPEAL(MD))",
        "CONT A(MD)(CONTEMPT APPEAL(MD))",
        "CONT APP(MD)(CONTEMPT APPLN. (MD))",
        "CONT P(MD)(CONTEMPT PETITION (MD))",
        "CP(MD)(COMPANY PETITION(MD))",
        "CRL A(Criminal Appeal)",
        "CRL A(MD)(CRIMINAL APPEAL(MD))",
        "CRL MP(CRIMINIAL MISC.PETITION)",
        "CRL MP(MD)(CRIMINIAL MISC.PETITION(MD))",
        "CRL OP(CRIMINAL ORIGINAL PETITION)",
        "CRL OP(MD)(CRIMINAL ORIGINAL PETITION(MD))",
        "CRL RC(CRIMINAL REVISION CASE)",
        "CRL RC(MD)(CRIMINAL REVISION CASE(MD))",
        "CRL REF(MD)(CRIMINAL REFERENCE)",
        "CROS.OBJ(CROSS OBJECTION)",
        "CROS.OBJ(MD)(CROSS OBJECTION(MD))",
        "CRP(CIVIL REVISION PETITION)",
        "CRP(MD)(CIVIL REVISION PETITION(MD))",
        "CRP NPD(CRP NPD)",
        "CRP NPD(MD)(CI.REV. PET.NPD (MD))",
        "CRP PD(CRP PD)",
        "CRP PD(MD)(CI. REV. PET. PD(MD))",
        "C.S(COMM DIV)(Civil Suit (Commercial Division))",
        "CS(MD)(CIVIL SUITS(MD))",
        "ELP(MD)(ELECT. PETITIONS(MD))",
        "EP(MD)(EXECUT. PETITION(MD))",
        "HCMP(MD)(HABEAS CORPUS MP(MD))",
        "HCP(HABEAS CORPUS PETITION)",
        "HCP(MD)(HABEAS CORPUS P.(MD))",
        "IA(MD)(INSOLVENCY APPLN(MD))",
        "IC(MD)(INSOLVENCY CASES(MD))",
        "IP(MD)(INSOLVENCY PET.(MD))",
        "LPA(LPA)",
        "LPA(MD)(LTRS.PATENT APPL(MD))",
        "MC(MD)(MATRIMO. CAUSES(MD))",
        "OA(MD)(ORIGINAL APPLN.(MD))",
        "OMS(MD)(ORI.MATRI.SUITS(MD))",
        "O.P(COMM DIV)(Original Petition (Commercial Division))",
        "OP(MD)(ORIGINAL PETITN.(MD))",
        "OSA(CAD)(Original Side AppealCommercial Appellate Division)",
        "OSA(MD)(ORI.SIDE APPEAL (MD))",
        "RC(MD)(REFERRED CASES(MD))",
        "RCMP(MD)(RCP MIS.PETITION(MD))",
        "RCP(MD)(REFERD. CASE PET(MD))",
        "REV.APCR(MD)(REVIEW APPLNS.(MD))",
        "REV.APLC(REVIEW APPLICATION CIVIL)",
        "REV.APLC(MD)(REVIEW APPLICATION CIVIL(MD))",
        "REV.APLW(MD)(REV. APPLICATION WRIT (MD))",
        "REV.APPL(REVIEW APPLICATION)",
        "REV.APPL(MD)(REVIEW APPLNS.(MD))",
        "REV.STP(MD)(REV.SPL.TRIBUNAL(MD))",
        "RT(MD)(REFERRED TRIAL(MD))",
        "SA(SECOND APPEAL)",
        "SA(MD)(SECOND APPEAL(MD))",
        "SCMP(MD)(SUP.COURT MISC.P(MD))",
        "SCP(MD)(SUP.COURT PETI.(MD))",
        "STA(STA)",
        "STA(MD)(SPL.TRIBUNAL APP(MD))",
        "STP(MD)(SPL.TRIBUNAL PET(MD))",
        "SUB A(MD)(SUB APPLICATION(MD))",
        "TCA(MD)(TAX CASES APPEAL(MD))",
        "TC(MD)(TAX CASES)",
        "TCMP(MD)(TAX CMP(MD))",
        "TCP(MD)(TAX CASE PET(MD))",
        "TCR(TAX CASES REV.)",
        "TCR(MD)(TAX CASES REV.(MD))",
        "TCTOP(MD)(Transferred City Tenant Original Petition(MD))",
        "TMA(MD)(TRADE MARKS APL.(MD))",
        "TMSA(MD)(TRADE MARKS SA (MD))",
        "TOS(MD)(TESTAMENTARY OS (MD))",
        "TR APPL(MD)(TRANSFER APPLN.(MD))",
        "TR AS(TRANSFER  APPL)",
        "TR AS(MD)(TRANSFER I APPL.(MD))",
        "TR CMA(MD)(TRANSFER CI.MISC(MD))",
        "TR CMP(MD)(TRANSFER CI.MISC(MD))",
        "TRCS(MD)(TRANSFER CI.SUIT(MD))",
        "TR OS(MD)(Transferred Original Suit(MD))",
        "VCMP(MD)(VACATING ORD. MP(MD))",
        "WA(WRIT APPEAL)",
        "WA(MD)(WRIT APPEAL(MD))",
        "WAMP(MD)(W.A. MISC. PET.(MD))",
        "WMP(WRIT MISC PETITION)",
        "WMP(MD)(WRIT MISC. PET.(MD))",
        "WP(WRIT PETITION)",
        "WP Crl. (MD)(Writ Petition Criminal (MD))",
        "WP(MD)(Writ Petition(MD))",
        "WPMP(MD)(W.P. MISC. PET.(MD))",
        "WVMP(MD)(VACATING ORD.MP(MD))",
      ],
    },

    "High Court of Sikkim": {
      "High Court of Sikkim": [
        "Appeal(C)(Under Section 30 of the Employees' Compensation Act, 1923) - 51",
        "Appeal(Crl.)(Under Section 42 of the Prevention of Money Laundering Act, 2002) - 49",
        "Arb.A.(Appeals Under Indian Arbitration Act) - 16",
        "Arb.P.(Petitions Under Indian Arbitration Act) - 15",
        "BAIL APPLN.(Bail Application) - 31",
        "Caveat Pet.(Caveat Petition) - 29",
        "C. M. App.(Civil Miscellaneous Appeal) - 39",
        "C.M.C(Civil Misc. Case) - 54",
        "CO(Cross Objection In First Appeal) - 3",
        "Com. A.(Commercial Appeal) - 55",
        "Cont. Cas(C)(Contempt Of Court Cases Relating To Civil Contempt) - 17",
        "Cont.Cas (Crl)(Cont. Cas) - 28",
        "C.Ref(Reference) - 8",
        "Crl. A.(Criminal Appeal) - 21",
        "CRL.L.P(Criminal Leave Petition) - 32",
        "Crl. M. C.(Criminal Misc. Case) - 22",
        "CRL. REV. P(Criminal Revision Petition) - 19",
        "CRP(Revision Petition) - 6",
        "El.Pet(Election Petitions) - 14",
        "Ex.P.(Execution Petition) - 47",
        "FAO(First Appeal From Order) - 2",
        "MAC App.(Motor Accident Appeals) - 13",
        "Mat.App(Matrimonial Appeals) - 12",
        "Review Pet.(Civil Review Petition) - 23",
        "Review Pet.(Cr)(Criminal Review Petition) - 24",
        "RFA(First Appeal From Judgment And Decree In Suit) - 1",
        "RP (FAM CT)(Revision Petition) - 34",
        "RSA(Second Appeal From Judgment And Decree) - 4",
        "SAO(Appeal From Appellate Other) - 5",
        "Sp. A.(Special Appeal) - 53",
        "Suo Motu Transfer Petition (Civil)(Suo Moto Transfer Petition (Civil)) - 50",
        "Suo Motu Transfer Petition(Crl.)(Suo Motu Transfer Petition(Crl.)) - 52",
        "Tax App.(Tax App (Under Section 35(G) of the Central Excise Act,1994)) - 46",
        "Tr.P.(C)(Transfer Petition Under Section 24 Cpc Person Restoration Application) - 10",
        "Tr. P. (Crl.)(Transfer Petition For Transfer A Criminal Proceeding) - 45",
        "WA(Writ Appeal) - 36",
        "WP(C)(Writ Petition Under Articles 226 And 227 Of The Constitution) - 11",
        "WP (Crl.)(Writ Petition Criminal) - 20",
        "WP(PIL)(Writ Petition Pil) - 26",
      ],
    },

    "High Court of Tripura": {
      "High Court of Tripura": [
        "AB (Anticipatory Bail) - 49",
        "Adml.S. (Admiralty Suits) - 164",
        "AIR (REF) - 201",
        "Arb.A. (Appeals under Indian Arbitration Act) - 139",
        "Arb.P. (Petition under Indian Arbitration Act) - 138",
        "BA (Bail Application) - 57",
        "Bkg.p. (Proceedings under Banking Regulation Act) - 118",
        "CA (CA) - 187",
        "CAPL (Contempt Appeal) - 47",
        "C.APPLN (Company Applications) - 181",
        "CAVT (Caveat) - 178",
        "CC (CIV) (Contempt Of Court (Civil)) - 7",
        "CCRP (Civil Revision Petition) - 239",
        "Central Ex.App. (Central Excise Appeal) - 240",
        "CE.Ref. (Reference under Central Excise Act) - 152",
        "CEREF (CENTRAL EXCISE REFERENCE) - 83",
        "CMAppl. (Miscellaneous Applications) - 109",
        "CMAppl. (Crl.) (Misc. Application in respect of Criminal Case) - 233",
        "CMAppl. (CRP) (Misc. Application In Civil Revision Petition) - 231",
        "CMAppl (FA) (Misc case in FA) - 225",
        "CMAppl. (REVIEW) (Misc. Appln. in respect of review matters) - 234",
        "CMAppl. (SA) (Misc. Appln. in respect of Second Appeals) - 232",
        "CMAppl. (WA) (Misc Appl. In respect WA) - 237",
        "CM Appl. (WP) (Misc. Application in Writ Petition) - 223",
        "CO (Cross Objection in First Appeal) - 97",
        "Co.App. (Appeals against Judgments/Orders in Company Petitions) - 117",
        "Co.Appl. (Application in Pending Proceeding) - 115",
        "COB (FA) (Cross Objection in First Appeal) - 6",
        "COB (MFA) (Cross Objection in Misc. First Appeal) - 5",
        "COB (T) (CROSS OBJECTION (TENDER)) - 81",
        "Co.Case (Matters transferred under Section 446(3)) - 116",
        "CO (FA) (Cross Objection - First Appeal) - 224",
        "COJO (Correction of Judgement/Order) - 34",
        "COMAPL (Company Appeal) - 4",
        "Commercial Appeal (Commercial Appeal) - 242",
        "COMPET (Company Petition) - 3",
        "CONTAPP (Contempt Appeal) - 94",
        "Cont.App. (C) (Appeals against orders in Civil Contempt matters) - 143",
        "Cont.App. (Crl.) (Appeals Against Orders In Criminal Contempt Matters) - 175",
        "Cont.Cas (C) (Contempt of Court Cases relating to Civil Contempt) - 142",
        "Cont.Cas. (Crl.) (Proceedings Relating To Criminal Contempt) - 174",
        "Co.Pet. (Original Petition) - 114",
        "CO (RSA) (Cross Objection Second Appeal) - 238",
        "CO (RSA) (Cross Objection on Regular 2nd Appeal) - 235",
        "CO (SAO) (Cross Appeal on Spl. 2nd appeal) - 236",
        "CO (WA) (Cross Objection in Writ Appeal) - 243",
        "CP (Condonation Petition) - 68",
        "C.PTN. (Company Petitions) - 180",
        "CR (Civil Rule) - 1",
        "CRAPL (Criminal Appeal) - 52",
        "CRAPPL (Cross Appeal) - 80",
        "CR (CC) (Criminal Contempt of Court) - 50",
        "CRDREF (Criminal Death Reference) - 58",
        "C.Ref. (Reference) - 104",
        "CREF (Civil Reference) - 13",
        "CREV (Civil Revision) - 14",
        "CREV (H) (Civil Revision (hills)) - 15",
        "C.REVN (C.REVN) - 188",
        "CR (HC) (Civil Rule (Habeas Corpus)) - 8",
        "Crl.A. (Appeal Against Judgment Or Sentence) - 166",
        "CR (LAB) (Civil Rule (labour)) - 9",
        "CRL.A(H) (Crl.appeal H) - 189",
        "Crl.A(J) (Criminal Appeal From Jail) - 190",
        "CR (LAN) (Civil Rule (land)) - 10",
        "Crl.L.P. (Application For Leave To Appeal Under Section 378 Cr.P.C.) - 176",
        "Crl.M.Appl. (Other Miscellaneous Application) - 172",
        "Crl.M.C. (Application Under Section 482 Cr.P.C.) - 170",
        "CRL.O.APPN (CRL.O.APPN) - 192",
        "CRL.OP(C) (CRL.OP(C)) - 179",
        "CRL.O.REF (CRL.O.REF) - 202",
        "Crl.Petn. (Application Us 482 Crpc For Quashing Of FIR Proceeding) - 220",
        "Crl.Ref. (Criminal Reference under 395 Cr.PC) - 169",
        "Crl.Ref(H) (Criminal Reference Hill) - 206",
        "CRL.REV(H) (CRL.REV(H)) - 194",
        "Crl.Rev.P. (Criminal Revision Petition Us 397 or 399 Or 482 Crpc) - 168",
        "Crl.Tr. (Criminal Trial) - 165",
        "CR(M) (CR(M)) - 196",
        "CR(MC) (Criminal Misc. Case) - 51",
        "CROA (Criminal Original Application) - 62",
        "CROA (C) (Criminal Original Application Contempt) - 69",
        "CROP (Criminal Original Petition) - 63",
        "CROP (C) (Criminal Original Petition (Contempt)) - 53",
        "CR(OTH)(Civil Rule(other)) - 11",
        "CRP(Civil Revision Petition) - 102",
        "CR.REF(Criminal Reference) - 54",
        "CRREVAP(Criminal Review Application) - 75",
        "CR(SER)(Civil Rule(service)) - 12",
        "CS(Civil Suit) - 95",
        "CS(OS)(Civil Suits) - 159",
        "Cus.Ref.(Reference under Customs Act.) - 151",
        "Death Sentence Ref.(Confirmation Case Under Section 336 Cr.p.c.) - 167",
        "DS(Divorce Suit) - 72",
        "EC(EC) - 182",
        "EDREF(Estate Duty Reference) - 16",
        "ED(REF)(ED(REF)) - 199",
        "El.App.(Appeals from judgments in Election Petition) - 135",
        "El.Pet.(Election Petitions) - 134",
        "Ex.Appl.(OS)(Execution Application) - 161",
        "Ex.FA.(Execution First Appeal) - 106",
        "Ex.P.(Execution Petition) - 105",
        "Ex.SA.(Execution Second Appeal) - 107",
        "FA(First Appeal) - 18",
        "FA(D)(First Appeal(Divorce)) - 60",
        "FA/MA(F)(FA/MA(F)) - 186",
        "FAO(First Appeal from Orders) - 98",
        "FA(OS)(First Appeal for Judgments in Original Suits.) - 160",
        "FA/SA(FA/SA) - 185",
        "GCA(Government Criminal Appeal) - 20",
        "GTA/WTA/EDA(Application for direction to make a reference) - 150",
        "GTREF(Gift Tax Reference) - 21",
        "GT(REF)(GT(REF)) - 198",
        "GTR/WTR/EDR(Reference to High Court) - 149",
        "Gua.P.(Petitions under Gurdianship and Wards Act.) - 125",
        "IA.(Interlocutory applications in pending Suits/Appeals) - 108",
        "Insurance App.(Appeals under Insurance Act.) - 141",
        "Insurance Ref.(Reference under Insurance Act.) - 140",
        "Intest.Cas.(Intestate cases,e.g.,Succession Certificates,etc.) - 124",
        "IP(Appl.)(Application submitted after adjudication) - 137",
        "IP(M)(Main Petition) - 136",
        "ITA(Application under Section 256(2)) - 148",
        "ITR(Reference under Section 256(1).) - 147",
        "ITREF(Income Tax Reference) - 22",
        "IT(REF)(IT(REF)) - 195",
        "ITREF(A)(AGRI IT REFERENCE) - 88",
        "LA.App.(Land Acquisition Appeals) - 127",
        "LA.Ref.(Reference) - 126",
        "LPA(Latters Patent Appeal) - 23",
        "LPA/Sp.A(Letters Patent Appeal or Special Appeal Before Division Bench against a Judgment or Order of Single) - 101",
        "LPA(T)(LEAVE PATTERN APPEAL (TENDER)) - 87",
        "MAC App.(Motor Accident Claims Appeal) - 133",
        "MAF(Miscellaneous Appeal(first)) - 24",
        "MA(F)(MA(F)) - 184",
        "MAF(T)(Miscellaneous Appeal First(tender)) - 25",
        "MA(S)(Misc. Appeal ( Second )) - 65",
        "MAS(T)(MISC. APPEAL SECOND (TENDER)) - 86",
        "Mat.App.(Matrimonial Appeals) - 121",
        "Mat.Cas.(Matrimonial Suits/Petitions) - 119",
        "Mat.Ref.(References) - 120",
        "MC(Arb.A.)(Miscellaneous case in Arbitration appeal) - 221",
        "MC(BA)(Miscellaneous Case in Bail Application) - 210",
        "MC[Cont(C)](Misc. case in Cont. Case(C)) - 207",
        "MC(CR)(Misc. Case in Civil Rule) - 26",
        "MC(Crl.A)(Misc. Case in Criminal Appeal) - 76",
        "MC(Crl.L.P.)(Misc. case in Criminal Leave Petition) - 222",
        "MC(Crl.M.Appl.)(Misc. case in Criminal Misc. Application) - 218",
        "MC(Crl.Rev.P)(Misc. Case In Criminal Revision) - 27",
        "MC(CRP)(Misc. Case in Civil Revision Petition) - 205",
        "MC(EP)(Misc. Case in Election Petition) - 29",
        "MC(F)(Miscellaneous Case (First)) - 71",
        "MC(FAO)(Misc. Case in First Appeal from order) - 79",
        "MC(Intest)(Miscellaneous cases filed in Intest Case) - 209",
        "MC(MACApp)(Misc. case in MAC Appeals) - 208",
        "MC(MFA)(Misc. Case in MFA) - 28",
        "MC(PIL)(Misc. Case in Public Interest Litigation) - 219",
        "MC(RA)(MISC. CASE (R.A.)) - 85",
        "MC[Review Pet.](Misc case in Review Petition) - 212",
        "MC[RFA](Misc case in first appeal from judgment & Decree in suit) - 213",
        "MC[RSA](Misc. case in Regular Second Appeal) - 216",
        "MC(SA)(Misc. Case in Second Appeal) - 30",
        "MC[Tr.P.(C)](Misc. case in Transfer Petition under section) - 211",
        "MC(WA)(Misc. Case in Writ Appeal) - 74",
        "MC[WP(C)](Miscellaneous Case in Writ Petition(Civil)) - 59",
        "MFA(First Appeal against judgments in Special jurisdiction cases.) - 144",
        "MFA(Customs Act)(Miscellaneous First Appeal ( Customs Act)) - 241",
        "MFA(EC)(Employees Compensation) - 226",
        "MFA(FA)(Fatal Accident) - 227",
        "MSA(Second appeal from judgments in miscellaneous cases.) - 145",
        "M.S.(S.J.,C.S. and so on)(Miscellaneous Summons e.g., Summons for Judgments, Chamber Summons etc.) - 162",
        "N.M.(Notice of Motion) - 163",
        "OC(ORIGINAL CASE) - 90",
        "OJ(Original Jurisdiction) - 92",
        "OTA(Other Tax Application) - 31",
        "OT.Appl(Other Tax Application) - 158",
        "OTC(Other Tax cases) - 157",
        "OTR(Other Tax Reference cases) - 156",
        "PIL(Public Interest Litigation) - 93",
        "RA(Review Application) - 70",
        "RA(CR)(Review Application in Civil Rule) - 32",
        "RA(FA)(Review Application in First Appeal) - 33",
        "RA(MFA)(Review Application in Misc. First Appeal) - 35",
        "RAT(Review Application (Tender)) - 66",
        "RCC(Original Suit/Petition) - 128",
        "RCFA(First Appeal) - 129",
        "RCRev.(Revision) - 131",
        "RCSA(Second Appeal) - 130",
        "REP(Recrimination Case in Election Petition) - 36",
        "Revenue Appeal(Revenue Appeal) - 244",
        "Review Pet.(Review Petition) - 103",
        "RFA(First Appeal from Judgment and Decree in Suit) - 96",
        "RLPA(Review in Latters Patent Appeal) - 37",
        "RP(FAM.CT.)(Revisions under section 19 of the Family Courts Act.) - 122",
        "RPT(Review Petition (Tender)) - 67",
        "RSA(Second Appeal from Judgment and Decree) - 99",
        "SA(Second Appeal) - 39",
        "SAO(Appeal from Appellate Order) - 100",
        "SA(T)(Second Appeal(tender)) - 40",
        "SCA(SUPREME COURT APPEAL) - 84",
        "SCLP(Petition for Leave to Appeal to Supreme Court) - 113",
        "SLP(Special Leave Petition) - 203",
        "SP.JC(Name of Act)(Special Jurisdiction cases assigned to High Courts e.g. Trust Act,Lunancy Act,Trade & Merchandise Ac) - 146",
        "ST.Appl.(Application for direction to make a reference) - 154",
        "ST.Ref.(Reference) - 153",
        "STREF(Sales Tax Refrence) - 41",
        "ST.Rev.(Revision) - 155",
        "Test.Cas.(Testamentary cases,e.g.,Probate or Letters of Administration,etc.) - 123",
        "Tr.P.(C).(Transfer Petition under Section 24 C.P.C.) - 204",
        "Tr.P.(Crl.)(Transfer Petition For Transfer A Criminal Proceeding.) - 177",
        "TS(Testamentory Suit) - 77",
        "WA(Appeal before Division Bench against judgment or order of Single Judge in a Writ Petition.) - 112",
        "WA(LAB)(Writ Appeal(labour)) - 42",
        "WA(LAN)(Writ Appeal(land)) - 43",
        "WA(OTH)(Writ Appeal(others)) - 45",
        "WA(SER)(Writ Appeal(service)) - 44",
        "WA(T)(Writ Appeal Tender) - 64",
        "WP(C)(Writ Petition under Article 226 and 227 of the Constitution) - 111",
        "WP(C)(CAT)(Common Administrative Tribunal) - 229",
        "WP(C)(HC)(Writ Petition Civil for Habeas Corpus) - 230",
        "WP(C)(PIL)(Public Interest Letigation) - 228",
        "W.P.(Crl.)(Petition U/A 226 For Writ And Habeas Corpus And Other Relief In Relation To A Criminal Pro) - 173",
        "WTREF(Wealth Tax Refrence) - 46",
        "WT(REF)(WT(REF)) - 197",
      ],
    },

    "High Court of Uttarakhand": {
      "High Court of Uttarakhand": [
        "ABA(ANTICIPATORY BAIL APPLICATION)",
        "ABAC(ANTICIPATORY BAIL CANCELLATION APPLICATION)",
        "AO(APPEAL FROM ORDER)",
        "ARBAP(ARBITRATION APPLICATION)",
        "ARBICA(ARBITRATION INTERNATIONAL COMMERCIAL ARBITRATION)",
        "BA1(FIRST BAIL APPLICATION)",
        "BA10(Tenth Bail Application)",
        "BA2(SECOND BAIL APPLICATION)",
        "BA3(THIRD BAIL APPLICATION)",
        "BA4(FOURTH BAIL APPLICATION)",
        "BA5(FIFTH BAIL APPLICATION)",
        "BA6(SIXTH BAIL APPLICATION)",
        "BA7(SEVENTH BAIL APPLICATION)",
        "BA8(EIGHTH BAIL APPLICATION)",
        "BA9(NINTH BAIL APPLICATION)",
        "BAC(BAIL CANCELLATION)",
        "BAC II(SECOND BAIL CANCELLATION)",
        "BAST(SHORT TERM BAIL APPLICATION)",
        "BAST 2(SECOND SHORT TERM BAIL APPLICATION)",
        "BAST 3(THIRD SHORT TERM BAIL APPLICATION)",
        "BAST 4(FOURTH SHORT TERM BAIL APPLICATION)",
        "BAST 5(FIFTH SHORT TERM BAIL APPLICATION)",
        "BAST 6(SIXTH SHORT TERM BAIL APPLICATION)",
        "BAST 7(SHORT TERM BAIL APPICATION)",
        "BAST 8(EIGHT SHORT TERM BAIL APPLICATION)",
        "BAST 9(NINE SHORT TERM BAIL APPLICATION)",
        "C24(CIVIL TRANSFER APPLICATION U/S 24)",
        "C24(APPLICATION UNDER SECTION 24 C.P.C.)",
        "C340(CRIMINAL MISC. APPLICATION)",
        "C378(U/S 378)",
        "C407(CRIMINAL TRANSFER APPLICATION U/S 407)",
        "C447(SECTION 447 BNSS)",
        "C482(CRIMINAL MISC. APPLICATION U/S 482)",
        "C528(CRIMINAL MISC APPLICATION)",
        "CAPS(CAPITAL SENTENCE)",
        "CAV(CAVEAT)",
        "CELE(CELEING)",
        "CEXA(CENTRAL EXCISE APPEAL)",
        "CEXRE(CENTRAL EXCISE REFERENCE)",
        "CIVIL REFERENCE(CIVIL REFERENCE)",
        "CLCON(CIVIL CONTEMPT)",
        "CLMA(CIVIL MISC. APPLICATION FOR SPECIAL CASES)",
        "CLR(CIVIL REVISION)",
        "CM(CIVIL MISC.)",
        "COMA(COMPANY APPEAL)",
        "COMP(COMPANY PETITION)",
        "COMPA(COMPANY APPLICATION)",
        "CONS(CONSOLIDATION)",
        "CONTA(CONTEMPT APPEAL)",
        "CRCON(CRIMINAL CONTEMPT)",
        "CRJA(CRIMINAL JAIL APPEAL)",
        "CRJR(CRIMINAL JAIL REVISION)",
        "CRLA(CRIMINAL APPEAL)",
        "CRLR(CRIMINAL REVISION)",
        "CRMA(CRIMINAL MISC. APPLICATION)",
        "CRMC(CRIMINAL MISC. CASE)",
        "CROSA(CROSS APPEAL)",
        "CROSS(CROSS OBJECTION)",
        "CTA(Customs Appeal)",
        "CTR(COMMERCIAL TAX REVISION)",
        "DAO(DEFECTIVE APPEAL FROM ORDER)",
        "DARBA(DEF ARBITRATION APPEAL DIVISION BENCH)",
        "DARBAP(DEFECTIVE ARBITRATION APPLICATION)",
        "DBA1(DEFECTIVE FIRST BAIL APPLICATION)",
        "DBA2(DEFECTIVE SECOND BAIL APPLICATION)",
        "DBA3(DEFECTIVE THIRD BAIL APPLICATION)",
        "DBA4(DEFECTIVE FOURTH BAIL APPLICATION)",
        "DBA5(DEFECTIVE FIFTH BAIL APPLICATION)",
        "DBA6(DEFECTIVE SIXTH BAIL APPLICATION)",
        "DBA7(DEFECTIVE SEVENTH BAIL APPLICATION)",
        "DBAC(DEFECTIVE BAIL CANCELLATION)",
        "DBAST(DEFECTIVE SHORT TERM BAIL APPLICATION)",
        "DC24(DEFECTIVE CIVIL TRANSFER APPLICATION)",
        "DC378(DEFECTIVE U/S 378)",
        "DC407(DEFECTIVE U/S 407)",
        "DC482(DEFECTIVE U/S 482)",
        "DCAPS(DEFECTIVE CAPITAL SENTENCE)",
        "DCAV(DEFECTIVE CAVEAT)",
        "DCELE(DEFECTIVE CELEING)",
        "DCEXA(DEFECTIVE CENTRAL EXCISE APPEAL)",
        "DCEXRE(DEFECTIVE CENTRAL EXCISE REFERENCE)",
        "DCLCON(DEFECTIVE CIVIL CONTEMPT)",
        "DCLMC(DEF APPLICATION UNDER SECTION 24 C.P.C.)",
        "DCLR(DEFECTIVE CIVIL REVISION)",
        "DCOMA(DEFECTIVE COMPANY APPEAL)",
        "DCOMP(DEFECTIVE COMPANY PETITION)",
        "DCOMPA(DEFECTIVE COMPANY APPLICATION)",
        "DCONS(DEFECTIVE CONSOLIDATION)",
        "DCONTA(DEFECTIVE CONTEMPT APPEAL)",
        "DCRCON(DEFECTIVE CRIMINAL CONTEMPT)",
        "DCRJA(DEFECTIVE CRIMINAL JAIL APPEAL)",
        "DCRLA(DEFECTIVE CRIMINAL APPEAL)",
        "DCRLR(DEFECTIVE CRIMINAL REVISION)",
        "DCROSS(DEFECTIVE CROSS OBJECTION)",
        "DEEP(THIS IS TEST TYPE)",
        "DEEP1(THIS IS TEST TYPE 111)",
        "DELEP(DEFECTIVE ELECTION PETITION)",
        "DEXED(DEFECTIVE EXECUTION DECREE)",
        "DFA(DEFECTIVE FIRST APPEAL)",
        "DFAD(DEFECTIVE FIRST APPEAL DEFECTIVE)",
        "DGA(DEFECTIVE GOVT. APEAL)",
        "DGOLD(DEFECTIVE GOLD CONTROL PETITION)",
        "DHABC(DEFECTIVE HABEAS CORPUS)",
        "DITA(DEFECTIVE INCOME TAX APPEAL)",
        "DITR(DEFECTIVE INCOME TAX REV.)",
        "DITRE(DEFECTIVE INCOME TAX REFERENCE)",
        "DLA(DEFECTIVE LAND AQUISITION)",
        "DLIQ(DEFECTIVE LIQUIDATION)",
        "DMATA(DEFECTIVE MATRIMONIAL APPEAL)",
        "DREF(DEFECTIVE REFERENCE)",
        "DRENC(DEFECTIVE RENT CONTROL)",
        "DREVP(DEFECTIVE REVIEW PETITION)",
        "DSA(DEFECTIVE SECOND APPEAL)",
        "DSPA(DEFECTIVE SPECIAL APPEAL)",
        "DSPLA(DEFECTIVE SPECIAL LEAVE APPLICATION)",
        "DSTR(DEFECTIVE SALES TAX REVISION)",
        "DTEST(DEFECTIVE TESTAMENTRY)",
        "DTTR(DEFECTIVE TRADE TAX REVISION)",
        "DWELC(DEFECTIVE WEALTH CONTROL PETITION)",
        "DWPMB(DEFECTIVE WRIT PETITION MISC. BENCH)",
        "DWPMS(DEFECTIVE WRIT PETITION MISC. SINGLE)",
        "DWPSB(DEFECTIVE WRIT PETITION SERVICE BENCH)",
        "DWPSS(DEFECTIVE WRIT PETITION SERVICE SINGLE)",
        "DWTAX(DEFECTIVE WEALTH TAX PETITION)",
        "DWTR(DEFECTIVE WEALTH TAX REVISION)",
        "ELEA(ELECTION APPEAL)",
        "ELEP(ELECTION PETITION)",
        "EXED(EXECUTION DECREE)",
        "FA(FIRST APPEAL)",
        "FAD(FIRST APPEAL DEFECTIVE)",
        "GA(GOVT. APPEAL)",
        "GOLD(GOLD CONTROL PETITION)",
        "GTA(GIFT TAX APPEAL)",
        "GTR(GIFT TAX REVISION)",
        "HABC(HABEAS CORPUS)",
        "IA(INTERLOCUTARY APPLICATION)",
        "ITA(INCOME TAX APPEAL)",
        "ITAPL(INCOME TAX APPLICATION)",
        "ITR(INCOME TAX REFERENCE)",
        "LA(LAND AQUISITION)",
        "LAO(LODGING APPEAL FROM ORDER)]",
        "LARBA(LODGING ARBITRATION APPEAL DEV. BENCH)",
        "LARBAP(LODGING ARBITRATION APPLICATION)",
        "LC24(LODGING C24)",
        "LC340(LODGING U/S 340)",
        "LC407(LODGING C407)",
        "LC482(LODGING U/S 482)",
        "LCLCON(LODGING CONTEMPT  PETITION)",
        "LCLR(LODGING CIVIL REVISION)",
        "LCOMA(LODGING COMPANY PETITION)",
        "LCOMP(LODGING COMPANY PETITION)",
        "LCOMPA(LODGING COMPA)",
        "LCRCON(LODGING CRIMINAL CONTEMPT)",
        "LCRJA(LODGING CRIMINAL JAIL APPEAL)",
        "LCRJR(LODGING CRIMINAL JAIL REVISION)",
        "LCRLA(LODGING CRIMINAL APPEAL)",
        "LCRLR(LODGING CRIMINAL REVISION)",
        "LFA(LODGING FIRST APPEAL)",
        "LGA(LODGING GOVT. APPEAL)",
        "LHABC(LODGING HEBEAS CORPUS)",
        "LIQ(LIQUIDATION)",
        "LITA(LODGING INCOME TAX APPEAL)",
        "LODG(LODGING NUMBER)",
        "LSA(LODGING SECOND APPEAL)",
        "LSPA(LODGING SPECIAL APPEAL)",
        "LTTR(LODGING TRADE TAX REVISION)",
        "LWPMB(LODGING WRIT PETITION MISC. BENCH)",
        "LWPMS(LODGING WRIT PETITION SINGLE BENCH)",
        "LWPSB(LODGING WRIT PETITION DIV. BENCH)",
        "LWPSS(LODGING WRIT PETITION SERVICE SINGLE)",
        "MATA(MATRIMONIAL APPEAL)",
        "MCC(MISC. CIVIL CASE)",
        "MCRC(MISC. CRIMINAL CASE)",
        "NBW CANCELLATION APPLICATION(NBW CANCELLATION APPLICATION)",
        "OS(ORIGINAL SUIT (CIVIL SUIT))",
        "PFCFA(PETITION FOR CERTIFICATION FITNESS TO APPEAL)",
        "REF(REFERENCE)",
        "RENC(RENT CONTROL)",
        "RERA APPEAL(R.E.R.A APPEAL)",
        "REVP(REVIEW PETITION)",
        "RTEST(REGULAR TESTAMENTARY CASE)",
        "SA(SECOND APPEAL)",
        "SABA(SECOND ANTICIPATORY BAIL APPLICATION)",
        "SPA(SPECIAL APPEAL)",
        "SPLA(SPECIAL LEAVE APPLICATION)",
        "STR(SALES TAX REVISION)",
        "TA(TICKET ALLOWANCE)",
        "TABA(THIRD ANTICIPATORY BAIL)",
        "TEST(TESTAMENTRY)",
        "TTR(TRADE TAX REVISION)",
        "TUA(TRADE UNION APPEAL)",
        "VATR(VAT REVISION)",
        "WELC(WEALTH CONTROL PETITION)",
        "WPCRL(WRIT PETITION CRIMINAL)",
        "WPCRLS(WRIT PETITION CRIMINAL SINGLE)",
        "WPMB(WRIT PETITION MISC. BENCH)",
        "WPMS(WRIT PETITION MISC. SINGLE)",
        "WPPIL(WRIT PETITION PUBLIC INTEREST LITIGATION)",
        "WPSB(WRIT PETITION SERVICE BENCH)",
        "WPSS(WRIT PETITION SERVICE SINGLE)",
        "WTA(WEALTH TAX APPEAL)",
        "WTAX(WEALTH TAX PETITION)",
        "WTR(WEALTH TAX REFERENCE)",
      ],
    },
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2000 + 1 },
    (v, k) => 2000 + k
  );

  useEffect(() => {
    fetchCases();
  }, []);

  useEffect(() => {
    if (court && bench) {
      const types = caseTypeMapping[court]?.[bench] || [];
      setCaseTypes(types);
    }
  }, [court, bench]);

  const fetchCases = async () => {
    try {
      const response = await axiosInstance.get("/allhighcourt/cases/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      setCases(response.data);
    } catch (error) {
      console.error("Failed to fetch cases:", error);
    } finally {
      setIsLoadingCases(false);
    }
  };

  const confirmDeleteCase = (caseId) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are you sure you want to delete this case?",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteCase(caseId),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const DeleteCase = async (caseId) => {
    try {
      const response = await axiosInstance.delete(
        `/allhighcourt/cases/${caseId}/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchCases();
      toast("Case Deleted Successfully");
    } catch (err) {
      console.log(err);
    }
  };

  const handleCaseDetails = async (case_number) => {
    try {
      const response = await axiosInstance.get(
        `allhighcourt/hcbank/${case_number}/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSelectAllHighCourt(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handlecase = async (e) => {
    e.preventDefault();
    if (!caseType || !caseNumber || !year) {
      toast.error("Please fill in all fields");
      return;
    }
    const data = {
      case_type: caseType,
      caseno: caseNumber,
      year: year,
      court: court,
      bench: bench,
      case_nickname: name,
    };

    setLoading(true);

    try {
      const response = await axiosInstance.post("allhighcourt/cases/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      setCaseType("");
      setCaseNumber("");
      setYear("");
      setName("");
      fetchCases();
      toast.success("Case added successfully. We are processing your case.");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        toast.error(`Failed to submit form: ${err.response.data.detail}`);
      } else {
        toast.error("Failed to submit form");
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoadingCases) {
    return (
      <div className="loading">
        <Navbar />
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color="#ffffff" size={150} css={override} />
          <p
            style={{
              color: "#ffffff",
              marginTop: 20,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Loading case details...
            <br />
            Please wait as we retrieve the latest information.
          </p>
        </div>
      </div>
    );
  }

  if (selectAllHighCourt) {
    return <Allhighcourtcasedetail />;
  }

  if (cases.length > 0) {
    return (
      <>
        <Navbar />
        <ToastContainer />
        <div className="main-case-priviledge">
          <div className="cases-privileged-container">
            <div className="add-case-section">
              <img
                src={backbutton}
                alt=""
                className="back-button"
                onClick={() => {
                  window.location.href = "/mycases";
                }}
              />
              <h2>
                {" "}
                <span style={{ color: "#5D54F1" }}>Add</span> Case
              </h2>
              <div className="form-group">
                <select
                  id="caseType"
                  name="caseType"
                  value={caseType}
                  onChange={(e) => setCaseType(e.target.value)}
                >
                  <option value="">Select Case Type</option>
                  {caseTypes.map((caseType, index) => (
                    <option key={index} value={caseType}>
                      {caseType}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="case-name"
                  placeholder="Case Number"
                  name="Case Number"
                  value={caseNumber}
                  onChange={(e) => setCaseNumber(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <select
                  id="year"
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="">Select Year</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <input
                  id="year"
                  name="year"
                  placeholder="Case Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              <button className="submit-btn" onClick={handlecase}>
                {loading ? <div className="loader"></div> : "Submit"}
              </button>
              <p className="info-text mt-5">
                <span className="icon">
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={infoimage}
                    alt=""
                  />
                </span>{" "}
                You can access a detailed version of your case, complete with
                summary and timeline, by clicking on the tab labeled with the
                Case number.
              </p>
              <img
                src={bottomimage}
                alt="Book"
                className=".case-bottom-right-image"
              />
            </div>
            <div className="my-cases-section">
              <div className="header-container">
                <h2>
                  <span style={{ color: "#5D54F1" }}>My</span> Cases
                </h2>
                <div className="search-container">
                  <input
                    type="search"
                    placeholder="Search Case"
                    className="search-bar"
                  />
                  <span className="search-icon">
                    <img src={search} alt="Search Icon" className="icon-img" />
                  </span>
                </div>
              </div>
              <div className="case-list">
                {cases.map((caseItem) => (
                  <div className="cases-item" style={{ display: "flex" }}>
                    <div
                      key={caseItem.id}
                      className="case-items"
                      onClick={() =>
                        handleCaseDetails(caseItem.allhighcourtcase)
                      }
                      style={{ display: "flex", gap: "10px", width: "100%" }}
                    >
                      <div className="case-item">
                        <span>CASE TYPE: {caseItem.case_type}</span>
                        <span>CASE NO: {caseItem.caseno}</span>
                        <span>CASE NAME: {caseItem.case_nickname}</span>
                      </div>
                    </div>
                    <div className="case-actions">
                      <button className="action-btn" aria-label="View Case">
                        <img src={unselected} alt="View" className="case-img" />
                      </button>
                      <button
                        className="action-btn"
                        aria-label="Delete Case"
                        onClick={(e) => {
                          e.stopPropagation();
                          confirmDeleteCase(caseItem.id);
                        }}
                      >
                        <img
                          src={unselecteddelete}
                          alt="Delete"
                          className="case-img"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {/* mobile add case section */}
              <div className="mobilePrivildgeContainer">
                <img src={backimg} alt="" className="back-image" />
                <div className="addcaseBut">
                  <button onClick={() => setShowAddCase(true)}>Add Case</button>
                </div>
              </div>
              <div
                className={`mobile-top-privilegde ${showAddCase ? "show" : ""}`}
              >
                <div className="mobile-privileged-main-side ">
                  <button
                    className="close-button"
                    onClick={() => setShowAddCase(false)}
                  >
                    <FaTimes />
                  </button>
                  <h1>
                    {" "}
                    <span style={{ color: "#5D54F1" }}>Add </span>Case
                  </h1>
                  <div className="high-court-mobile-privileged-centered-box">
                    <div className="mobile-privileged-container">
                      <div className="high-court-form-container">
                        <form onSubmit={handlecase}>
                          <div className="form-group">
                            <label htmlFor="caseType">Case Type</label>
                            <select
                              id="caseType"
                              name="caseType"
                              value={caseType}
                              onChange={(e) => setCaseType(e.target.value)}
                            >
                              <option value="">Select Case Type</option>
                              {caseTypes.map((caseType, index) => (
                                <option key={index} value={caseType}>
                                  {caseType}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="caseNumber">Case Number</label>
                            <input
                              type="text"
                              id="caseNumber"
                              placeholder="Case Number"
                              name="caseNumber"
                              value={caseNumber}
                              onChange={(e) => setCaseNumber(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="year">Year</label>
                            <select
                              id="year"
                              name="year"
                              value={year}
                              onChange={(e) => setYear(e.target.value)}
                            >
                              <option value="">Select Year</option>
                              {years.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="year">Case Name</label>
                            <input
                              id="year"
                              name="year"
                              placeholder="Case Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            ></input>
                          </div>
                          <div className="form-group laptop-submit">
                            <button
                              type="submit"
                              disabled={loading}
                              onClick={handlecase}
                            >
                              {loading ? (
                                <div className="loader"></div>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="mobile-footer-content">
                        <div className="mobile-footer-image">
                          <button
                            className="form-group mobile-submit"
                            onClick={handlecase}
                          >
                            {loading ? (
                              <div className="loader"></div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <img
                            src={mobilePriviledge}
                            alt=""
                            className="mobileFooterImage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <ToastContainer />
        <div className="high-court-container">
          <div className="high-court-container-content">
            <div className="img-text">
              <img
                src={backbutton}
                alt="Back"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href = "/mycases";
                }}
              />
              <h1>
                <span style={{ color: "#5D54F1" }}>Enter the following to</span>{" "}
                know your case filing status
              </h1>
            </div>
            <p>Access Case Summaries Within Seconds.</p>
            <div className="form-container">
              <form onSubmit={handlecase}>
                <div className="form-group">
                  <label htmlFor="caseType">Case Type</label>
                  <select
                    id="caseType"
                    name="caseType"
                    value={caseType}
                    onChange={(e) => setCaseType(e.target.value)}
                  >
                    <option value="">Select Case Type</option>
                    {caseTypes.map((caseType, index) => (
                      <option key={index} value={caseType}>
                        {caseType}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="caseNumber">Case Number</label>
                  <input
                    type="text"
                    id="caseNumber"
                    name="caseNumber"
                    value={caseNumber}
                    onChange={(e) => setCaseNumber(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="year">Year</label>
                  <select
                    id="year"
                    name="year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value="">Select Year</option>
                    {years.map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <button className="mobile-btn" onClick={handlecase}>
      {loading ? <div className="loader"></div> : 'Submit'}
  </button> */}
                <div className="form-group">
                  <label htmlFor="year">Case Name</label>
                  <input
                    id="year"
                    name="year"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
                <div className="submit-laptop">
                  <div className="laptop-submit" onClick={handlecase}>
                    <button type="submit" disabled={loading}>
                      {loading ? <div className="loader"></div> : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <button className="form-group mobile-submit" onClick={handlecase}>
              {loading ? <div className="loader"></div> : "Submit"}
            </button>
          </div>
          <div className="high-court-top-container">
            <img
              src={illustration}
              alt="New top image"
              className="high-court-landing-top-image"
            />
          </div>
        </div>
      </>
    );
  }
};

export default Allhighcourtprivildge;
