import React, { useContext, useState } from "react";
import axios from "axios";
import "../assets/css/NotificationBar.css";
import feedbackIcon from "../assets/images/notification/feedbackIncon.svg";
import { useNotificationContext } from "../context/NotificationContext";
import axiosInstance from "../utils/axiosInstance";
import { toast } from "react-toastify";

const NotificationBar = () => {
  const { isPopupVisible, setIsPopupVisible } = useNotificationContext();
  const [visible, setVisible] = useState(true);
  const [selectedRating, setSelectedRating] = useState(null);
  const [service, setPage] = useState("");
  const [description, setFeedback] = useState("");
  const [loading, setLoading] = useState("");
  const access_token = sessionStorage.getItem("access_token");

  const handleRatingChange = (event) => {
    setSelectedRating(event.target.value);
  };

  const handlePageChange = (event) => {
    setPage(event.target.value);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleFeedbackClick = () => {
    setIsPopupVisible(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!service || !selectedRating) {
      toast.error("Please fill in all fields");
      return;
    }
    const feedbackData = {
      service,
      ratings: selectedRating,
      description,
    };

    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "feedback/feedback/",
        feedbackData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Feedback submitted successfully:", response.data);
      setIsPopupVisible(false);
      service("");
      description("");
      selectedRating("");
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!visible) return null;

  return (
    <div className={isPopupVisible ? "blur-background" : ""}>
      {isPopupVisible && (
        <div className="popup bg-light-600/90 dark:bg-dark-600/90">
          <div className="popup-content bg-white dark:bg-dark-400">
            <button
              className="popup-close-button invert-[1] dark:invert-[0]"
              onClick={() => setIsPopupVisible(false)}
            >
              ×
            </button>
            <div className="popup-beside-content">
              <div className="notificationBar-content bg-gray-200 dark:bg-dark-500 px-8">
                <div className="under-side-content">
                  <img
                    src={feedbackIcon}
                    alt=""
                    className="invert-[1] dark:invert-[0]"
                  />
                  <div className="text-content-under-side text-gray-950 dark:text-gray-300">
                    <h2>Send us your feedback!</h2>
                    <p>
                      Do you have a suggestion or had any problems? Let us know
                      in the fields below.
                    </p>
                  </div>
                </div>
              </div>
              <div className="offside-content">
                <div className="inputs">
                  <p className="text-gray-700 dark:text-gray-300">
                    Choose the page you want to rate
                  </p>
                  <select
                    className="select border-none border-r-6 border-transparent border-gray-500 dark:bg-dark-500 dark:text-gray-400"
                    onChange={handlePageChange}
                    value={service}
                  >
                    <option value="">Select a page</option>
                    <option value="HOMEPAGE">Homepage</option>
                    <option value="CASE TRACKING">Case Tracking</option>
                    <option value="CHATBOT">ChatBot</option>
                  </select>
                </div>
                <div className="rating">
                  <h6 className="text-gray-700 dark:text-gray-300">
                    Rate your experience with us
                  </h6>
                  <form>
                    {[...Array(10)].map((_, i) => (
                      <React.Fragment key={i + 1}>
                        <input
                          type="radio"
                          id={`rate${i + 1}`}
                          name="rating"
                          value={i + 1}
                          checked={selectedRating === `${i + 1}`}
                          onChange={handleRatingChange}
                        />
                        <label
                          className="border flex items-center justify-center border-gray-700 text-gray-700 dark:border-gray-300 dark:text-gray-300 rounded-full"
                          htmlFor={`rate${i + 1}`}
                        >
                          {i + 1}
                        </label>
                      </React.Fragment>
                    ))}
                  </form>
                </div>
                <div className="under-input-section">
                  <h5 className="text-gray-700 dark:text-gray-300">
                    Tell us about your experience
                  </h5>
                  <textarea
                    placeholder="Description"
                    value={description}
                    onChange={handleFeedbackChange}
                    required
                    className="border border-gray-500 dark:border-none dark:bg-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                  ></textarea>
                  <div className="pop-up-submit">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="max-sm:text-xs px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#313134] transition duration-200"
                    >
                      {loading ? "Please Wait..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationBar;
