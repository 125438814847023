import React from 'react';
import ChatBot from './ChatBot';

const comparedLawyers = [/* some data */];

const ChatBotParent = () => {
    return (
        <div className="app-container">
            <ChatBot comparedLawyers={comparedLawyers} />
        </div>
    );
};

export default ChatBotParent;
