import React from 'react';
// import './App.css';
import '../assets/css/lawyerfilter.css'
import SearchBar from './SearchBar';
import Filters from './Filteredlawyer'
import LawyerList from './LawyerList';
import Navbar from './Navbar';
import SearchLawyerProfile from './SarchLawyerProfile'
import topImage from '../assets/images/profile/searchlawyertopimage.png'

function App() {
    return (
        <div className="lawyer-filter-container">
            <Navbar />
            <div className="lawyer-filter-contain1">
                <div className="lawyer-filter-container-heading">
                    <h2><span style={{ color: '#5d54F1' }}>Search</span> Lawyers</h2>
                </div>
                <div className="lawyer-filter-contain">
                    <div className="filterlawyer-mobile-heading">
                        <h2><span style={{ color: '#5d54F1' }}>Search</span> Lawyers</h2>
                    </div>
                    {/* <img src={topImage} alt="" /> */}
                    <SearchBar />
                    <div className="main-content">
                        <div className="divided-content">
                            <Filters />
                            <LawyerList />
                            <SearchLawyerProfile />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
