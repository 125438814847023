import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import backbutton from "../assets/images/mycases/back-button.svg";
import info from "../assets/images/mycases/info-image.svg";
import highTop from "../assets/images/mycases/high-court-top.png";
import { useCaseContext } from "../context/CaseContext";
import "../assets/css/highcourtcasedetails.css";
import "../assets/css/supremecourtdetail.css";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import chaticon from "../assets/images/mycases/caseDetailsChatButtonIcon.png";
import caseDetailswaiting from "../assets/images/comingSoon/CaseWaitingPage.svg";
import { SpinningCircles } from "react-loading-icons";

const SupremeCoutDetail = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    selecteSupremeCourtCase,
    setSelecteSupremeCourtCase,
    goBackSupremeCourt,
    setPropmtsVisibel,
    propmtsVisibel,
  } = useCaseContext();

  const access_token = sessionStorage.getItem("access_token");
  const navigate = useNavigate();

  const [caseDetailsFetched, setCaseDetailsFetched] = useState(false);
  const [details, setDetails] = useState(selecteSupremeCourtCase);
  const [caseStatus, setCaseStatus] = useState(selecteSupremeCourtCase?.status);
  const [soonDetails, setSoonDetails] = useState(selecteSupremeCourtCase);

  const fetchCaseDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `sccourt/supremecourt/${selecteSupremeCourtCase.id}/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setCaseStatus(response.data.status);
      if (response.data.status === "COMPLETED_EXTRACTION") {
        setSoonDetails(response.data);
        // toast.success('');
      }

      if (response.data.status === "COMPLETED") {
        setDetails(response.data);
        setCaseDetailsFetched(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!caseDetailsFetched) {
        fetchCaseDetails();
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [caseDetailsFetched]);

  const casename = `${selecteSupremeCourtCase?.petitioner_vs_respondent}`;
  console.log(casename);

  const createChatRoom = async (cnr_number, is_true) => {
    try {
      const response = await axiosInstance.get("chatapp/chat-room/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await axiosInstance.post(
        "chatapp/chat-room/",
        {
          supremecourt_id: cnr_number,
          is_query: is_true,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Chat room created:", response.data);
      setPropmtsVisibel(true);
      console.log(propmtsVisibel);
      // setShowPromptsInChat(true);
      navigate("/talkaboutcase");
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const formatText = (text) => {
    if (!text) return null;

    const lines = text.split("\n");

    return lines
      .map((line, index) => {
        if (!line.trim()) return null;

        // Handle headings
        const headingMatch = line.match(/^(#+)\s+(.*)/);
        if (headingMatch) {
          const level = headingMatch[1].length;
          const content = headingMatch[2].replace(/\*\*(.*?)\*\*/g, "$1");
          return <strong key={index}>{content}</strong>;
        }

        const numberedMatch = line.match(/^(\d+\.\s+)\*\*(.*?)\*\*$/);
        if (numberedMatch) {
          const numberPart = numberedMatch[1]; // e.g., "1. "
          const content = numberedMatch[2].trim(); // Get the text after ** and remove it
          return (
            <div key={index}>
              {numberPart}
              <strong>{content}</strong>
            </div>
          );
        }

        // Handle list items with bold text
        if (line.startsWith("-**")) {
          const content = line.substring(3, line.length - 2).trim();
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("-**")) {
          const content = line.substring(8).trim();
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("   - **")) {
          const content = line.substring(7).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("  - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }
        if (line.startsWith("   - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }

        if (line.startsWith("     - ")) {
          const content = line.substring(6).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul style={{ marginLeft: "10px" }}>
              <li key={index} style={{ marginLeft: "10px" }}>
                {content}
              </li>
            </ul>
          );
        }

        // Handle regular list items
        if (line.startsWith("-")) {
          const cleanLine = line.substring(1).trim();
          const contentWithoutBold = cleanLine.replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <p style={{ marginLeft: "10px" }}>
              <li key={index}>{contentWithoutBold}</li>
            </p>
          );
        }

        if (line.startsWith("**") && line.endsWith("**")) {
          const cleanLine = line.substring(2, line.length - 2).trim(); // Remove the first and last two asterisks
          return (
            <strong>
              <p>{cleanLine}</p>
            </strong>
          );
        }

        // Handle bold text within a paragraph
        const parts = line
          .replace(/\*\*$/, "")
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          .split(/(<strong>.*?<\/strong>)/)
          .map((part, idx) => {
            if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
              const cleanText = part.substring(8, part.length - 9);
              return <strong key={idx}>{cleanText}</strong>;
            } else {
              return <span key={idx}>{part}</span>;
            }
          });

        return (
          <p key={index} style={{ textAlign: "justify" }}>
            {parts}
          </p>
        );
      })
      .filter((component) => component !== null);
  };

  if (caseStatus === "RETRIEVING") {
    return (
      <>
        {/* <Navbar /> */}
        <div className="casedetails-app-container">
          <div className="casedetails-content-container">
            {/* </div> */}
            <div className="casedetails-lower-section1">
              <img src={caseDetailswaiting} alt="" />
              <div className="casedetails-lower-section">
                <h3>Your Case Is Now in Motion!</h3>
                <p>Thank you for letting us assist you.</p>
              </div>
            </div>
          </div>
          <div className="casedetails-right-section">
            <div className="footer-right">
              <h6>What’s next?</h6>
              <ul>
                <li>
                  1.⁠ ⁠🕵️‍♂️ We're extracting the intricate details of your case.
                </li>
                <li>
                  2.⁠ ⁠🧠 These details are then sent to our state-of-the-art
                  Multimodal AI for thorough analysis.
                </li>
                <li>
                  ⁠3.🔔 You'll receive a prompt notification and an email update
                  very soon.
                </li>
              </ul>
              <h6>While you wait,</h6>
              <p>
                Why not explore our innovative AI Chatbot? It's designed to
                assist you with a wealth of legal knowledge and is trained on
                millions of cases and laws. Click here to dive in!
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* <Navbar /> */}
      <div className="high-court-case-detail-container dark:bg-dark-600 bg-light-600">
        <div className="high-court-case-detail-container-contain">
          <div className="case-detail-img-text text-center">
            <img
              src={backbutton}
              alt="Back"
              onClick={goBackSupremeCourt}
              style={{ position: "absolute", left: "3%" }}
              className="dark:invert-[1]"
            />
            <div className="highcourt-case-main-head">
              <h1 className="dark:text-gray-400 text-gray-600">
                {" "}
                <span className="dark:text-gray-300 text-gray-900">
                  View status
                </span>{" "}
                of case here
              </h1>
            </div>
          </div>
          {details?.complete_summary ? (
            <>
              <div className="chatButton">
                <button
                  className="chatbot-button flex items-center px-5 py-3 dark:bg-sh-dark-500 dark:text-gray-300 font-raleway tracking-wide font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#000] transition duration-200"
                  onClick={() =>
                    createChatRoom(selecteSupremeCourtCase.id, "True")
                  }
                >
                  Let's talk about your case{" "}
                  {/* <img src={chaticon} alt="" className="scale-50 p-0 m-0" /> */}
                </button>
                <p className="dark:text-gray-300 text-gray-700">
                  Try our legal chatbot in your preferred language to uncover
                  valuable insights and hidden answers in your case
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="Ai-model text-gray-700 dark:text-gray-300">
                <p className="dark:text-gray-300 text-gray-700">
                  Our AI model is extracting your case details. Please wait...
                </p>
                <SpinningCircles />
              </div>
            </>
          )}
          <div className="supreme-court-case-detail-containers ">
            <div className="supreme-court-table-with-vector ">
              <div className="supreme-court-case-content dark:bg-sh-dark-500 bg-light-400 tracking-wide">
                {/* <img src={tablevec} alt="Vector Graphic" className="table-vector" /> */}
                <div className="supreme-court-case-details font-roboto">
                  <span className="middle-dot"></span>
                  <table>
                    <tbody>
                      {soonDetails?.dairy_number && (
                        <tr>
                          <th className="dark:text-light-300 text-gray-900">
                            Diary No.
                          </th>
                          <td className="dark:text-gray-300">
                            {soonDetails?.dairy_number}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th className="dark:text-light-300">Case No.</th>
                        <td className="dark:text-gray-300">
                          {soonDetails?.case_number}
                        </td>
                      </tr>
                      <tr>
                        <th className="dark:text-light-300">
                          Present/Last Listed on
                        </th>
                        <td className="dark:text-gray-300">
                          {soonDetails?.listed_date}
                        </td>
                      </tr>
                      <tr>
                        <th className="dark:text-light-300">Case Status</th>
                        <td className="dark:text-gray-300">
                          {soonDetails?.case_status}
                        </td>
                      </tr>
                      <tr>
                        <th className="dark:text-light-300">Category</th>
                        <td className="dark:text-gray-300">
                          {soonDetails?.category}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="supreme-court-table-with-vector">
              <div className="supreme-court-case-content dark:bg-sh-dark-500">
                {/* <img src={tablevec} alt="Vector Graphic" className="table-vector" /> */}
                <div className="supreme-court-case-details">
                  <span className="middle-dot"></span>
                  <table>
                    <tbody>
                      <tr>
                        <th className="dark:text-light-300">Petitioner(s)</th>
                        <td className="dark:text-gray-300">
                          {soonDetails?.petitioner_name}
                        </td>
                      </tr>
                      <tr>
                        <th className="dark:text-light-300">Respondent(s)</th>
                        <td className="dark:text-gray-300">
                          {soonDetails?.respondent_name}
                        </td>
                      </tr>
                      <tr>
                        <th className="dark:text-light-300">
                          Petitioner Advocate(s)
                        </th>
                        <td className="dark:text-light-300">
                          {soonDetails?.petitioner_advocate}
                        </td>
                      </tr>
                      <tr>
                        <th className="dark:text-light-300">
                          Respondent Advocate(s)
                        </th>
                        <td className="dark:text-light-300">
                          {soonDetails?.respondent_advocate}
                        </td>
                      </tr>
                      {/* <tr><th>Case Status</th><td>{soonDetails.case_status}</td></tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="discuss-and-button">
            {/* <div className="high-court-info-text">
                            <img src={info} alt="" />
                            <p>To discuss about this case and get more information, click on our Chatbot button</p>
                        </div> */}
            <div className="court-buttons">
              <button
                className="gradient-button px-5 py-2 dark:bg-sh-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-white text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#000] transition duration-200"
                onClick={() => window.open(soonDetails.pdf_path, "_blank")}
              >
                Download Orders
              </button>
              <button
                className="gradient-button px-5 py-2 dark:bg-sh-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-white text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#000] transition duration-200"
                onClick={() => window.open(soonDetails.latest_pdf, "_blank")}
              >
                Download Latest Orders
              </button>
            </div>
          </div>
          {details?.complete_summary ? (
            <div className="order-summary rounded-none dark:bg-dark-500">
              <div
                className="order-summary-header dark:bg-dark-500 bg-light-300"
                onClick={() => setIsOpen(!isOpen)}
              >
                <h2 className="dark:text-gray-300 text-gray-700 font-semibold text-xl">
                  Order Summary
                </h2>
              </div>
              <div className="order-summary-content tracking-wide leading-loose font-roboto dark:bg-sh-dark-500 bg-white dark:text-gray-300">
                <p>{formatText(details?.complete_summary)}</p>
              </div>
            </div>
          ) : (
            <div className="Ai-model">
              <p className="dark:text-gray-300 text-gray-700">
                Our AI model is extracting your case details. Please wait...
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SupremeCoutDetail;
