import React from "react";

import { useOutletContext } from "react-router-dom";

import moment from "moment";

import { FaFilePdf } from "react-icons/fa6";

const Timeline = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  const timelines = caseDetails.order_pdfs;

  return (
    <div className="flex flex-col gap-4 px-3 pb-6 border-b border-b-[#d9d9d9] dark:border-b-gray-600">
      <ul className="flex flex-col gap-0">
        {timelines.map((timeline) => {
          return (
            <li
              key={timeline.order_date}
              className="flex flex-col gap-0 rounded-lg px-3 py-4"
            >
              <p className="font-semibold">
                Order Date :{" "}
                {moment(timeline.order_date).format("DD MMMM YYYY")}
              </p>
              <div className="flex gap-4 w-1/4 justify-between items-center px-5 py-3 border border-[#d9d9d9] dark:border-gray-600">
                <FaFilePdf className="text-2xl text-red-600 dark:text-red-400" />
                <a
                  className="font-raleway px-4 text-sm bg-black rounded-md dark:text-black dark:bg-light-600 text-white py-1 font-medium"
                  href={timeline.pdf_path}
                  target="_blank"
                  rel="noreferrer"
                >
                  Read Now
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Timeline;
