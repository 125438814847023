import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import "../assets/css/NotificationDropdown.css";
import notificationIcon from "../assets/images/notification/notification.svg";
import axiosInstance from "../utils/axiosInstance";
import allread from "../assets/images/notification/all_read.svg";
import noNotificationsImage from "../assets/images/notification/noNotification.svg"; // Add your no notifications image here
import { useCaseContext } from "../context/CaseContext";
import { useNavigate } from "react-router-dom";

const NotificationDropdown = ({ isOpen, toggleDropdown }) => {
  const dropdownRef = useRef(null);
  const [category, setCategory] = useState("all");
  const {
    unreadNotifications,
    setUnreadNotifications,
    notifications,
    fetchNotifications,
    handleCategoryClick,
    filteredNotifications,
  } = useCaseContext();
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("access_token");

  useEffect(() => {
    if (isOpen) {
      fetchNotifications();
      markAllAsRead();
    }
  }, [isOpen]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        isOpen
      ) {
        toggleDropdown();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  const markAsRead = async (id) => {
    try {
      await axiosInstance.patch(
        `notify/notification/${id}/`,
        { read: true },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchNotifications();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const markAllAsRead = async () => {
    try {
      const markAllPromises = unreadNotifications.map((notification) =>
        axiosInstance.patch(
          `notify/notification/${notification.id}/`,
          { read: true },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
      );
      await Promise.all(markAllPromises);
      fetchNotifications();
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const handleCategoryClickLocal = (e, category) => {
    handleCategoryClick(e, category);
    setCategory(category); // Update the category state
  };

  const handleNotificationClick = (notification) => {
    markAsRead(notification.id);
    // Navigate to the desired page with the case ID
    navigate(`/case/${notification.caseId}`);
  };

  return (
    <div
      className={`notification-dropdown-container ${
        isOpen ? "show" : ""
      } absolute top-10 left-1/2 translate-x-[-30%]`}
      onClick={toggleDropdown}
    >
      {isOpen && (
        <div className="notification-dropdown-menu dark:bg-dark-600">
          <div className="notification-header dark:bg-dark-500 bg-gray-200 text-gray-700 dark:text-gray-300">
            <h5>Notifications</h5>
            <p onClick={markAllAsRead}>
              <img
                src={allread}
                alt=""
                className="invert-[1] dark:invert-[0]"
              />{" "}
              Mark All As Read
            </p>
          </div>
          <div className="notification-categories text-gray-700 dark:text-gray-200 m-0">
            <button
              onClick={(e) => handleCategoryClickLocal(e, "all")}
              className={category === "all" ? "active" : ""}
            >
              All ({notifications.length})
            </button>
            <button
              onClick={(e) => handleCategoryClickLocal(e, "unread")}
              className={category === "unread" ? "active" : ""}
            >
              Unread ({unreadNotifications.length})
            </button>
          </div>
          {filteredNotifications.length > 0 ? (
            <div className="notification-content">
              {filteredNotifications.map((notification, index) => (
                <div
                  key={index}
                  className="notification-item dark:text-gray-200"
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className="notification-icon dark:invert-[1]"></div>
                  <div className="notification-info">
                    <p className="notification-title">{notification.message}</p>
                    <p className="notification-time">
                      {new Date(notification.created_at).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-notifications">
              <img src={noNotificationsImage} alt="No Notifications" />
              <p>No notifications yet</p>
              <h4>You’ll see notifications here when they are available</h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
