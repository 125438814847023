// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faTwitter,
//   faFacebook,
//   faInstagram,
// } from "@fortawesome/free-brands-svg-icons";
// import "../assets/css/footer.css";
// import logo from "../assets/images/navbar/legal-tech-logo.svg";

// const Footer = ({ scrollToOurservices }) => {
//   return (
//     <div className="footer">
//       <div className="">
//         <div className="row">
//           <div className="col-sm-3 column">
//             <div className="logo">
//               <img src={logo} alt="Logo" />
//             </div>
//             <p style={{ marginTop: "10px" }}>
//               &copy; 2023 Advanced Technology and Research Innovation Group. All
//               rights reserved.
//             </p>
//           </div>
//           {/* <div className="col-sm-3 column">
//             <h5>CASE MINISTER </h5>
//             <ul className="list-unstyled">
//               <li className="mt-3">
//                 Advanced Technology and Research Innovation Group
//               </li>
//             </ul>
//           </div> */}
//           <div className="col-md-3 column">
//             <h5>CASE MINISTER</h5>
//             <ul className="list-unstyled">
//               <p>
//                 Advanced Technology and Research <br /> Innovation Group
//               </p>
//               <div className="next-grp">
//                 <p>
//                   Suite 01, 64 Nile Street <br />
//                   London <br /> N1 7SR
//                 </p>
//               </div>
//             </ul>
//           </div>
//           <div className="col-md-3 column">
//             <h5>COMPANY</h5>
//             <ul className="list-unstyled">
//               <li>
//                 <a href="/">Home</a>
//               </li>
//               <li>
//                 <a href="/privacy-policy">Privacy Policy</a>
//               </li>
//               <li>
//                 <a href="/terms-and-conditions">Terms</a>
//               </li>
//               <li>
//                 <a href="/Contactus">Contact Us</a>
//               </li>
//               <li>
//                 <a href="/faq">FAQs</a>
//               </li>
//             </ul>
//           </div>
//           <div className="col-sm-3 column">
//             <h5>HELP</h5>
//             <ul className="list-unstyled">
//               <li>
//                 <a href="#" onClick={scrollToOurservices}>
//                   Services
//                 </a>
//               </li>
//               <li>
//                 <a href="#">Help & Support</a>
//               </li>
//               <li>
//                 <a href="#">Signup</a>
//               </li>
//               {/* <li>
//                 <a href="#">Message</a>
//               </li> */}
//             </ul>
//           </div>
//         </div>
//         {/* <div className="row">
//           <div className="col-12 copy">
//             <p>
//               Advanced Technology and Research Innovation Group Suite 01, 64
//               Nile StreetLondon N1 7SR
//             </p>
//           </div>
//         </div> */}
//       </div>
//     </div>
//   );
// };
// export default Footer;

import React from "react";

import CMlogo from "../assets/images/navbar/legal-tech-logo.svg";

const Footer = ({ scrollToOurservices }) => {
  return (
    <div className="bg-gray-200 dark:bg-sh-dark-500 dark:text-gray-300 md:px-32 py-10 flex max-md:flex-wrap justify-evenly gap-10 font-roboto">
      <div className="flex flex-col gap-2 w-[330px]">
        <div className="flex items-center gap-5">
          <img src={CMlogo} alt="logo" className="w-16" />
          <p className="text-3xl font-tinos">CaseMinister</p>
        </div>
        <div className="flex flex-col font-roboto gap-6">
          <p>
            Suite 01, 64 Nile Street
            <br /> London, N1 7SR
          </p>
          <p className="font-semibold">
            © 2023 Advanced Technology and Research Innovation Group. All rights
            reserved.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h4 className="text-xl font-raleway font-bold">Company</h4>
        <div className="flex flex-col gap-2 font-raleway font-semibold">
          <a href="/">Home</a>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-and-conditions">Terms & Conditions</a>
          <a href="/contactus">Contact Us</a>
          <a href="/faq">FAQs</a>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h4 className="text-xl font-raleway font-bold">Help</h4>
        <div className="flex flex-col gap-2 font-raleway font-semibold">
          <a href="#" onClick={scrollToOurservices}>
            Services
          </a>
          <a href="/contactus">Help and Support</a>
          <a href="/signup">Signup</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
