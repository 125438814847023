import React, { useState } from 'react';
import starIcon from '../assets/images/navbar/star-icon.svg'
import '../assets/css/starrate.css'

const Startrate = () => {
    const [rating, setRating] = useState(null);

    return (
        <>
            <div className='stars mt-2'>
                {[...Array(5)].map((star, index) => {
                    const currentRate = index + 1;
                    return (
                        <label key={index} className='starlabel' >
                            <img
                                src={starIcon}
                                className='starImg'
                                alt="star"
                                onClick={() => setRating(currentRate)}
                            />
                        </label>
                    );
                })}
            </div>
        </>
    );
};

export default Startrate;
